import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ExperimentType } from '../services/labpartner.service.model';

export interface IAddColumnFormGroup extends UntypedFormGroup {
  value: {
    columnName: string;
  };
  controls: {
    batchReason: AbstractControl;
    fieldReasons: { columnName: string }[] & UntypedFormArray;
  };
}

export interface IAddColumnResult {
  columnName?: string;
}

@Component({
  selector: 'add-column-dialog',
  templateUrl: 'add-column-dialog.component.html',
  styleUrls: ['add-column-dialog.component.scss'],
})
export class AddColumnDialogComponent implements OnInit {
  addColumnForm!: IAddColumnFormGroup;

  ExperimentType = ExperimentType;

  constructor(public dialogRef: MatDialogRef<AddColumnDialogComponent>, private _fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.addColumnForm = this._fb.group({
      columnName: ['', Validators.required],
    } as Record<keyof IAddColumnResult, any>) as IAddColumnFormGroup;
  }

  onSubmit(): void {
    this.dialogRef.close(<IAddColumnResult>{
      columnName: this.addColumnForm.value.columnName,
    });
  }

  onCancel(): void {
    this.dialogRef.close(<IAddColumnResult>{
      columnName: undefined,
    });
  }
}
