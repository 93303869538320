import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AssayName, User } from '../services/labpartner.service.model';
import { MatDialog } from '@angular/material/dialog';
import { AssayDialogComponent } from './assay-dialog/assay-dialog.component';
import { LabpartnerService } from '../services/labpartner.service';
import { BaseComponent } from '../support/base.component';
import { UserAccountService } from '../services/user-account.service';
import { NotificationService } from '../shared/notification.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-manage-assays',
    templateUrl: './manage-assays.component.html',
    styleUrls: ['./manage-assays.component.scss']
})
export class ManageAssaysComponent extends BaseComponent implements AfterViewInit, OnInit {
    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    assays: AssayName[] = [];

    dataSource: MatTableDataSource<AssayName> = new MatTableDataSource();

    showArchivedAssays: boolean = false;

    displayedColumns: string[] = [
        'assayId',
        'assayRevision',
        'assayName',
        'assayShortName',
        'analyteName',
        'analyteShortName',
        'chamber',
        'channel',
        'groupId',
        'instrument',
        'actions'
    ]

    currentRoles: string[] = [];
    user: User | undefined = undefined;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private labpartnerService: LabpartnerService,
        private accountService: UserAccountService,
        private notificationService: NotificationService,
    ) {
        super();
        this.loadAssays();
    }

    // After the component is initialized, subscribe to the currentRoles observable
    ngOnInit(): void {
        this.subscription.add(
            this.accountService.currentUser$.subscribe(user => {
                this.user = user;
            })
        );

        this.subscription.add(
            this.accountService.currentRoles$.subscribe(roles => {
                this.currentRoles = roles;
                if (!this.accountService.isAdmin()) {
                    setTimeout(() => {
                        this.router.navigate(['/']);
                    }, 100);
                }
            })
        );
    }

    // After the view is initialized, set the paginator
    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    // Clean up the subscription
    protected ngOnDestroyInternal(): void {

    }

    // Load the assays from the service
    loadAssays() {
        this.subscription.add(
            this.labpartnerService.getAssayNames(true).subscribe(assays => {
                assays.forEach(a => a.analyteTemplates = a.analyteTemplates.filter(an => an.isActive));
                this.assays = assays;
                this.setAssays();
            })
        );
    }

    // Filter the assays based on the showArchivedAssays flag
    setAssays() {
        if (this.showArchivedAssays) {
            this.dataSource.data = this.assays;
        } else {
            this.dataSource.data = this.assays.filter(assay => assay.isActive == true);
        }

        this.dataSource._updateChangeSubscription();
    }

    // Open the dialog to add a new assay
    addAssay() {
        const dialogRef = this.dialog.open(AssayDialogComponent);

        dialogRef.afterClosed().subscribe((dialogResult) => {
            if (!dialogResult) {
                return;
            }

            this.loadAssays();
        });
    }

    // Archive an assay
    archiveAssay(assay: AssayName) {
        if (!assay.id) return;

        this.subscription.add(
            this.labpartnerService.archiveAssayName(assay.id).subscribe(() => {
                this.loadAssays();
            })
        );
    }

    // Unarchive an assay
    unarchiveAssay(assay: AssayName) {
        if (!assay.id) return;

        this.subscription.add(
            this.labpartnerService.unarchiveAssayName(assay.id).subscribe(() => {
                this.loadAssays();
            })
        );
    }
}
