import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-common';
import { User, UserAccess } from './labpartner.service.model';
import { LabpartnerService } from './labpartner.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

type TokenClaims = {
  roles: string[];
};

export const ROLE_NAMES = {
  ADMIN: 'LabPartner.Administrators',
  WRITER: 'LabPartner.Writers',
  READER: 'LabPartner.Readers',
  CLINICALTRIAL: 'ResultsBundler.ClinicalTrials',
  VIEWER: 'LabPartner.Viewers',
};

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  private user!: User;
  private users: User[] = [];
  private roles: string[] = [];

  private currentRoles = new BehaviorSubject<string[]>([]);
  currentRoles$ = this.currentRoles.asObservable();

  private currentUser = new BehaviorSubject<User | undefined>(undefined);
  currentUser$ = this.currentUser.asObservable();

  constructor(private apiService: LabpartnerService) {
    if ((window as any)['Cypress']) {
      (window as any)['UserAccountService'] = this;
    }

    this.clearUser();
  }

  public async SetUserList() {
    const response = await this.apiService.getUsersList().toPromise();

    if (!response) { return; }

    const sortedUsers = response.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    this.users = sortedUsers;
  }

  public clearUser() {
    this.user = {
      userId: 0,
      displayName: '',
      accountId: '',
      email: '',
    };

    this.roles = [];
  }

  public setUser(acct: AccountInfo) {
    this.checkAddUser(acct);
    this.user.accountId = acct.localAccountId;
    this.user.displayName = acct.name!;
    this.user.email = acct.username;

    const claims = acct.idTokenClaims as TokenClaims;
    this.roles = claims.roles;

    this.currentRoles.next(this.roles);
    // console.log( `user accountId = ${this.user.accountId}` );

    //TODO: fire event that this info has changed
    // but these current properties are not going to change in the lifetime of a login
  }

  checkAddUser(acct: AccountInfo) {
    this.apiService.getUserByAccountId(acct.localAccountId).subscribe(
      (result: UserAccess) => {
        this.user = result;
        if (this.user != null) {
          this.apiService.setLoggedInUser(this.user.userId);
          console.log('User found Name:' + this.user.displayName + ' id:' + this.user.userId);
          this.checkUpdateUser(this.user, acct);
        } else {
          console.log('Failed to get user: returned null');
          this.createUser(acct);
        }
        this.currentUser.next(result);
      },
      err => {
        console.log('Failed to get user: ' + err);
        //Create the user
        this.createUser(acct);
      }
    );
  }

  private checkUpdateUser(user: User, acct: AccountInfo) {
    if (user.displayName != acct.name! || user.email != acct.username) {
      user.displayName = acct.name!;
      user.email = acct.username;
      this.apiService
        .updateUser(user)
        .pipe(
          finalize(() => {
            this.currentUser.next(this.user);
          })
        )
        .subscribe(
          (result: User) => {
            this.user = user;
          },
          err => console.log('Error updating user err:' + err)
        );
    }
  }

  private createUser(acct: AccountInfo) {
    let user: User = {
      userId: 0,
      accountId: acct.localAccountId,
      displayName: acct.name!,
      email: acct.username,
    };

    this.apiService
      .createUser(user)
      .pipe(
        finalize(() => {
          this.currentUser.next(this.user);
        })
      )
      .subscribe(
        (result: User) => {
          this.user = result;
          this.apiService.setLoggedInUser(this.user.userId);
        },
        err => console.log('Error creating user err:' + err)
      );
  }

  getUserName(userId: number): string {
    const found = this.users.find(u => u.userId == userId);
    if (found != null) {
      return found.displayName;
    }
    return 'Unknown User';
  }

  public removeAdmin() {
    const roles = this.roles.filter(r => r != ROLE_NAMES.ADMIN);
    this.roles = roles;
    this.currentRoles.next(roles);
  }

  public removeWriter() {
    const roles = this.roles.filter(r => r != ROLE_NAMES.WRITER);
    this.roles = roles;
    this.currentRoles.next(roles);
  }

  public removeClinicalTrial() {
    const roles = this.roles.filter(r => r != ROLE_NAMES.CLINICALTRIAL);
    this.roles = roles;
    this.currentRoles.next(roles);
  }

  public removeReader() {
    const roles = this.roles.filter(r => r != ROLE_NAMES.READER);
    this.roles = roles;
    this.currentRoles.next(roles);
  }

  public removeViewer() {
    const roles = this.roles.filter(r => r != ROLE_NAMES.VIEWER);
    this.roles = roles;
    this.currentRoles.next(roles);
  }

  public async WaitUntilWeHaveUser(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      const timer = setInterval(() => {
        if (this.user != null) {
          clearInterval(timer);
          resolve(true);
        }
      }, 250);
    });

    return promise;
  }

  public getUser(): User {
    return this.user;
  }

  public getUsers(): User[] {
    return this.users;
  }

  public getRoles(): string[] {
    return this.roles;
  }

  public hasValidRole() {
    if (!this.roles) return false;

    for (const role of Object.values(ROLE_NAMES)) {
      if (this.roles.includes(role)) {
        return true;
      }
    }
    return false;
  }

  public isUserInRole(roleName: string): boolean {
    if (!this.roles) {
      return false;
    }
    return this.roles.indexOf(roleName) != -1;
  }

  public isAdmin(): boolean {
    if (!this.roles) {
      return false;
    }
    return this.isUserInRole(ROLE_NAMES.ADMIN);
  }

  public isWriter(): boolean {
    if (!this.roles) {
      return false;
    }
    return this.isUserInRole(ROLE_NAMES.WRITER);
  }

  public isReader(): boolean {
    if (!this.roles) {
      return false;
    }
    return this.isUserInRole(ROLE_NAMES.READER);
  }


  public isViewer(): boolean {
    if (!this.roles) {
      return false;
    }
    return this.isUserInRole(ROLE_NAMES.VIEWER);
  }

  public isClinicalTrial(): boolean {
    if (!this.roles) {
      return false;
    }
    return this.isUserInRole(ROLE_NAMES.CLINICALTRIAL);
  }

  public canEditBundle(userId: number): boolean {
    if (this.user.userId == userId) return true;
    return false;
  }

  public canDeleteBundle(userId: number): boolean {
    if (this.user.userId === userId) return true;
    return false;
  }

  public canEditClinicalTrial(userId: number): boolean {
    return this.isClinicalTrial();
  }
}
