import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';

import { ExperimentService } from './shared/experiment.service';
import { ExperimentListComponent } from './experiments/experiment-list/experiment-list.component';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { ExperimentDetailComponent } from './experiment-detail/experiment-detail.component';
import { SampleListComponent } from './samples/sample-list/sample-list.component';
import { ConditionListComponent } from './conditions/condition-list/condition-list.component';
import { XlsImportComponent } from './xls-import/xls-import.component';
import { CsvFormComponent } from './csv-form/csv-form.component';
import { DetailListComponent } from './detail/detail-list/detail-list.component';
import { BarcodeDisplayComponent } from './barcode-display/barcode-display.component';
import { NoteDisplayComponent } from './note-display/note-display.component';
import { UserDisplayComponent } from './user-display/user-display.component';
import { CellEditDisplayComponent } from './cell-edit-display/cell-edit-display.component';
import { DetailListPrintComponent } from './detail/detail-list-print/detail-list-print.component';
import { LogisticComponent } from './logistics/logistic/logistic.component';
import { LogisticListComponent } from './logistics/logistic-list/logistic-list.component';
import { ErrorHandlerService } from './services/error-handler-service';
import { LoggingService } from './services/logging.service';
import { ScanDisplayComponent } from './scan-display/scan-display.component';
import { BarcodePrintComponent } from './barcode-print/barcode-print.component';
import { DetailListPrintAveryComponent } from './detail/detail-list-print-avery/detail-list-print-avery.component';
import { DetailListPrintBarcodesComponent } from './detail/detail-list-print-barcodes/detail-list-print-barcodes.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { MatAckDialogComponent } from './mat-ack-dialog/mat-ack-dialog.component';
import { NoAccessComponent } from './noaccess/noaccess.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DetailListPrintZebraComponent } from './detail/detail-list-print-zebra/detail-list-print-zebra.component';
import { BundleListComponent } from './bundles/bundle-list/bundle-list.component';
import { BundleComponent } from './bundles/bundle/bundle.component';
import { BundleService } from './shared/bundle.service';
import { SavannaResultsComponent } from './results/savanna-results/savanna-results.component';
import { DetailsResultsComponent } from './results/details-results/details-results.component';
import { ChooseExperimentComponent } from './results/choose-experiment/choose-experiment.component';
import { InputsComponent } from './inputs-list/inputs.component';
import { ExperimentFormatPipe } from './pipes/experiment-format.pipe';
import { ChangesDialogComponent } from './changes-dialog/changes-dialog.component';
import { ExperimentDialogComponent } from './experiments/experiment-dialog/experiment-dialog.component';
import { AuditDialogComponent } from './audit-dialog/audit-dialog.component';
import { ReleaseNotesDialogComponent } from './release-notes-dialog/release-notes-dialog.component';
import { AssayTypeCodeFormatPipe } from './pipes/assayTypeCode-format.pipe';
import { BundleAssayTypeFormatPipe } from './pipes/bundle-assayType-format.pipe';

import { SavannaConfigComponent } from './savanna-config/savanna-config.component';
import { SavannaCreateConfigDialogComponent } from './savanna-config/savanna-create-config-dialog/savanna-create-config-dialog.component';
import { AddColumnDialogComponent } from './add-column-dialog/add-column-dialog.component';
import { SavannaResultsImgSrcPipe } from './pipes/savanna-results-img-src.pipe';

import { HasAnyRolesPipe } from './pipes/has-any-roles.pipe';
import { ExperimentStatusIsPipe } from './pipes/experiment-status-is.pipe';
import { ExperimentStatusIsNotPipe } from './pipes/experiment-status-is-not.pipe';

import { CanEditExperimentPipe } from './pipes/can-edit-experiment.pipe';
import { MobileBarcodesComponent } from './mobile-barcodes/mobile-barcodes.component';
import { BarcodeDialogComponent } from './mobile-barcodes/barcode-dialog/barcode-dialog.component';
import { ColumnFilterComponent } from './column-filter/column-filter.component';
import { ExperimentListForBundleComponent } from './bundles/experiment-list-for-bundle/experiment-list-for-bundle.component';
import { HttpRequestCountInterceptor } from './interceptors/http-request-count.interceptor';
import { LabpartnerService } from './services/labpartner.service';
import { InstrumentErrorDialogComponent } from './instrument-error-dialog/instrument-error-dialog.component';
import { DeviceTargetErrorDialogComponent } from './device-target-error-dialog/device-target-error-dialog.component';
import { DeviceTargetErrorChannelDialogComponent } from './device-target-error-dialog/device-target-error-dialog/device-target-error-dialog.component';
import { DatePipe } from '@angular/common';
import { ResultsSummaryComponent } from './results/results-summary/results-summary.component';
import { LoaderComponent } from './loader/loader.component';
import { ConditionsPrecisionGenerationComponent } from './conditions/conditions-precision-generation/conditions-precision-generation.component';

const permissionPipes = [HasAnyRolesPipe, ExperimentStatusIsPipe, ExperimentStatusIsNotPipe, CanEditExperimentPipe];

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ExperimentDialogComponent,
    ExperimentListComponent,
    MatConfirmDialogComponent,
    ExperimentDetailComponent,
    SampleListComponent,
    ConditionListComponent,
    XlsImportComponent,
    CsvFormComponent,
    DetailListComponent,
    LogisticListComponent,
    MobileBarcodesComponent,
    BarcodeDisplayComponent,
    NoteDisplayComponent,
    UserDisplayComponent,
    CellEditDisplayComponent,
    DetailListPrintComponent,
    LogisticComponent,
    ScanDisplayComponent,
    BarcodePrintComponent,
    DetailListPrintBarcodesComponent,
    DetailListPrintAveryComponent,
    NotesDialogComponent,
    MatAckDialogComponent,
    NoAccessComponent,
    FileUploadComponent,
    DetailListPrintZebraComponent,
    BundleListComponent,
    BundleComponent,
    SavannaResultsComponent,
    ResultsSummaryComponent,
    DetailsResultsComponent,
    ChooseExperimentComponent,
    InputsComponent,
    ExperimentFormatPipe,
    AssayTypeCodeFormatPipe,
    BundleAssayTypeFormatPipe,
    ChangesDialogComponent,
    AuditDialogComponent,
    ReleaseNotesDialogComponent,
    SavannaConfigComponent,
    SavannaCreateConfigDialogComponent,
    AddColumnDialogComponent,
    SavannaResultsImgSrcPipe,
    BarcodeDialogComponent,
    ColumnFilterComponent,
    ...permissionPipes,
    ExperimentListForBundleComponent,
    InstrumentErrorDialogComponent,
    DeviceTargetErrorDialogComponent,
    DeviceTargetErrorChannelDialogComponent,
    ConditionsPrecisionGenerationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    ExperimentService,
    LoggingService,
    MatConfirmDialogComponent,
    BundleService,
    ExperimentFormatPipe,
    AssayTypeCodeFormatPipe,
    CanEditExperimentPipe,
    ChangesDialogComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestCountInterceptor,
      deps: [LabpartnerService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    DatePipe,
    BundleAssayTypeFormatPipe,
    HasAnyRolesPipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
