import { Component, OnDestroy, Inject } from '@angular/core';

import {
  Detail,
  Experiment,
  ExperimentType,
  DeviceTargetError,
} from 'src/app/services/labpartner.service.model';
import { NotificationService } from 'src/app/shared/notification.service';
import { DialogService } from 'src/app/shared/dialog.services';
import { IChangeSet } from 'src/app/changes-dialog/changes-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../support/base.component';
import { ExperimentService } from '../shared/experiment.service';
import { LabpartnerService } from '../services/labpartner.service';
import { DeviceTargetErrorChannelDialogComponent } from './device-target-error-dialog/device-target-error-dialog.component';
import { UserAccountService } from '../services/user-account.service';
import { HasAnyRolesPipe } from '../pipes/has-any-roles.pipe';

export interface DeviceTargetErrorDialogData {
  experiment: Experiment;
  detail: Detail;
  selectedDetails: Detail[];
}

export interface DeviceTargetErrorDialogResult {
  success: boolean;
  data: any;
  updated: boolean;
}

@Component({
  selector: 'app-device-target-error-dialog',
  templateUrl: './device-target-error-dialog.component.html',
  styleUrls: ['./device-target-error-dialog.component.scss'],
})
export class DeviceTargetErrorDialogComponent extends BaseComponent implements OnDestroy {
  isLoading: boolean = false;

  deviceTargetErrorForm!: UntypedFormGroup;

  hasError: boolean = false;

  currentErrors: DeviceTargetError[] = [];

  errorTargets: any[] = [];

  CHAMBERS_NUM = 4;

  CHAMBERS_ARRAY = Array(this.CHAMBERS_NUM).fill(0);

  CHANNELS_NUM = 3;

  CHANNELS_ARRAY = Array(this.CHANNELS_NUM).fill(0);

  deviceName?: string;

  currentRoles: string[] = [];

  currentExperiment: Experiment;

  constructor(
    private _fb: UntypedFormBuilder,
    public experimentService: ExperimentService,
    public dialogRef: MatDialogRef<DeviceTargetErrorDialogComponent>,
    private apiService: LabpartnerService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DeviceTargetErrorDialogData,
    private accountService: UserAccountService,
    private hasAnyRolesPipe: HasAnyRolesPipe
  ) {
    super();
    this.currentExperiment = this.data.experiment;
    this.deviceName = this.experimentService.devices.find(x => x.id == this.currentExperiment?.deviceType)?.value;
    this.createPossibleErrors();
    this.loadData();
  }

  loadData() {
    const errorTextValidator = this.currentExperiment?.type == ExperimentType.ValidationAndVerification ? [Validators.required] : [];
    this.deviceTargetErrorForm = this._fb.group({
      errorText: ["", errorTextValidator]
    });
    this.deviceTargetErrorForm.disable();

    this.isLoading = true;

    this.subscription.add(
      this.accountService.currentRoles$.subscribe(roles => {
        this.currentRoles = roles;
        if (!this.hasAnyRolesPipe.transform(this.currentRoles, ['WRITER', 'READER'], null, 'IsAnyStatus')) {
          this.deviceTargetErrorForm.disable();
        }
      })
    );

    this.subscription.add(
      this.apiService.getDeviceTargetErrorsByExperimentId(this.data.detail.experimentId)
        .subscribe((errors: DeviceTargetError[]) => {
          this.currentErrors = errors;

          this.isLoading = false;
          this.deviceTargetErrorForm.enable();

          let error = this.currentErrors.find(x => x.detailId == this.data.detail.detailId);

          if (error && error.isActive) {
            this.hasError = true;

            error.errorTargets.forEach(e => {
              // Active current error targets
              let errorTarget = this.errorTargets.find(et => et.chamberNum === e.chamberNum && et.channelNum === e.channelNum);
              if (errorTarget) { errorTarget.isActive = true; }
            });

            // Trigger validations
            this.onEntireCartridgeChange();
            this.errorTargets
              .filter(et => et.chamberNum !== null && et.channelNum === null && et.isActive)
              .forEach(c => this.onChamberChange(c.chamberNum, true));

            this.deviceTargetErrorForm.get('errorText')?.setValue(error.errorText);
          }
        },()=>{
          this.isLoading = false;
          this.notificationService.error(':: Error getting Not Evaluable information.');
          this.dialogRef.close();
        })
    )
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  onCancel() {
    let dialogresult = { ok: false, data: null };
    this.onClose(dialogresult);
  }

  async onRemove() {
    this.isLoading = true;
    const changes: IChangeSet[] = [];

    // Find changes
    this.data.selectedDetails.forEach(detail => {
      var error = this.currentErrors.find(e => e.detailId == detail.detailId);
      if (error && error.isActive) {
        changes.push({
          identifier: `${error.id}`,
          field: 'IsActive',
          oldValue: "TRUE",
          newValue: "FALSE",
        });
      }
    });

    if (changes.length < 0) {
      this.isLoading = false;
      return;
    }

    // Confirm dialog
    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changes,
      this.currentExperiment?.type,
      { multiReason: true }
    );

    dialogRef.afterClosed().subscribe(async dialogData => {
      if (
        dialogData?.submitClicked &&
        (dialogData?.reasonProvided || this.currentExperiment?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        // Convert changes to API required model
        const details = changes.map((c, i) => {
          return {
            detailId: this.currentErrors.find(e => e.id + "" == c.identifier)?.detailId,
            fieldReason: dialogData.fieldReasons[i]
          };
        });

        this.apiService.deleteMultipleDeviceTargetErrors({
          experimentId: this.data.detail.experimentId,
          details: details,
          batchReason: dialogData.batchReason
        }).subscribe(() => {
          this.dialogRef.close({
            success: true, data: { value: null }, updated: true
          })
        }, () => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
        this.notificationService.warn(':: Canceled');
      }
    });
  }

  async onSubmit(experiment: Experiment) {
    this.isLoading = true;
    const changes: IChangeSet[] = [];
    const newErrorText = this.deviceTargetErrorForm.get('errorText')?.value;
    const newTargets = this.targetsToString(this.errorTargets.filter(e => e.isActive));
    const newErrors: any[] = [];
    // Find changes
    this.data.selectedDetails.forEach(detail => {
      var error = this.currentErrors.find(e => e.detailId == detail.detailId);
      if (error) {
        const oldTargets = this.targetsToString(error.errorTargets);
        if (newTargets !== oldTargets) {
          changes.push({
            identifier: `${error.id}`,
            field: 'Targets',
            oldValue: oldTargets,
            newValue: newTargets
          });
        }
        if (error.errorText !== newErrorText) {
          changes.push({
            identifier: `${error.id}`,
            field: 'ErrorText',
            oldValue: error.errorText,
            newValue: newErrorText
          });
        }
      } else {
        newErrors.push({
          detailId: detail.detailId
        });
      }
    });

    if (changes.length > 0) {
      const dialogRef = this.dialogService.openConfirmChangesDialog(
        changes,
        this.currentExperiment?.type,
        { multiReason: true }
      );

      dialogRef.afterClosed().subscribe(async dialogData => {
        if (
          dialogData?.submitClicked &&
          (dialogData?.reasonProvided || this.currentExperiment?.type == ExperimentType.ResearchAndDevelopment)
        ) {
          const details = [...newErrors, ...changes.map((c, i) => {
            return {
              type: c.field === 'ErrorText' ? 1 : 2,
              detailId: this.currentErrors.find(e => e.id + "" == c.identifier)?.detailId,
              fieldReason: dialogData.fieldReasons[i]
            };
          })];

          let errorTargets = this.errorTargets.filter(e => e.isActive).map(e => ({ chamberNum: e.chamberNum, channelNum: e.channelNum }));
          let errorText = newErrorText;
          this.apiService.createOrUpdateMultipleDeviceTargetErrors({
            experimentId: this.data.detail.experimentId,
            details: details,
            errorText: errorText,
            errorTargets: errorTargets,
            batchReason: dialogData.batchReason
          }).subscribe(() => {
            this.dialogRef.close({
              success: true, data: { value: { errorText, errorTargets, isActive: true } }, updated: true
            })
          }, () => {
            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
          this.notificationService.warn(':: Canceled');
        }
      });
    } else {

      let errorText = newErrorText;
      let errorTargets = this.errorTargets.filter(e => e.isActive).map(e => ({ chamberNum: e.chamberNum, channelNum: e.channelNum }));
      this.apiService.createOrUpdateMultipleDeviceTargetErrors({
        experimentId: this.data.detail.experimentId,
        details: newErrors,
        errorText: errorText,
        errorTargets: errorTargets,
      }).subscribe(() => {
        this.dialogRef.close({
          success: true, data: { value: { errorText, errorTargets, isActive: true } }, updated: false
        })
      }, () => {
        this.isLoading = false;
      });
    }
  }

  onClose(dialogResult: any) {
    this.dialogRef.close(dialogResult);
  }

  onEntireCartridgeChange() {
    this.errorTargets.forEach(e => {
      if (e.chamberNum !== null || e.channelNum !== null) {
        e.disabled = this.errorTargets[0].isActive;
        if (this.errorTargets[0].isActive) {
          e.isActive = false;
        }
      }
    });
  }

  onChamberChange(chamberNum: number, value: boolean) {
    this.errorTargets
      .filter(x => x.chamberNum === chamberNum && x.channelNum !== null)
      .forEach(x => {
        x.disabled = value;
        x.isActive = false;
      });

    let chambersState = this.errorTargets.filter(x => x.chamberNum != null && x.channelNum == null)
      .reduce((prev, curr) => prev && curr.isActive, true);

    setTimeout(() => {
      if (chambersState) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '30%';
        dialogConfig.data = {
          CHANNELS_NUM: this.CHANNELS_NUM,
          CHAMBERS_NUM: this.CHAMBERS_NUM,
          isEntireCartridgeError: true
        };

        this.dialog.open(
          DeviceTargetErrorChannelDialogComponent,
          dialogConfig
        );

        this.errorTargets.forEach(x => {
          x.isActive = false;
          x.disabled = true;
        })
        this.errorTargets[0].isActive = true;
        this.errorTargets[0].disabled = false;
      }
    }, 0);
  }
  onChannelChange(chamberNum: number) {
    let chamber = this.errorTargets
      .find(x => x.chamberNum === chamberNum && x.channelNum === null);

    let channels = this.errorTargets
      .filter(x => x.chamberNum === chamberNum && x.channelNum !== null);

    let value = channels
      .reduce((prev, curr) => prev && curr.isActive, true);

    setTimeout(() => {
      if (value) {
        channels.forEach(c => { c.isActive = false; c.disabled = true; });
        chamber.isActive = true;

        let chambersState = this.errorTargets
          .filter(x => x.chamberNum != null && x.channelNum == null)
          .reduce((prev, curr) => prev && curr.isActive, true);

        const dialogConfigChannels = new MatDialogConfig();
        dialogConfigChannels.disableClose = false;
        dialogConfigChannels.autoFocus = true;
        dialogConfigChannels.width = '30%';
        dialogConfigChannels.data = {
          CHANNELS_NUM: this.CHANNELS_NUM,
          CHAMBERS_NUM: this.CHAMBERS_NUM,
          isEntireCartridgeError: false
        };


        if (chambersState) {
          this.errorTargets.forEach(x => {
            x.isActive = false;
            x.disabled = true;
          });
          this.errorTargets[0].isActive = true;
          this.errorTargets[0].disabled = false;

          const dialogConfigChambers = new MatDialogConfig();
          dialogConfigChambers.disableClose = false;
          dialogConfigChambers.autoFocus = true;
          dialogConfigChambers.width = '30%';
          dialogConfigChambers.data = {
            CHANNELS_NUM: this.CHANNELS_NUM,
            CHAMBERS_NUM: this.CHAMBERS_NUM,
            isEntireCartridgeError: true
          };

          this.dialog.open(
            DeviceTargetErrorChannelDialogComponent,
            dialogConfigChambers
          );
        }

        this.dialog.open(
          DeviceTargetErrorChannelDialogComponent,
          dialogConfigChannels
        );
      }
    }, 0);
  }

  hasErrorTargets() {
    return !!this.targetsToString(this.errorTargets.filter(e => e.isActive));
  }

  private createPossibleErrors() {
    this.errorTargets.push({
      chamberNum: null,
      channelNum: null,
      isActive: false,
      disabled: false
    })

    this.CHAMBERS_ARRAY.forEach((_, iChamber) => {
      this.errorTargets.push({
        chamberNum: iChamber + 1,
        channelNum: null,
        isActive: false,
        disabled: false
      });
      this.CHANNELS_ARRAY.forEach((_, iChannel) => {
        this.errorTargets.push({
          chamberNum: iChamber + 1,
          channelNum: iChannel + 1,
          isActive: false,
          disabled: false
        })
      });
    });
  }

  private targetsToString(errorTargets: any[]): string {
    return errorTargets.map((e) => {
      let str = "0";

      if (e.chamberNum !== null) {
        str = "C" + e.chamberNum;
        if (e.channelNum !== null) {
          str += "-" + e.channelNum;
        }
      }

      return str;
    }).join(", ");
  }
}
