<div class="dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary" mat-dialog-title> Lab Partner - Release Notes </mat-toolbar>
</div>
<div mat-dialog-content class="dialog-content">

  <div class="release-number">Version 2.19</div>
  <ul>
    <li>Renamed Reader role to Operator role (cannot create/edit experiment structure but can add replicates, take notes, scan IDs, mark unevaluable, etc).
    </li>
    <li>Limit number of Samples and Conditions to 99 per experiment.
    </li>
    <li>Column header in Details view remanis fixed when scroll long lists.
    </li>
    <li>User can adjust replicates per sample + condition combination when generate details.
    </li>
    <li>Notes, Intstrument Error, and Unevaluable functionality now available in Savanna Results (shares the same data with Lab Partner views).
    </li>
    <li>Include result counts per Sample & Condition in the Savanna Results XLSX export.
    </li>
    <li>Savanna Results includes duplicate sampleId records in counts above columns and in XLSX export.
    </li>
    <li>Result counts in the Savanna Results column headers are now specific to the selected assay type.
    </li>
  </ul>

  <div class="release-number">Version 2.15</div>
  <ul>
    <li>Updated Savanna Results XLSX export to match format for BioStats run list.
    </li>
    <li>Support new "Viewer" role for users; allows read-only access to all data.
    </li>
  </ul>

  <div class="release-number">Version 2.13</div>
  <ul>
    <li>Results data added to download XLSX from Results Bundler (experiment-based bundles only).
    </li>
  </ul>

  <div class="release-number">Version 2.11</div>
  <ul>
    <li>Clone experiment to make a copy of the current one (samples, conditions, settings).
    </li>
    <li>Edit column names in sample & conditions setup (when experiment is "Ready To Test").
    </li>
  </ul>

  <div class="release-number">Version 2.10</div>
  <ul>
    <li>Unevaluable counts included in the analyte column headers of Savanna Results and XLSX export.
    </li>
    <li>Instrument error column in Savanna Results and included in XLSX export.
    </li>
    <li>Link to navigate from Savanna Results to the associated Lab Partner details for that experiment.
    </li>
    <li>Results Bundler provides download / export XLSX that includes data from all experiments in the bundle.
    </li>
  </ul>

  <div class="release-number">Version 2.9</div>
  <ul>
    <li>Track instrument errors (serial number & error text) as separate data from unevaluable.
    </li>
    <li>(Savanna specific) Track unevaluable data at the chamber & channel level in addition to whole cartridge/cassette. Included in XLSX export.
    </li>
  </ul>

  <div class="release-number">Version 2.8</div>
  <ul>
    <li>Create a single "live note" for a cartridge/cassette run that can be edited over time.
    </li>
    <li>App navigation among other modules (Lab Partner, Savanna Results, Results Bundler), via hamburger menu.
    </li>
    <li>Savanna Results: option to export invalid analytes only.
    </li>
    <li>Choose which columns to show and in what order, in run sheet (on screen and printed).
    </li>
    <li>Link from Details to view test results in Fusion (requires access to Fusion, currently only available for Savanna instrument results)
    </li>
    <li>Archive experiments to remove them from the main list. Select 'Show archived experiments' to view and un-archive an experiment.
    </li>
  </ul>

  <div class="release-number">Version 2.7</div>
  <ul>
    <li>Change column order, visibility, and width in Savanna-Results. 
      Saves column settings per AMF verison in browser local storage.
    </li>
    <li>Skip user-defined number of rows when printing Avery label sheets of barcodes.
    </li>
    <li>Authentication now requires <b>QuidelOrtho.com accounts</b>.
    </li>
  </ul>

  <div class="release-number">Version 2.6</div>
  <ul>
    <li>Select multiple records in Details view, then apply an operation 
      (scan sample tube or device ID, mark unevaluable, add replicate, add note, print features) to all of 
      the selected items at once. <br/>
      Select items manually, by page, or entire table.
    </li>
    <li>Column level filtering in Experiment List and Details View. <br/>
      Values in a column filter use OR logic; filters across mutliple columns use AND logic.
    </li>
  </ul>
  <div class="release-number">Version 2.5</div>
  <ul>
    <li>Parse and display serial numbers from scanned Sample and Device ID values.</li>
    <li>Detect duplicate serial numbers in Sample and Device ID values and show alert message.</li>
    <li>User defined barcode print option, to specify number of barcodes per row on printed sheet.</li>
    <li>Generate and scan QR code to display barcodes on mobile phone.</li>
  </ul>
  <div class="release-number">Version 2.3</div>
  <ul>
    <li>Users with "Writer" role can make changes to experiments owned by others. (import/edit samples & conditions, ready to test, etc)</li>
    <li>Allow adding columns to samples & conditions after experiment is in ReadyToTest state.</li> 
    <li>Prompt for the number of replicates to generate for newly added samples & conditions. Default is the replicate count set on the experiment.</li>
    <li>Can automatically move to the next row or cell after scanning sample tube or device. Toggle setting from down to right to off. 
      See <a href="assets/scanner-help.html" target="_blank">this page</a> for help on configuring scanner to support this.</li>
  </ul>
  <div class="release-number">Version 2.2</div>
  <ul>
    <li>Tracks data changes into change log (shown in UI and export file). Prompts for reasons for changes.</li>
    <li>View change log records in the application, related to experiment, samples, conditions, details.</li>
    <li>
      Edit and append Samples & Conditions from within the app (no longer have to append via Excel workbook after
      experiment is Ready-To-Test).
    </li>
    <li>Updates Excel import sheets on behalf of user when append or change Samples & Conditions.</li>
    <li>
      Export is now Excel workbook format (XLSX) and contains tabs for each data set, including notes and change logs.
    </li>
    <li>Experiment name at top of app allows edit/view settings, view notes & change logs.</li>
    <li>Operator role (formerly reader role) can now add replicates in details view.</li>
  </ul>
  <div class="release-number">Version 2.1</div>
  <ul>
    <li>Import Samples & Conditions via a single Excel workbook (one worksheet/tab for each in the workbook).</li>
    <li>Saves imported Excel workbook used when creating Samples & Conditions.</li>
    <li>Changed DATA ERROR field to be called UNEVALUABLE.</li>
    <li>
      Can mark Sample or Condition as unevaluable, which makes all details related to that item unevaluable as well.
    </li>
    <li>Mark experiment as R&D or V&V when it's created. (Use this later in the app to enforce requirements).</li>
    <li>Changed barcode format (starts with R or V).</li>
  </ul>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button color="primary" (click)="onClickClose()">Close</button>
</div>
