import { PropertyNamesAsStringOf } from '../shared/types-helper';

export type AuditCountMap = Partial<{ [k in keyof typeof AuditObjectName]: AuditObjectIdCountMap }>
export type AuditObjectIdCountMap = { [key: number]: number };

export type AuditDisplayedColumnKeys = PropertyNamesAsStringOf<IAudit>;
export type AuditDisplayedKeysWithNames = Partial<{ [k in (string & AuditDisplayedColumnKeys)]: string }> & { expand?: '' };

export enum AuditObjectName {
  Bundles = 'Bundles',
  Conditions = 'Conditions',
  Details = 'Details',
  Experiment = 'Experiment',
  ImportData = 'ImportData',
  Logistics = 'Logistics',
  Notes = 'Notes',
  Samples = 'Samples',
  Uploads = 'Uploads',
  Users = 'Users',
}

export enum AuditObjectKey {
  ExperimentId = 'ExperimentId',
  ObjectId = 'ObjectId',
}

export enum AuditSortOrder {
  Ascending = 'ASC',
  Descending = 'DESC'
}

export interface IAudit {
  id?: string;
  batchReason?: string;
  changeDate?: Date;
  changedBy?: number;
  changedByName?: string;
  experimentId: number;
  fieldReason?: string;
  newValue?: string | null;
  objectField: string;
  objectId: number;
  objectName: string;
  prevValue?: string | null;
}

export interface IAuditCount {
  objectId: number;
  totalCount: number;
}

export interface IAuditChangeReasons {
  batchReason: string;
  fieldReasons: { [key: string]: string };
}

export interface ILogisticsAuditChangeReasons {
  batchReason?: string;
  oldName?: string,
  newName?: string,
  nameReason?: string,
  oldValue?: string,
  newValue?: string,
  valueReason?: string
}
