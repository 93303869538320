
    <div [ngSwitch]="dataType" *ngIf="!isEdit">
      <span *ngSwitchDefault>
        {{colValue}}
      </span>
    </div>
    <div [ngSwitch]="dataType" *ngIf="isEdit">
          <mat-form-field *ngSwitchCase="'date'" appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="colValue">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field *ngSwitchDefault>
            <mat-label>{{colName}}</mat-label>
            <input  matInput [(ngModel)]="colValue">
          </mat-form-field>
    </div>

