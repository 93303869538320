<div class="change-dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary">
    {{ !replicatesSelected ?  (adjustReplicates ? 'Adjust' : 'Choose') + ' Replicates to Generate' : data.title }}
  </mat-toolbar>
</div>
<div mat-dialog-content class="replicates-dialog-content" *ngIf="!replicatesSelected">
  <div class="replicates-with-scroll">
    <form [formGroup]="replicatesForm" (submit)="onReplicateSelection()" class="replicates-form">
      <div class="replicates-header">
        <span class="replicates-cell">Sample Label</span>
        <span class="replicates-cell">Condition Label</span>
        <span class="replicates-cell">Number of Replicates</span>
        <span class="replicates-cell">Ignore</span>
        <div class="scroll-header"></div>
      </div>
      <form formArrayName="replicatesFormArray">
        <cdk-virtual-scroll-viewport [itemSize]="30" class="replicate-viewport">
        <div
          class="replicates-dialog-body replicate-item"
          *cdkVirtualFor="let replicatePair of data.dataExt.replicateData; templateCacheSize: 30; trackBy: trackBy; let i = index"
          [formGroup]="replicatesForm.controls.replicatesFormArray.controls[i]" >
          <span
            class="replicates-cell"
            [class.new]="replicatePair.sampleId == 0"
            [class.ignored]="replicatesForm.controls.replicatesFormArray.value[i].ignore">
            {{ replicatePair.sampleLabel }}
          </span>
          <span
            class="replicates-cell"
            [class.new]="replicatePair.conditionId == 0"
            [class.ignored]="replicatesForm.controls.replicatesFormArray.value[i].ignore">
            {{ replicatePair.conditionLabel }}
          </span>
          <span class="replicates-cell">
            <mat-form-field class="replicates-input">
              <input
                matInput
                type="number"
                min="1"
                max="1000"
                step="1"
                class="replicates-input"
                formControlName="numberOfReplicates"
                [class.ignored]="replicatesForm.controls.replicatesFormArray.value[i].ignore" />
            </mat-form-field>
          </span>
          <span class="replicates-cell">
            <p-checkbox
              [binary]="true"
              [formControl]="replicatesForm.controls.replicatesFormArray.controls[i].controls.ignore"></p-checkbox>
          </span>
        </div>
        </cdk-virtual-scroll-viewport>
        <div class="scroll-fix"></div>
      </form>
    </form>
  </div>
</div>
<div mat-dialog-content class="change-dialog-content" *ngIf="replicatesSelected">
  <form [formGroup]="reasonForm" (submit)="onSubmit()" class="reason-form">
    <div class="changes-with-scroll" cdkScrollable>
      <div class="change-dialog-header">
        <span class="change-cell" *ngIf="data.changes[0].identifier"> Identifier </span>
        <span class="change-cell">{{ data.dataExt.isLogistic ? 'Name' : 'Field' }}</span>
        <span class="change-cell">{{ data.dataExt.isLogistic ? 'Value' : 'Old Value' }}</span>
        <span class="change-cell">New Value</span>
        <span class="change-cell" *ngIf="data.dataExt.multiReason">Reason</span>
      </div>
      <form formArrayName="fields">
        <div class="change-dialog-changesets" *ngFor="let change of data.changes; let i = index">
          <span class="change-cell" *ngIf="change.identifier">{{ change.identifier }}</span>
          <span class="change-cell {{ data.dataExt.isDelete ? 'deleted' : '' }}" matTooltip="{{ change.field }}">
            {{ change.field }}
          </span>
          <div class="change-cell">
            <span
            class="change-cell" style="padding: 0 10px 0 0 ;"
            [class.old-value]="data.dataExt.isDelete || !data.dataExt.isLogistic"
            matTooltip="{{ change.oldValue }}"
            matTooltipPosition="above">
            {{ change.oldValue }}
          </span>
          </div>
          <span
            class="change-cell {{ data.dataExt.isDelete || change.data == 'DELETED' ? 'deleted' : 'new-value' }}"
            matTooltip="{{ change.newValue }}"
            matTooltipPosition="above">
            {{ change.newValue }}
          </span>
          <span class="change-cell new-value" *ngIf="data.dataExt.multiReason">
            <div class="change-reason-input" [formGroup]="reasonForm.controls.fields.controls[i]">
              <input pInput type="text" class="change-reason-input" formControlName="fieldReason" />
            </div>
          </span>
        </div>
      </form>
    </div>
    <mat-form-field appearance="fill" class="reason-form-field">
      <mat-label>{{ data.title }}</mat-label>
      <textarea matInput cdkFocusInitial class="reason-textarea" formControlName="batchReason"></textarea>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="change-dialog-actions">
  <button mat-raised-button (click)="!replicatesSelected && data.dataExt.isAppend ? onReplicateCancel() : onCancel()">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="!replicatesSelected && data.dataExt.isAppend ? onReplicateSelection() : onSubmit()"
    [disabled]="
      ((!replicatesSelected && data.dataExt.isAppend ? !replicatesForm.valid : !reasonForm.valid) &&
      data.experimentType == ExperimentType.ValidationAndVerification)
      ||
      (appendMode && !validateReplicates())
    ">
    Submit
  </button>
</div>
