import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExperimentType } from '../services/labpartner.service.model';

export interface IConfirmChangesExtendedData {
  isDelete?: boolean;
  multiReason?: boolean;
  isLogistic?: boolean;
  isAppend?: boolean;
  defaultReplicates?: number;
  replicateData?: IReplicatePair[];
  skipReasonForChange?: boolean;
  adjustReplicates?: boolean;
}

export interface IConfirmChangesDialogData {
  title: string;
  changes: IChangeSet[];
  experimentType: ExperimentType;
  dataExt: IConfirmChangesExtendedData;
}

export interface IChangeSet {
  identifier: string;
  field: string;
  oldValue: string | boolean;
  newValue: string | boolean;
  data?: string;
}

export interface IReplicatePair {
  id?: string;
  sampleId?: number;
  sampleLabel?: string;
  conditionId?: number;
  conditionLabel?: string;
  numberOfReplicates?: number;
  ignore?: boolean;
}

export interface IChangeDialogResult {
  submitClicked: boolean;
  reasonProvided: boolean;
  batchReason?: string;
  fieldReasons: string[];
  numberOfReplicates?: number[];
}

export interface IReplicateForm {
  numberOfReplicates: FormControl<number>;
  ignore: FormControl<boolean>;
}

export interface IBatchReplicatesAndReasonForm {
  fieldReplicates: FormControl<number>;
  fieldReason: FormControl<string>;
}

export interface IFieldReplicatesForm {
  fieldReplicates: FormControl<number>;
}

export interface IChangeReasonForm {
  batchReason: FormControl<string>;
  fields: FormArray<FormGroup<IBatchReplicatesAndReasonForm>>;
  fieldReplicates: FormArray<FormGroup<IFieldReplicatesForm>>;
}

@Component({
  selector: 'changes-dialog',
  templateUrl: 'changes-dialog.component.html',
  styleUrls: ['changes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ChangesDialogComponent implements OnInit {
  replicatesForm!: FormGroup<{
    replicatesFormArray: FormArray<FormGroup<IReplicateForm>>;
  }>;
  reasonForm!: FormGroup<IChangeReasonForm>;

  ExperimentType = ExperimentType;

  replicatesSelected?: boolean = false;

  appendMode: boolean | undefined = false;

  skipReasonForChange?: boolean = false;

  adjustReplicates?: boolean = false;

  groupIndexes: any[] = [];

  private MAX_REPLICATES = 1000;

  constructor(
    public dialogRef: MatDialogRef<ChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmChangesDialogData,
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.adjustReplicates = this.data.dataExt.adjustReplicates;
    this.skipReasonForChange = this.data.dataExt.skipReasonForChange;
    this.appendMode = this.data.dataExt.isAppend && (this.data.dataExt.replicateData?.length ?? 0) > 0;
    this.replicatesSelected = !this.appendMode;

    if (this.appendMode) {
      let samplesDic: any = {};
      let conditionsDic: any = {};
      this.data.dataExt.replicateData?.forEach(r => {
        r.id = r.sampleId + '_' + r.conditionId;

        if (r.sampleId && !samplesDic[r.sampleId]) {
          samplesDic[r.sampleId] = 1;
        }

        if (r.conditionId && !conditionsDic[r.conditionId]) {
          conditionsDic[r.conditionId] = 1;
        }
      });

      const samplesIds = Object.keys(samplesDic) as any;
      const conditionsIds = Object.keys(conditionsDic) as any;

      for (let sampleId of samplesIds) {
        let group: number[] = [];
        this.data.dataExt.replicateData?.forEach((replicate, index: number) => {
          if (replicate.sampleId == sampleId) {
            group.push(index);
          }
        });
        this.groupIndexes.push(group);
      }

      for (let conditionId of conditionsIds) {
        let group: number[] = [];
        this.data.dataExt.replicateData?.forEach((condition, index) => {
          if (condition.conditionId == conditionId) {
            group.push(index);
          }
        });
        this.groupIndexes.push(group);
      }

      this.replicatesForm = this._fb.nonNullable.group({
        replicatesFormArray: this._fb.nonNullable.array<FormGroup<IReplicateForm>>([]),
      });

      this.data.dataExt?.replicateData?.forEach(value => {
        this.replicatesForm?.controls.replicatesFormArray.push(
          this._fb.group<IReplicateForm>({
            numberOfReplicates: this._fb.nonNullable.control(value.numberOfReplicates ?? 0, {
              validators: [Validators.required, Validators.min(1), Validators.max(this.MAX_REPLICATES)],
            }),
            ignore: value.numberOfReplicates == 0 ? this._fb.nonNullable.control(true) : this._fb.nonNullable.control(false),
          })
        );
      });
    }

    this.reasonForm = this._fb.group<IChangeReasonForm>({
      batchReason: this._fb.nonNullable.control(''),
      fields: this._fb.nonNullable.array<FormGroup<IBatchReplicatesAndReasonForm>>([]),
      fieldReplicates: this._fb.nonNullable.array<FormGroup<IFieldReplicatesForm>>([]),
    });

    this.reasonForm.validator = this.data.dataExt.multiReason ? this.oneOrBothValidator : this.batchReasonValidator;

    this.data.changes.forEach(_ =>
      this.reasonForm.controls.fields.push(
        this._fb.group<IBatchReplicatesAndReasonForm>({
          fieldReason: this._fb.nonNullable.control(''),
          fieldReplicates: this._fb.nonNullable.control(this.data.dataExt.defaultReplicates ?? 0),
        })
      )
    );
  }

  onReplicateSelection() {
    if (this.skipReasonForChange && this.data.dataExt.replicateData) {
      for (let i = 0; i < this.data.dataExt.replicateData.length; i++) {
        let replicate = this.data.dataExt.replicateData[i];
        replicate.ignore = this.replicatesForm.controls.replicatesFormArray.value[i].ignore;
        replicate.numberOfReplicates = this.replicatesForm.controls.replicatesFormArray.value[i].numberOfReplicates;
      }

      this.dialogRef.close({
        submitClicked: true,
        replicatePairs: this.data.dataExt.replicateData
      });
      return;
    }

    this.replicatesSelected = true;
    if (this.data.dataExt.replicateData) {
      for (let i = 0; i < this.data.dataExt.replicateData.length; i++) {
        this.data.dataExt.replicateData[i].ignore = this.replicatesForm.getRawValue().replicatesFormArray[i].ignore;
        this.data.dataExt.replicateData[i].numberOfReplicates =
          this.replicatesForm.getRawValue().replicatesFormArray[i].numberOfReplicates;
      }
    }
  }

  onSubmit(): void {
    this.dialogRef.close(this.parseDialogCloseResult(true));
  }

  onReplicateCancel() {
    this.dialogRef.close(this.parseDialogCloseResult(false));
  }

  onCancel(): void {
    if (!this.data.dataExt.isAppend || !this.data.dataExt.replicateData?.length) {
      this.dialogRef.close(this.parseDialogCloseResult(false));
    } else {
      this.replicatesSelected = false;
    }
  }

  parseDialogCloseResult(submitClicked: boolean): IChangeDialogResult {
    return {
      submitClicked: submitClicked,
      reasonProvided:
        !!this.reasonForm.value.batchReason?.trim() ||
        !!this.reasonForm.getRawValue().fields.every(fr => !!fr.fieldReason.trim()),
      batchReason: this.reasonForm.value.batchReason,
      fieldReasons: this.reasonForm.getRawValue().fields.map(fr => fr.fieldReason),
      numberOfReplicates: this.reasonForm.getRawValue().fieldReplicates?.map(fr => fr.fieldReplicates),
    };
  }

  oneOrBothValidator(control: AbstractControl): ValidationErrors | null {
    const currentControl = control as FormGroup<IChangeReasonForm>;
    const batchReason = !!currentControl.value.batchReason?.trim();
    const fieldReasonsComplete = currentControl.value.fields?.every(fr => !!fr.fieldReason?.trim());

    return batchReason || fieldReasonsComplete
      ? null
      : { required: 'Either batch reason or individual reasons must be completed.' };
  }

  batchReasonValidator(control: AbstractControl): ValidationErrors | null {
    const currentControl = control as FormGroup<IChangeReasonForm>;
    const batchReason = !!currentControl.value.batchReason?.trim();

    return batchReason ? null : { required: 'Batch reason must be completed.' };
  }

  validateReplicates() {
    if (!this.data.dataExt.replicateData)
      return true;

    let isValid = true;
    let hasReplicates = true;

    // Validate replicates values 1 to 1000
    for (let replicates of this.replicatesForm.controls.replicatesFormArray.controls) {
      if (!replicates.controls.ignore.value &&
        (replicates.controls.numberOfReplicates.value <= 0 || replicates.controls.numberOfReplicates.value > this.MAX_REPLICATES)
      ) {
        isValid = false;
      }
    }
    // Validate that each sample and each conditions has at least one replicate
    for (let groupIndex of this.groupIndexes) {
      let groupValue = groupIndex.reduce((prev: boolean, curr: number) => {
        let controls = this.replicatesForm.controls.replicatesFormArray.controls[curr].controls;
        if (!controls.ignore.value &&
          (controls.numberOfReplicates.value > 0 && controls.numberOfReplicates.value <= this.MAX_REPLICATES)
        ) {
          return prev || true;
        }
        else {
          return prev || false;
        }
      }, false)

      if (!groupValue) {
        hasReplicates = false;
      }
    }

    return isValid && hasReplicates;
  }

  trackBy(index: any, item: any) {
    return item.id;
  }
}
