<div class="no-print">
  Instructions:<br />
  For printing Avery sheets on stock #94102 paper, right click on this page and select print.<br />
  At the options page, please set Layout to "Portrait". <br />
  Expand the more settings and then change Margins to none.<br />
  Then select the print using system dialog at the bottom of the screen.<br />
  In the print dialog, select prefernces and click the arrow to select "Other Type" or "Select by Tray".<br />
  If you select "Other Type" then select labels. If you select a tray then load the labels into that tray.<br />
  Rows to skip:
  <p-dropdown
    [options]="skipRowOptions"
    [(ngModel)]="selectedSkipRowOption"
    (onChange)="onSkipRowChange($event)"></p-dropdown>
</div>
<div class="page-container" style="display: flex; flex-wrap: wrap; width: 215mm; max-width: 215mm"  [style.visibility]="isLoading? 'hidden': 'visible'">
  <ng-container *ngFor="let skipRow of skipRows; let itemIndex = index">
    <div class="averyheader" *ngIf="itemIndex == 0"></div>
    <div class="averybarcode" style="width: 100%; height: 25.5mm"></div>
  </ng-container>
  <ng-container *ngFor="let barcode of barcodes; let itemIndex = index">
    <div 
      class="averyheader"
      *ngIf="isFirstOnPage(itemIndex + 1) && (skipRows.length == 0 || (skipRows.length > 0 && itemIndex != 0))"></div>
    <div class="averyspacer"></div>
    <div class="averybarcode" margin-bottom="5mm">
      <app-barcode-print [barcodeData]="barcode" (onLoaded)="onBarcodeLoaded($event)"></app-barcode-print>
    </div>
    <div class="averyrowspacer" *ngIf="isLastInRow(itemIndex + 1) && !isLastOnPage(itemIndex + 1)"></div>
    <div class="averyfooter" *ngIf="isLastOnPage(itemIndex + 1)"></div>
    <div class="pagebreak" *ngIf="isLastOnPage(itemIndex + 1)"></div>
  </ng-container>
</div>
