<div style="display: inline-flex; flex-wrap: wrap; width: 112mm ">

    <ng-container *ngFor="let barcode of barcodes; let itemIndex = index">

        <div class="zebrapageheader"  *ngIf="this.isFirstOnPage(itemIndex+1)"> </div> 
        <div class="zebraleftpagemargin" *ngIf="this.isFirstInRow(itemIndex+1)"></div>

        <div class="zebrabarcode">
            <app-barcode-print  [barcodeData]="barcode" (onLoaded)="onBarcodeLoaded($event)"></app-barcode-print>
        </div>

        <div class="zebraspacerbarcoderight" *ngIf="!this.isLastInRow(itemIndex+1)"></div>

    </ng-container>

</div>