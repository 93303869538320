import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { SavannaAssayRevisionConfig, SavannaConfigResults } from './labpartner.service.model';

@Injectable({
  providedIn: 'root',
})
export class SavannaConfigService {
  private apiUrlBase: string;
  private v1SavannaConfigBaseUrl = 'v1/SavannaAssayConfig';

  configUpdated$: EventEmitter<boolean> = new EventEmitter<boolean>();

  private pendingChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient, private configService: ConfigurationService) {
    const config = this.configService.getConfig();

    this.apiUrlBase = config.apiServiceEndpoint;
    if (!this.apiUrlBase.endsWith('/')) {
      this.apiUrlBase = `${this.apiUrlBase}/`;
    }
  }

  hasPendingChanges() {
    return this.pendingChanges$.getValue();
  }

  setPendingChanges(value: boolean) {
    this.pendingChanges$.next(value);
  }

  createSavannaConfiguration(
    assayName: string,
    assayId: number,
    assayRevision?: number
  ): Observable<SavannaConfigResults> {
    const url = `${this.apiUrlBase}${this.v1SavannaConfigBaseUrl}/CreateConfiguration`;
    return this.httpClient.post<SavannaConfigResults>(url, {
      assayName,
      assayId,
      assayRevision,
    });
  }

  getSavannaConfigs(): Observable<SavannaConfigResults> {
    const url = `${this.apiUrlBase}${this.v1SavannaConfigBaseUrl}/GetAllConfigurations`;
    return this.httpClient.get<SavannaConfigResults>(url);
  }

  updateAssayConfigurationName(assayNameId: number, assayName: string): Observable<string> {
    const url = `${this.apiUrlBase}${this.v1SavannaConfigBaseUrl}/UpdateAssayConfigurationName`;
    return this.httpClient.post<string>(url, {
      assayNameId,
      assayName,
    });
  }

  updateConfigurations(
    assayId: number,
    assayRevision: number,
    assayNameId: number,
    modifiedConfigs: SavannaAssayRevisionConfig[],
    deletedConfigIds?: number[]
  ): Observable<SavannaConfigResults> {
    const url = `${this.apiUrlBase}${this.v1SavannaConfigBaseUrl}/UpdateConfigurations`;

    return this.httpClient.post<SavannaConfigResults>(url, {
      assayId: assayId,
      assayRevision: assayRevision == -1 ? null : assayRevision,
      assayNameId: assayNameId,
      modifiedAssayConfigs: modifiedConfigs,
      deletedAssayConfigIds: deletedConfigIds ?? [],
    });
  }

  deleteConfiguration(assayId: number, assayRevision: number, assayNameId: number): Observable<SavannaConfigResults> {
    const url = `${this.apiUrlBase}${this.v1SavannaConfigBaseUrl}/DeleteConfiguration`;

    return this.httpClient.delete<SavannaConfigResults>(url, {
      body: {
        assayId: assayId,
        assayRevision: assayRevision == -1 ? null : assayRevision,
        assayNameId: assayNameId,
      },
    });
  }
}
