import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class LoggingService {
    private appInsights: ApplicationInsights;

    constructor(private configService: ConfigurationService) {
        const config = this.configService.getConfig();
        const insightsKey = config.appInsightsKey;

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: insightsKey,
                enableAutoRouteTracking: false // option to log all route changes
            }
        });
        this.appInsights.loadAppInsights();
    }

    setUserId(userId: number) {  
        this.appInsights.setAuthenticatedUserContext(userId.toString());  
    }  

    clearUserId() {  
        this.appInsights.clearAuthenticatedUserContext();  
    }  

    logPageView(name?: string, url?: string) { // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}