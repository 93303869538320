
<div class="container">
  <p-blockUI [blocked]="isCurtainBlocked"></p-blockUI>

  <mat-toolbar id="dialog-titlebar" mat-dialog-title color="primary">
    <span>External Controls Experiment</span>
  </mat-toolbar>

  <form
      #theForm="ngForm"
      [formGroup]="bundleService.form"
      class="normal-form">
      <div class="bundle-controls-inputs">

        <div class="bundle-controls-column top-right-half">
          <mat-table [dataSource]="bundleData" matSort #bundleExperimentsSort="matSort" matSortActive="dateCreated" matSortDirection="desc">
            <ng-container matColumnDef="experimentId">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Exp ID</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.experimentId | experimentFormat : element.type }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="protocolNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Protocol</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.protocolNumber }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="studyType">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Study Type</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.studyType != null">
                  {{ getStudyTypeName(element.studyType) }}
                </ng-container>
                <ng-container  *ngIf="element.studyType == null">
                </ng-container>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="assayName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Assay Name</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.assayNameId == null">
                  {{ element.assayName }}
                </ng-container>
                <ng-container *ngIf="element.assayNameId != null">
                  {{ getAssayName(element.assayNameId) }}
                </ng-container>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actionsRemove">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let row">
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="loading">
              <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="noData">
              <mat-footer-cell *matFooterCellDef colspan="6"> Add one experiment from the list below... </mat-footer-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="selectedDisplayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: selectedDisplayedColumns"></mat-row>
            <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: bundleData != null }"></mat-footer-row>
            <mat-footer-row
              *matFooterRowDef="['noData']"
              [ngClass]="{ hide: !(bundleData != null && bundleData.data.length == 0) }"></mat-footer-row>
          </mat-table>
        </div>
      </div>
    </form>

  <div class="bundle-controls-inputs">
    <div class="bundle-controls-column bottom-half">
      <div class="search-div bottom-half">
        <span class="p-input-icon-right">
          <input
            type="text"
            pInputText
            placeholder="Search Experiments"
            autocomplete="off"
            [(ngModel)]="searchKey"
            (ngModelChange)="onChange($event)" />
          <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
            <i class="pi pi-times" *ngIf="searchKey"></i>
          </span>
        </span>
      </div>

      <div class="mat-elevation-z8">
        <mat-table [dataSource]="experimentsData" matSort #allExperimentsSort="matSort" matSortActive="dateCreated"
          matSortDirection="desc" id="experiments-table" mat-row-no-shrink>
          <ng-container matColumnDef="actionsAdd">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button *ngIf="!showAddButton(row)" (click)="onAdd(row)" matTooltip="Add Experiment">
                <mat-icon>add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="experimentId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Exp ID</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.experimentId | experimentFormat : element.type }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="protocolNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Protocol</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.protocolNumber }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="studyType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Study Type</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.studyType != null">
                {{ getStudyTypeName(element.studyType) }}
              </ng-container>
              <ng-container  *ngIf="element.studyType == null">
              </ng-container>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="assayName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assay Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.assayNameId == null">
                {{ element.assayName }}
              </ng-container>
              <ng-container *ngIf="element.assayNameId != null">
                {{ getAssayName(element.assayNameId) }}
              </ng-container>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="createdByName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.createdByName }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="device">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Device</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.deviceName  }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="dateCreated">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.dateCreated | date : 'shortDate' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef colspan="6"> No data. </mat-footer-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" class="mat-row-no-shrink"></mat-row>
          <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: experimentsData != null }"></mat-footer-row>
          <mat-footer-row
            *matFooterRowDef="['noData']"
            [ngClass]="{ hide: !(experimentsData != null && experimentsData.data.length == 0) }"></mat-footer-row>
        </mat-table>
        <mat-paginator #paginatorAllExperiments [pageSizeOptions]="[5]" [pageSize]="5" showFirstLastButtons></mat-paginator>
      </div>

      <div class="bundle-action-buttons" mat-dialog-actions>
        <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSubmit(bundleService.form.value)" [disabled]="isFormDisabled()">Ok</button>
      </div>
    </div>
  </div>
</div>
