<div class="dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary" mat-dialog-title> Error </mat-toolbar>
</div>

<div mat-dialog-content class="dialog-content">
  <span class="content-span full-width">{{ data.message }}</span>
</div>

<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button color="primary" id="ok-button" [mat-dialog-close]="true">Ok</button>
</div>
