import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-barcode-display',
  templateUrl: './barcode-display.component.html',
  styleUrls: ['./barcode-display.component.scss']
})
export class BarcodeDisplayComponent implements OnChanges {
  @Input() barcodeData!: string;
  public barcodeID: string = "barcode";

  constructor( public configService : ConfigurationService) { 
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.barcodeID = this.barcodeData;
  }

  ngOnInit(): void {
  }

}
