import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DetailGroupedBySampleAndCondition } from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';

@Component({
    selector: 'app-results-summary',
    templateUrl: './results-summary.component.html',
    styleUrls: ['./results-summary.component.scss']
})
export class ResultsSummaryComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() experimentResults?: { data: DetailGroupedBySampleAndCondition[], dynamicColumns: string[] };

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    dataSource: MatTableDataSource<any>

    defaultColumns = [
        "sample",
        "condition"
    ];

    displayedColumns: string[] = [
        ...this.defaultColumns
    ];

    constructor(
    ) {
        super();
        this.dataSource = new MatTableDataSource([] as any);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.experimentResults) return;

        this.displayedColumns = [...this.defaultColumns, ...this.experimentResults.dynamicColumns];

        this.dataSource.data = this.experimentResults.data;
        this.dataSource._updateChangeSubscription();
    }

    ngOnInit(): void { }

    protected ngOnDestroyInternal(): void {
        // required by base component. clean up any component specific resources
    }
}
