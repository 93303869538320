import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';

export interface IBarcodeDialogData {
  experimentId: number;
  identifier: string;
  expiry: Date;
}

@Component({
  selector: 'app-barcode-dialog',
  templateUrl: './barcode-dialog.component.html',
  styleUrls: ['./barcode-dialog.component.scss'],
})
export class BarcodeDialogComponent {
  apiServiceEndpoint?: string;
  barcodeImageLink?: string;
  barcodeRouterLink?: string;

  constructor(
    private configService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: IBarcodeDialogData,
    public dialogRef: MatDialogRef<BarcodeDialogComponent>
  ) {}

  ngOnInit() {
    this.apiServiceEndpoint = this.configService.getConfig().apiServiceEndpoint;
    const barcodeLink = `mobile-barcodes/${this.data.experimentId}/${this.data.identifier}`;
    this.barcodeRouterLink = `/${barcodeLink}`;
    this.barcodeImageLink = `${this.apiServiceEndpoint}v1/Barcode/GetMobileBarcodeQrImage?linkTarget=${this.apiServiceEndpoint}${barcodeLink}&pixelSize=6`;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
