<p-galleria
  #galleria
  [numVisible]="1"
  [value]="images"
  [(activeIndex)]="activeIndex"
  [containerClass]="fullscreen ? 'custom-galleria fullscreen' : 'custom-galleria'"
  [showThumbnails]="false"
  [showThumbnailNavigators]="false"
  [circular]="true"
  [autoPlay]="false"
  *ngIf="!linkError">
  <ng-template pTemplate="header" let-item>
    {{ images[activeIndex].header }}
  </ng-template>
  <ng-template pTemplate="item" let-item>
    <div class="custom-galleria-footer">
      <div class="footer-top">
        <button mat-icon-button (click)="navBackward($event)">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="thumbnails">
          <span class="title">{{ images[activeIndex].title }}</span>
        </div>
        <button mat-icon-button (click)="navForward($event)">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
      <div class="footer-bottom">
        <span class="title-container">
          <span>{{ activeIndex + 1 }}/{{ images.length }}</span>
        </span>
        <button mat-button type="button" (click)="toggleFullScreen()" class="fullscreen-button">
          <mat-icon>{{ fullscreen ? 'close_fullscreen' : 'open_in_full' }}</mat-icon>
        </button>
      </div>
      <div class="info-msg">Increase brightness on your phone for better scanning</div>
    </div>
    <div
      class="barcode-image"
      (touchstart)="onTouchStart($event)"
      (touchmove)="onTouchMove($event)"
      (touchend)="onTouchEnd($event)">
      <img
        referrerpolicy="unsafe-url"
        [src]="item.previewImageSrc"
        [ngStyle]="{ width: !fullscreen ? '100%' : '', display: !fullscreen ? 'block' : '' }" />
    </div>
  </ng-template>
  <ng-template pTemplate="footer" let-item> </ng-template>
</p-galleria>
<div *ngIf="linkError">Invalid link or link has expired. Please return to LabPartner and regenerate.</div>
<div *ngIf="noBarcodesError">
  No barcodes to display for the provided link. Please return to LabPartner and regenerate.
</div>
