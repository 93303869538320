import { Injectable, OnDestroy } from '@angular/core';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { BaseComponent } from 'src/app/support/base.component';
import { Detail } from 'src/app/services/labpartner.service.model';
import { LoadBarcodes } from './LoadBarcodes';
import { NotificationService } from 'src/app/shared/notification.service';

@Injectable()
export class BaseDetailListPrintFormatsComponent extends LoadBarcodes implements OnDestroy {
  // override these in inherited class
  protected LABELS_PER_PAGE: number = 80;
  protected LABELS_PER_ROW: number = 8;
  protected LABELS_ON_FIRST_PAGE: number = 80;

  constructor(
    protected apiService: LabpartnerService,
    protected route: ActivatedRoute,
    protected loggingService: LoggingService,
    public notificationService: NotificationService
  ) {
    super(notificationService);
  }

  protected OnInit(pageNameForLogging: string) {
    var param = this.route.snapshot.queryParamMap.get('barcodes');
    if (param != undefined) {
      this.isLoading = true;
      this.barcodes = param.split('|');
    } else {
      var experimentIdParam = this.route.snapshot.queryParamMap.get('experimentId');
      if (experimentIdParam != undefined) {
        var experimentId = parseInt(experimentIdParam);
        const detailsSub = this.apiService
          .getDetailsList('experimentId', experimentId)
          .subscribe((details: Detail[]) => {
            this.apiService.detailsDefaultSort(details);
            details.forEach(element => {
              var barcode = element.readableBarcode;
              this.barcodes.push(barcode);
            });
            this.isLoading = true;
            detailsSub.unsubscribe();
          });
      }
    }
    this.loggingService.logPageView(pageNameForLogging);
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  public isFirstOnPage(itemIndex: number): boolean {
    // If printing avery and we've skipped rows,we reduce itemIndex by that for offset
    let idx = this.LABELS_ON_FIRST_PAGE < this.LABELS_PER_PAGE ? itemIndex - this.LABELS_ON_FIRST_PAGE : itemIndex;
    if (idx % this.LABELS_PER_PAGE == 1) {
      return true;
    }
    return false;
  }

  public isFirstInRow(itemIndex: number): boolean {
    if (itemIndex % this.LABELS_PER_ROW == 1) {
      return true;
    }
    return false;
  }

  public isLastInRow(itemIndex: number): boolean {
    if (itemIndex % this.LABELS_PER_ROW == 0) {
      return true;
    }
    return false;
  }

  public isLastOnPage(itemIndex: number): boolean {
    let idx = this.LABELS_ON_FIRST_PAGE < this.LABELS_PER_PAGE ? itemIndex - this.LABELS_ON_FIRST_PAGE : itemIndex;
    if (idx % this.LABELS_PER_PAGE == 0) {
      return true;
    }
    return false;
  }
}
