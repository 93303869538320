<div class="search-div">
  <span class="p-input-icon-right">
    <input
      type="text"
      pInputText
      placeholder="Search"
      autocomplete="off"
      [(ngModel)]="searchKey"
      (ngModelChange)="onChange($event)" />
    <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
      <i class="pi pi-times" *ngIf="searchKey"></i>
    </span>
  </span>
  <button mat-raised-button aria-label="Cancel" (click)="endEditMode()" *ngIf="isEditMode && !postEditLoading">
    <mat-icon>cancel</mat-icon>Cancel
  </button>
  <button
    id="inline-save-btn"
    mat-raised-button
    [attr.aria-label]="isEditMode ? 'Save' : 'Edit Data'"
    (click)="isEditMode ? onConditionsEditCommit() : onConditionsEdit()"
    [disabled]="(apiService.isLoading$ | async) || !(
      (currentExperiment?.studyType == StudyType.ImportExcel && (currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsReadyToTest'))
      || (currentExperiment?.studyType != StudyType.ImportExcel && (currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsAnyStatus'))
    )"
    *ngIf="((currentExperiment | statusIs : ['ReadyToTest']) && listData) || currentExperiment?.studyType != StudyType.ImportExcel">
    <ng-container *ngIf="!isEditMode"><mat-icon>edit</mat-icon>Edit Data</ng-container>
    <ng-container *ngIf="isEditMode"><mat-icon>save</mat-icon>Save</ng-container>
  </button>
  <span class="notification-msg-box" *ngIf="isEditMode && !postEditLoading">
    ** Currently in Edit mode, don't forget to Save! **
  </span>

  <button
    id="inline-save-btn"
    mat-raised-button
    [attr.aria-label]="'Adjust Conditions'"
    (click)="openPrecisionConfig()"
    [disabled]="(apiService.isLoading$ | async) ||  (currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsReadyToTest')"
    *ngIf="((currentExperiment | statusIs : ['Created']) || (currentExperiment | statusIs : ['DetailsGenerated'])) && currentExperiment?.studyType == StudyType.Precision && isEditMode">
    <span>Adjust Conditions</span>
  </button>

  <div class="progress-bar-container" *ngIf="apiService.isLoading$ | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="separator"></div>

  <div *ngIf="currentExperiment?.studyType != StudyType.ImportExcel">
    <div class="header-actions">
      <mat-icon
        *ngIf="!(apiService.isLoading$ | async)"
        class="audit-count-icon"
        [class.disabled]="isEditMode"
        matTooltip="{{ conditionsAuditCountMap && conditionsAuditCountMap[0] }} change log record(s)"
        matBadgeColor="warn"
        (click)="!isEditMode && showColumnAuditDialog()">
        receipt_long
      </mat-icon>
    </div>
  </div>
</div>

<div class="table-container mat-elevation-z1" [ngClass]="{'edit-mode' : isEditMode}">
  <!-- Import excel and legacy conditions table view  -->
  <mat-table [dataSource]="listData" matSort  *ngIf="currentExperiment?.studyType == null || currentExperiment?.studyType == StudyType.ImportExcel">
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Label</mat-header-cell>
      <mat-cell *matCellDef="let element; let rowIndex = index">
        <ng-container *ngIf="element.id > 0">{{ element.label }}</ng-container>
        <ng-container *ngIf="element.id <= 0"
          >C{{ (+newestConditionLabel + (rowIndex - newestRowIndex)).toString().padStart(2, '0') }}</ng-container
        >
      </mat-cell>
    </ng-container>

    <!-- this is a repeating container for dynamic columns, repeat for all in propertyNames, because of the *ngFor -->
    <ng-container *ngFor="let disCol of propertyNames; let colIndex = index" matColumnDef="data.{{ disCol }}">
      <mat-header-cell *matHeaderCellDef [ngClass]="{ 'cell-edit-mode': isEditMode }">
        <div class="header-container">
          <ng-container *ngIf="(!isEditMode || unsavedColumns.includes(disCol)) && !(apiService.isLoading$ | async)">{{ disCol }}</ng-container>
          <div *ngIf="isEditMode && !(apiService.isLoading$ | async) && !unsavedColumns.includes(disCol)">
            <div
              class="current-value"
              [matTooltip]="disCol"
              matTooltipPositionAtOrigin="true"
              matTooltipPosition="above"
              [ngClass]="{ modified: disCol != newPropertyNames[disCol] }">
              {{ disCol }}
            </div>
            <div
              class="new-value"
              [ngClass]="{
                empty: !newPropertyNames[disCol],
                modified: disCol != newPropertyNames[disCol],
                'required-field': !newPropertyNames[disCol]
              }">
              <input
                matInput
                class="modifiable-input"
                [(ngModel)]="newPropertyNames[disCol]"
                (keydown)="onInputKeyDown($event)" />
            </div>
          </div>
          <button
            mat-icon-button
            class="remove-column-btn"
            (click)="removeColumn(disCol)"
            matTooltip="Remove Column"
            *ngIf="isEditMode && unsavedColumns.includes(disCol) && !(apiService.isLoading$ | async)">
            <mat-icon class="material-icons-outlined">indeterminate_check_box</mat-icon>
          </button>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let rowIndex = index" [ngClass]="{ 'cell-edit-mode': isEditMode }">
        <ng-container *ngIf="!isEditMode">{{ element.data[disCol] }}</ng-container>
        <ng-container *ngIf="isEditMode">
          <div
            class="current-value"
            [matTooltip]="element.data[disCol]"
            matTooltipPositionAtOrigin="true"
            matTooltipPosition="above"
            [ngClass]="{ modified: element.data[disCol] != modifiedListData[element.id].data[disCol] }">
            {{ !!element.data[disCol] ? element.data[disCol] : '&nbsp;' }}
          </div>
          <div
            class="new-value"
            [ngClass]="{
              empty: !modifiedListData[element.id].data[disCol],
              modified: element.data[disCol] != modifiedListData[element.id].data[disCol]
            }">
            <input
              matInput
              class="modifiable-input"
              [(ngModel)]="modifiedListData[element.id].data[disCol]"
              (keydown)="onInputKeyDown($event)" />
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>
    <!-- *************************************** -->

    <ng-container matColumnDef="unevaluable">
      <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isEditMode">
        <span *ngIf="!isEditMode">Ignore</span>
        <span *ngIf="isEditMode">
          <button
            mat-icon-button
            class="add-column-btn"
            (click)="addColumn()"
            matTooltip="Add Column"
            *ngIf="isEditMode && !(apiService.isLoading$ | async)">
            <mat-icon class="material-icons-outlined">add_box</mat-icon>
          </button>
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          [disabled]="
            !(currentRoles | hasAnyRoles : ['READER', 'WRITER'] : currentExperiment : 'IsReadyToTest') ||
            (currentExperiment | statusIsNot : ['ReadyToTest']) ||
            isEditMode
          "
          [checked]="element.unevaluable"
          (change)="$event ? toggleConditionUnevaluable(element, $event) : null"
          *ngIf="!isEditMode">
        </mat-checkbox>
        <button
          mat-icon-button
          (click)="duplicateRow(modifiedListData[element.id])"
          matTooltip="Duplicate Row"
          *ngIf="isEditMode">
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="removeRow(element)"
          matTooltip="Remove Row"
          *ngIf="isEditMode && element.id < 0">
          <mat-icon>remove</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        <div class="header-actions">
          <mat-icon
            *ngIf="!(apiService.isLoading$ | async)"
            class="audit-count-icon"
            [class.disabled]="isEditMode"
            matTooltip="{{ conditionsAuditCountMap && conditionsAuditCountMap[0] }} change log record(s)"
            matBadgeColor="warn"
            (click)="!isEditMode && showColumnAuditDialog()">
            receipt_long
          </mat-icon>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="onEdit(row)" disabled>
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Delete condition and related details"
          (click)="onDelete(row)"
          [disabled]="
            !(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsLessThanReadyToTest') ||
            isEditMode ||
            ((currentExperiment | statusIs : ['ReadyToTest']) && listData.data.length == 1)
          ">
          <mat-icon>delete</mat-icon>
        </button>
        <app-note-display
          [experimentId]="experimentId"
          [noteCount]="conditionsNotesCountMap && conditionsNotesCountMap[row.id]"
          [objectID]="row.id"
          [objectName]="objectName"
          [isEditMode]="isEditMode">
        </app-note-display>
        <mat-icon
          *ngIf="conditionsAuditCountMap && conditionsAuditCountMap[row.id] > 0"
          class="audit-count-icon"
          [class.disabled]="isEditMode"
          matTooltip="{{ conditionsAuditCountMap && conditionsAuditCountMap[row.id] }} change log records"
          matBadgeColor="warn"
          (click)="!isEditMode && showAuditDialog(row)">
          receipt_long
        </mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6"> No data. </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: listData != null }"></mat-footer-row>
    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }">
    </mat-footer-row>
  </mat-table>
  <!-- Other study type condition view -->
  <div class="conditions-container" *ngIf="currentExperiment?.studyType != StudyType.ImportExcel">
    <mat-accordion [id]="'condition-'+element.id" *ngFor="let element of getFilteredAndPagedData(); let rowIndex = index">
      <mat-expansion-panel hideToggle [expanded]="false">
        <mat-expansion-panel-header [collapsedHeight]=" isEditMode ? '96px' : '70px'" [expandedHeight]="isEditMode ? '96px' : '70px'" #panelH (click)="panelH._toggle()" (keydown.Space)="$event.stopImmediatePropagation();">
          <mat-panel-title class="panel-title" (keydown.Space)="$event.stopPropagation()"> 
            <!-- Condition, iterating over static columns  -->
            <div class="dynamic-column" *ngFor="let disCol of staticColumns; let colIndex = index">
              <div [ngClass]="{ 'cell-edit-mode': isEditMode }">
                <div class="header-container" [matTooltip]="disCol.header + (disCol.required ? '*' : '')">
                  {{disCol.header}}
                  <span *ngIf="disCol.required" class="required">*</span>
                </div>
                <div class="value-container" *ngIf="!isEditMode">
                  <span *ngIf="!disCol.isCustom" [matTooltip]="element[disCol.prop]">
                    {{ element[disCol.prop] }}
                  </span>
                  <span *ngIf="disCol.isCustom && disCol.prop === 'intSubsConcentration1x'" [matTooltip]="toScientificNotation(element[disCol.prop])+''">
                    {{ toScientificNotation(element[disCol.prop]) }}
                  </span>
                  <span *ngIf="disCol.isCustom && disCol.prop == 'externalControlType'" [matTooltip]="element[disCol.prop] === 'false' ? 'Negative' : 'Positive'">
                    {{ element[disCol.prop] === 'false' ? 'Negative' : 'Positive'}}
                  </span>
                  <span *ngIf="disCol.isCustom && disCol.inputType == 'date'" [matTooltip]="element[disCol.prop]">
                    {{ element[disCol.prop] }}
                  </span>
                </div>
                <ng-container *ngIf="isEditMode">
                  <div
                    class="current-value"
                    [ngClass]="{ modified: element[disCol.prop] != modifiedListData[element.id][disCol.prop] }">
                    <span *ngIf="!disCol.isCustom" [matTooltip]="!!element[disCol.prop] ? element[disCol.prop] : '&nbsp;'">
                      {{ !!element[disCol.prop] ? element[disCol.prop] : '&nbsp;' }}
                    </span>
                    <span *ngIf="disCol.isCustom && disCol.prop === 'intSubsConcentration1x'">
                      {{ !!disCol.prop ? toScientificNotation(element[disCol.prop]) : '&nbsp;' }}
                    </span>
                    <span *ngIf="disCol.isCustom && disCol.prop == 'externalControlType'" [matTooltip]="element[disCol.prop] == 'false' ? 'Negative' : 'Positive'">
                      {{ element[disCol.prop] == 'false' ? 'Negative' : 'Positive'}}
                    </span>
                    <span *ngIf="disCol.isCustom && disCol.inputType == 'date'" [matTooltip]="!!element[disCol.prop] ? element[disCol.prop] : '&nbsp;'">
                      {{ !!element[disCol.prop] ? element[disCol.prop] : '&nbsp;' }}
                    </span>
                  </div>
                  <div class="new-value-container">
                    <mat-icon class="date-warning" matTooltip="A past date were selected" *ngIf="!testingDateIsValid(disCol.prop, modifiedListData[element.id][disCol.prop])">warning</mat-icon>
                    <div
                      class="new-value"
                      [ngClass]="{
                        empty: !modifiedListData[element.id][disCol.prop],
                        modified: element[disCol.prop] != modifiedListData[element.id][disCol.prop],
                        'required-field': disCol.required && !modifiedListData[element.id][disCol.prop]
                      }">
                      <input
                        *ngIf="!disCol.inputType && !disCol.isCustom"
                        matInput
                        class="modifiable-input"
                        [(ngModel)]="modifiedListData[element.id][disCol.prop]"
                        (keydown)="onInputKeyDown($event)" />
                      <input
                        *ngIf="disCol.inputType == 'number'"
                        type="number"
                        matInput
                        class="modifiable-input"
                        [(ngModel)]="modifiedListData[element.id][disCol.prop]"
                        (keydown)="onInputKeyDown($event)" />
                      <select *ngIf="disCol.isCustom && disCol.prop == 'externalControlType'" [(ngModel)]="modifiedListData[element.id][disCol.prop]">
                        <option value="false">Negative</option>
                        <option value="true">Postive</option>
                      </select>
                      <input
                        *ngIf="disCol.isCustom && disCol.inputType == 'date'"
                        type="date"
                        matInput
                        class="modifiable-input"
                        [(ngModel)]="modifiedListData[element.id][disCol.prop]"
                        (keydown)="onInputKeyDown($event)" />
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- Condition, iterating over dynamic columns -->
            <div class="dynamic-column" *ngFor="let disCol of propertyNames; let colIndex = index">
              <div [ngClass]="{ 'cell-edit-mode': isEditMode }" >
                <div class="header-container">
                  <ng-container *ngIf="(!isEditMode || unsavedColumns.includes(disCol)) && !(apiService.isLoading$ | async)">
                    <span [matTooltip]="disCol">
                      {{ disCol }}
                    </span>
                  </ng-container>
                  <div class="w-100" *ngIf="isEditMode && !(apiService.isLoading$ | async) && !unsavedColumns.includes(disCol)">
                    <div
                      class="current-value"
                      [matTooltip]="disCol"
                      matTooltipPositionAtOrigin="true"
                      matTooltipPosition="above"
                      [ngClass]="{ modified: disCol != newPropertyNames[disCol] }">
                      {{ disCol }}
                    </div>
                    <div
                      class="new-value"
                      [ngClass]="{
                        empty: !newPropertyNames[disCol],
                        modified: disCol != newPropertyNames[disCol],
                        'required-field': !newPropertyNames[disCol]
                      }">
                      <input
                        matInput
                        class="modifiable-input"
                        [(ngModel)]="newPropertyNames[disCol]"
                        (keydown)="onInputKeyDown($event)" />
                    </div>
                  </div>
                  <button
                    mat-icon-button
                    class="remove-column-btn"
                    (click)="removeColumn(disCol)"
                    matTooltip="Remove Column"
                    *ngIf="isEditMode && unsavedColumns.includes(disCol) && !(apiService.isLoading$ | async)">
                    <mat-icon class="material-icons-outlined">indeterminate_check_box</mat-icon>
                  </button>
                </div>
              </div>
              <div [ngClass]="{ 'cell-edit-mode': isEditMode }">
                <div class="value-container" *ngIf="!isEditMode" [matTooltip]="element.data[disCol]">
                  <span>
                    {{ element.data[disCol] }}
                  </span>
                </div>
                <ng-container *ngIf="isEditMode">
                  <div
                    class="current-value"
                    [matTooltip]="element.data[disCol]"
                    matTooltipPositionAtOrigin="true"
                    matTooltipPosition="above"
                    [ngClass]="{ modified: element.data[disCol] != modifiedListData[element.id].data[disCol] }">
                    {{ !!element.data[disCol] ? element.data[disCol] : '&nbsp;' }}
                  </div>
                  <div
                    class="new-value"
                    [ngClass]="{
                      empty: !modifiedListData[element.id].data[disCol],
                      modified: element.data[disCol] != modifiedListData[element.id].data[disCol]
                    }">
                    <input
                      matInput
                      class="modifiable-input"
                      [(ngModel)]="modifiedListData[element.id].data[disCol]"
                      (keydown)="onInputKeyDown($event)" />
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- *************************************** -->
            <span *ngIf="isEditMode  && !(apiService.isLoading$ | async)">
              <button
                [style.visibility]="rowIndex == 0 ? 'visible': 'hidden'"
                mat-icon-button
                class="add-column-btn"
                (click)="addColumn()"
                matTooltip="Add Column"
                [style.visibility]="isEditMode && rowIndex == 0 ? 'visible': 'hidden'">
                <mat-icon class="material-icons-outlined">add_box</mat-icon>
              </button>
            </span>
            <div class="separator"></div>
            <div class="actions">
              <div class="unevaluable-container" *ngIf="!isEditMode">
                <div class="header-container">Ignore</div>
                <mat-checkbox
                  class="value-container"
                  [disabled]="
                    !(currentRoles | hasAnyRoles : ['READER', 'WRITER'] : currentExperiment : 'IsReadyToTest') ||
                    (currentExperiment | statusIsNot : ['ReadyToTest']) ||
                    isEditMode
                  "
                  [checked]="element.unevaluable"
                  (change)="$event ? toggleConditionUnevaluable(element, $event) : null"
                  >
                </mat-checkbox>
              </div>
              <button
                class="action-button"
                mat-icon-button
                (click)="duplicateRow(modifiedListData[element.id])"
                matTooltip="Duplicate Row"
                *ngIf="isEditMode && currentExperiment?.studyType != StudyType.Precision">
                <mat-icon>add</mat-icon>
              </button>
              <button
                class="action-button"
                mat-icon-button
                (click)="removeRow(element)"
                matTooltip="Remove Condition"
                *ngIf="isEditMode"
                [style.visibility]="element.id < 0 && currentExperiment?.studyType != StudyType.Precision ? 'visible': 'hidden'">
                <mat-icon>remove</mat-icon>
              </button>
              <button
                class="action-button"
                mat-icon-button
                matTooltip="Delete condition and related details"
                (click)="onDelete(element)"
                *ngIf="!isEditMode && currentExperiment?.studyType != StudyType.Precision"
                [disabled]="
                  !(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsLessThanReadyToTest') ||
                  isEditMode ||
                  ((currentExperiment | statusIs : ['ReadyToTest']) && listData.data.length == 1)
                ">
                <mat-icon>delete</mat-icon>
              </button>
              <app-note-display
                class="action-button"
                *ngIf="!isEditMode"
                [experimentId]="experimentId"
                [noteCount]="conditionsNotesCountMap && conditionsNotesCountMap[element.id]"
                [objectID]="element.id"
                [objectName]="objectName"
                [isEditMode]="isEditMode">
              </app-note-display>
              <button
                mat-icon-button
                [style.visibility]="conditionsAuditCountMap && conditionsAuditCountMap[element.id] > 0  ? 'visible': 'hidden'"
                *ngIf="!isEditMode"
                class="audit-count-icon action-button"
                [class.disabled]="isEditMode"
                matTooltip="{{ conditionsAuditCountMap && conditionsAuditCountMap[element.id] }} change log records"
                matBadgeColor="warn"
                (click)="!isEditMode && showAuditDialog(element)">
                <mat-icon>receipt_long</mat-icon>
            </button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<mat-paginator
  class="mat-elevation-z1"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [pageSize]="10"
  showFirstLastButtons
  [style.display]="isEditMode ? 'none' : 'block'">
</mat-paginator>
<div class="edit-mode-add-row mat-elevation-z1" *ngIf="isEditMode && !postEditLoading && currentExperiment?.studyType != StudyType.Precision">
  <button
    mat-raised-button
    aria-label="Append New Row"
    (click)="appendNewRow()">
    <mat-icon>add</mat-icon>
    Append New Row
  </button>
</div>
