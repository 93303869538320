import { ErrorHandler, Injectable } from "@angular/core";
import { NotificationService } from "../shared/notification.service";
import { LoggingService } from "./logging.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private loggingService: LoggingService,
        private notificationService: NotificationService) {

        super();
    }

    handleError(error: Error) {
        this.loggingService.logException(error); // Manually log exception

        this.notificationService.error( 'An Error Occurred. Check browser console for more info.' );

        throw error;    // let it flow through to the browser so we'll see it there too
    }
}
