<div class="dialog-header">
  <mat-toolbar id="dialog-titlebar" class="barcode-dialog-title" color="primary" mat-dialog-title> Mobile Barcode View </mat-toolbar>
</div>
<div mat-dialog-content class="barcode-dialog-content">
  <div>
    Scan the QR code below with your mobile device and follow the link. This link expires on
    {{ data.expiry | date : 'MMM d, y, h:mm:ss a' }}.
  </div>
  <a [routerLink]="[barcodeRouterLink]" target="_blank">
    <img alt="Mobile Barcode Link" class="barcode-link-qr-code" [src]="barcodeImageLink" />
  </a>
</div>
<div mat-dialog-actions class="barcode-dialog-actions">
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Close</button>
</div>
