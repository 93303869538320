import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-noaccess',
  templateUrl: './noaccess.component.html',
  styleUrls: ['./noaccess.component.scss']
})
export class NoAccessComponent implements OnInit {

  public contactMessage: string;

  constructor( private configService : ConfigurationService ) {
      
    this.contactMessage = configService.getConfig().noAccessContactMessage;
   }


  ngOnInit(): void {
  }

}
