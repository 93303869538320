<div [hidden]="isEdit">
  <button
    mat-icon-button
    (click)="setEdit()"
    [disabled]="disabled || !(currentRoles | hasAnyRoles : ['READER', 'WRITER'] : currentExperiment : 'IsReadyToTest')">
    <mat-icon>sensors</mat-icon>
  </button>
</div>
<div [hidden]="!isEdit">
  <mat-form-field>
    <input
      #scanInput
      (focus)="onFocus($event)"
      (focusout)="saveValue()"
      placeholder="{{ colName }}"
      matInput
      [(ngModel)]="currentValue"
      [disabled]="disabled || !(currentRoles | hasAnyRoles : ['READER', 'WRITER'] : currentExperiment : 'IsReadyToTest')" />
  </mat-form-field>
  <div class="parsed-scan-value">{{ parsedValue }}</div>
</div>
