import { Pipe, PipeTransform } from '@angular/core';
import { SavannaResult } from '../services/labpartner.service.model';

@Pipe({ name: 'savannaResultsImgSrc' })
export class SavannaResultsImgSrcPipe implements PipeTransform {
  private static readonly RESULT_POSITIVE = 'Positive';
  private static readonly RESULT_NEGATIVE = 'Negative';
  private static readonly RESULT_INVALID = 'Invalid';
  private static readonly POSITIVE_URI = '/assets/images/result-positive.png';
  private static readonly NEGATIVE_URI = '/assets/images/result-negative.png';
  private static readonly INVALID_URI = '/assets/images/result-invalid.png';
  private static readonly DEFAULT_URI = '';

  transform(result: SavannaResult, shortName: string): string {
    const target = result.targets.find(t => t.shortName == shortName);

    switch (target?.qualitativeResult) {
      case SavannaResultsImgSrcPipe.RESULT_POSITIVE:
        return SavannaResultsImgSrcPipe.POSITIVE_URI;
      case SavannaResultsImgSrcPipe.RESULT_NEGATIVE:
        return SavannaResultsImgSrcPipe.NEGATIVE_URI;
      case SavannaResultsImgSrcPipe.RESULT_INVALID:
        return SavannaResultsImgSrcPipe.INVALID_URI;
      default:
        return SavannaResultsImgSrcPipe.DEFAULT_URI;
    }
  }
}
