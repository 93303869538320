import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuditDialogComponent, IAuditDialogData, IAuditDialogResult } from '../audit-dialog/audit-dialog.component';
import {
  ChangesDialogComponent,
  IChangeDialogResult,
  IChangeSet,
  IConfirmChangesDialogData,
  IConfirmChangesExtendedData,
} from '../changes-dialog/changes-dialog.component';
import { MatAckDialogComponent } from '../mat-ack-dialog/mat-ack-dialog.component';
import { MatConfirmDialogComponent } from '../mat-confirm-dialog/mat-confirm-dialog.component';
import { BarcodeDialogComponent, IBarcodeDialogData } from '../mobile-barcodes/barcode-dialog/barcode-dialog.component';
import { AuditService } from '../services/audit.service';
import { AuditDisplayedKeysWithNames, AuditSortOrder } from '../services/audit.service.models';
import { ExperimentType } from '../services/labpartner.service.model';
import { EVENT_USER_VIEW_AUDITS } from '../services/logging-constants';
import { LoggingService } from '../services/logging.service';
import { UserAccountService } from '../services/user-account.service';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private dialog: MatDialog,
    private userAccountService: UserAccountService,
    private auditService: AuditService,
    private loggingService: LoggingService
  ) { }

  openConfirmDialog(msg: string) {
    return this.dialog.open<MatConfirmDialogComponent, { message: string }, boolean>(MatConfirmDialogComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: false,
      position: { top: '10px' },
      data: {
        message: msg,
      },
    });
  }

  openAckDialog(msg: string) {
    return this.dialog.open(MatAckDialogComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: false,
      position: { top: '10px' },
      data: {
        message: msg,
      },
    });
  }

  openConfirmChangesDialog(changes: IChangeSet[], experimentType: ExperimentType, flags: IConfirmChangesExtendedData) {
    return this.dialog.open<ChangesDialogComponent, IConfirmChangesDialogData, IChangeDialogResult>(
      ChangesDialogComponent,
      {
        width: '800px',
        panelClass: 'confirm-dialog-container',
        disableClose: false,
        position: { top: '10px' },
        data: {
          title: changes.length > 1 ? 'Reason for changes' : 'Reason for change',
          changes: changes,
          experimentType: experimentType,
          dataExt: flags,
        },
      }
    );
  }

  openChangelogDialog(
    title: string,
    experimentId: number,
    objectName: string,
    objectId: number,
    displayedColumns?: AuditDisplayedKeysWithNames,
    forceObjectId?: boolean
  ) {
    this.loggingService.logEvent(EVENT_USER_VIEW_AUDITS, {
      UserID: this.userAccountService.getUser().userId,
      ExperimentID: experimentId,
      AuditsViewed: objectName,
      AuditObjectID: objectId,
    });

    this.auditService.getAudits(experimentId, objectName, objectId, AuditSortOrder.Descending, forceObjectId).subscribe(auditData => {
      return this.dialog.open<AuditDialogComponent, IAuditDialogData, IAuditDialogResult>(AuditDialogComponent, {
        width: '1200px',
        panelClass: 'audit-dialog-container',
        disableClose: false,
        data: {
          title: title,
          auditData: auditData,
          displayedColumns: displayedColumns ?? {
            objectField: 'Field',
            prevValue: 'Previous Value',
            newValue: 'New Value',
            fieldReason: 'Field Reason',
            batchReason: 'Batch Reason',
            changedByName: 'Changed By',
            changeDate: 'Change Date',
            expand: '',
          },
        },
      });
    });
  }

  openBarcodeDialog(experimentId: number, barcodeIdentifier: string, linkExpiration: Date) {
    return this.dialog.open(BarcodeDialogComponent, {
      width: '400px',
      panelClass: 'barcode-dialog-container',
      position: { top: '10px' },
      data: <IBarcodeDialogData>{
        experimentId: experimentId,
        identifier: barcodeIdentifier,
        expiry: linkExpiration,
      },
    });
  }
}
