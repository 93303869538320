import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ExperimentService } from '../../shared/experiment.service';
import { BaseComponent } from '../../support/base.component';
import { LabpartnerService } from '../../services/labpartner.service';
import {
  AssayName,
  AuditedChangeResponse,
  Experiment,
  ExperimentExtended,
  ExperimentStatus,
  ExperimentType,
  ExperimentTypeDisplayName,
  StudyType,
  StudyTypes,
} from 'src/app/services/labpartner.service.model';
import { NotificationService } from 'src/app/shared/notification.service';
import { LoggingService } from 'src/app/services/logging.service';
import { EVENT_CREATE_EXPERIMENT, EVENT_MODIFY_EXPERIMENT } from 'src/app/services/logging-constants';
import { DialogService } from 'src/app/shared/dialog.services';
import { IChangeSet } from 'src/app/changes-dialog/changes-dialog.component';
import { ExperimentFormatPipe } from 'src/app/pipes/experiment-format.pipe';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FeatureFlags } from 'src/app/services/configuration.service.model';
import { Observable } from 'rxjs';

export interface ExperimentDialogData {
  isReadOnly: boolean;
  isEditMode: boolean;
  experiment: Experiment & Partial<ExperimentExtended>;
}

export interface ExperimentDialogResult {
  success: boolean;
  data: Experiment;
}

@Component({
  selector: 'app-experiment-dialog',
  templateUrl: './experiment-dialog.component.html',
  styleUrls: ['./experiment-dialog.component.scss'],
})
export class ExperimentDialogComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  wasCanceled: boolean = false;
  devices!: any;
  hideExperimentType: boolean = false;

  ExperimentTypeIndexes = Object.keys(ExperimentTypeDisplayName);
  ExperimentTypeDisplayNames = ExperimentTypeDisplayName;

  initialFormValues!: Experiment;

  experimentForm!: UntypedFormGroup;

  private MAX_REPLICATES = 1000;

  StudyType = StudyType;
  studyTypes: any[] = StudyTypes;

  assayNames: AssayName[] = [];

  featureFlags$: Observable<FeatureFlags>;

  constructor(
    private _fb: UntypedFormBuilder,
    public experimentService: ExperimentService,
    public dialogRef: MatDialogRef<ExperimentDialogComponent>,
    private apiService: LabpartnerService,
    private notificationService: NotificationService,
    private loggingService: LoggingService,
    private dialogService: DialogService,
    private experimentFormatPipe: ExperimentFormatPipe,
    private configService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: ExperimentDialogData
  ) {
    super();
    this.featureFlags$ = this.configService.featureFlags$;
  }

  ngOnInit(): void {
    this.devices = this.experimentService.devices;
    console.log(this.devices);
    this.initialFormValues = { ...this.data.experiment };

    this.experimentForm = this._fb.group({
      name: [this.data.experiment.name, Validators.required],
      studyType: [this.data.experiment.studyType],
      assayName: [this.data.experiment.assayName],
      assayNameId: [this.data.experiment.assayNameId],
      protocolNumber: [this.data.experiment.protocolNumber],
      deviceType: [this.data.experiment.deviceType],
      replicates: [this.data.experiment.replicates, [Validators.required, Validators.min(1), Validators.max(this.MAX_REPLICATES)]],
      type: [this.data.experiment.type],
    });

    if (!this.showOldForm()) {
      this.experimentForm.controls.studyType.addValidators(Validators.required);
    }

    this.getAssayNames();
  }

  getAssayNames() {
    this.isLoading = true;
    this.experimentForm.disable();
    this.subscription.add(
      this.apiService.getAssayNames(true).subscribe((data: AssayName[]) => {
        this.isLoading = false;
        this.experimentForm.enable();

        // Format assay name to add assay id and revision.
        if (data) {
          // Add assay id and revision to assay name
          data.forEach(a => {
            a.name = `${a.name} (${a.assayId}.${a.assayRevision})`
          });

          this.assayNames = this.data.isEditMode ? data : data.filter(a => a.isActive);
        }

        this.applyExperimentTypeDisplayLogic();
        // Disable study type if in edit mode
        if (this.data.isEditMode) {
          this.experimentForm.controls.studyType.disable();
          this.experimentForm.controls.assayNameId.disable();
          if (this.experimentService.getDeviceName(this.data.experiment.deviceType) === 'Savanna') {
            this.experimentForm.controls.deviceType.disable();
          } else {
            this.devices = this.devices.filter((d: any) => d.value !== 'Savanna');
          }
        }
        this.validateStudyType();
        this.replicatesDisableLogic();
      }, (error) => {
        this.isLoading = false;
        this.notificationService.error(":: Error getting assay names");
        this.dialogRef.close();
      })
    );
  }

  validateStudyType() {
    if (this.experimentForm.controls.studyType.value == StudyType.ImportExcel || this.experimentForm.controls.studyType.value == null) {
      this.experimentForm.controls.assayNameId.removeValidators(Validators.required);
      this.experimentForm.controls.assayNameId.setValue(null);
    }
    else {
      this.experimentForm.controls.assayNameId.addValidators(Validators.required);
      if (this.data.experiment.assayNameId === null && this.assayNames.length > 0) {
        this.experimentForm.controls.assayNameId.setValue(this.assayNames[0].id);
      }
      this.experimentForm.controls.assayName.setValue("");
    }
    this.experimentForm.controls.assayNameId.updateValueAndValidity();
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  onCancel() {
    this.notificationService.warn(this.data.isReadOnly ? ':: Closed' : ':: Canceled');
    let dialogresult = { ok: false, data: null };
    this.onClose(dialogresult);
  }

  async onSubmit() {
    let experiment: Experiment = this.experimentForm.getRawValue();
    experiment.experimentId = this.data.experiment.experimentId;

    let dialogResult: ExperimentDialogResult = { success: false, data: this.data.experiment };

    if (!this.data.isEditMode) {
      this.loadingDisableForm();

      try {
        const createdExperiment: Experiment | undefined = await this.apiService.createExperiment(experiment).toPromise();

        if (!createdExperiment) { throw new Error("createdExperiment is null"); }

        this.notificationService.success(':: Experiment created successfully');
        dialogResult = { success: true, data: createdExperiment };

        const props: { [key: string]: number } = {
          ExperimentId: createdExperiment.experimentId,
          DeviceType: experiment.deviceType,
        };
        this.loggingService.logEvent(EVENT_CREATE_EXPERIMENT, props);
      } catch (err: any) {
        this.notificationService.warn(':: Error creating experiment');
        dialogResult = { success: false, data: err };
      } finally {
        this.loadingCompleteEnableForm();
        this.onClose(dialogResult);
      }
    } else {
      const fieldsToDiff = ['name', 'assayName', 'protocolNumber', 'deviceType', 'replicates'];

      let changeSet: IChangeSet[] = [];

      for (let key of fieldsToDiff) {
        if ((this.initialFormValues as any)[key] != (this.experimentForm.getRawValue() as any)[key]) {
          changeSet.push({
            identifier: this.experimentFormatPipe.transform(
              this.initialFormValues.experimentId,
              this.initialFormValues.type
            ),
            field: `${key[0].toLocaleUpperCase() +
              key
                .slice(1)
                .replace(/([A-Z])/g, ' $1')
                .trim()
              }`,
            oldValue:
              key == 'deviceType'
                ? this.experimentService.devices.find(d => d.id == (this.initialFormValues as any)[key])?.value
                : (this.initialFormValues as any)[key],
            newValue:
              key == 'deviceType'
                ? this.experimentService.devices.find(d => d.id == (this.experimentForm.value as any)[key])?.value
                : (this.experimentForm.value as any)[key],
            // C# field name
            data: `${key[0].toLocaleUpperCase() + key.slice(1).trim()}`,
          });
        }
      }

      if (changeSet.length == 0) {
        this.notificationService.warn('No changes have been made.');
        return;
      }

      const dialogRef = this.dialogService.openConfirmChangesDialog(changeSet, this.initialFormValues.type, {
        multiReason: true,
      });

      dialogRef.afterClosed().subscribe(async dialogData => {
        this.loadingDisableForm();
        if (
          dialogData?.submitClicked &&
          (dialogData?.reasonProvided || this.initialFormValues.type == ExperimentType.ResearchAndDevelopment)
        ) {
          try {
            const fieldReasonMap: { [key: string]: string } = {};
            for (let i = 0; i < changeSet.length; i++) {
              fieldReasonMap[changeSet[i].data!] = dialogData.fieldReasons[i];
            }
            const result = await this.apiService
              .updateExperiment(experiment, dialogData.batchReason ?? '', fieldReasonMap)
              .toPromise();

            if (!result) { throw new Error("Experiment is null"); }

            this.notificationService.success(':: Experiment updated successfully');
            dialogResult = { success: true, data: result };

            const props: { [key: string]: number } = {
              ExperimentId: experiment.experimentId,
              DeviceType: experiment.deviceType,
            };
            this.loggingService.logEvent(EVENT_MODIFY_EXPERIMENT, props);
          } catch (err: any) {
            dialogResult = { success: false, data: err };

            const expectedErr = err.error as AuditedChangeResponse;

            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          } finally {
            this.loadingCompleteEnableForm();
            this.onClose(dialogResult);
          }
        } else {
          this.loadingCompleteEnableForm();
        }
      });
    }
  }

  onClose(dialogResult: any) {
    this.dialogRef.close(dialogResult);
  }

  private loadingDisableForm(): void {
    this.isLoading = true;
    this.experimentForm.disable();
  }

  private loadingCompleteEnableForm(): void {
    this.isLoading = false;
    this.experimentForm.enable();
    this.replicatesDisableLogic();
    this.applyExperimentTypeDisplayLogic();
  }

  private applyExperimentTypeDisplayLogic() {
    if (this.initialFormValues.type == null) {
      this.hideExperimentType = true;
    } else {
      this.hideExperimentType = false;
    }

    if (this.data?.isEditMode ?? false) {
      this.experimentForm.controls.type.disable();
    } else {
      this.experimentForm.controls.type.enable();
    }

    if (this.data?.isReadOnly ?? false) {
      this.experimentForm.disable();
    }
  }

  showOldForm() {
    // Creation mode
    if (!this.data.isEditMode) {
      return this.getDeviceName(this.experimentForm.controls.deviceType.value) !== 'Savanna';
    }

    // Edit mode
    return this.data.isEditMode
      && this.data.experiment.studyType == null
      && this.getDeviceName(this.experimentForm.controls.deviceType.value) === 'Savanna';
  }

  replicatesDisableLogic() {
    if (this.data.experiment.experimentId && this.data.experiment.status !== ExperimentStatus.Created) {
      this.experimentForm.controls.replicates.disable();
    }
  }

  // Get device name by id
  getDeviceName(id: number) {
    return this.devices.find((d: any) => d.id == id)?.value;
  }
}
