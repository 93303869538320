import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { BaseComponent } from '../../support/base.component';
import { NotificationService } from 'src/app/shared/notification.service';
import { IChangeSet } from 'src/app/changes-dialog/changes-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ExperimentType } from 'src/app/services/labpartner.service.model';
import { DialogService } from 'src/app/shared/dialog.services';
import { ILogisticsAuditChangeReasons } from 'src/app/services/audit.service.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ILogisticDialogData {
  experimentType: ExperimentType;
  logisticKey: string;
  name: string;
  value: string;
  isCreate: boolean;
}

export interface ILogisticDialogResult {
  submitClicked: boolean;
  data: ILogisticDialogData;
  auditChangeReasons?: ILogisticsAuditChangeReasons;
}

export interface ILogisticForm {
  name: FormControl<string>;
  value: FormControl<string>;
}

@Component({
  selector: 'app-logistic',
  templateUrl: './logistic.component.html',
  styleUrls: ['./logistic.component.scss'],
})
export class LogisticComponent extends BaseComponent implements OnInit, OnDestroy {
  logisticForm: FormGroup<ILogisticForm> = this.fb.group<ILogisticForm>({
    name: this.fb.control(this.dialogData.name, { nonNullable: true, validators: [Validators.required] }),
    value: this.fb.control(this.dialogData.value, { nonNullable: true }),
  });

  constructor(
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public dialogData: ILogisticDialogData,
    public dialogRef: MatDialogRef<LogisticComponent, ILogisticDialogResult>
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  onCancel() {
    this.notificationService.warn(':: Canceled');
    this.dialogRef.close({
      submitClicked: false,
      data: { ...this.dialogData },
    });
  }

  onSubmit(logisticForm: FormGroup<ILogisticForm>) {
    if (!this.dialogData.isCreate) {
      let changeSets: IChangeSet[] = [];
      let logisticsAuditChangeReasons: ILogisticsAuditChangeReasons = {};

      logisticsAuditChangeReasons.oldName = this.dialogData.name;
      logisticsAuditChangeReasons.newName = logisticForm.value.name;

      logisticsAuditChangeReasons.oldValue = this.dialogData.value;
      logisticsAuditChangeReasons.newValue = logisticForm.value.value;

      if (logisticForm.value.name != this.dialogData.name) {
        changeSets.push({
          identifier: '',
          field: 'Name',
          oldValue: this.dialogData.name,
          newValue: logisticForm.value.name ?? '',
        });
        logisticsAuditChangeReasons.newName = logisticForm.value.name;
      }

      if (logisticForm.value.value != this.dialogData.value) {
        changeSets.push({
          identifier: '',
          field: 'Value',
          oldValue: this.dialogData.value,
          newValue: logisticForm.value.value ?? '',
        });
        logisticsAuditChangeReasons.newValue = logisticForm.value.value;
      }

      if (changeSets.length > 0) {
        const changeDialogRef = this.dialogService.openConfirmChangesDialog(
          changeSets,
          this.dialogData.experimentType,
          { multiReason: true }
        );

        changeDialogRef.afterClosed().subscribe(async dialogResult => {
          if (!dialogResult?.submitClicked) {
            this.notificationService.warn(':: Canceled');
          } else {
            logisticsAuditChangeReasons.batchReason = dialogResult.batchReason;

            if (dialogResult.fieldReasons.length == 2) {
              logisticsAuditChangeReasons.nameReason = dialogResult.fieldReasons[0];
              logisticsAuditChangeReasons.valueReason = dialogResult.fieldReasons[1];
            } else {
              switch (changeSets[0].field) {
                case 'Name':
                  logisticsAuditChangeReasons.nameReason = dialogResult.fieldReasons[0];
                  break;
                case 'Value':
                  logisticsAuditChangeReasons.valueReason = dialogResult.fieldReasons[0];
                  break;
              }
            }

            this.dialogRef.close({
              submitClicked: true,
              data: { ...this.dialogData, ...this.logisticForm?.value },
              auditChangeReasons: logisticsAuditChangeReasons,
            });
          }
        });
      }
    } else {
      this.dialogRef.close({
        submitClicked: true,
        data: { ...this.dialogData, ...this.logisticForm?.value },
      });
    }
  }

  private initializeForm(): void {
    this.logisticForm = this.fb.group<ILogisticForm>({
      name: this.fb.control(this.dialogData.name, { nonNullable: true, validators: [Validators.required] }),
      value: this.fb.control(this.dialogData.value, { nonNullable: true }),
    });
  }
}
