import { NotificationService } from "src/app/shared/notification.service";
import { BaseComponent } from "src/app/support/base.component";

export class LoadBarcodes extends BaseComponent {
    public isLoading: boolean = false;
    loadedBarcodesCount: number = 0;
    barcodes: string[] = [];
    hasError: boolean = false;

    constructor(public notificationService: NotificationService) {
        super();
    }

    protected ngOnDestroyInternal(): void {
    }

    onBarcodeLoaded(result: boolean) {
        this.loadedBarcodesCount++;
        if (!result) {
            this.hasError = true;
        }
        if (this.loadedBarcodesCount === this.barcodes.length) {
            this.isLoading = false;
            if (this.hasError) {
                this.notificationService.error('Error loading barcodes');
            }
        }
    }
}