import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { AssayName, Experiment, ExperimentExtended } from 'src/app/services/labpartner.service.model';
import { UserAccountService } from 'src/app/services/user-account.service';
import { ExperimentService } from 'src/app/shared/experiment.service';
import { BaseComponent } from 'src/app/support/base.component';
import { ActivatedRoute } from '@angular/router';
import { AppStateService } from 'src/app/services/app-state.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-choose-experiment',
  templateUrl: './choose-experiment.component.html',
  styleUrls: ['./choose-experiment.component.scss'],
})
export class ChooseExperimentComponent extends BaseComponent implements OnInit, OnDestroy {
  public experiments!: Experiment[];
  assayNames: AssayName[] = [];
  public selectedExperiment: number = 0;

  public displayedColumns = [
    'name',
    'experimentId',
    'assayName',
    'protocolNumber',
    'replicates',
    'dateCreated',
    'expStatus',
    'createdByName',
  ];
  @ViewChild(MatTable) table!: MatTable<any>;
  listData!: MatTableDataSource<any>;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  public searchKey: string = '';

  @Output() experimentSelected = new EventEmitter<{ expId: number; expOwner: number }>();

  constructor(
    private expService: ExperimentService,
    private apiService: LabpartnerService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    private route: ActivatedRoute
  ) {
    super();
  }

  async ngOnInit() {
    // await to make sure userlist is available
    await this.accountService.SetUserList();

    // await until the user has been (possibly created and) fetched, set in our account state
    await this.accountService.WaitUntilWeHaveUser();



    const sub =
      forkJoin([
        this.apiService.getExperimentsList(),
        this.apiService.getAssayNames(false)
      ])
        .subscribe((results) => {
          this.assayNames = results[1];
          this.setAssayName(results[0]);
          const savannaId = this.expService.devices.find(d => d.value == 'Savanna')?.id;
          this.experiments = results[0].filter(e => e.deviceType == savannaId);
          for (const experiment of results[0] as ExperimentExtended[]) {
            experiment.createdByName = this.accountService.getUserName(experiment.createdBy);
            experiment.deviceName = this.expService.getDeviceName(experiment.deviceType);
            experiment.expStatus = this.expService.getStatus(experiment.status);
          };
          this.route.queryParams.subscribe((params) => {
            // link from lab partner
            if (params.experimentid) {
              const linkedExperiment = this.experiments.find(e => e.experimentId == params.experimentid);
              if (linkedExperiment) {
                this.appState.ClearCurrentExperiment();
                this.onOpenExperiment(linkedExperiment)
              }
            }
            this.listData = new MatTableDataSource(this.experiments);
            this.listData.sort = this.sort;
            this.listData.paginator = this.paginator;
            this.listData.filterPredicate = (data, filter) => {
              return this.displayedColumns.some(ele => {
                return data[ele] != undefined && data[ele].toString().toLowerCase().indexOf(filter) != -1;
              });
            };
          });
        });
    this.subscription.add(sub);
  }

  onSearchClear() {
    this.searchKey = '';
    this.onChange('');
  }

  onChange(newVal: string) {
    this.listData.filter = this.searchKey.trim().toLowerCase();

    console.log(`filter change. len = ${this.listData.filteredData.length}`);
  }

  onOpenExperiment(exp: Experiment) {
    console.log('Opening experiment: ' + exp.experimentId);

    this.selectedExperiment = exp.experimentId;

    // switch to next tab, navigate by way of parent
    // so they can change tabs and tell the child component
    this.experimentSelected.emit({ expId: exp.experimentId, expOwner: exp.createdBy });
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  // Set the assay name for the experiments
  setAssayName(experiments: Experiment[]) {
    experiments.forEach(experiment => {
      if (experiment.assayNameId) {
        experiment.assayName = this.assayNames.find(a => a.id == experiment.assayNameId)?.name + "";
      }
    });
  }
}
