import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { retry, map, catchError, throwError, mergeMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export enum BarcodesTemplates{
  AVERY_SHEET,
  BARCODES,
  DISPLAY
}
@Component({
  selector: 'app-barcode-print',
  templateUrl: './barcode-print.component.html',
  styleUrls: ['./barcode-print.component.scss']
})
export class BarcodePrintComponent implements OnChanges {
  BarcodesTemplates = BarcodesTemplates;
  
  @Input() templateType:BarcodesTemplates = BarcodesTemplates.AVERY_SHEET
  @Input() barcodeData!: string;
  @Input() labelsPerRow: number = 0;
  @Output() onLoaded: EventEmitter<boolean> = new EventEmitter();
  
  public barcodeID: string = "barcode";
  public upperLabel: string = "E0001";
  public lowerLabel: string = "S01-C01-R01";
  public base64Image: string = "";

  constructor(
    public configService: ConfigurationService,
    private httpClient: HttpClient
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.barcodeID = this.barcodeData;
    this.loadBarcodeImage();
    this.upperLabel = this.barcodeData.slice(0, this.barcodeData.indexOf('_'));
    this.lowerLabel = this.barcodeData.slice(this.barcodeData.indexOf('_') + 1);
  }

  ngOnInit(): void {
  }

  loadBarcodeImage() {
    this.sendRequestWithRetries()
      .subscribe(
        (base64Image: any) => {
          this.base64Image = base64Image;
          this.onLoaded.emit(true);
        },
        (error) => {
          console.error('Error loading barcode:', error);
          this.onLoaded.emit(false);
        }
      );
  }

  sendRequestWithRetries(retries = 3) {
    let url = `${this.configService.getConfig().apiServiceEndpoint}v1/Barcode/DataMatrix/${this.barcodeID}`;

    // Add query parameters based on template type
    if (this.templateType === BarcodesTemplates.BARCODES) {
      url += '?pixelSize=4';
    }

    // Return the observable that retries on error
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      retry(retries),  // Retry the request up to `retries` times
      mergeMap((blob: Blob) => {
        // Convert the blob to base64
        return this.blobToBase64(blob);
      }),
      catchError((error) => {
        return throwError(() => new Error('Failed after 3 retries'));
      })
    );
  }

  // Helper function to convert blob to base64 string
  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
}
