import { AfterViewInit, Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { BaseComponent } from '../support/base.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LabpartnerService } from '../services/labpartner.service';
import { UserAccountService } from '../services/user-account.service';
import { AutomatedReport, AutomatedReportStatus, Bundle, Experiment, User } from '../services/labpartner.service.model';
import { AppStateService } from '../services/app-state.service';
import { FixDuplicateResultsComponent } from './fix-duplicate-results/fix-duplicate-results.component';
import { NotificationService } from '../shared/notification.service';
import { SelectExternalControlComponent } from './select-external-control/select-external-control.component';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-automated-reports',
    templateUrl: './automated-reports.component.html',
    styleUrls: ['./automated-reports.component.scss']
})
export class AutomatedReportsComponent extends BaseComponent implements AfterViewInit, OnInit {
    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    automatedReports: AutomatedReport[] = [];

    dataSource: MatTableDataSource<AutomatedReport> = new MatTableDataSource();

    showArchivedAutomatedReports: boolean = false;

    displayedColumns: string[] = [
        'fileName',
        'createdBy',
        'dateCreated',
        'actions',
        'dailyExternalControls',
        'actions2'
    ]

    currentRoles: string[] = [];
    user: User | undefined = undefined;

    AutomatedReportStatus = AutomatedReportStatus;

    isInDialog: boolean;

    experimentId?: number | null = null;
    experiment: Experiment | null = null;

    bundleId?: number | null = null;
    bundle: Bundle | null = null;

    dailyExternalControlExperimentId: number = 0;

    constructor(
        private dialog: MatDialog,
        private labpartnerService: LabpartnerService,
        private accountService: UserAccountService,
        public appState: AppStateService,
        private notificationService: NotificationService,
        @Optional() public dialogRef: MatDialogRef<AutomatedReportsComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
        this.isInDialog = !!dialogRef;
    }

    // After the component is initialized, subscribe to the currentRoles observable
    ngOnInit(): void {
        this.bundleId = this.data?.bundleId;
        this.experimentId = this.appState.GetCurrentExperiment()?.experimentId;
        if (this.experimentId) {
            this.loadExperiment();
        }
        else if (this.bundleId) {
            this.loadBundle();
        }
        this.loadAutomatedReports();

        this.subscription.add(
            this.accountService.currentUser$.subscribe(user => {
                this.user = user;
            })
        );
        this.subscription.add(
            this.accountService.currentRoles$.subscribe(roles => {
                this.currentRoles = roles;
            })
        );
    }

    // After the view is initialized, set the paginator
    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    // Clean up the subscription
    protected ngOnDestroyInternal(): void {

    }

    // Load the experiment from the service
    loadExperiment() {
        if (!this.experimentId) {
            return;
        }

        this.subscription.add(
            this.labpartnerService.getExperiment(this.experimentId).subscribe(experiment => {
                this.experiment = experiment;
                this.loadBundle();
            })
        );
    }

    // Load the bundle from the service
    loadBundle() {
        if (this.bundleId) {
            this.subscription.add(
                this.labpartnerService.getBundleById(this.bundleId).subscribe((bundle: Bundle) => {
                    this.bundle = bundle;
                    if (bundle.dailyExternalControlExperimentId) {
                        this.dailyExternalControlExperimentId = bundle.dailyExternalControlExperimentId;
                    }
                })
            );
        }
    }

    // Load the automatedReports from the service
    loadAutomatedReports() {
        // If the experimentId is set, get the automatedReports for that experiment
        if (this.experimentId) {
            this.subscription.add(
                this.labpartnerService.getAutomatedReportsByExperimentId(this.experimentId).subscribe(automatedReports => {
                    automatedReports.forEach(automatedReport => {
                        if (automatedReport.createdBy) {
                            Object.assign(automatedReport, { createdByName: this.accountService.getUserName(automatedReport.createdBy) });
                        }
                    });
                    this.automatedReports = automatedReports;
                    this.setAutomatedReports();
                })
            );
        }
        // If the bundleId is set, get the automatedReports for that bundle
        else if (this.bundleId) {
            this.subscription.add(
                this.labpartnerService.getAutomatedReportsByBundleId(this.bundleId).subscribe(automatedReports => {
                    automatedReports.forEach(automatedReport => {
                        if (automatedReport.createdBy) {
                            Object.assign(automatedReport, { createdByName: this.accountService.getUserName(automatedReport.createdBy) });
                        }
                    });
                    this.automatedReports = automatedReports;
                    this.setAutomatedReports();
                })
            );
        }
    }

    // Filter the automatedReports based on the showArchivedAutomatedReports flag
    setAutomatedReports() {
        if (this.showArchivedAutomatedReports) {
            this.dataSource.data = this.automatedReports;
        } else {
            this.dataSource.data = this.automatedReports.filter(automatedReport => automatedReport.isActive == true);
        }

        this.dataSource._updateChangeSubscription();
    }

    // Add an automatedReport
    addAutomatedReport() {
        if (this.experimentId) {
            this.addAutomatedReportForExperiment();
        }
        else if (this.bundleId) {
            this.addAutomatedReportForBundle();
        }
    }

    // Add an automatedReport for an experiment
    async addAutomatedReportForExperiment() {
        if (!this.experimentId) return;

        // Open the SelectExternalControlComponent dialog
        const selectExternalControlResult = await lastValueFrom(this.dialog.open(SelectExternalControlComponent).afterClosed());

        // If the dialog was cancelled, return
        if (!selectExternalControlResult || selectExternalControlResult.ok == false) {
            return
        }

        this.dailyExternalControlExperimentId = selectExternalControlResult.data.experimentId;

        let userSelection = await this.resolveDuplicatedResults([this.experimentId, this.dailyExternalControlExperimentId]);

        if (userSelection == null) {
            return;
        }

        var automatedReport: AutomatedReport = {
            experimentId: this.experimentId,
            studyType: this.experiment?.studyType,
            hasDuplicateResults: userSelection.length > 0,
            dailyExternalControlExperimentId: this.dailyExternalControlExperimentId,
            userSelectedResults: userSelection.map((x: any) => {
                return {
                    sampleId: x.sampleId,
                    testResultId: x.testResultId
                }
            })
        }

        this.subscription.add(
            this.labpartnerService.addAutomatedReport(automatedReport).subscribe(() => {
                this.notificationService.success(':: Data Insights Preview created successfully');
                this.loadAutomatedReports();
            }, () => {
                this.notificationService.error(':: Error creating Data Insights Preview');
            })
        );
    }

    // Add an automatedReport for a bundle
    async addAutomatedReportForBundle() {
        if (!this.bundleId) return;
        if (this.bundle?.bundleStudyType == null) return;

        let userSelection = await this.resolveDuplicatedResults(this.bundle.experimentIds.split(','));

        if (userSelection == null) {
            return;
        }

        var automatedReport: AutomatedReport = {
            bundleId: this.bundle?.bundleId,
            studyType: this.bundle?.bundleStudyType,
            hasDuplicateResults: userSelection.length > 0,
            dailyExternalControlExperimentId: this.dailyExternalControlExperimentId,
            userSelectedResults: userSelection.map((x: any) => {
                return {
                    sampleId: x.sampleId,
                    testResultId: x.testResultId
                }
            })
        }

        this.subscription.add(
            this.labpartnerService.addAutomatedReport(automatedReport).subscribe(() => {
                this.notificationService.success(':: Data Insights Preview created successfully');
                this.loadAutomatedReports();
            }, () => {
                this.notificationService.error(':: Error creating Data Insights Preview');
            })
        );
    }

    // Archive an automatedReport
    archiveAutomatedReport(automatedReport: AutomatedReport) {
        if (!automatedReport.automatedReportId) return;

        this.subscription.add(
            this.labpartnerService.archiveAutomatedReport(automatedReport.automatedReportId).subscribe(() => {
                this.loadAutomatedReports();
            })
        );
    }

    // Unarchive an automatedReport
    unarchiveAutomatedReport(automatedReport: AutomatedReport) {
        if (!automatedReport.automatedReportId) return;

        this.subscription.add(
            this.labpartnerService.unarchiveAutomatedReport(automatedReport.automatedReportId).subscribe(() => {
                this.loadAutomatedReports();
            })
        );
    }

    // Download an automatedReport
    downloadAutomatedReport(row: AutomatedReport) {
        if (!row.blobURL) return;

        const a = document.createElement('a');
        a.href = row.blobURL;
        a.download = this.getFileName(row) + '.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(row.blobURL);
    }

    // Get the filename for the automatedReport
    getFileName(row: AutomatedReport) {
        let filename = '';
        if (row.experimentId) {
            switch (row.studyType) {
                case 0: filename = 'LoD - Range Finding';
                    break;
                case 1: filename = 'LoD - Confirmation';
                    break;
                case 5: filename = 'Precision';
                    break;
            }
        }
        else if (row.bundleId) {
            switch (row.studyType) {
                case 0: filename = 'LoD';
                    break;
                case 5: filename = 'Precision';
                    break;
            }
        }
        return filename;
    }

    // Resolve duplicated results
    private async resolveDuplicatedResults(experimentIds: any[]) {
        let userSelection: any[] = [];
        for (let experimentId of experimentIds) {
            const dialogResult = await lastValueFrom(this.dialog.open(FixDuplicateResultsComponent, {
                data: {
                    experimentId: experimentId,
                }
            }).afterClosed());

            if (!dialogResult) {
                this.notificationService.warn(':: Cancelled');
                return null;
            }
            if (!dialogResult.status) {
                return null;
            }

            userSelection.push(...dialogResult.userSelection.map((x: any) => {
                return {
                    sampleId: x.sampleId,
                    testResultId: x.testResultId
                }
            }));
        }
        return userSelection;
    }
}
