import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { AuditedChangeResponse, SavannaConfigResults } from 'src/app/services/labpartner.service.model';
import { SavannaConfigService } from 'src/app/services/savanna-config.service';
import { NotificationService } from 'src/app/shared/notification.service';

export interface ISavannaConfigDialogData {
  currentAssayNameId?: number;
  currentAssayName?: string;
}

export interface ISavannaCreateConfig {
  assayName: string;
  assayId: number;
  assayRevision: number;
}

export interface ISavannaCreateConfigFormGroup extends UntypedFormGroup {
  value: ISavannaCreateConfig;
  controls: Record<keyof ISavannaCreateConfig, AbstractControl>;
}

@Component({
  selector: 'savanna-create-config-dialog',
  templateUrl: 'savanna-create-config-dialog.component.html',
  styleUrls: ['savanna-create-config-dialog.component.scss'],
})
export class SavannaCreateConfigDialogComponent implements OnInit {
  isLoading: boolean = false;
  configFormGroup!: ISavannaCreateConfigFormGroup;
  allRevisionsChecked: boolean = false;

  constructor(
    private savannaConfigService: SavannaConfigService,
    private notificationService: NotificationService,
    private _fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SavannaCreateConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData?: ISavannaConfigDialogData
  ) {}

  ngOnInit(): void {
    if (!this.dialogData?.currentAssayName) {
      this.configFormGroup = this._fb.group(
        {
          assayName: [undefined, [Validators.required]],
          assayId: [undefined, [Validators.required, this.wholeNumberValidator()]],
          assayRevision: [undefined, [Validators.required, this.wholeNumberValidator()]],
        } as Record<keyof ISavannaCreateConfig, any>,
        { updateOn: 'change' }
      ) as ISavannaCreateConfigFormGroup;
    } else {
      this.configFormGroup = this._fb.group(
        {
          assayName: [this.dialogData.currentAssayName, [Validators.required]],
        } as Record<keyof ISavannaCreateConfig, any>,
        { updateOn: 'change' }
      ) as ISavannaCreateConfigFormGroup;
    }
  }

  toggleAllRevisions(event: any) {
    if (event.checked) {
      this.configFormGroup.controls.assayRevision.disable();
      this.allRevisionsChecked = true;
    } else {
      this.configFormGroup.controls.assayRevision.enable();
      this.allRevisionsChecked = false;
    }
  }

  async onClickCreate() {
    this.isLoading = true;
    this.configFormGroup.disable();

    const formValues = this.configFormGroup.value;

    const assayId = Number(formValues.assayId);
    const assayRevision = this.allRevisionsChecked ? undefined : Number(formValues.assayRevision);

    this.savannaConfigService
      .createSavannaConfiguration(formValues.assayName, assayId, assayRevision)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.configFormGroup.enable();
          if (this.allRevisionsChecked) {
            this.configFormGroup.controls.assayRevision.disable();
          }
        })
      )
      .subscribe(
        (configs: SavannaConfigResults) => {
          this.dialogRef.close(configs);
        },
        (res: any) => {
          const expectedError = res.error as AuditedChangeResponse;
          if (expectedError.expectedException) {
            this.notificationService.error(expectedError.message);
          } else {
            throw res;
          }
        }
      );
  }

  async onClickUpdate() {
    this.isLoading = true;
    this.configFormGroup.disable();

    this.savannaConfigService
      .updateAssayConfigurationName(this.dialogData?.currentAssayNameId ?? 0, this.configFormGroup.value.assayName)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.configFormGroup.enable();
        })
      )
      .subscribe(
        (newAssayName: string) => this.dialogRef.close(newAssayName),
        (errorResponse: any) => {
          const expectedError = errorResponse.error as AuditedChangeResponse;
          if (expectedError.expectedException) {
            this.notificationService.error(expectedError.message);
          } else {
            throw errorResponse;
          }
        }
      );
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  private wholeNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      if (/[-.e]+/.test(value) || !/^\d+$/.test(value)) {
        return { wholeNumbersOnly: true };
      }

      return !isNaN(value) && parseFloat(value) == parseInt(value) ? null : { wholeNumbersOnly: true };
    };
  }
}
