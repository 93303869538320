import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { Detail } from 'src/app/services/labpartner.service.model';
import { PAGE_NAME_PRINTAVERYSHEET } from 'src/app/services/logging-constants';
import { LoggingService } from 'src/app/services/logging.service';
import { LoadBarcodes } from '../detail-list-print/LoadBarcodes';
import { BarcodesTemplates } from 'src/app/barcode-print/barcode-print.component';
import { NotificationService } from 'src/app/shared/notification.service';

export class ColumnNumberErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    return !control?.value || control.value <= 0 || control.value > 10;
  }
}

@Component({
  selector: 'app-detail-list-print-barcodes',
  templateUrl: './detail-list-print-barcodes.component.html',
  styleUrls: ['./detail-list-print-barcodes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailListPrintBarcodesComponent extends LoadBarcodes implements OnInit {
  BarcodesTemplates = BarcodesTemplates;
  @HostListener('window:beforeprint')
  onBeforePrint() {
    const head = document.head || document.getElementsByTagName('head')[0];
    this.currentPrintStyle = document.createElement('style');
    this.currentPrintStyle.media = 'print';

    this.currentPrintStyle.appendChild(
      document.createTextNode(this.LABELS_PER_ROW > 7 ? '@page { size: landscape; }' : '@page { size: portrait; }')
    );

    head.appendChild(this.currentPrintStyle);
  }

  @HostListener('window:afterprint')
  onAfterPrint() {
    document.head.removeChild(this.currentPrintStyle!);
  }

  @ViewChild(MatInput) columnNumberInput?: MatInput;

  LABELS_PER_ROW: number = 8;
  LABELS_PER_PAGE: number = 40;

  numberOfColumns: number = 8;
  inputError: boolean = false;
  errorMatcher = new ColumnNumberErrorStateMatcher();

  currentPrintStyle?: HTMLStyleElement;

  apiServiceEndpoint?: string;

  get labelsPerRow() {
    return this.LABELS_PER_ROW;
  }

  constructor(
    private configService: ConfigurationService,
    private apiService: LabpartnerService,
    private route: ActivatedRoute,
    private loggingService: LoggingService,
    private cdRef: ChangeDetectorRef,
    public notificationService: NotificationService
  ) {
    super(notificationService);
  }

  ngOnInit(): void {
    this.apiServiceEndpoint = this.configService.getConfig().apiServiceEndpoint;
    var param = this.route.snapshot.queryParamMap.get('barcodes');
    if (param != undefined) {
      this.isLoading = true;
      this.barcodes = param.split('|');
    } else {
      var experimentIdParam = this.route.snapshot.queryParamMap.get('experimentId');
      if (experimentIdParam != undefined) {
        var experimentId = parseInt(experimentIdParam);
        this.apiService.getDetailsList('experimentId', experimentId).subscribe((details: Detail[]) => {
          this.apiService.detailsDefaultSort(details);
          details.forEach(element => {
            var barcode = element.readableBarcode;
            this.barcodes.push(barcode);
          });
          this.isLoading = true;
          this.cdRef.detectChanges();
        });
      }
    }
    this.loggingService.logPageView(PAGE_NAME_PRINTAVERYSHEET);
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  isLastInRow(itemIndex: number): boolean {
    if (itemIndex % this.LABELS_PER_ROW == 0) {
      return true;
    }
    return false;
  }

  isLastOnPage(itemIndex: number): boolean {
    if (itemIndex % this.LABELS_PER_PAGE == 0) {
      return true;
    }
    return false;
  }

  print() {
    window.print();
  }

  onColumnNumberKeydown(evt: KeyboardEvent) {
    if (evt.key == 'e' || evt.key == '-' || evt.key == '+') {
      evt.preventDefault();
    }
  }

  onColumnNumberChange(evt: any) {
    if (this.columnNumberInput?.errorState) {
      return;
    }

    this.LABELS_PER_ROW = evt;
    this.LABELS_PER_PAGE = evt <= 7 ? 6 * evt : 5 * evt;
  }
}
