<div class="dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary" mat-dialog-title> Notes </mat-toolbar>
</div>
<div mat-dialog-content class="dialog-content">
  <div class="list-container" *ngIf="!hideSideNav" [ngClass]="{'detail-notes': objectName=='Detail'}">
    <mat-tab-group #tabGroup class="small-tabs " *ngIf="objectName=='Detail'" [(selectedIndex)]="selectedIndexTab" (selectedTabChange)="onTabChange()">
      <mat-tab label="One time note">
        <div class="tab-content">
          <div class="search-bar">
            <input
              matInput
              type="text"
              class="search-bar-input"
              [(ngModel)]="searchKey"
              placeholder="Search"
              autocomplete="off"
              (ngModelChange)="onSearchChange($event)"
              #searchInput />
          </div>
          <mat-card
            class="note-card"
            [class.selected]="note.selected"
            (click)="onClickNote(note)"
            *ngFor="let note of filteredList">
            <mat-card-title>{{ note.noteTitle }}</mat-card-title>
            <mat-card-content>
              {{ note.noteText }}
            </mat-card-content>
            <mat-card-actions>
              <div class="note-author">{{ note.createdByName }}</div>
              <div class="note-date">{{ note.dateCreated | date: 'short' }}</div>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="Live note">
        <div class="tab-content">
          <mat-card
          class="note-card live-note-card">
          <mat-card-title>{{ liveNote?.noteTitle }}</mat-card-title>
          <mat-card-content #liveNoteContent>
            {{ liveNote?.noteText }}
            <span class="ellipsis" *ngIf="checkOverflow()">...</span>
          </mat-card-content>
          <mat-card-actions>
            <div class="note-author">{{ liveNote?.createdByName }}</div>
            <div class="note-date">{{ liveNote?.dateCreated | date: 'short' }}</div>
          </mat-card-actions>
        </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
    <ng-container *ngIf="objectName!='Detail'">
      <div class="search-bar">
        <input
          matInput
          type="text"
          class="search-bar-input"
          [(ngModel)]="searchKey"
          placeholder="Search"
          autocomplete="off"
          (ngModelChange)="onSearchChange($event)"
          #searchInput />
      </div>
      <mat-card
        class="note-card"
        [class.selected]="note.selected"
        (click)="onClickNote(note)"
        *ngFor="let note of filteredList">
        <mat-card-title>{{ note.noteTitle }}</mat-card-title>
        <mat-card-content>
          {{ note.noteText }}
        </mat-card-content>
        <mat-card-actions>
          <div class="note-author">{{ note.createdByName }}</div>
          <div class="note-date">{{ note.dateCreated | date: 'short' }}</div>
        </mat-card-actions>
      </mat-card>
    </ng-container>
    
  </div>
  <div class="note-container">
    <div class="note-detail">
      <div class="note-title">
        <label class="note-title-label">Title</label>
        <textarea
          matInput
          [placeholder]="'Add Title'"
          [(ngModel)]="noteTitle"
          class="note-title-input"
          [disabled]="!addMode"
          maxlength="100"
          #titleTextarea
          cdkFocusInitial
          [disabled]="!(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"

          >
        </textarea>
      </div>
      <div class="note-body">
        <label class="note-body-label">Note</label>
        <textarea
          matInput
          [placeholder]="'Add Note'"
          [(ngModel)]="noteBody"
          class="note-body-input"
          [disabled]="!addMode || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"
          #bodyTextarea>
        </textarea>
      </div>
    </div>
    <div class="dialog-actions">
      <button mat-raised-button color="warn" (click)="closeNoteHandler()">Close</button>
      <button
        mat-raised-button
        color="primary"
        (click)="!addMode ? addNoteHandler() : saveNoteHandler()"
        [disabled]="addMode && !(!!noteTitle && !!noteBody) || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus') || isSaving">
        {{ !addMode ? 'Add' : 'Save' }}
      </button>
    </div>
  </div>
</div>
