import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserAccountService } from 'src/app/services/user-account.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { BaseComponent } from 'src/app/support/base.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  AuditedChangeResponse,
  SavannaAssayRevisionConfig,
  SavannaConfigResults,
} from '../services/labpartner.service.model';
import { SavannaConfigService } from '../services/savanna-config.service';
import {
  ISavannaConfigDialogData,
  SavannaCreateConfigDialogComponent,
} from './savanna-create-config-dialog/savanna-create-config-dialog.component';
import { finalize } from 'rxjs/operators';
import { DialogService } from '../shared/dialog.services';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { TableEditCompleteEvent } from 'primeng/table';

@Component({
  selector: 'app-savanna-config',
  templateUrl: './savanna-config.component.html',
  styleUrls: ['./savanna-config.component.scss'],
})
export class SavannaConfigComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() tabGroup?: MatTabGroup;

  numberOfRows = 5;
  numberOfColumns = 5;
  chamberNames = ['FAM', 'VIC', 'CFR', 'QSR'];
  editableColumnIndices = [...Array(this.numberOfColumns).keys()].slice(1);

  selectedAssayId?: number = undefined;
  selectedRevisionId?: number = undefined;

  currentConfigs: { [key: number]: { [key: number]: SavannaAssayRevisionConfig[] } } = {};
  currentAssayIds: number[] = [];
  currentAssayRevisions: number[] = [];

  positionalConfigurations: SavannaAssayRevisionConfig[] = [];

  tableData: SavannaAssayRevisionConfig[][] = [];
  baselineChamberChannelTableData: SavannaAssayRevisionConfig[][] = [];

  pendingChanges: boolean = false;
  isLoading: boolean = false;

  constructor(
    private savannaConfigService: SavannaConfigService,
    public accountService: UserAccountService,
    private notificationService: NotificationService,
    private matDialog: MatDialog,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.savannaConfigService
      .getSavannaConfigs()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        configs => {
          this.resetStateAndUpdateConfigurations(configs);
        },
        (error: any) => {
          this.notificationService.error('Error loading existing Savanna Configurations.');
          throw error;
        }
      );
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  onAssayIdChange() {
    if (this.currentConfigs && this.selectedAssayId) {
      this.currentAssayRevisions = Object.keys(this.currentConfigs[this.selectedAssayId]).map(x => Number(x));

      if (this.selectedRevisionId && this.currentAssayRevisions.findIndex(x => x == this.selectedRevisionId) >= 0) {
        this.onRevisionChange();
      } else {
        this.selectedRevisionId = undefined;
      }
    }
  }

  onRevisionChange() {
    this.resetConfigurationStates();

    if (!this.currentConfigs || !this.selectedAssayId || !this.selectedRevisionId) {
      return;
    }

    const currentlySelectedConfiguration: SavannaAssayRevisionConfig[] = JSON.parse(
      JSON.stringify(this.currentConfigs[this.selectedAssayId][this.selectedRevisionId])
    );

    // The 0:0 config entry is a sort of base, just rely on it for consistency
    const currentBaseConfig = currentlySelectedConfiguration.find(x => x.chamber == 0 && x.channel == 0);

    // Walk chamber/channel 5x5 (counting 0-index) and populate with either the existing config or a blank stub
    const rows: SavannaAssayRevisionConfig[][] = [];
    [...Array(this.numberOfRows).keys()].forEach(rowIndex => {
      const configsByChamber = currentlySelectedConfiguration.filter(c => c.chamber == rowIndex).sort(c => c.channel);

      [...Array(this.numberOfColumns).keys()].forEach(colIndex => {
        rows[rowIndex] = rows[rowIndex] ?? [];

        // 0-column is for labels
        if (colIndex == 0) {
          rows[rowIndex][colIndex] = {
            shortName: `${rowIndex}`,
            channel: 0,
            chamber: 0,
            position: -1,
            assayName: currentBaseConfig?.assayName,
            assayNameId: currentBaseConfig?.assayNameId ?? 0,
          };
        } else {
          rows[rowIndex][colIndex] = configsByChamber.find(c => c.channel == colIndex) ?? {
            assayId: this.selectedAssayId,
            assayRevision: this.selectedRevisionId,
            chamber: rowIndex,
            channel: colIndex,
            shortName: '',
            position: -1,
            assayName: currentBaseConfig?.assayName,
            assayNameId: currentBaseConfig?.assayNameId ?? 0,
          };
        }
      });
    });

    this.tableData = rows;

    // Break referential integrity on purpose, this gives us a list of configurations as a sort of WIP
    this.baselineChamberChannelTableData = JSON.parse(JSON.stringify(rows));

    this.updatePositionalList();
  }

  onEditComplete(event: TableEditCompleteEvent) {
    const tableConfig = this.tableData[event.data.chamber][event.data.channel];

    const newlyConfiguredShortName = event.data.shortName?.trim();

    // Modifying a short name to nothing should act as a deletion
    if (!newlyConfiguredShortName) {
      this.onClickDeleteAssay(event.data);
      this.refreshPositionalListPositions();
      return;
    }

    // If the modified config has no current position (likely new), give it the max position for the current configurations
    if (tableConfig.position == null || tableConfig.position < 0) {
      tableConfig.position =
        Math.max(
          -1,
          ...this.tableData
            .reduce((prev, cur) => prev.concat(cur), [])
            .filter(sc => sc.position != null && sc.position >= 0)
            .map(sc => sc.position!)
        ) + 1;
    }

    tableConfig.shortName = event.data.shortName;

    this.updatePositionalList();
    this.updatePendingChangesFlag();
  }

  onDropConfig(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.positionalConfigurations, event.previousIndex, event.currentIndex);
    this.refreshPositionalListPositions();
    this.updatePendingChangesFlag();
  }

  onClickDeleteAssay(config: SavannaAssayRevisionConfig) {
    this.tableData[config.chamber][config.channel] = {
      assayConfigId: config.assayConfigId,
      assayId: this.selectedAssayId,
      assayRevision: this.selectedRevisionId,
      chamber: config.chamber,
      channel: config.channel,
      shortName: '',
      position: -1,
      assayNameId: this.baselineChamberChannelTableData[0][0].assayNameId ?? 0,
    };

    this.updatePositionalList();
    this.updatePendingChangesFlag();
  }

  onCreateConfiguration() {
    const dialogRef = this.matDialog.open(SavannaCreateConfigDialogComponent, {
      width: '300px',
      panelClass: 'savanna-dialog-container',
      disableClose: false,
      position: { top: '10px' },
    });

    dialogRef.afterClosed().subscribe((configs: SavannaConfigResults) => {
      if (configs) {
        this.updateCurrentConfigurations(configs);
        this.savannaConfigService.configUpdated$.emit(true);
        this.notificationService.success('New assay configuration created');
      }
    });
  }

  onEditName() {
    const dialogRef = this.matDialog.open(SavannaCreateConfigDialogComponent, {
      width: '300px',
      panelClass: 'savanna-dialog-container',
      disableClose: false,
      position: { top: '10px' },
      data: <ISavannaConfigDialogData>{
        currentAssayNameId: this.tableData[0][0].assayNameId,
        currentAssayName: this.tableData[0][0].assayName,
      },
    });

    dialogRef.afterClosed().subscribe((newAssayName: string) => {
      if (newAssayName) {
        this.tableData.forEach(chamber => chamber.forEach(channel => (channel.assayName = newAssayName)));
      }
    });
  }

  saveChanges() {
    if (!this.selectedAssayId || !this.selectedRevisionId) {
      return;
    }

    this.isLoading = true;

    const modifiedConfigurations = this.getModifiedConfigurations();

    const newOrUpdatedConfigurations = modifiedConfigurations.filter(x => x.position >= 0 && x.shortName);

    const deletedConfigurations = modifiedConfigurations
      .filter(x => x.assayConfigId && x.assayConfigId >= 0)
      .filter(x => x.position == -1 && !x.shortName)
      .map(x => x.assayConfigId!);

    this.savannaConfigService
      .updateConfigurations(
        this.selectedAssayId,
        this.selectedRevisionId,
        this.baselineChamberChannelTableData[0][0].assayNameId,
        newOrUpdatedConfigurations,
        deletedConfigurations
      )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        configs => {
          this.resetStateAndUpdateConfigurations(configs);
          this.onRevisionChange();
        },
        (errorResponse: any) => {
          const expectedError = errorResponse.error as AuditedChangeResponse;
          if (expectedError.expectedException) {
            this.notificationService.error(expectedError.message);
          } else {
            throw errorResponse;
          }
        }
      );
  }

  onDeleteConfiguration() {
    if (!this.selectedAssayId || !this.selectedRevisionId) {
      return;
    }

    const selectedRevision = this.selectedRevisionId == -1 ? '(none)' : this.selectedRevisionId;
    const confirmResult = this.dialogService.openConfirmDialog(
      `Are you sure you want to delete the configuration for Assay ${this.selectedAssayId}, Revision ${selectedRevision}?`
    );

    confirmResult.afterClosed().subscribe(confirmedDeletion => {
      if (confirmedDeletion) {
        this.savannaConfigService
          .deleteConfiguration(
            this.selectedAssayId!,
            this.selectedRevisionId!,
            this.baselineChamberChannelTableData[0][0].assayNameId
          )
          .pipe(
            finalize(() => {
              this.isLoading = false;
              this.selectedAssayId = undefined;
              this.selectedRevisionId = undefined;
            })
          )
          .subscribe(
            configs => {
              this.resetStateAndUpdateConfigurations(configs, true);
              this.notificationService.success('Assay configuration deleted');
            },
            (errorResponse: any) => {
              const expectedError = errorResponse.error as AuditedChangeResponse;
              if (expectedError.expectedException) {
                this.notificationService.error(expectedError.message);
                this.resetConfigurationStates();
              } else {
                throw errorResponse;
              }
            }
          );
      }
    });
  }

  private getModifiedConfigurations() {
    const modifiedConfigurations: SavannaAssayRevisionConfig[] = [];

    for (let rowIndex = 0; rowIndex < this.numberOfRows; rowIndex++) {
      for (let colIndex = 0; colIndex < this.numberOfColumns; colIndex++) {
        const currentBaselineEntry = this.baselineChamberChannelTableData[rowIndex][colIndex];
        const currentConfigEntry = this.tableData[rowIndex][colIndex];

        if (
          currentConfigEntry.shortName != currentBaselineEntry.shortName ||
          currentConfigEntry.position != currentBaselineEntry.position
        ) {
          modifiedConfigurations.push(currentConfigEntry);
        }
      }
    }

    return modifiedConfigurations;
  }

  private updatePendingChangesFlag() {
    this.pendingChanges = this.getModifiedConfigurations().length > 0;
    this.savannaConfigService.setPendingChanges(this.pendingChanges);
  }

  private updatePositionalList() {
    this.positionalConfigurations = this.tableData
      .reduce((prev, cur) => prev.concat(cur), [])
      .filter(x => x.chamber != 0 && x.channel != 0 && x.position >= 0)
      .sort((a, b) => a.position - b.position);
  }

  private refreshPositionalListPositions() {
    this.positionalConfigurations.forEach((sc, idx) => {
      sc.position = idx;
      this.tableData[sc.chamber][sc.channel].position = idx;
    });
  }

  private resetConfigurationStates() {
    this.pendingChanges = false;
    this.savannaConfigService.setPendingChanges(this.pendingChanges);
    this.isLoading = false;
    this.tableData = [];
    this.positionalConfigurations = [];
    this.baselineChamberChannelTableData = [];
  }

  private resetStateAndUpdateConfigurations(configs: SavannaConfigResults, resetSelections: boolean = false): void {
    if (resetSelections) {
      this.selectedAssayId = undefined;
      this.selectedRevisionId = undefined;
    }

    this.resetConfigurationStates();
    this.updateCurrentConfigurations(configs);
    this.savannaConfigService.configUpdated$.emit(true);
  }

  private updateCurrentConfigurations(configs: SavannaConfigResults) {
    this.currentConfigs = configs;
    this.currentAssayIds = Object.keys(this.currentConfigs ?? {}).map(x => Number(x));
    this.currentAssayRevisions = Object.keys(
      this.currentConfigs && this.selectedAssayId ? this.currentConfigs[this.selectedAssayId] : {}
    ).map(x => Number(x));
  }
}
