import { Component, OnDestroy, Inject } from '@angular/core';

import {
  Detail,
  ErrorUserResponses,
  Experiment,
  ExperimentType,
  InstrumentError,
} from 'src/app/services/labpartner.service.model';
import { NotificationService } from 'src/app/shared/notification.service';
import { LoggingService } from 'src/app/services/logging.service';
import { DialogService } from 'src/app/shared/dialog.services';
import { IChangeSet } from 'src/app/changes-dialog/changes-dialog.component';
import { ExperimentFormatPipe } from 'src/app/pipes/experiment-format.pipe';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '../support/base.component';
import { ExperimentService } from '../shared/experiment.service';
import { LabpartnerService } from '../services/labpartner.service';
import { UserAccountService } from '../services/user-account.service';
import { HasAnyRolesPipe } from '../pipes/has-any-roles.pipe';
import { forkJoin } from 'rxjs';

export interface InstrumentErrorDialogData {
  experiment: Experiment;
  detail: Detail;
  selectedDetails: Detail[];
}

export interface InstrumentErrorDialogResult {
  success: boolean;
  data: any;
  updated: boolean;
}

@Component({
  selector: 'app-instrument-error-dialog',
  templateUrl: './instrument-error-dialog.component.html',
  styleUrls: ['./instrument-error-dialog.component.scss'],
})
export class InstrumentErrorDialogComponent extends BaseComponent implements OnDestroy {
  isLoading: boolean = false;

  instrumentErrorForm!: UntypedFormGroup;

  selectedIntrumentError: number = -1;

  errorUserResponses: ErrorUserResponses[] = [];

  hasError: boolean = false;

  currentErrors: InstrumentError[] = [];

  currentExperiment: Experiment;

  currentRoles: string[] = [];

  constructor(
    private _fb: UntypedFormBuilder,
    public experimentService: ExperimentService,
    public dialogRef: MatDialogRef<InstrumentErrorDialogComponent>,
    private apiService: LabpartnerService,
    private notificationService: NotificationService,
    private loggingService: LoggingService,
    private dialogService: DialogService,
    private experimentFormatPipe: ExperimentFormatPipe,
    @Inject(MAT_DIALOG_DATA) public data: InstrumentErrorDialogData,
    private accountService: UserAccountService,
    private hasAnyRolesPipe: HasAnyRolesPipe
  ) {
    super();
    this.currentExperiment = this.data.experiment;
    this.loadData();
  }

  loadData() {
    const errorTextValidator = this.currentExperiment?.type == ExperimentType.ValidationAndVerification ? [Validators.required] : [];
    this.instrumentErrorForm = this._fb.group({
      instrumentNumber: ["", [Validators.required, Validators.maxLength(200)]],
      errorText: ["", errorTextValidator]
    });
    this.instrumentErrorForm.disable();

    this.isLoading = true;
    this.subscription.add(
      forkJoin([
        this.apiService.getErroUserResponses(),
        this.apiService.getInstrumentErrorsByExperimentId(this.data.detail.experimentId)
      ]).subscribe((response) => {
        const errorUserResponses = response[0];
        const errors = response[1];

        this.isLoading = false;
        this.instrumentErrorForm.enable();
        
        this.errorUserResponses = [{ responseId: -1, responseTitle: 'none', responseText: '' }, ...errorUserResponses];

        this.currentErrors = errors;
        let error = this.currentErrors.find(x => x.detailId == this.data.detail.detailId);
        if (error && error.isActive) {
          this.hasError = true;
          this.instrumentErrorForm.get('instrumentNumber')?.setValue(error.instrumentNumber);
          this.instrumentErrorForm.get('errorText')?.setValue(error.errorText);
        }
      },()=>{
        this.isLoading = false;
        this.notificationService.error(':: Error getting instrument error information.');
        this.dialogRef.close();
      })
    );

    this.subscription.add(
      this.accountService.currentRoles$.subscribe(roles => {
        this.currentRoles = roles;
        if (!this.hasAnyRolesPipe.transform(this.currentRoles, ['WRITER', 'READER'], null, 'IsAnyStatus')) {
          this.instrumentErrorForm.disable();
        }
      })
    );
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  onErrorChange(evt: any) {
    this.instrumentErrorForm
      .get('errorText')
      ?.setValue(this.errorUserResponses.find(x => x.responseId == evt.value)?.responseText)
  }

  onCancel() {
    let dialogresult = { ok: false, data: null };
    this.onClose(dialogresult);
  }

  async onRemove() {
    this.isLoading = true;
    const changes: IChangeSet[] = [];

    this.data.selectedDetails.forEach(detail => {
      var error = this.currentErrors.find(e => e.detailId == detail.detailId);
      if (error && error.isActive) {
        changes.push({
          identifier: `${error.errorId}`,
          field: 'IsActive',
          oldValue: "TRUE",
          newValue: "FALSE",
        });
      }

    });

    if (changes.length < 0) {
      this.isLoading = false;
      return;
    }

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changes,
      this.currentExperiment?.type,
      { multiReason: true }
    );

    dialogRef.afterClosed().subscribe(async dialogData => {
      if (
        dialogData?.submitClicked &&
        (dialogData?.reasonProvided || this.currentExperiment?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        const details = changes.map((c, i) => {
          return {
            type: 0,
            detailId: this.currentErrors.find(e => e.errorId + "" == c.identifier)?.detailId,
            fieldReason: dialogData.fieldReasons[i]
          };
        });

        this.apiService.deleteMultipleInstrumentErrors({
          experimentId: this.data.detail.experimentId,
          details: details,
          batchReason: dialogData.batchReason
        }).subscribe(() => {
          this.dialogRef.close({
            success: true, data: { value: false }, updated: true
          })
        }, () => {
          this.isLoading = false
        });
      } else {
        this.isLoading = false;
        this.notificationService.warn(':: Canceled');
      }
    });

  }

  async onSubmit(experiment: Experiment) {
    this.isLoading = true;
    const changes: IChangeSet[] = [];
    const newErrorText = this.instrumentErrorForm.get('errorText')?.value;
    const newInstrumentNumber = this.instrumentErrorForm.get('instrumentNumber')?.value;

    const newErrors: any[] = [];
    this.data.selectedDetails.forEach(detail => {
      var error = this.currentErrors.find(e => e.detailId == detail.detailId);
      if (error) {
        if (error.instrumentNumber !== newInstrumentNumber) {
          changes.push({
            identifier: `${error.errorId}`,
            field: 'InstrumentNumber',
            oldValue: error.instrumentNumber,
            newValue: newInstrumentNumber,
          });
        }

        if (error.errorText !== newErrorText) {
          changes.push({
            identifier: `${error.errorId}`,
            field: 'ErrorText',
            oldValue: error.errorText,
            newValue: newErrorText,
          });
        }
      } else {
        newErrors.push({
          detailId: detail.detailId,
          type: 0
        });
      }
    });

    if (changes.length > 0) {
      const dialogRef = this.dialogService.openConfirmChangesDialog(
        changes,
        this.currentExperiment?.type,
        { multiReason: true }
      );

      dialogRef.afterClosed().subscribe(async dialogData => {
        if (
          dialogData?.submitClicked &&
          (dialogData?.reasonProvided || this.currentExperiment?.type == ExperimentType.ResearchAndDevelopment)
        ) {
          const details = [...newErrors, ...changes.map((c, i) => {
            return {
              type: c.field == 'InstrumentNumber' ? 1 : 2,
              detailId: this.currentErrors.find(e => e.errorId + "" == c.identifier)?.detailId,
              fieldReason: dialogData.fieldReasons[i]
            };
          })];

          this.apiService.createOrUpdateMultipleInstrumentErrors({
            experimentId: this.data.detail.experimentId,
            details: details,
            instrumentNumber: newInstrumentNumber,
            errorText: newErrorText,
            batchReason: dialogData.batchReason
          }).subscribe(() => {
            this.dialogRef.close({
              success: true, data: { value: { newErrorText } }, updated: true
            })
          }, () => {
            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
          this.notificationService.warn(':: Canceled');
        }
      });
    } else {
      this.apiService.createOrUpdateMultipleInstrumentErrors({
        experimentId: this.data.detail.experimentId,
        details: newErrors,
        instrumentNumber: newInstrumentNumber,
        errorText: newErrorText
      }).subscribe(() => {
        this.dialogRef.close({
          success: true, data: { value: { newErrorText } }, updated: false
        })
      }, () => {
        this.isLoading = false;
      });
    }
  }

  onClose(dialogResult: any) {
    this.dialogRef.close(dialogResult);
  }
}
