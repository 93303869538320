import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BarcodesTemplates } from 'src/app/barcode-print/barcode-print.component';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { Detail, User } from 'src/app/services/labpartner.service.model';
import { PAGE_NAME_PRINTRUNSHEET } from 'src/app/services/logging-constants';
import { LoggingService } from 'src/app/services/logging.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { BaseComponent } from 'src/app/support/base.component';
import { LoadBarcodes } from './LoadBarcodes';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-detail-list-print',
  templateUrl: './detail-list-print.component.html',
  styleUrls: ['./detail-list-print.component.scss']
})
export class DetailListPrintComponent extends LoadBarcodes implements OnInit {
  BarcodesTemplates = BarcodesTemplates;
  experimentId: number = 0;
  experimentLabel: string = '';
  detailsToPrint: Detail[] = [];
  detailIDs: number[] = [];
  isFilteredList: boolean = false;
  displayedColumns: string[] = ['sampleName', 'conditionName', 'replicateNo', 'barcode'];
  defaultColumns: any[] = [
    {
      header: 'Sample',
      dropdownHeader: 'Sample',
      index: 0,
      visible: true
    },
    {
      header: 'Condition',
      dropdownHeader: 'Condition',
      index: 1,
      visible: true
    },
    {
      header: 'Replicate #',
      dropdownHeader: 'Replicate #',
      index: 2,
      visible: true
    },
    {
      header: 'Barcode',
      dropdownHeader: 'Barcode [string]',
      index: 3,
      visible: true
    },
    {
      header: '',
      dropdownHeader: 'Barcode [graphic]',
      index: 4,
      visible: true
    }
  ];
  columnVisibilityOptions = this.defaultColumns.map(x => { return { header: x.dropdownHeader } });
  selectedColumns: any[] = this.columnVisibilityOptions;
  tableStateKey: string = "";
  tableConfig = {
    columns: this.defaultColumns
  }
  currentUser?: User;
  constructor(private apiService: LabpartnerService,
    public accountService: UserAccountService,
    private route: ActivatedRoute,
    private loggingService: LoggingService,
    public notificationService: NotificationService
  ) {
    super(notificationService);
  }

  ngOnInit(): void {

    this.experimentId = Number(this.route.snapshot.paramMap.get('id'));
    this.experimentLabel = this.apiService.padLeadingZeros(this.experimentId, 4);

    console.log('printing id ' + this.experimentId);

    var param = this.route.snapshot.queryParamMap.get('ids');
    if (param != undefined) {
      this.detailIDs = param.split('|').map(x => parseInt(x));
      this.isFilteredList = true;
    }

    this.renderPrintableList();

    this.loggingService.logPageView(PAGE_NAME_PRINTRUNSHEET);

    this.accountService.currentUser$.subscribe(user => {
      this.currentUser = user;
      if (!user) return;
      this.tableStateKey = "Run_Sheet_" + this.currentUser?.userId + "_" + this.experimentId;
      this.loadTable();
    });

  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  private renderPrintableList() {
    this.subscription.add(
      this.apiService.getDetailsList('experimentId', this.experimentId).subscribe((details: Detail[]) => {
        if (details.length == 0) {
          // TODO: show message that we didnt find any details
          return;
        }
  
        this.apiService.detailsDefaultSort(details);
  
        if (this.isFilteredList) {
          // filter the list of details to only those that are passed in on the query string
          this.detailsToPrint = details.filter(d => {
            return this.detailIDs.includes(d.detailId);
          });
        } else {
          this.detailsToPrint = details;
          this.barcodes = this.detailIDs.map(d=>"");
        }
        this.isLoading = true;
        this.barcodes = this.detailsToPrint.map(d=>"");
      })
    );
  }

  onColumnSelectionChange(evt: any) {
    const selectedColumns = this.selectedColumns.map(x => x.header);

    this.tableConfig.columns.forEach(c => {
      c.visible = selectedColumns.includes(c.dropdownHeader)
    });
    this.selectedColumns = this.tableConfig.columns
      .filter(c => c.visible)
      .sort((a, b) => a.index > b.index ? 1 : -1)
      .map(c => { return { header: c.dropdownHeader } });
    this.saveTable();
  }

  saveTable() {
    if (!this.tableStateKey) return;

    window.localStorage.setItem(this.tableStateKey, JSON.stringify(this.tableConfig.columns));
  }

  loadTable() {
    let tableString = window.localStorage.getItem(this.tableStateKey);
    if (tableString) {
      this.tableConfig.columns = JSON.parse(tableString);
      this.selectedColumns = this.tableConfig.columns
        .filter(x => x.visible)
        .map(x => { return { header: x.dropdownHeader } });
      this.onColumnSelectionChange(null);
    }
  }

  moveColumnRight(index: number) {
    const col1 = this.tableConfig.columns.find(x => x.dropdownHeader == this.selectedColumns[index].header)
    const col2 = this.tableConfig.columns.find(x => x.dropdownHeader == this.selectedColumns[index + 1].header)

    var tmp = col1.index;
    col1.index = col2.index;
    col2.index = tmp;

    this.onColumnSelectionChange(null);
  }

  moveColumnLeft(index: number) {
    const col1 = this.tableConfig.columns.find(x => x.dropdownHeader == this.selectedColumns[index].header)
    const col2 = this.tableConfig.columns.find(x => x.dropdownHeader == this.selectedColumns[index - 1].header)

    var tmp = col1.index;
    col1.index = col2.index;
    col2.index = tmp;

    this.onColumnSelectionChange(null);
  }
}
