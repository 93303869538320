import { Injectable, QueryList } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { ColumnFilterComponent } from './column-filter.component';

// Used to track column filters in a centralized
// manner by table and column name
export interface ITableColumnFilter {
  experiment?: { [columnName: string]: Set<string> };
  detail?: { [columnName: string]: Set<string> };
}

// Used to track per-filter-value counts based on the application
// of - other - active table filters by table and column name
export interface ITableColumnRowCount {
  experiment?: { [columnName: string]: { [value: string]: number } };
  detail?: { [columnName: string]: { [value: string]: number } };
}

@Injectable({
  providedIn: 'root',
})
export class ColumnFilterService {
  tableFiltersUpdated$: Subject<keyof ITableColumnFilter | 'all'> = new Subject<keyof ITableColumnFilter | 'all'>();
  tableFiltersReset$: Subject<keyof ITableColumnFilter | 'all'> = new Subject<keyof ITableColumnFilter | 'all'>();

  filterMap: ITableColumnFilter = {};
  displayFilterMap: ITableColumnFilter = {};
  fadeoutFilterMap: ITableColumnRowCount = {};

  private _filterPanelOpened: Subject<string> = new Subject<string>();
  filterPanelOpened$ = this._filterPanelOpened.asObservable();

  getDisplayTableFilters(tableName: keyof ITableColumnFilter) {
    return this.displayFilterMap[tableName] ?? {};
  }

  getDisplayTableFilterValues(tableName: keyof ITableColumnFilter, columnName: string) {
    return this.getDisplayTableFilters(tableName)[columnName] ?? new Set<string>();
  }

  getActiveTableFilters(tableName: keyof ITableColumnFilter) {
    return this.filterMap[tableName] ?? {};
  }

  getActiveTableFilterValues(tableName: keyof ITableColumnFilter, columnName: string) {
    return this.getActiveTableFilters(tableName)[columnName] ?? new Set<string>();
  }

  getTableFilterColumns(tableName: keyof ITableColumnFilter) {
    return Object.keys(this.filterMap[tableName] ?? {});
  }

  getOtherFiltersRowCounts(tableName: keyof ITableColumnFilter, columnName: string) {
    return (this.fadeoutFilterMap[tableName] ?? {})[columnName] ?? {};
  }

  setTableFilterValues(tableName: keyof ITableColumnFilter, columnName: string, filterValues: string[]) {
    this.filterMap[tableName] = this.filterMap[tableName] ?? {};
    this.filterMap[tableName]![columnName] = new Set();
    filterValues.forEach(fv => {
      this.filterMap[tableName]![columnName].add(fv);
    });
    this.tableFiltersUpdated$.next(tableName);
  }

  refreshQueryListDisplayFilters(
    filterComponents: QueryList<ColumnFilterComponent>,
    tableData: MatTableDataSource<any>,
    searchKey: string
  ) {
    // Close any open filter panels during refresh to avoid filter panels getting out of sync
    // or filters being applied that are not yet actively applied by the user
    filterComponents.filter(x => x.filterPanel?.overlayVisible ?? false).forEach(x => x.closeFilterPanel());

    const filteredColumns = filterComponents.filter(x => x.selectedFilterOptions.length > 0).map(x => x.columnName);
    filterComponents.forEach(columnFilter => {
      this.displayFilterMap[columnFilter.tableName] = this.displayFilterMap[columnFilter.tableName] ?? {};
      this.displayFilterMap[columnFilter.tableName]![columnFilter.columnName] =
        this.displayFilterMap[columnFilter.tableName]![columnFilter.columnName!] ?? new Set<string>();

      this.displayFilterMap[columnFilter.tableName]![columnFilter.columnName!].clear();

      this.fadeoutFilterMap[columnFilter.tableName] = this.fadeoutFilterMap[columnFilter.tableName] ?? {};
      this.fadeoutFilterMap[columnFilter.tableName]![columnFilter.columnName] = {};

      const rowKeys = tableData.data.length ? Object.keys(tableData.data[0]) : [];
      tableData.data.forEach((row: any) => {
        this.displayFilterMap[columnFilter.tableName]![columnFilter.columnName!].add(row[columnFilter.columnName!]);

        const matchesOtherFilters = filteredColumns
          .filter(x => x != columnFilter.columnName)
          .map(fc => this.filterMap[columnFilter.tableName]![fc].has((row as any)[fc]))
          .every(matchesFilters => matchesFilters);

        const matchesGlobalFilter = rowKeys.some(
          x => !!row && !!row[x] && row[x].toString().toLowerCase().indexOf(searchKey.toLowerCase()) != -1
        );

        this.fadeoutFilterMap[columnFilter.tableName]![columnFilter.columnName!]![row[columnFilter.columnName!]] =
          this.fadeoutFilterMap[columnFilter.tableName]![columnFilter.columnName!]![row[columnFilter.columnName!]] ?? 0;

        if (matchesOtherFilters && (!searchKey || (searchKey && matchesGlobalFilter))) {
          this.fadeoutFilterMap[columnFilter.tableName]![columnFilter.columnName!]![row[columnFilter.columnName!]]++;
        }
      });
    });
  }

  deleteQueryListFilters(filterComponents: QueryList<ColumnFilterComponent>) {
    let tableName: keyof ITableColumnFilter | undefined = undefined;
    filterComponents
      .filter(x => x.filterActive)
      .forEach(columnFilter => {
        tableName = tableName ?? columnFilter.tableName;
        this.filterMap[columnFilter.tableName] = this.filterMap[columnFilter.tableName] ?? {};
        delete this.filterMap[columnFilter.tableName]![columnFilter.columnName];
        columnFilter.filterActive = false;
        columnFilter.selectedFilterOptions = [];
        columnFilter.oldSelectedFilterOptions = [];
      });

    if (!tableName) { return; }
    this.tableFiltersUpdated$.next(tableName);
  }

  resetTableFilter(tableName: keyof ITableColumnFilter) {
    this.tableFiltersReset$.next(tableName);
  }

  clearTableColumnFilter(tableName: keyof ITableColumnFilter, columnName: string) {
    this.filterMap[tableName] = this.filterMap[tableName] ?? {};
    delete this.filterMap[tableName]![columnName];
    this.tableFiltersUpdated$.next(tableName);
  }

  filterPanelOpened(colName: string) {
    this._filterPanelOpened.next(colName);
  }
}
