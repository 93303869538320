import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatConfirmDialogComponent } from 'src/app/mat-confirm-dialog/mat-confirm-dialog.component';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { AnalyteTemplate, AssayName } from 'src/app/services/labpartner.service.model';
import { DialogService } from 'src/app/shared/dialog.services';
import { NotificationService } from 'src/app/shared/notification.service';
import { BaseComponent } from 'src/app/support/base.component';

@Component({
    selector: 'app-assay-dialog',
    templateUrl: './assay-dialog.component.html',
    styleUrls: ['./assay-dialog.component.scss']
})
export class AssayDialogComponent extends BaseComponent implements OnInit {
    fileName: string = "";

    assay: AssayName = {
        analyteTemplates: [] as AnalyteTemplate[]
    } as AssayName;

    constructor(
        private dialog: MatDialog,
        private dialogService: DialogService,
        private labpartnerService: LabpartnerService,
        private notificationService: NotificationService,
        public dialogRef: MatDialogRef<AssayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
    }

    ngOnInit(): void { }

    // This method is called when the component is destroyed
    protected ngOnDestroyInternal(): void {

    }

    // This method is called when the user selects a file
    onFileChange(ev: any) {
        const reader = new FileReader();

        const file = ev.target.files[0] as File;
        this.fileName = file.name;

        if (!file.name.endsWith('.amf')) {
            return this.notifyWithErrorMessage('Uploaded file must be an AMF file.');
        }

        reader.onload = (event) => {
            const data = reader.result as string;

            // Remove checksum and parse to json
            let jsonData;
            try {
                jsonData = JSON.parse(data.split('\n').slice(1).join('\n'));

                this.assay = {
                    assayId: jsonData.id,
                    assayRevision: jsonData.revision,
                    name: jsonData.name,
                    shortName: jsonData.short_name,
                    instrument: jsonData.assay_type,
                    analyteTemplates: jsonData.targets.map((t: any) => {
                        return ({
                            name: t.name,
                            shortName: t.short_name,
                            chamber: t.chamber,
                            channel: t.channel,
                            displaySeq: t.display_seq,
                            groupId: t.group_id ? t.group_id : null
                        });
                    })
                }
                if (!this.assayIsValid()) {
                    this.assayInvalidDialog();
                }
            }
            catch (ex) {
                this.assayInvalidDialog();
                return this.notifyWithErrorMessage('AMF file has incorrect format.');
            }
        }

        reader.readAsText(file);
    }

    // This method is called when the user clicks the cancel button
    cancel() {
        this.dialogRef.close();
    }

    // This method is called when the user clicks the save button
    save() {
        this.subscription.add(
            this.labpartnerService.getAssayNameByIdAndRevision(this.assay.assayId, this.assay.assayRevision).subscribe((assay) => {
                if (assay) {
                    this.dialogService.openConfirmDialog('An assay already exists with the same name, ID and revision.\n' +
                        'If you overwrite this existing assay AMF, past experiments with results\n' +
                        'that refer to this assay may not be found or show incorrectly in reports.\n' +
                        '\n' +
                        'Would you like to continue and overwrite the\n' +
                        'existing AMF?'
                        , { width: '500px', panelClass: 'confirm-dialog-container-pre-wrap' }).afterClosed().subscribe((result) => {
                            if (result) {
                                this.createOrUpdateAssay();
                            }
                            else {
                                this.notificationService.warn(':: Cancelled');
                            }
                        });
                }
                else {
                    this.createOrUpdateAssay();
                }
            })
        );

    }

    // This method is called to create or update the assay
    createOrUpdateAssay() {
        this.subscription.add(
            this.labpartnerService.createOrUpateAssayName(this.assay).subscribe(() => {
                this.notificationService.success('Assay saved successfully.');
                this.dialogRef.close(true);
            }, (error) => {
                this.notifyWithErrorMessage('Failed to save assay.');
            })
        );
    }

    assayInvalidDialog() {
        this.dialogService.openConfirmDialog('The AMF does not have the required data to store the assay.\n',
            {
                width: '500px',
                panelClass: 'confirm-dialog-container-pre-wrap',
                isOk: true
            })
            .afterClosed().subscribe(() => { });
    }

    // This method is called to determine if the assay is valid
    assayIsValid() {
        return (
            this.assay.assayId &&
            this.assay.assayRevision &&
            this.assay.name &&
            this.assay.shortName &&
            this.assay.instrument &&
            this.assay.analyteTemplates.filter(t => !t.name || !t.shortName || !t.chamber || !t.channel).length == 0
        );
    }

    private notifyWithErrorMessage(msg: string): void {
        this.notificationService.error(msg);
        this.fileName = '';
        return;
    }
}
