<!-- Title Container -->
<mat-toolbar color="primary">
    <span>Assay</span>
</mat-toolbar>
<div class="body-container">
    <!-- Import File Button -->
    <div class="import-file-container">
        <span>Upload the AMF to charge the info</span>
        <button id="select-amf-input" mat-raised-button color="primary" (click)="hiddenfileinput.click()">
            <mat-icon>upload</mat-icon>Import AMF
        </button>

        <input
            type="file"
            onclick="this.value = null"
            (change)="onFileChange($event)"
            style="display: none"
            #hiddenfileinput />
    </div>
    <!-- Assay Info -->
    <div class="assay-info-container">
        <div class="row">
            <div class="label">Assay Id:</div>
            <div class="value">{{assay.assayId}}</div>
            <div class="label">Assay Short Name:</div>
            <div class="value">{{assay.shortName}}</div>
            <div class="label">Instrument</div>
            <div class="value">{{assay.instrument}}</div>

        </div>
        <div class="row">
            <div class="label">Revision:</div>
            <div class="value">{{assay.assayRevision}}</div>
            <div class="label">Assay Name:</div>
            <div class="value">{{assay.name}}</div>
        </div>
    </div>
    <!-- Analytes Info -->
    <div class="analytes-info-container">
        <div class="row sticky">
            <div class="label-lg">Analyte Name:</div>
            <div class="label-lg">Analyte Short Name:</div>
            <div class="label-lg">Chamber:</div>
            <div class="label-lg">Channel:</div>
            <div class="label-lg">GroupId:</div>
        </div>
        <div class="row" *ngFor="let analyte of assay.analyteTemplates">
            <div class="value">{{analyte.name}}</div>
            <div class="value">{{analyte.shortName}}</div>
            <div class="value">{{analyte.chamber}}</div>
            <div class="value">{{analyte.channel}}</div>
            <div class="value">{{analyte.groupId}}</div>
        </div>
    </div>
    <!-- Actions Sections -->
    <div class="actions">
        <div class="separator"></div>
        <button mat-raised-button color="light" (click)="cancel()">Cancel</button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!assayIsValid()">Save</button>
    </div>
</div>