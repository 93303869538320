<div class="experiment-dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary">
    <div>{{ data.isEditMode ? 'Edit' : 'Create' }} Experiment</div>
  </mat-toolbar>
</div>

<form [formGroup]="experimentForm" class="normal-form" (submit)="onSubmit()">
  <div mat-dialog-content class="experiment-dialog-content">
    <div class="experiment-controls-group">
      <div class="p-default-form-field p-float-label bottom-border-only">
        <input pInputText id="name" type="text" aria-describedby="name-error" formControlName="name" />
        <label for="name">Name</label>
        <small id="name-error" class="p-error" *ngIf="experimentForm.controls.name.errors?.required">
          This field is mandatory.
        </small>
      </div>

      <ng-container *ngIf="(featureFlags$ | async)?.EnableAutomatedReporting && !showOldForm()">
        <div class="p-default-form-field p-float-label p-static-float">
          <p-dropdown
            id="study-type"
            styleClass="study-type-dropdown"
            appendTo="body"
            [options]="studyTypes"
            optionLabel="name"
            optionValue="id"
            (onChange)="validateStudyType()"
            formControlName="studyType"></p-dropdown>
          <label for="device-type">Study Type</label>
        </div>
      </ng-container>

      <div class="p-default-form-field p-float-label bottom-border-only"
        *ngIf="experimentForm.controls.studyType.value === StudyType.ImportExcel || showOldForm()">
        <input pInputText id="assay-name" type="text" aria-describedby="assay-name-error" formControlName="assayName" />
        <label for="assay-name">Assay Name</label>
      </div>

      <div class="p-default-form-field p-float-label p-static-float"
        *ngIf="experimentForm.controls.studyType.value !== StudyType.ImportExcel && !showOldForm()">
        <p-dropdown
          id="assay-name-dropdown"
          styleClass="assay-name-dropdown"
          appendTo="body"
          [options]="assayNames"
          optionLabel="name"
          optionValue="id"
          optionDisabled="inactive"
          formControlName="assayNameId"></p-dropdown>
        <label for="assay-name-dropdown">Assay Name</label>
        <small id="assay-name-error" class="p-error" *ngIf="experimentForm.controls.assayNameId.errors?.required">
          Assay Name is required.
        </small>
      </div>

      <div class="p-default-form-field p-float-label bottom-border-only" *ngIf="!(featureFlags$ | async)?.EnableAutomatedReporting || showOldForm()">
        <input pInputText id="protocol-number" type="text" formControlName="protocolNumber" />
        <label for="protocol-number">Protocol Number</label>
      </div>
    </div>
    <div class="experiment-controls-group">
      <ng-container *ngIf="(featureFlags$ | async)?.EnableAutomatedReporting" && !showOldForm()>
        <div class="p-default-form-field p-float-label bottom-border-only">
          <input pInputText id="protocol-number" type="text" formControlName="protocolNumber" />
          <label for="protocol-number">Protocol Number</label>
        </div>  
      </ng-container>
      
      <div class="p-default-form-field p-float-label p-static-float">
        <p-dropdown
          id="device-type"
          styleClass="device-type-dropdown"
          appendTo="body"
          [options]="devices"
          optionLabel="value"
          optionValue="id"
          optionDisabled="inactive"
          formControlName="deviceType"></p-dropdown>
        <label for="device-type">Device Type</label>
      </div>

      <div class="p-default-form-field p-float-label bottom-border-only">
        <input pInputText id="replicates" type="number" formControlName="replicates" max="1000" />
        <label for="replicates">Default Replicates</label>
        <small id="replicates-error-required" class="p-error" *ngIf="experimentForm.controls.replicates.errors?.required">
          Maximum 1000 replicates.
        </small>
        <small id="replicates-error-value" class="p-error" *ngIf="experimentForm.controls.replicates.errors?.max || experimentForm.controls.replicates.errors?.min">
          Replicates must be between 1 and 1000 inclusive.
        </small>
      </div>

      <div class="radio-and-created-by">
        <div class="p-default-form-field p-float-label bottom-border-only" *ngIf="!hideExperimentType">
          <label class="custom-label" for="type"> Experiment Type </label>
          <div class="radio-btns">
            <p-radioButton
              name="type"
              styleClass="exp-radio-btn"
              labelStyleClass="exp-radio-btn-label"
              *ngFor="let experimentTypeDisplayName of ExperimentTypeDisplayNames | keyvalue; let idx = index"
              [value]="ExperimentTypeIndexes.indexOf(experimentTypeDisplayName.key)"
              [label]="experimentTypeDisplayName.value"
              formControlName="type"></p-radioButton>
          </div>
        </div>

        <div class="p-default-form-field p-float-label bottom-border-only" *ngIf="data.isEditMode || data.isReadOnly">
          <label class="custom-label"> Created By </label>
          <div class="created-by-name">
            {{ data.experiment.createdByName }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="experiment-dialog-actions">
    <div class="button-row" *ngIf="!data.isReadOnly">
      <button mat-raised-button color="warn" type="button" (click)="onCancel()" [disabled]="isLoading">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="experimentForm.invalid || isLoading">
        Ok
      </button>
    </div>
    <div class="button-row" *ngIf="data.isReadOnly">
      <button mat-raised-button color="primary" type="button" (click)="onCancel()">Close</button>
    </div>
  </div>
</form>
