<div class="search-div">
  <button
    pButton
    pRipple
    type="button"
    class="p-button-raised p-button-default"
    icon="pi pi-plus"
    iconPos="left"
    label="Create"
    (click)="onCreate()"
    [disabled]="!(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsAnyStatus')"></button>
  <span class="p-input-icon-right">
    <input
      type="text"
      pInputText
      placeholder="Search"
      autocomplete="off"
      [(ngModel)]="searchKey"
      (ngModelChange)="onSearchChange($event)" />
    <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
      <i class="pi pi-times" *ngIf="searchKey"></i>
    </span>
  </span>
  <div class="separator"></div>
  <label for="date-filter">
    Show experiments from:
  </label>
  <mat-select class="date-filter" id="date-filter" [(ngModel)]="experimentMonthFilter" name="date-filter" (selectionChange)="loadData()">
    <mat-option [value]="6">
      Last 6 months
    </mat-option>
    <mat-option [value]="12">
      Last year
    </mat-option>
    <mat-option [value]="0">
      All
    </mat-option>
  </mat-select>
  <mat-slide-toggle color="primary" [checked]="showArchivedExperiments" (change)="showArchivedExperimentsToggle()">
    Show archived experiments
  </mat-slide-toggle>
</div>
<div class="mat-elevation-z8 table-container">
  <mat-table
    [dataSource]="listData"
    matSort
    matSortActive="dateCreated"
    matSortDirection="desc"
    (contentChanged)="tableDataChanged()">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a
          [routerLink]="['/experiment-detail', element.experimentId]"
          (click)="onOpenExperiment(element)"
          matTooltip="Open this experiment">
          {{ element.name }}
        </a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="experimentId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Exp ID </mat-header-cell>
      <mat-cell *matCellDef="let element"
        >{{ element.experimentId | experimentFormat : element.type }}
        <button
          *ngIf="element.isArchived"
          mat-icon-button
          (click)="onUnarchive(element)"
          matTooltip="Unarchive the experiment"
          [disabled]="!(currentRoles | hasAnyRoles : ['ADMIN', 'WRITER'] : element : 'IsAnyStatus')">
          <mat-icon>unarchive</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="assayName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Assay Name
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="assayName"
          displayName="Assay Name"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ getAssayName(element) }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="protocolNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Protocol Number
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="protocolNumber"
          displayName="Protocol Number"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.protocolNumber }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="replicates">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Replicate No</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.replicates }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="deviceName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Device
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="deviceName"
          displayName="Device"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.deviceName }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="expStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Status
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="expStatus"
          displayName="Status"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.expStatus }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="dateCreated">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.dateCreated | date : 'shortDate' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdByName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Created By
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="createdByName"
          displayName="Created By"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.createdByName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="action-column"></mat-header-cell>
      <mat-cell *matCellDef="let row" class="action-column">
        <button
          mat-icon-button
          (click)="onEdit(row)"
          matTooltip="{{
            (currentUser | canEditExperiment : currentRoles : row) ? 'Edit the experiment' : 'View experiment settings'
          }}">
          <mat-icon>{{ (currentUser | canEditExperiment : currentRoles : row) ? 'edit' : 'info_outline' }}</mat-icon>
        </button>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          matTooltip="Change the owner of the experiment"
          [disabled]="!(currentRoles | hasAnyRoles : ['ADMIN'] : row : 'IsAnyStatus')">
          <mat-icon>manage_accounts</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngFor="let user of accountService.getUsers()"
            (click)="onChangeOwner(row, user.userId)"
            [value]="user.userId"
            [disabled]="loading">
            {{ user.displayName }}
          </button>
        </mat-menu>

        <app-note-display
          [experimentId]="row.experimentId"
          [noteCount]="allExperimentNoteCounts && allExperimentNoteCounts[row.experimentId]"
          [objectID]="row.experimentId"
          [objectName]="objectName">
        </app-note-display>
        <button
          *ngIf="!row.isArchived"
          mat-icon-button
          (click)="onArchive(row)"
          matTooltip="Archive the experiment"
          [disabled]="!(currentRoles | hasAnyRoles : ['ADMIN', 'WRITER'] : row : 'IsAnyStatus')">
          <mat-icon>archive</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6"> No data. </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: listData != null }"></mat-footer-row>
    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }"></mat-footer-row>
  </mat-table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
