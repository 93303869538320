import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExperimentService } from 'src/app/shared/experiment.service';
import { MatDialogRef } from '@angular/material/dialog';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { MatSort } from '@angular/material/sort';
import {
  AssayName,
  Bundle,
  BundleStudyType,
  BundleStudyTypes,
  Experiment,
  ExperimentExtended,
  NotesCountMap,
  StudyType,
  StudyTypes,
  User,
} from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';
import { UserAccountService } from 'src/app/services/user-account.service';
import { LoggingService } from 'src/app/services/logging.service';
import { EVENT_CREATE_BUNDLE, PAGE_NAME_EXPERIMENTSLIST } from 'src/app/services/logging-constants';
import { NotificationService } from 'src/app/shared/notification.service';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppStateService } from 'src/app/services/app-state.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BundleService } from 'src/app/shared/bundle.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BundleComponent } from '../bundle/bundle.component';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'app-experiment-list-for-bundle',
  templateUrl: './experiment-list-for-bundle.component.html',
  styleUrls: ['./experiment-list-for-bundle.component.scss'],
})
export class ExperimentListForBundleComponent extends BaseComponent implements OnInit, OnDestroy {
  public experiments!: Experiment[];
  public objectName = 'Experiment';
  public CanCreate = false;
  public displayedColumns = [
    'actionsAdd',
    'experimentId',
    'name',
    'protocolNumber',
    'studyType',
    'assayName',
    'createdByName',
    'device',
    'dateCreated',
  ];
  public selectedDisplayedColumns = [
    'experimentId',
    'name',
    'protocolNumber',
    'studyType',
    'assayName',
    'actionsRemove',
  ];
  @ViewChild(MatTable) table!: MatTable<any>;
  experimentsData!: MatTableDataSource<any>;
  bundleData!: MatTableDataSource<any>;
  @ViewChild('allExperimentsSort') allExperimentsSort!: MatSort;
  @ViewChild('bundleExperimentsSort') bundleExperimentsSort!: MatSort;
  sort!: MatSort;
  @ViewChild('paginatorAllExperiments') paginatorAllExperiments!: MatPaginator;
  @ViewChild('paginatorBundleExperiments') paginatorBundleExperiments!: MatPaginator;

  paginator!: MatPaginator;
  searchKey: string = '';
  devices!: any;
  status!: any;
  allExperimentNoteCounts?: NotesCountMap;

  currentExperiment: Experiment | null = null;
  selectedExperiments: Experiment[] = [];
  currentUser?: User;
  currentRoles: string[] = [];
  wasCanceled: boolean = false;
  isCurtainBlocked: boolean = false;
  isClinicalTrialChecked: boolean = false;
  showClinicalTrial: boolean = false;
  disableClinicalTrialCheckBox: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  selectedBundleTypeId: number = 1;
  bundleId: number | undefined;
  bundleExperimentIds!: string;

  StudyType = StudyType
  studyTypes: any[] = StudyTypes;
  BundleStudyType = BundleStudyType;
  bundleStudyTypes: any[] = BundleStudyTypes;

  assayNames: AssayName[] = [];

  constructor(
    public bundleService: BundleService,
    private experimentService: ExperimentService,
    private apiService: LabpartnerService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    private notificationService: NotificationService,
    private loggingService: LoggingService,
    public dialogRef: MatDialogRef<BundleComponent>
  ) {
    super();
  }

  async ngOnInit() {
    /********************** Experiments ************************/
    await this.accountService.SetUserList();
    this.accountService.currentUser$
      .pipe(
        takeUntil(this._destroying$),
        filter(u => u != undefined),
        switchMap(u => {
          this.currentUser = u!;
          return this.apiService.getExperimentsList();
        })
      )
      .subscribe(experiments => this.parseExperimentsForDisplay(experiments));
    this.loggingService.logPageView(PAGE_NAME_EXPERIMENTSLIST);

    /***********************  Bundles ************************/
    this.selectedBundleTypeId = this.bundleService.form.value.bundleType;
    this.bundleId = this.bundleService.form.value.$key;
    this.bundleExperimentIds = this.bundleService.form.value.experimentIds;
    this.showClinicalTrial = this.accountService.isClinicalTrial(); //if clincial trial role

    if (this.bundleService.form.value.isClinicalTrial) {
      this.isClinicalTrialChecked = true;
      this.disableClinicalTrialCheckBox = true;
    }

    this.subscription.add(
      this.bundleService.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        // this.setIsValid();    // used for form debugging
      })
    );


    this.subscription.add(
      this.accountService.currentRoles$.subscribe(roles => {
        this.currentRoles = roles;
      }));

    this.subscription.add(
      this.apiService.getAssayNames(true).subscribe(assayNames => {
        this.assayNames = assayNames;
        if (this.assayNames.length > 0) {
          this.bundleService.form.controls.assayNameId.setValue(this.assayNames[0].id);
        }
      })
    );
  }

  setIsValid(): void {
    const form = this.bundleService.form;
    let isFormValid = form.valid;
    if (!isFormValid) {
      this.displayInvalidFormFields(form);
    }
  }

  private parseExperimentsForDisplay(experiments: Experiment[]) {
    this.experiments = experiments;
    for (const experiment of this.experiments as ExperimentExtended[]) {
      experiment.createdByName = this.accountService.getUserName(experiment.createdBy);
      experiment.deviceName = this.experimentService.getDeviceName(experiment.deviceType);
      experiment.expStatus = this.experimentService.getStatus(experiment.status);
    }

    // populates table with all experiments
    this.experimentsData = new MatTableDataSource(this.experiments);
    this.experimentsData.sort = this.allExperimentsSort;
    this.experimentsData.paginator = this.paginatorAllExperiments;
    this.experimentsData.filterPredicate = (data, filter) => {
      return this.displayedColumns.some((ele: string) => {
        if (data[ele] != undefined) {
          let value = data[ele];
          if (ele == 'studyType') {
            value = this.getStudyTypeName(data[ele]);
          }
          else if (ele == 'assayName' && data['assayNameId'] != null) {
            value = this.getAssayName(data['assayNameId']);
          }
          return value.toString().toLowerCase().indexOf(filter) != -1;
        } else {
          return false
        }
      });
    };

    // populates table with user added experiments
    if (this.bundleId == null) {
      this.bundleData = new MatTableDataSource();
    } else {
      this.selectedExperiments = this.getExperimentsByExperimentId(this.bundleExperimentIds);
      this.bundleData = new MatTableDataSource(this.selectedExperiments);

      this.filterExperimentsByStudyType(false);
    }

    this.bundleData.sort = this.bundleExperimentsSort;
    this.bundleData.paginator = this.paginatorBundleExperiments;
    this.bundleData.filterPredicate = (data, filter) => {
      return this.displayedColumns.some(ele => {
        return data[ele] != undefined && ele != 'actions' && data[ele].toString().toLowerCase().indexOf(filter) != -1;
      });
    };
  }

  getExperimentsByExperimentId(bundleExperimentIds: string): Experiment[] {
    let selectedExperiments: Experiment[] = [];
    let experimentIds = bundleExperimentIds.split(',').map(Number);
    experimentIds.forEach(experimentId => {
      let experiment = this.experiments.filter(x => {
        return x.experimentId === experimentId
      });

      selectedExperiments.push(experiment[0]);
    });

    return selectedExperiments;
  }


  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  onSearchClear() {
    setTimeout(() => {
      this.searchKey = '';
      this.onChange('');
    });
  }

  onChange(newVal: string) {
    this.experimentsData.filter = this.searchKey.trim().toLowerCase();
  }

  onCancel() {
    this.wasCanceled = true;
    this.notificationService.warn(':: Canceled');
    let dialogresult = { ok: false, data: null };
    if (this.selectedExperiments.length < 1) {
      this.onClose(dialogresult);
    }
    this.isCurtainBlocked = false;
    this.dialogRef.close(dialogresult);
  }

  onClose(dialogresult: any) {
    this.bundleService.form.reset();
    this.bundleService.initializeExperimentFormGroup();
    this.isCurtainBlocked = false;
    this.dialogRef.close(dialogresult);
  }

  toggleClinicalStatus($event: MatCheckboxChange): void {
    this.isClinicalTrialChecked = !this.isClinicalTrialChecked;
    console.log('isClinicalTrialChecked: ', this.isClinicalTrialChecked);
  }

  onSubmit(data: any) {
    this.isCurtainBlocked = true;
    if (this.wasCanceled) return;
    if (this.isClinicalTrialChecked) {
      data.isClinicalTrial = true;
    }
    console.log(data);

    if (!this.bundleService.form.get('$key')!.value) {
      console.log('inserting new record');
      const sub = this.apiService.createExperimentIdsBundle(data).subscribe(
        (result: Bundle) => {
          this.notificationService.success(':: Bundle created successfully');
          let dialogresult = { ok: true, data: result };
          this.onClose(dialogresult);

          const props: { [key: string]: any } = {
            BundleId: result.bundleId,
            IsUsedToGenerateReport: result.isUsedToGenerateReport,
            BundleStudyType: "",
            AssayName: ""
          };

          if (result.bundleStudyType != null) {
            props.BundleStudyType = this.bundleStudyTypes.find(x => x.id == result.bundleStudyType)?.name;
            props.AssayName = this.assayNames.find(x => x.id == result.assayNameId)?.name;
          }

          this.loggingService.logEvent(EVENT_CREATE_BUNDLE, props);
        },
        (err: any) => {
          // TODO: show an error with toast, etc.
          this.notificationService.warn(':: Error creating Bundle');
          console.log(err);
          let dialogresult = { ok: false, data: err };
          this.onClose(dialogresult);
        }
      );
      this.subscription.add(sub);
    } else {
      console.log('updating existing record');



      const sub = this.apiService.updateBundle(this.bundleService.form.get('$key')!.value, data).subscribe(
        (result: Bundle) => {
          this.notificationService.success(':: Bundle updated successfully');
          let dialogresult = { ok: true, data: result };
          this.onClose(dialogresult);
        },
        (err: any) => {
          // TODO: show an error with toast, etc.
          this.notificationService.warn(':: Error updating Bundle');
          let dialogresult = { ok: false, data: err };
          console.log(err);
          this.onClose(dialogresult);
        }
      );
      this.subscription.add(sub);
    }
  }

  showAddButton(row: any): boolean {
    return this.bundleData.data.includes(row);
  }


  onAdd(row: any) {
    this.bundleData.data = [...this.bundleData.data, row];
    this.selectedExperiments = [...this.selectedExperiments, row];
    const experimentIds = this.selectedExperiments.map(x => x.experimentId.toString());
    this.bundleExperimentIds = experimentIds.toString();
    this.bundleService.form.patchValue({
      experimentIds: this.bundleExperimentIds,
    });
    this.bundleData._updateChangeSubscription();

    if (this.bundleService.form.controls.isUsedToGenerateReport.value && row.studyType == StudyType.DailyExternalControls) {
      this.filterExperimentsByStudyType(false);
    }
  }

  onDelete(row: any) {
    const indexInBundleData = this.bundleData.data.findIndex(item => item.experimentId == row.experimentId);
    if (indexInBundleData !== -1) {
      this.bundleData.data.splice(indexInBundleData, 1);
    }
    const indexInSelectedExperiments = this.selectedExperiments.findIndex(item => item.experimentId == row.experimentId);
    if (indexInSelectedExperiments !== -1) {
      this.selectedExperiments.splice(indexInSelectedExperiments, 1);
    }
    const experimentIds = this.selectedExperiments.map(x => x.experimentId.toString());
    this.bundleExperimentIds = experimentIds.toString();
    this.bundleService.form.patchValue({
      experimentIds: this.bundleExperimentIds,
    });
    this.bundleData._updateChangeSubscription();

    if (this.bundleService.form.controls.isUsedToGenerateReport.value && row.studyType == StudyType.DailyExternalControls) {
      this.filterExperimentsByStudyType(true);
    }
  }

  displayInvalidFormFields(form: UntypedFormGroup): void {
    if (!form.valid) {
      let invalids = this.findInvalidControlsRecursive(form);
      console.log(invalids);
    }
  }

  // Returns an array of invalid control/group names, or a zero-length array if
  // no invalid controls/groups where found.
  findInvalidControlsRecursive(formToInvestigate: UntypedFormGroup | UntypedFormArray): string[] {
    var invalidControls: string[] = [];
    let recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control?.invalid) invalidControls.push(field);
        if (control instanceof UntypedFormGroup) {
          recursiveFunc(control);
        } else if (control instanceof UntypedFormArray) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  isFormDisabled() {
    if (!this.bundleService.form.controls.isUsedToGenerateReport.value) {
      return this.bundleService.form.invalid;
    }
    else {
      return this.bundleService.form.invalid
        || this.selectedExperiments.length < 2
        || !this.selectedExperiments.some(x => x.studyType == StudyType.DailyExternalControls);
    }
  }

  onChangeReportFlag() {
    this.selectedExperiments = [];
    this.bundleData.data = [];
    if (!this.bundleService.form.controls.isUsedToGenerateReport.value) {
      this.experimentsData.data = this.experiments;

    } else {
      this.experimentsData.data = [];
      this.bundleService.form.controls.bundleStudyType.setValue(this.bundleStudyTypes[0].id);
      this.onChangeStudyType();
    }

    this.experimentsData._updateChangeSubscription();
  }

  onChangeStudyType() {
    this.selectedExperiments = [];
    this.bundleData.data = [];
    if (this.bundleService.form.controls.bundleStudyType.value != null) {
      this.filterExperimentsByStudyType(true);
    }
  }

  getStudyTypeName(id: number) {
    return this.studyTypes.find(x => x.id == id)?.name;
  }

  getAssayName(id: number) {
    return this.assayNames.find(x => x.id == id)?.name;
  }

  filterExperimentsByStudyType(showDailyExternalControls: boolean) {
    switch (this.bundleService.form.controls.bundleStudyType.value) {
      case BundleStudyType.LoD:
        this.experimentsData.data = this.experiments
          .filter(x =>
            x.studyType == StudyType.LoDConfirmation
            || x.studyType == StudyType.LoDRangeFinding
            || (x.studyType == StudyType.DailyExternalControls && showDailyExternalControls)
          );
        break;
    }
    this.experimentsData._updateChangeSubscription();
  }
}
