
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '../../shared/notification.service';
import { BundleService } from 'src/app/shared/bundle.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { MatSort } from '@angular/material/sort';
import { DialogService } from 'src/app/shared/dialog.services';
import { AssayType, Bundle, Experiment, User } from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';
import { UserAccountService } from 'src/app/services/user-account.service';
import { LoggingService } from 'src/app/services/logging.service';
import { PAGE_NAME_BUNDLESLIST } from 'src/app/services/logging-constants';
import { BundleComponent } from '../bundle/bundle.component';
import { Subject } from 'rxjs';
import { takeUntil, filter, switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExperimentListForBundleComponent } from '../experiment-list-for-bundle/experiment-list-for-bundle.component';
import { AuditService } from 'src/app/services/audit.service';
import { DatePipe } from '@angular/common';
import { BundleAssayTypeFormatPipe } from 'src/app/pipes/bundle-assayType-format.pipe';
import { AutomatedReportsComponent } from 'src/app/automated-reports/automated-reportscomponent';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


interface IBundleDisplayItem extends Bundle {
  bundleTypeName: string;
  createdByName: string;
  assayType: Partial<AssayType>;
}

@Component({
  selector: 'app-bundle-list',
  templateUrl: './bundle-list.component.html',
  styleUrls: ['./bundle-list.component.scss'],
})

export class BundleListComponent extends BaseComponent implements OnInit, OnDestroy {
  public Bundles!: Bundle[];
  public objectName = 'bundle';
  public CanCreate = false;
  public displayedColumns = [
    'actionsEdit',
    'name',
    'bundleId',
    'bundleTypeName',
    'assayTypeCode',
    'instrumentSerialNumbers',
    'experimentIds',
    'startDate',
    'endDate',
    'dateCreated',
    'createdByName',
    'actionsDelete',
    'exportXLSX',
    'automatedReports'
  ];
  generatingExport: boolean = false;
  @ViewChild(MatTable) table!: MatTable<any>;
  listData!: MatTableDataSource<any>;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  public searchKey: string = '';
  bundleTypes!: any;
  status!: any;
  sliderActive: boolean = true;
  currentRoles: string[] = [];
  currentExperiment!: Experiment;

  private assayTypes: AssayType[] = [];
  private currentUser!: User;
  private readonly _destroying$ = new Subject();

  constructor(
    private service: BundleService,
    public apiService: LabpartnerService,
    public accountService: UserAccountService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private loggingService: LoggingService,
    private auditService: AuditService,
    private datePipe: DatePipe,
    private bundleAssayTypeFormatPipe: BundleAssayTypeFormatPipe,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    super();
    this.bundleTypes = service.bundleTypes;
    this.matIconRegistry.addSvgIcon(
      'xlsx',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/excel.svg'
      )
    );
  }

  async ngOnInit() {
    await this.accountService.SetUserList();
    this.accountService.currentUser$
      .pipe(
        takeUntil(this._destroying$),
        filter(u => u != undefined),
        switchMap(u => {
          this.currentUser = u!;
          return this.apiService.getAssayTypes();
        }),
        switchMap((assayTypes: AssayType[]) => {
          this.assayTypes = assayTypes;
          return this.apiService.getBundlesList();
        })
      )
      .subscribe(bundles => this.parseBundlesForDisplay(bundles));
    this.loggingService.logPageView(PAGE_NAME_BUNDLESLIST);



    this.subscription.add(
      this.accountService.currentRoles$.subscribe(roles => {
        this.currentRoles = roles;
      }));
  }

  onSearchClear() {
    this.searchKey = '';
    this.onChange('');
  }

  onChange(newVal: string) {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  onCreateForSerials() {
    this.service.initializeFormGroup();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';

    const dialogRef = this.dialog.open(BundleComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((dialogResult: any) => {
          if (dialogResult != undefined && dialogResult.ok) {
            let data = dialogResult.data;
            Object.assign(data, { createdByName: this.accountService.getUserName(data.createdBy) });
            Object.assign(data, { bundleTypeName: this.service.getBundleTypeNames(data.bundleType) });
            data.assayType = this.assayTypes.find(at => at.code == data.assayTypeCode);
            this.countInstrumentSerialNumbers(data);
            this.Bundles.push(data);
            this.refresh();
          }
          return this.apiService.getBundlesList();
        })
      )
      .subscribe(bundles => this.parseBundlesForDisplay(bundles));
  }

  onCreateForExperiments() {
    this.service.initializeExperimentFormGroup();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '90%';
    dialogConfig.height = '95%';

    const dialogRef = this.dialog.open(ExperimentListForBundleComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((dialogResult: any) => {
          if (dialogResult != undefined && dialogResult.ok) {
            let data = dialogResult.data;
            Object.assign(data, { createdByName: this.accountService.getUserName(data.createdBy) });
            Object.assign(data, { bundleTypeName: this.service.getBundleTypeNames(data.bundleType) });
            data.assayType = this.assayTypes.find(at => at.code == data.assayTypeCode);
            this.countExperimentIds(data);
            this.Bundles.push(data);
            this.refresh();
          }
          return this.apiService.getBundlesList();
        })
      )
      .subscribe(bundles => this.parseBundlesForDisplay(bundles));
  }

  showEditButton(row: IBundleDisplayItem): boolean {
    return row.createdBy == this.currentUser.userId;
  }

  showDeleteButton(row: IBundleDisplayItem): boolean {
    return row.createdBy == this.currentUser.userId;
  }

  onEdit(row: IBundleDisplayItem) {
    if (row.bundleType == 0) {
      this.editSerialBundle(row);
    } else {
      this.editExperimentBundle(row);
    }
  }

  editSerialBundle(row: IBundleDisplayItem) {
    this.service.populateForm(row);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    const dialogRef = this.dialog.open(BundleComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((dialogresult: any) => {
          if (dialogresult != undefined && dialogresult.ok) {
            let data = dialogresult.data;
            row.bundleId = data.bundleId;
            row.name = data.name;
            row.instrumentSerialNumbers = data.instrumentSerialNumbers;
            row.experimentIds = data.experimentIds;
            row.assayTypeCode = data.assayTypeCode;
            row.assayType = this.assayTypes.find(at => at.code == data.assayTypeCode) as Partial<AssayType>;
            row.bundleType = data.bundleType; // this.service.getBundleTypeNames(data.bundleType);
            row.startDate = data.startDate;
            row.endDate = data.endDate;
            row.dateCreated = data.dateCreated;
            this.countInstrumentSerialNumbers(data);
            row.instrumentSerialNumbers = data.instrumentSerialNumbers;
            console.log(data);
          }
          return this.apiService.getBundlesList();
        })
      )
      .subscribe(bundles => {
        this.parseBundlesForDisplay(bundles);
      });
    this.apiService.getBundlesList().subscribe(bundles => this.parseBundlesForDisplay(bundles));
  }

  editExperimentBundle(row: IBundleDisplayItem) {
    this.service.populateForm(row);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '90%';
    dialogConfig.height = '95%';
    const dialogRef = this.dialog.open(ExperimentListForBundleComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((dialogresult: any) => {
          if (dialogresult != undefined && dialogresult.ok) {
            let data = dialogresult.data;
            row.bundleId = data.bundleId;
            row.name = data.name;
            row.instrumentSerialNumbers = data.instrumentSerialNumbers;
            row.experimentIds = data.experimentIds;
            row.assayTypeCode = data.assayTypeCode;
            row.assayType = this.assayTypes.find(at => at.code == data.assayTypeCode) as Partial<AssayType>;
            row.bundleType = data.bundleType; // this.service.getBundleTypeNames(data.bundleType);
            row.startDate = data.startDate;
            row.endDate = data.endDate;
            row.dateCreated = data.dateCreated;
            this.countExperimentIds(data);
            row.experimentIdsCount = data.experimentIdsCount;
            console.log(data);
          }
          return this.apiService.getBundlesList();
        })
      )
      .subscribe(bundles => {
        this.parseBundlesForDisplay(bundles);
      });
    this.apiService.getBundlesList().subscribe(bundles => this.parseBundlesForDisplay(bundles));
  }

  onDelete(row: IBundleDisplayItem) {
    const bundleId = row.bundleId;
    const dialog = this.dialogService.openConfirmDialog(
      `Are you sure you want to permanently delete this bundle? (Name: ${row.name})`
    );
    dialog.afterClosed().subscribe(async res => {
      if (res) {
        console.log('Deleting bundle id:' + bundleId);
        this.apiService
          .deleteBundle(row)
          .pipe(
            switchMap(() => {
              return this.apiService.getBundlesList();
            })
          )
          .subscribe(bundles => {
            this.parseBundlesForDisplay(bundles);
            const index = this.Bundles.indexOf(row, 0);
            if (index > -1) {
              this.Bundles.splice(index, 1);
              this.refresh();
            }
            this.listData._updateChangeSubscription();
            this.notificationService.success(':: Bundle deleted');
          });
      }
    });
  }

  async exportXLSX(row: IBundleDisplayItem) {
    this.generatingExport = true;

    try {
      const exportResult = await this.auditService.getAuditExportByBundle(row.bundleId).toPromise();

      if (!exportResult) { throw new Error("exportResult is null"); }

      this.generatingExport = false;

      const contentDisposition = exportResult.headers.get('content-disposition')!;
      const filePart = /filename=(.+);/.exec(contentDisposition);

      let filename = '';
      if (filePart) {
        filename = filePart[1];
      }

      if (exportResult.body) {
        const objUrl = window.URL.createObjectURL(exportResult.body);
        var fileLink = document.createElement('a');
        fileLink.href = objUrl;

        // If for some reason this fails, we'll create the filename on the front-end, but ideally we rely on the server
        if (filePart && !!filename) {
          fileLink.download = filename;
        } else {
          const curDate = new Date();
          fileLink.download =
            `${curDate.getUTCFullYear()}-${curDate.getUTCMonth() + 1}-${curDate.getUTCDate()}_` +
            `${curDate.getUTCHours()}${curDate.getUTCMinutes()}${curDate.getUTCSeconds()}_` +
            `Bundle-${row.bundleId}_Export.xlsx`;
        }

        fileLink.click();
      }
    } catch (err) {
      this.generatingExport = false;
      throw err;
    }
  }

  // resets mat-table properties when datasource changes
  refresh(): void {
    if (this.sliderActive) {
      this.listData.data = this.Bundles.filter(b => b.createdBy == this.currentUser.userId)
    } else {
      this.listData.data = this.Bundles;
    }
  }

  toggleSlider($evt: MatSlideToggleChange): void {
    this.sliderActive = !this.sliderActive;
    this.refresh();
  }

  openAutomatedReports(row: IBundleDisplayItem) {
    this.dialog.open(AutomatedReportsComponent, {
      data: { bundleId: row.bundleId },
    });
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
    this._destroying$.next(true);
    this._destroying$.complete();
  }

  private parseBundlesForDisplay(bundles: Bundle[]) {
    this.Bundles = bundles;
    for (let bundle of this.Bundles as IBundleDisplayItem[]) {
      this.countExperimentIds(bundle);
      this.countInstrumentSerialNumbers(bundle);
      bundle.bundleTypeName = this.service.getBundleTypeNames(bundle.bundleType);
      bundle.createdByName = this.accountService.getUserName(bundle.createdBy);
      let foundAssayType = this.assayTypes.find(at => at.code == bundle.assayTypeCode);
      if (foundAssayType != undefined) {
        bundle.assayType = foundAssayType;
      }
      else {
        bundle.assayType = { name: 'UNK', code: bundle.assayTypeCode };
      }
      bundle.endDate.setDate(bundle.endDate.getDate() - 1);
    }
    this.listData = new MatTableDataSource(this.Bundles);
    this.searchKey = "";
    this.refresh();
    this.listData.sort = this.sort;
    this.listData.paginator = this.paginator;
    this.listData.filterPredicate = (data, filter) => {
      return this.displayedColumns.some((propertyName: string) => {
        let value = data[propertyName];

        if (value && propertyName.toLowerCase().includes("date")) {
          value = this.datePipe.transform(value, 'shortDate');;
        }

        if (data && propertyName == "assayTypeCode") {
          value = this.bundleAssayTypeFormatPipe.transform(data);
        }

        return value != undefined && propertyName != 'actions' && value.toString().toLowerCase().indexOf(filter) != -1;
      });
    };
  }

  private countExperimentIds(data: any) {
    data.experimentIdsCount = data.experimentIds ? (data.experimentIds as string).split(",").length : 0;
  }

  private countInstrumentSerialNumbers(data: any) {
    data.instrumentSerialNumbersCount = data.instrumentSerialNumbers ? (data.instrumentSerialNumbers as string).split(",").length : 0;
  }
}
