import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BundleListComponent } from './bundles/bundle-list/bundle-list.component';
import { DetailListPrintAveryComponent } from './detail/detail-list-print-avery/detail-list-print-avery.component';
import { DetailListPrintBarcodesComponent } from './detail/detail-list-print-barcodes/detail-list-print-barcodes.component';
import { DetailListPrintZebraComponent } from './detail/detail-list-print-zebra/detail-list-print-zebra.component';
import { DetailListPrintComponent } from './detail/detail-list-print/detail-list-print.component';
import { ExperimentDetailComponent } from './experiment-detail/experiment-detail.component';
import { ExperimentListComponent } from './experiments/experiment-list/experiment-list.component';
import { NoAccessComponent } from './noaccess/noaccess.component';
import { SavannaResultsComponent } from './results/savanna-results/savanna-results.component';
import { SavannaConfigCanDeactivateGuard } from './savanna-config/savanna-config-can-deactivate';
import { MobileBarcodesComponent } from './mobile-barcodes/mobile-barcodes.component';
import { ManageAssaysComponent } from './manage-assays/manage-assays.component';
import { RolesGuard } from './services/roles.guard';
import { ROLE_NAMES } from './services/user-account.service';

const routes: Routes = [
  { path: 'mobile-barcodes/:experimentId/:linkIdentifier', component: MobileBarcodesComponent },
  {
    // parent route without a component, only here to protect the entire app (all child routes) and require auth
    path: '',
    canActivate: [MsalGuard],
    children: [
      {
        path: '', component: ExperimentListComponent,
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
      { path: 'noaccess', component: NoAccessComponent },
      {
        path: 'experiment-list', component: ExperimentListComponent,
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
      {
        path: 'experiment-detail/:id/:tab', component: ExperimentDetailComponent,
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
      {
        path: 'experiment-detail-print-barcodes', component: DetailListPrintBarcodesComponent,
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
      {
        path: 'experiment-detail-print-avery', component: DetailListPrintAveryComponent,
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
      {
        path: 'experiment-detail-print/:id', component: DetailListPrintComponent,
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
      {
        path: 'experiment-detail-print-zebra', component: DetailListPrintZebraComponent,
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
      {
        path: 'bundles', component: BundleListComponent
      },
      {
        path: 'experiment-detail-print-zebra', component: DetailListPrintZebraComponent,
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
      {
        path: 'savanna-results',
        component: SavannaResultsComponent,
        canDeactivate: [SavannaConfigCanDeactivateGuard],
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
      {
        path: 'admin/assays',
        component: ManageAssaysComponent,
        canActivate: [RolesGuard], data: {
          roles: [ROLE_NAMES.ADMIN]
        }
      },
      {
        path: '**', component: ExperimentListComponent,
        canActivate: [RolesGuard], data: {
          roles: Object.values(ROLE_NAMES)
        }
      },
    ]
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
