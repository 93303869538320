import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private messageService: MessageService) {}

  success(msg: string, stickyClose: boolean = false) {
    this.messageService.add({
      severity: 'success',
      detail: msg,
      icon: '',
      closable: stickyClose,
      sticky: stickyClose,
    });
  }

  info(msg: string, stickyClose: boolean = false) {
    this.messageService.add({
      severity: 'info',
      detail: msg,
      icon: '',
      closable: stickyClose,
      sticky: stickyClose,
    });
  }

  warn(msg: string, stickyClose: boolean = false) {
    this.messageService.add({
      severity: 'warn',
      detail: msg,
      icon: '',
      closable: stickyClose,
      sticky: stickyClose,
    });
  }

  error(msg: string, stickyClose: boolean = false) {
    this.messageService.add({
      severity: 'error',
      detail: msg,
      icon: '',
      closable: stickyClose,
      sticky: stickyClose,
    });
  }
}
