import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectItemGroup } from 'primeng/api';
import { Listbox } from 'primeng/listbox';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStateService } from '../services/app-state.service';
import { EVENT_APPLY_COLUMN_FILTER } from '../services/logging-constants';
import { LoggingService } from '../services/logging.service';
import { ColumnFilterService, ITableColumnFilter } from './column-filter.service';

@Component({
  selector: 'app-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss'],
})
export class ColumnFilterComponent implements OnInit {
  @Input() tableName!: keyof ITableColumnFilter;
  @Input() columnName!: string;
  @Input() displayName?: string;
  @Input() disabled: boolean = false;

  @Output() filterPanelOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('filterPanel') filterPanel?: OverlayPanel;
  @ViewChild('columnFilterListbox') columnFilterListbox?: Listbox;

  filterActive: boolean = false;

  filterOptions: SelectItemGroup[] = [];
  oldSelectedFilterOptions: string[] = [];
  selectedFilterOptions: string[] = [];

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private _columnFilterService: ColumnFilterService,
    private appState: AppStateService,
    private loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this._columnFilterService.filterPanelOpened$.pipe(takeUntil(this._destroying$)).subscribe(colName => {
      // Only close if open, this avoids additional change detections
      if (this.columnName != colName && this.filterPanel?.overlayVisible) {
        this.closeFilterPanel();
      }
    });

    const availableFilters = this._columnFilterService.getActiveTableFilterValues(this.tableName, this.columnName);
    this.selectedFilterOptions = Array.from(availableFilters.values() ?? []);
    if (this.selectedFilterOptions.length) {
      this.filterActive = true;
    }
    this.updateFilterValues();
  }

  updateFilterValues() {
    this.oldSelectedFilterOptions = this.selectedFilterOptions;

    const alreadySelectedFilters = new Set<string>();
    const availableFilters = this._columnFilterService.getDisplayTableFilterValues(this.tableName, this.columnName);
    const availableRowCount = this._columnFilterService.getOtherFiltersRowCounts(this.tableName, this.columnName);

    if (availableFilters.size == 0 && this.selectedFilterOptions.length == 0) {
      this.filterOptions = [];
    } else {
      this.filterOptions = [
        {
          label: 'selected',
          items: [],
        },
        {
          label: 'available',
          items: [],
        },
      ];
    }

    this.selectedFilterOptions.forEach(x => {
      alreadySelectedFilters.add(x);
      this.filterOptions[0].items.push({
        label: x,
        value: x,
        title: availableRowCount[x]?.toString() ?? 0,
        disabled: !availableFilters.has(x),
      });
    });

    Array.from(availableFilters.values() ?? [])
      .sort()
      .filter(x => !alreadySelectedFilters.has(x))
      .forEach(x =>
        this.filterOptions[1].items.push({ label: x, value: x, title: availableRowCount[x]?.toString() ?? 0 })
      );
  }

  showColumnFilter(evt: MouseEvent) {
    evt.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.filterPanel?.toggle(evt);
    this._columnFilterService.filterPanelOpened(this.columnName);
  }

  clearFilter() {
    this.filterActive = false;
    this.selectedFilterOptions = [];
    this.oldSelectedFilterOptions = this.selectedFilterOptions;
    this._columnFilterService.clearTableColumnFilter(this.tableName, this.columnName);
    this.filterPanel?.hide();
  }

  clearAllFilters() {
    this._columnFilterService.resetTableFilter(this.tableName);
    this.filterPanel?.hide();
  }

  applyFilter() {
    if (!this.selectedFilterOptions || !this.selectedFilterOptions.length) {
      this.clearFilter();
    } else {
      this.filterActive = true;
      this.oldSelectedFilterOptions = this.selectedFilterOptions;
      this._columnFilterService.setTableFilterValues(this.tableName, this.columnName, this.selectedFilterOptions);

      const activeFilters = this._columnFilterService.getActiveTableFilters(this.tableName);
      const filteredColumnNames = Object.keys(activeFilters);

      let totalFilteredItems = 0;
      const filteredColumns = filteredColumnNames
        .map(columnName => {
          totalFilteredItems += activeFilters[columnName].size;
          return `${columnName} (${activeFilters[columnName].size})`;
        })
        .join(', ');

      const multiselectProps: { [key: string]: number | string } = {
        ExperimentId: this.appState.GetCurrentExperiment()?.experimentId ?? 0,
        DeviceType: this.appState.GetCurrentExperiment()?.deviceType ?? -1,
        ExperimentOwner: this.appState.GetCurrentExperiment()?.createdBy ?? 0,
        MultiselectAction: EVENT_APPLY_COLUMN_FILTER,
        FilterView: this.tableName,
        FilteredColumns: filteredColumns,
        NumFilteredColumns: filteredColumnNames.length,
        TotalFilteredItems: totalFilteredItems,
      };
      this.loggingService.logEvent(EVENT_APPLY_COLUMN_FILTER, multiselectProps);
    }
    this.filterPanel?.hide();
  }

  closeFilterPanel() {
    // Purposely here as the onFilterPanelHide() callback does not happen soon enough
    // for some calls to closeFilterPanel(), resulting in a brief incorrect view of selected options
    this.selectedFilterOptions = this.oldSelectedFilterOptions;
    this.filterPanel?.hide();
  }

  onFilterPanelHide() {
    this.selectedFilterOptions = this.oldSelectedFilterOptions;
  }
}
