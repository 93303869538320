import { StaticColumn } from "src/app/services/labpartner.service.model";

export class ConditionColumns {
    static lodColumns: StaticColumn[] = [
        {
            prop: 'cartridgeLot',
            header: 'Cartridge Lot',
            required: true
        }
    ];

    static generalColumns: StaticColumn[] = [
        {
            prop: 'name',
            header: 'Name',
            required: true
        },
        {
            prop: 'cartridgeLot',
            header: 'Cartridge Lot'
        }

    ];

    static dailyExternalControlsColumns: StaticColumn[] = [
        {
            prop: 'cartridgeLot',
            header: 'Cartridge Lot',
            required: true
        },
        {
            prop: 'testingDate',
            header: 'TestingDate',
            required: true,
            isCustom: true,
            valueType: 'date', inputType: 'date'
        },
    ];

    static precisionColumns: any[] = [
        {
            prop: 'precisionDay',
            header: 'Precision Day',
            required: true,
            type: 'number'
        },
        {
            prop: 'cartridgeLot',
            header: 'Cartridge Lot',
            required: true
        },
        {
            prop: 'precisionEvent',
            header: 'Precision Event',
            required: true,
            type: 'number'
        },
    ];
}