import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-device-target-error-dialog',
    templateUrl: './device-target-error-dialog.component.html',
    styleUrls: ['./device-target-error-dialog.component.scss']
})
export class DeviceTargetErrorChannelDialogComponent implements OnInit {
    CHANNELS_NUM: number = 3;
    CHAMBERS_NUM: number = 4;

    isEntireCartridgeError: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DeviceTargetErrorChannelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.CHANNELS_NUM = this.data.CHANNELS_NUM;
        this.CHAMBERS_NUM = this.data.CHAMBERS_NUM;
        this.isEntireCartridgeError = this.data.isEntireCartridgeError;
    }

    close() {
        this.dialogRef.close();
    }
}
