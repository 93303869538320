import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotesCountMap, NotesForExperimentCountMap } from './labpartner.service.model';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  allExperimentsNotesCountMap: BehaviorSubject<NotesCountMap> = new BehaviorSubject<NotesCountMap>({});
  experimentNotesCountMap: BehaviorSubject<NotesForExperimentCountMap> = new BehaviorSubject<NotesForExperimentCountMap>({
    Experiment: {},
    Detail: {},
    DetailLiveNote:{},
    Samples: {},
    Condition: {},
    FileUpload: {}
  });

  constructor() { }
}
