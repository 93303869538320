import { Pipe, PipeTransform } from '@angular/core';
import { ExperimentTypeDisplayName } from '../services/labpartner.service.model';

@Pipe({ name: 'experimentFormat' })
export class ExperimentFormatPipe implements PipeTransform {
  experimentTypeDisplayNameValues = Object.values(ExperimentTypeDisplayName);

  transform(experimentId: number, experimentType: number): string {
    return `${this.experimentTypeDisplayNameValues[experimentType]?.charAt(0) ?? 'E'}${experimentId.toString().padStart(5, '0')}`;
  }
}
