import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NotificationService } from '../../shared/notification.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { MatSort } from '@angular/material/sort';
import { DialogService } from 'src/app/shared/dialog.services';
import {
  AuditedChangeResponse,
  Detail,
  Experiment,
  ExperimentType,
  KibanaData,
  StudyType,
  UserAccess,
} from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';
import { NavigationExtras, Router } from '@angular/router';

import { LoggingService } from 'src/app/services/logging.service';
import {
  EVENT_ADD_REPLICATE,
  EVENT_CREATE_BARCODE_LINK,
  EVENT_DELETE_DETAIL,
  EVENT_GEN_DETAILS,
  EVENT_MULTISELECT_DETAILS,
  EVENT_PRINT_AVERY,
  EVENT_PRINT_BARCODES,
  EVENT_PRINT_RUN_SHEET,
  EVENT_SCAN_DEVICE_ID,
  EVENT_SCAN_SAMPLE_TUBE_ID,
  EVENT_STATUS_COMPLETE,
  EVENT_STATUS_READY,
  EVENT_TOGGLE_SCAN_DIRECTION,
  EVENT_TOGGLE_UNEVALUABLE,
  EVENT_RESULTS_LINK_FUSION,
  EVENT_TOGGLE_INSTRUMENT_ERROR,
} from 'src/app/services/logging-constants';
import { SAVANNA_DEVICE_NAME } from 'src/app/services/labpartner-constants';
import { AppStateService } from 'src/app/services/app-state.service';
import { HttpStatusCode } from '@angular/common/http';
import { AuditObjectIdCountMap, AuditObjectName } from 'src/app/services/audit.service.models';
import { AuditService } from 'src/app/services/audit.service';
import { IChangeSet, IReplicatePair } from 'src/app/changes-dialog/changes-dialog.component';
import { DetailsService } from 'src/app/services/details.service';
import { filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject, forkJoin, lastValueFrom, timer } from 'rxjs';
import { parseMatches } from 'src/app/shared/regex-helper';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ISingleParsedValueEvent } from 'src/app/scan-display/scan-display.component';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { ColumnFilterService, ITableColumnFilter } from 'src/app/column-filter/column-filter.service';
import { ColumnFilterComponent } from 'src/app/column-filter/column-filter.component';
import { UserAccountService } from 'src/app/services/user-account.service';
import { ExperimentService } from 'src/app/shared/experiment.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InstrumentErrorDialogComponent, InstrumentErrorDialogData, InstrumentErrorDialogResult } from 'src/app/instrument-error-dialog/instrument-error-dialog.component';
import { DeviceTargetErrorDialogComponent, DeviceTargetErrorDialogData, DeviceTargetErrorDialogResult } from 'src/app/device-target-error-dialog/device-target-error-dialog.component';
import { BarcodesTemplates } from 'src/app/barcode-print/barcode-print.component';

export interface IDetailDisplayItem extends Detail {
  [key: string]: any;
  data: { [key: string]: any };
}

@Component({
  selector: 'app-detail-list',
  templateUrl: './detail-list.component.html',
  styleUrls: ['./detail-list.component.scss'],
  animations: [
    trigger('barcodeFade', [
      // based on the flag barcodeDialogIsOpen
      state('false', style({ opacity: 1 })),
      state('true', style({ opacity: 0 })),
      transition('false => true', animate('600ms')),
      transition('true => false', animate('300ms')),
    ]),
  ],
})
export class DetailListComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() experimentId: number = 0;
  @Input() detailsNotesCountMap?: { [key: number]: number };
  @Input() detailsLiveNotesCountMap?: { [key: number]: number };
  @Input() detailsAuditCountMap?: AuditObjectIdCountMap;
  @Input() currentExperiment: Experiment | null = null;
  @Input() currentRoles: string[] = [];

  @ViewChildren(ColumnFilterComponent) columnFilterQueryList?: QueryList<ColumnFilterComponent>;

  displayedColumns = [
    'select',
    'sampleLabel',
    'sampleName',
    'conditionLabel',
    'conditionName',
    'replicateNo',
    'readableBarcode',
    'barcodeID',
    'sampleTubeID',
    'deviceID',
    'instrumentError',
    'unevaluable',
    'actions',
  ];
  listData!: MatTableDataSource<IDetailDisplayItem>;
  initialSelection = [];
  selectionModel = new SelectionModel<IDetailDisplayItem>(true, this.initialSelection);
  loading = false;

  @ViewChild('detailsTable') detailsTable!: MatTable<IDetailDisplayItem>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  searchKey: string = '';
  generatingExport: boolean = false;

  addingReplicate: boolean = false;
  barcodeDialogIsOpen: boolean = false;
  multiselectDialogsOpen: boolean = false;

  scanCompleteAdvanceDirection: 'DOWN' | 'RIGHT' | 'OFF' = 'DOWN';

  conflictingSampleTubeDetailIds: { [detailId: number]: boolean } = {};
  conflictingDeviceDetailIds: { [detailId: number]: boolean } = {};

  multiSelectItems: MenuItem[] = [
    {
      id: 'page',
      label: 'Page',
      icon: PrimeIcons.FILE,
      items: [],
    },
    {
      id: 'table',
      label: 'Table',
      icon: PrimeIcons.TABLE,
      items: [],
    },
    {
      label: 'Clear Selection',
      icon: PrimeIcons.REFRESH,
      command: () => {
        this.selectionModel.clear();
      },
    },
  ];

  filterTableName: keyof ITableColumnFilter = 'detail';
  experimentCount: number = 0;
  isExperimentLinkCounterDisabled: boolean = true;
  fusionUrl: string = '';
  refreshDelayMilliSecs: number = 0;
  currentUser?: UserAccess;
  isSavannaDevice: boolean = false;
  currentDevice: number = 0;

  BarcodesTemplates = BarcodesTemplates;

  private _parsedSampleTubeIds: { [parsedId: string]: Set<number> } = {};
  private _parsedDeviceIds: { [parsedId: string]: Set<number> } = {};
  private readonly _destroying$ = new Subject<void>();

  constructor(
    public apiService: LabpartnerService,
    private appState: AppStateService,
    private detailsService: DetailsService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private auditService: AuditService,
    private router: Router,
    private columnFilterService: ColumnFilterService,
    private loggingService: LoggingService,
    private userAccountService: UserAccountService,

    private experimentService: ExperimentService,
    private dialog: MatDialog
  ) {
    super();
    appState.ExperimentDataChanged.subscribe(() => {
      this.populateDetailsTable();
    });

    this.userAccountService.currentUser$.pipe(takeUntil(this._destroying$)).subscribe(user => {
      this.currentUser = user as UserAccess;
    });
  }

  async ngOnInit() {


    this.subscription.add(
      this.userAccountService.currentRoles$.subscribe(roles => {
        this.currentRoles = roles;
      }));

    this.columnFilterService.tableFiltersUpdated$.pipe(takeUntil(this._destroying$)).subscribe(tableName => {
      if (tableName != 'detail' && tableName != 'all') {
        return;
      }
      this.refreshTableFilter();
    });

    this.columnFilterService.tableFiltersReset$.pipe(takeUntil(this._destroying$)).subscribe(tableName => {
      if (tableName != 'detail' && tableName != 'all') {
        return;
      }
      this.columnFilterService.deleteQueryListFilters(this.columnFilterQueryList!);
    });

    this.populateDetailsTable();

    // Bug 26968 race-condition fix
    // If things go fast, we have a currentExperiment, so just use its deviceType
    // otherwise, wait until things settle to fetch it to avoid null error issues
    if (!this.currentExperiment) {
      this.appState.ExperimentOpened.pipe(
        filter(x => x != null),
        take(1)
      ).subscribe({
        next: x => this.initializeDevicesAndFusionData(x.deviceType),
      });
    } else {
      this.initializeDevicesAndFusionData(this.currentExperiment.deviceType);
    }
  }

  initializeDevicesAndFusionData(deviceType: number) {
    let savannaDevice = this.experimentService.devices.find(x => x.value === SAVANNA_DEVICE_NAME);
    if (deviceType === savannaDevice?.id && this.currentUser?.hasFusionAccess) {
      this.setFusionData(savannaDevice.typeCode);
    }
  }

  setFusionData(deviceTypeCode: string) {
    this.apiService
      .getExperimentCountForExperimentId(this.experimentId, deviceTypeCode, true)
      .pipe(takeUntil(this._destroying$))
      .subscribe(data => {
        this.isSavannaDevice = true;
        this.refreshDelayMilliSecs = data.delayToRefreshSecs * 1000;
        this.experimentCount = data.experimentCount;
        this.fusionUrl = data.urlTemplate;
        this.updateFusionData(data);
        this.refreshFusionData(deviceTypeCode);
      }, (error) => {
        console.log(error);
      });
  }

  refreshFusionData(deviceTypeCode: string) {
    timer(this.refreshDelayMilliSecs, this.refreshDelayMilliSecs)
      .pipe(
        takeUntil(this._destroying$),
        switchMap(_ => this.apiService.getExperimentCountForExperimentId(this.experimentId, deviceTypeCode, true))
      )
      .subscribe(data => {
        this.updateFusionData(data);
      });
  }

  updateFusionData(data: KibanaData): void {
    if (data.experimentCount > 0) {
      this.isExperimentLinkCounterDisabled = false;
      this.experimentCount = data.experimentCount;
    } else {
      this.isExperimentLinkCounterDisabled = true;
      this.experimentCount = 0;
    }
  }

  ngAfterViewInit(): void {
    this.detailsTable.contentChanged.pipe(takeUntil(this._destroying$)).subscribe(() => {
      this.detailsService.setCurrentDetails(this.listData.data);
    });
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.detailsService.clearCurrentDetails();
  }

  tableDataChanged() {
    this.columnFilterService.refreshQueryListDisplayFilters(this.columnFilterQueryList!, this.listData, this.searchKey);
  }

  onMultiSelectMenuShow(evt: any) {
    // Filter is active
    const tableMenu = this.multiSelectItems.find(x => x.id === 'table');
    const pageMenu = this.multiSelectItems.find(x => x.id === 'page');

    if (this.listData.filteredData.length != this.listData.data.length) {
      this.setFilteredDataMenus(tableMenu, pageMenu);
    } else {
      this.setUnfilteredDataMenus(tableMenu, pageMenu);
    }

    this.multiSelectItems = Object.assign([], this.multiSelectItems);
  }

  onSearchClear() {
    this.searchKey = '';
    this.onChange('');
  }

  onChange(newVal: string) {
    this.refreshTableFilter();
  }

  onGenerateDetails() {
    if (this.apiService.isLoading) {
      return;
    }

    if (this.listData.data.length > 0) {
      const dialog = this.dialogService.openConfirmDialog(
        'Are you sure you want to generate details,  previous generated data will be overwritten ?'
      );

      dialog.afterClosed().subscribe(res => {
        if (res) {
          this.generateDetailsInternal();
          return;
        }
      });
    } else {
      this.generateDetailsInternal();
    }
  }

  generateDetailsInternal() {
    this.subscription.add(
      forkJoin([
        this.apiService.getSamplesList('experimentId', this.experimentId),
        this.apiService.getConditionsList('experimentId', this.experimentId),
        this.apiService.getDetailsList('experimentId', this.experimentId)
      ]).subscribe((results) => {
        const samples = results[0];
        const conditions = results[1];
        const details = results[2];

        const replicatePairs: IReplicatePair[] = [];

        for (let sample of samples) {
          for (let condition of conditions) {
            let numberOfReplicates = this.appState.GetCurrentExperiment().replicates;

            if (details && details.length > 0) {
              numberOfReplicates = details.filter(x => x.sampleId == sample.sampleId && x.conditionId == condition.conditionId).length;
            }

            replicatePairs.push({
              sampleId: sample.sampleId,
              sampleLabel: sample.label,
              conditionId: condition.conditionId,
              conditionLabel: condition.label,
              numberOfReplicates: numberOfReplicates,
            });
          }
        }

        const dialogRef = this.dialogService.openConfirmChangesDialog(
          [],
          this.appState.GetCurrentExperiment().type,
          {
            multiReason: true,
            isAppend: true,
            defaultReplicates: this.appState.GetCurrentExperiment().replicates,
            replicateData: replicatePairs,
            skipReasonForChange: true,
            adjustReplicates: this.appState.GetCurrentExperiment().status > 1
          }
        );

        dialogRef.afterClosed().subscribe(async data => {
          if (
            data?.submitClicked
          ) {
            for (let replicate of replicatePairs) {
              if (replicate.ignore) {
                replicate.numberOfReplicates = 0;
              }
            }

            this.apiService.generateDetails(this.experimentId, replicatePairs).subscribe((result: number) => {
              if (result == 0) {
                this.dialogService.openAckDialog(
                  'No details were generated, make sure you have created samples, conditions, and replicates!'
                );
              } else {
                // results generated, update our status
                this.apiService.getExperiment(this.experimentId).subscribe((experiment: Experiment) => {
                  this.appState.SetCurrentExperiment(experiment);

                  const props: { [key: string]: number | string } = {
                    ExperimentId: experiment.experimentId,
                    DeviceType: this.appState.GetCurrentExperiment().deviceType,
                    ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
                  };
                  this.loggingService.logEvent(EVENT_GEN_DETAILS, props);
                });
              }
              this.populateDetailsTable();
            });
          }
        });
      })
    )
  }

  async onSetReady() {
    if (this.apiService.isLoading) {
      return;
    }

    // If the study type is Precision, we need to confirm that the user wants to proceed
    if (this.currentExperiment?.studyType == StudyType.Precision) {
      let dialogRef = this.dialogService.openConfirmDialog(`Once the experiment is Set Ready to Test, Adjust Conditions option will not be available anymore, are you sure to continue?`)
      let response = await lastValueFrom(dialogRef.afterClosed());
      if (!response) {
        return;
      }
    }

    this.apiService
      .getDetailCountByExperimentId(this.experimentId)
      .pipe(
        switchMap((detailCount: number) => {
          if (detailCount == 0) {
            const dialogRef = this.dialogService.openAckDialog(
              'Please generate details before proceeding to Ready to Test.'
            );
            return dialogRef.afterClosed();
          }
          return this.apiService.setStatus(this.experimentId, 3).pipe(map(res => res.ok));
        }),
        switchMap(_ => {
          return this.apiService.getExperiment(this.experimentId);
        })
      )
      .subscribe({
        next: (experiment: Experiment) => {
          this.appState.SetCurrentExperiment(experiment);
          const props: { [key: string]: number | string } = {
            ExperimentId: experiment.experimentId,
            DeviceType: this.appState.GetCurrentExperiment().deviceType,
            ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
          };
          this.loggingService.logEvent(EVENT_STATUS_READY, props);
        },
      });
  }

  onSetComplete() {
    if (this.apiService.isLoading) {
      return;
    }

    this.apiService
      .setStatus(this.experimentId, 4)
      .pipe(
        switchMap(_ => {
          return this.apiService.getExperiment(this.experimentId);
        })
      )
      .subscribe({
        next: (experiment: Experiment) => {
          this.appState.SetCurrentExperiment(experiment);
          const props: { [key: string]: number | string } = {
            ExperimentId: experiment.experimentId,
            DeviceType: this.appState.GetCurrentExperiment().deviceType,
            ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
          };
          this.loggingService.logEvent(EVENT_STATUS_COMPLETE, props);
        },
      });
  }

  toggleScanDirection() {
    switch (this.scanCompleteAdvanceDirection) {
      case 'DOWN':
        this.scanCompleteAdvanceDirection = 'RIGHT';
        break;
      case 'RIGHT':
        this.scanCompleteAdvanceDirection = 'OFF';
        break;
      case 'OFF':
        this.scanCompleteAdvanceDirection = 'DOWN';
        break;
    }

    const props: { [key: string]: number | string } = {
      ExperimentId: this.currentExperiment?.experimentId ?? 0,
      DeviceType: this.appState.GetCurrentExperiment().deviceType,
      ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
      ScanDirection: this.scanCompleteAdvanceDirection,
    };
    this.loggingService.logEvent(EVENT_TOGGLE_SCAN_DIRECTION, props);
  }

  onScanComplete(rowIndex: number, sampleOrDevice: number) {
    if (this.scanCompleteAdvanceDirection == 'OFF') {
      return;
    }

    const maxRowIndex =
      this.listData.data.length < this.paginator.pageSize ? this.listData.data.length - 1 : this.paginator.pageSize - 1;

    let nextRowIndex = rowIndex;
    let nextColIndex = -1;

    if (this.scanCompleteAdvanceDirection == 'DOWN') {
      nextColIndex = sampleOrDevice;
      nextRowIndex = rowIndex == maxRowIndex ? 0 : rowIndex + 1;
    } else if (this.scanCompleteAdvanceDirection == 'RIGHT') {
      nextColIndex = sampleOrDevice == 0 ? 1 : 0;
      if (sampleOrDevice == 1) {
        nextRowIndex = rowIndex == maxRowIndex ? 0 : rowIndex + 1;
      }
    }

    if (nextColIndex >= 0) {
      const nextScanField = document.getElementById(`scan-field-${nextRowIndex}-${nextColIndex}`);
      const nextScanButton = nextScanField?.getElementsByTagName('button')[0];
      if (!nextScanButton?.disabled && nextScanField && nextScanButton) {
        (nextScanButton as HTMLButtonElement).click();
      }
    }

    const multiselectProps: { [key: string]: number | string } = {
      ExperimentId: this.experimentId,
      DeviceType: this.appState.GetCurrentExperiment().deviceType,
      ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
    };

    if (this.selectionModel.selected.length > 1) {
      multiselectProps.MultiselectAction = sampleOrDevice ? EVENT_SCAN_SAMPLE_TUBE_ID : EVENT_SCAN_DEVICE_ID;
      multiselectProps.ItemsSelected = this.selectionModel.selected.length;
      this.loggingService.logEvent(EVENT_MULTISELECT_DETAILS, multiselectProps);
    }
  }

  addReplicate(row: any) {
    this.addingReplicate = true;
    const detail = row as IDetailDisplayItem;

    const props: { [key: string]: number | string } = {
      ExperimentId: this.experimentId,
      DeviceType: this.appState.GetCurrentExperiment().deviceType,
      ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
    };
    this.loggingService.logEvent(EVENT_ADD_REPLICATE, props);

    this.apiService
      .addReplicate(this.experimentId, detail.detailId)
      .pipe(finalize(() => (this.addingReplicate = false)))
      .subscribe(
        async (result: IDetailDisplayItem) => {
          if (result && result.detailId > 0 && result.replicateNo > 0) {
            let relatedRows = this.listData.data.filter(
              (x: IDetailDisplayItem) =>
                x.sampleLabel == detail.sampleLabel && x.conditionLabel == detail.conditionLabel
            ) as IDetailDisplayItem[];
            let maxReplicateRow = relatedRows.reduce((prev, cur) => (prev.replicateNo < cur.replicateNo ? cur : prev));
            let maxReplicateRowIdx = this.listData.data.indexOf(maxReplicateRow);
            this.listData.data.splice(maxReplicateRowIdx + 1, 0, result);

            this.listData._updateChangeSubscription();

            this.notificationService.success(':: Replicate successfully added');

            await this.auditService.getAuditCounts(this.experimentId).toPromise();
          }
        },
        (err: any) => {
          const expectedErr = err.error as AuditedChangeResponse;
          if (expectedErr.expectedException) {
            this.notificationService.error(expectedErr.message);
          } else {
            throw err;
          }
        }
      );
  }

  addMultipleReplicates() {
    if (this.selectionModel.selected.length <= 0) {
      return;
    }

    this.addingReplicate = true;

    let replicateCount = 0;
    const capturedReplicates = new Set<string>();

    const targetDetails: IDetailDisplayItem[] = [];
    this.selectionModel.selected.forEach(detail => {
      const sampleConditionLabel = detail.sampleLabel + detail.conditionLabel;
      if (!capturedReplicates.has(sampleConditionLabel)) {
        replicateCount++;
        targetDetails.push(detail);
        capturedReplicates.add(sampleConditionLabel);
      }
    });

    if (replicateCount == 0) {
      return;
    }

    let replicatePluralSuffix = replicateCount == 1 ? '' : 's';
    let detailPluralSuffix = this.selectionModel.selected.length == 1 ? '' : 's';

    const confirmResult = this.dialogService.openConfirmDialog(
      `This action will add ${targetDetails.length} replicate${replicatePluralSuffix} based on the selected Detail${detailPluralSuffix}, are you sure you want to continue?`
    );

    confirmResult.afterClosed().subscribe(dialogResult => {
      if (!dialogResult) {
        this.addingReplicate = false;
        return;
      }

      const props: { [key: string]: number | string } = {
        ExperimentId: this.experimentId,
        DeviceType: this.appState.GetCurrentExperiment().deviceType,
        ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
      };
      this.loggingService.logEvent(EVENT_ADD_REPLICATE, props);

      this.apiService
        .addMultipleReplicates(
          this.experimentId,
          targetDetails.map(d => d.detailId)
        )
        .pipe(finalize(() => (this.addingReplicate = false)))
        .subscribe(
          async (result: IDetailDisplayItem[]) => {
            result.forEach(detailResult => {
              if (detailResult && detailResult.detailId > 0 && detailResult.replicateNo > 0) {
                let relatedRows = this.listData.data.filter(
                  (x: IDetailDisplayItem) =>
                    x.sampleLabel == detailResult.sampleLabel && x.conditionLabel == detailResult.conditionLabel
                ) as IDetailDisplayItem[];
                let maxReplicateRow = relatedRows.reduce((prev, cur) =>
                  prev.replicateNo < cur.replicateNo ? cur : prev
                );
                let maxReplicateRowIdx = this.listData.data.indexOf(maxReplicateRow);
                this.listData.data.splice(maxReplicateRowIdx + 1, 0, detailResult);

                this.listData._updateChangeSubscription();

                if (targetDetails.length > 1) {
                  const multiselectProps: { [key: string]: number | string } = {
                    ExperimentId: this.experimentId,
                    DeviceType: this.appState.GetCurrentExperiment().deviceType,
                    ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
                    MultiselectAction: EVENT_ADD_REPLICATE,
                    ItemsSelected: targetDetails.length,
                  };
                  this.loggingService.logEvent(EVENT_MULTISELECT_DETAILS, multiselectProps);
                }

                this.notificationService.success(`:: Replicate${replicatePluralSuffix} successfully added`);
              }
            });

            await this.auditService.getAuditCounts(this.experimentId).toPromise();
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        );
    });
  }

  onEdit(row: any) {
    row.isEdit = true;
  }

  async toggleDetailInstrumentError(detail: Detail, evt: MatCheckboxChange) {
    const prevValue = detail.instrumentError;

    const dialogConfig = new MatDialogConfig<InstrumentErrorDialogData>();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.data = {
      experiment: this.appState.GetCurrentExperiment(),
      detail: detail,
      selectedDetails: [detail]
    };

    const dialogRef = this.dialog.open<InstrumentErrorDialogComponent, InstrumentErrorDialogData, InstrumentErrorDialogResult>(
      InstrumentErrorDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.success) {
        evt.source.checked = dialogResult.data.value;
        detail.instrumentError = dialogResult.data.value;

        if (dialogResult.updated) {
          this.notificationService.success('Instrument Error record updated');
        } else {
          this.notificationService.success('Instrument Error recorded successfully');
        }

        this.appState.SetExperimentDataChanged(this.experimentId);
      } else {
        this.notificationService.warn(':: Canceled');
        evt.source.checked = prevValue;
        detail.instrumentError = prevValue;
      }
    });
  }

  toggleMultipleDetailsInstrumentError(evtDetail: Detail, evt: MatCheckboxChange) {
    const totalOfElements = this.selectionModel.selected.length;
    const numOfElementsToModify = this.selectionModel.selected.filter(x => !x.unevaluable).length;
    const numOfElementCantModify = totalOfElements - numOfElementsToModify;

    const prevValue = evtDetail.instrumentError;

    const confirmResult = this.dialogService.openConfirmDialog(
      `This action will modify data for ${numOfElementsToModify} of ${totalOfElements} selected details. ` +
      (numOfElementCantModify == 1 ? `1 selected detail has an Not Evaluable record and it can not be overwritten. ` : '') +
      (numOfElementCantModify > 1 ? `${numOfElementCantModify} selected details have an Not Evaluable record and they can not be overwritten. ` : '') +
      'Are you sure you want to continue?'
    );

    confirmResult.afterClosed().subscribe(confirmed => {
      if (!confirmed) {
        evt.source.checked = prevValue;
        return;
      };

      const targetDetails: Detail[] = this.selectionModel.selected.filter(x => !x.unevaluable).map(old => {
        let detail: Detail = {} as Detail;
        detail.detailId = old.detailId;
        return detail;
      });

      const dialogConfig = new MatDialogConfig<InstrumentErrorDialogData>();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '60%';
      dialogConfig.data = {
        experiment: this.appState.GetCurrentExperiment(),
        detail: evtDetail,
        selectedDetails: targetDetails
      };

      const dialogRef = this.dialog.open<InstrumentErrorDialogComponent, InstrumentErrorDialogData, InstrumentErrorDialogResult>(
        InstrumentErrorDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult && dialogResult.success) {

          evt.source.checked = dialogResult.data.value;
          evtDetail.instrumentError = dialogResult.data.value;
          this.selectionModel.selected.filter(x => !x.unevaluable).forEach(d => {
            d.instrumentError = dialogResult.data.value;
          });

          if (targetDetails.length > 1) {
            const props: { [key: string]: number | string } = {
              ExperimentId: this.experimentId,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
              MultiselectAction: EVENT_TOGGLE_INSTRUMENT_ERROR,
              ItemsSelected: targetDetails.length,
            };
            this.loggingService.logEvent(EVENT_MULTISELECT_DETAILS, props);
          }

          if (dialogResult.updated) {
            this.notificationService.success('Instrument Error record updated');
          } else {
            this.notificationService.success('Instrument Error recorded successfully');
          }

          this.appState.SetExperimentDataChanged(this.experimentId);
        } else {
          this.notificationService.warn(':: Canceled');
          evt.source.checked = prevValue;
        }
      });
    });
  }

  async toggleDetailUnevaluable(detail: Detail, evt: MatCheckboxChange) {
    const prevValue = detail.unevaluable;

    const deviceName = this.experimentService.devices.find(x => x.id == this.appState.GetCurrentExperiment().deviceType)?.value;
    const dialogConfig = new MatDialogConfig<DeviceTargetErrorDialogData>();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = deviceName === 'Savanna' ? '670px' : '330px';
    dialogConfig.data = {
      experiment: this.appState.GetCurrentExperiment(),
      detail: detail,
      selectedDetails: [detail]
    };

    const dialogRef = this.dialog.open<DeviceTargetErrorDialogComponent, DeviceTargetErrorDialogData, DeviceTargetErrorDialogResult>(
      DeviceTargetErrorDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.success) {
        evt.source.checked = dialogResult.data.value;
        detail.unevaluable = dialogResult.data.value;

        if (dialogResult.updated) {
          this.notificationService.success('Not Evaluable record updated');
        } else {
          this.notificationService.success('Not Evaluable recorded successfully');
        }

        this.appState.SetExperimentDataChanged(this.experimentId);
      } else {
        this.notificationService.warn(':: Canceled');
        evt.source.checked = prevValue;
        detail.unevaluable = prevValue;
      }
    });
  }

  toggleMultipleDetailsUnevaluable(evtDetail: Detail, evt: MatCheckboxChange) {
    const totalOfElements = this.selectionModel.selected.length;
    const numOfElementsToModify = this.selectionModel.selected.filter(x => !x.instrumentError).length;
    const numOfElementCantModify = totalOfElements - numOfElementsToModify;

    const prevValue = evtDetail.unevaluable;

    const confirmResult = this.dialogService.openConfirmDialog(
      `This action will modify data for ${numOfElementsToModify} of ${totalOfElements} selected details. ` +
      (numOfElementCantModify == 1 ? `1 selected detail has an Instrument error record and it can not be overwritten. ` : '') +
      (numOfElementCantModify > 1 ? `${numOfElementCantModify} selected details have an Instrument error record and they can not be overwritten. ` : '') +
      'Are you sure you want to continue?'
    );

    confirmResult.afterClosed().subscribe(confirmed => {
      if (!confirmed) {
        evt.source.checked = prevValue;
        return;
      };

      const targetDetails: Detail[] = this.selectionModel.selected.filter(x => !x.instrumentError).map(old => {
        let detail: Detail = {} as Detail;
        detail.detailId = old.detailId;
        return detail;
      });

      const deviceName = this.experimentService.devices.find(x => x.id == this.appState.GetCurrentExperiment().deviceType)?.value;
      const dialogConfig = new MatDialogConfig<DeviceTargetErrorDialogData>();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '60%';
      dialogConfig.height = deviceName === 'Savanna' ? '670px' : '330px';
      dialogConfig.data = {
        experiment: this.appState.GetCurrentExperiment(),
        detail: evtDetail,
        selectedDetails: targetDetails
      };

      const dialogRef = this.dialog.open<DeviceTargetErrorDialogComponent, DeviceTargetErrorDialogData, DeviceTargetErrorDialogResult>(
        DeviceTargetErrorDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult && dialogResult.success) {

          evt.source.checked = dialogResult.data.value;
          evtDetail.unevaluable = dialogResult.data.value;
          this.selectionModel.selected.filter(x => !x.instrumentError).forEach(d => {
            d.unevaluable = dialogResult.data.value;
          });

          if (targetDetails.length > 1) {
            const props: { [key: string]: number | string } = {
              ExperimentId: this.experimentId,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
              MultiselectAction: EVENT_TOGGLE_UNEVALUABLE,
              ItemsSelected: targetDetails.length,
            };
            this.loggingService.logEvent(EVENT_MULTISELECT_DETAILS, props);
          }
          if (dialogResult.updated) {
            this.notificationService.success('Not Evaluable record updated');
          } else {
            this.notificationService.success('Not Evaluable recorded successfully');
          }

          this.appState.SetExperimentDataChanged(this.experimentId);
        } else {
          this.notificationService.warn(':: Canceled');
          evt.source.checked = prevValue;
        }
      });
    });
  }

  onDelete(row: IDetailDisplayItem) {
    let changeSet: IChangeSet[] = [];
    changeSet.push({
      identifier: `Detail ${row.sampleLabel} ${row.conditionLabel}`,
      field: 'Entire Row',
      oldValue: `Detail ${row.sampleLabel} ${row.conditionLabel}`,
      newValue: 'DELETED',
    });

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      this.appState.GetCurrentExperiment().type,
      { isDelete: true }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        this.apiService.deleteDetail(this.experimentId, row.detailId, data.batchReason).subscribe(
          () => {
            const index = this.listData.data?.indexOf(row, 0);
            if (index != null && index > -1) {
              this.listData.data.splice(index, 1);
            }

            this.listData._updateChangeSubscription();
            this.appState.SetExperimentDataChanged(this.experimentId);

            const parsedSampleTubeValue = parseMatches(row.sampleTubeID);
            this.resetSampleTubeIdMapForDetailId(row.detailId, parsedSampleTubeValue, parsedSampleTubeValue);

            const parsedDeviceValue = parseMatches(row.deviceID);
            this.resetSampleTubeIdMapForDetailId(row.detailId, parsedDeviceValue, parsedDeviceValue);

            this.notificationService.success('Detail deleted');

            const props: { [key: string]: string | number } = {
              ExperimentId: this.experimentId,
              DeletedDetailId: row.detailId,
              SampleLabel: row.sampleLabel,
              ConditionLabel: row.conditionLabel,
              ReplicateNo: row.replicateNo,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
            };
            this.loggingService.logEvent(EVENT_DELETE_DETAIL, props);
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        );
      }
    });
  }

  onPrint(which: string) {
    const queryParams: any = {};
    const arrayOfBarcodes: string[] = [];
    let navigationExtras: NavigationExtras = {};

    const telemetryProps: { [key: string]: number | string } = {
      ExperimentId: this.experimentId,
      DeviceType: this.appState.GetCurrentExperiment().deviceType,
      ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
      ItemsSelected: this.selectionModel.selected.length,
    };

    let whichRoute = '/experiment-detail-print-';
    switch (which) {
      case 'Avery':
        whichRoute += 'avery';
        telemetryProps.MultiselectAction = EVENT_PRINT_AVERY;
        break;
      case 'Barcodes':
        whichRoute += 'barcodes';
        telemetryProps.MultiselectAction = EVENT_PRINT_BARCODES;
        break;
      default:
        // The cases above are the only two ways to get to this method right now
        // so we should not continue if we've somehow called this otherwise
        return;
    }

    // If we have selected Details, they supercede Filtered Details
    // Else If filter exists, fallback to using Filtered Details as before (maintain legacy functionality)
    // Else capture all Details
    if (this.selectionModel.selected.length > 0) {
      this.selectionModel.selected.forEach(element => {
        let barcode = element.readableBarcode;
        arrayOfBarcodes.push(barcode);
      });

      queryParams.barcodes = arrayOfBarcodes.join('|');
      navigationExtras = { queryParams };
    } else if (this.listData.filter.length > 0) {
      this.listData.filteredData.forEach(element => {
        let barcode = element.readableBarcode;
        arrayOfBarcodes.push(barcode);
      });

      queryParams.barcodes = arrayOfBarcodes.join('|');
      navigationExtras = { queryParams };
    } else {
      queryParams.experimentId = this.experimentId;
      navigationExtras = { queryParams };
    }

    const url = this.router.serializeUrl(this.router.createUrlTree([whichRoute], navigationExtras));
    window.open(url, '_blank');

    // Defer the logEvent call a tick so that the new tab can open on the thread first
    // This avoids AppInsights double-logging due to both tabs thinking the same entry
    // is awaiting to be batched out
    if (this.selectionModel.selected.length > 1) {
      setTimeout(() => this.loggingService.logEvent(EVENT_MULTISELECT_DETAILS, telemetryProps));
    }
  }

  onPrintRunSheet() {
    const queryParams: any = {};
    const arrayOfIDs: number[] = [];
    let navigationExtras: NavigationExtras = {};
    const pageUrl = `/experiment-detail-print/${this.experimentId}`;

    // Assume all Details due to no selection and no filtering
    let url: string = this.router.serializeUrl(this.router.createUrlTree([pageUrl]));

    const multiselectProps: { [key: string]: number | string } = {
      ExperimentId: this.experimentId,
      DeviceType: this.appState.GetCurrentExperiment().deviceType,
      ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
      MultiselectAction: EVENT_PRINT_RUN_SHEET,
      ItemsSelected: this.selectionModel.selected.length,
    };

    // If we have selected Details, they supercede Filtered Details
    // Else If filter exists, fallback to using Filtered Details as before (maintain legacy functionality)
    // Else capture all Details
    if (this.selectionModel.selected.length > 0) {
      this.selectionModel.selected.forEach(element => {
        arrayOfIDs.push(element.detailId);
      });

      queryParams.ids = arrayOfIDs.join('|');
      navigationExtras = { queryParams };
      url = this.router.serializeUrl(this.router.createUrlTree([pageUrl], navigationExtras));
    } else if (this.listData.filter.length > 0) {
      this.listData.filteredData.forEach(element => {
        arrayOfIDs.push(element.detailId);
      });

      queryParams.ids = arrayOfIDs.join('|');
      navigationExtras = { queryParams };
      url = this.router.serializeUrl(this.router.createUrlTree([pageUrl], navigationExtras));
    }

    window.open(url, '_blank');

    // Defer the logEvent call a tick so that the new tab can open on the thread first
    // This avoids AppInsights double-logging due to both tabs thinking the same entry
    // is awaiting to be batched out
    if (this.selectionModel.selected.length > 1) {
      setTimeout(() => this.loggingService.logEvent(EVENT_MULTISELECT_DETAILS, multiselectProps));
    }
  }

  async downloadFile() {
    this.generatingExport = true;

    try {
      const exportResult = await this.auditService.getAuditExport(this.experimentId).toPromise();

      if (!exportResult) { throw new Error("exportResult is null"); }

      this.generatingExport = false;

      const contentDisposition = exportResult.headers.get('content-disposition')!;
      const filePart = /filename=(.+);/.exec(contentDisposition);

      let filename = '';
      if (filePart) {
        filename = filePart[1];
      }

      if (exportResult.body) {
        const objUrl = window.URL.createObjectURL(exportResult.body);
        var fileLink = document.createElement('a');
        fileLink.href = objUrl;

        // If for some reason this fails, we'll create the filename on the front-end, but ideally we rely on the server
        if (filePart && !!filename) {
          fileLink.download = filename;
        } else {
          const curDate = new Date();
          fileLink.download =
            `${curDate.getUTCFullYear()}-${curDate.getUTCMonth() + 1}-${curDate.getUTCDate()}_` +
            `${curDate.getUTCHours()}${curDate.getUTCMinutes()}${curDate.getUTCSeconds()}_` +
            `Experiment-${this.experimentId}_Export.xlsx`;
        }

        fileLink.click();
      }
    } catch (err) {
      this.generatingExport = false;
      throw err;
    }
  }

  showAuditDialog(detail: Detail) {
    this.dialogService.openChangelogDialog(
      `Change log for ${detail.sampleLabel} ${detail.conditionLabel} R${detail.replicateNo
        .toString()
        .padStart(2, '0')}`,
      this.experimentId,
      AuditObjectName.Details,
      detail.detailId
    );
  }

  sampleTubeIdParsed(evt: ISingleParsedValueEvent) {
    this.resetSampleTubeIdMapForDetailId(evt.detailId, evt.previousParsedValue, evt.parsedValue);
    this.addParsedDetailIdAndUpdateConflicts(
      this._parsedSampleTubeIds,
      this.conflictingSampleTubeDetailIds,
      evt.detailId,
      evt.parsedValue
    );
  }

  deviceIdParsed(evt: ISingleParsedValueEvent) {
    this.resetBarcodeDetailIds(
      this.conflictingDeviceDetailIds,
      this._parsedDeviceIds,
      evt.detailId,
      evt.previousParsedValue,
      evt.parsedValue
    );
    this.addParsedDetailIdAndUpdateConflicts(
      this._parsedDeviceIds,
      this.conflictingDeviceDetailIds,
      evt.detailId,
      evt.parsedValue
    );
  }

  generateBarcodeLinkIdentifier() {
    const detailIds: number[] = [];

    if (this.selectionModel.selected.length > 0) {
      this.selectionModel.selected.forEach(element => detailIds.push(element.detailId));
    } else if (this.listData.filter.length > 0) {
      this.listData.filteredData.forEach(element => detailIds.push(element.detailId));
    }

    this.apiService.generateBarcodeLinkIdentifier(this.experimentId, detailIds).subscribe(res => {
      const props: { [key: string]: number | string | null } = {
        ExperimentId: this.experimentId,
        DeviceType: this.appState.GetCurrentExperiment().deviceType,
        ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
        LinkIdentifier: res.linkIdentifier,
        BarcodeCount: detailIds.length == 0 ? this.listData.data.length : detailIds.length,
      };
      this.loggingService.logEvent(EVENT_CREATE_BARCODE_LINK, props);

      if (this.selectionModel.selected.length > 1) {
        const multiselectProps: { [key: string]: number | string } = {
          ExperimentId: this.experimentId,
          DeviceType: this.appState.GetCurrentExperiment().deviceType,
          ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
          MultiselectAction: EVENT_CREATE_BARCODE_LINK,
          ItemsSelected: this.selectionModel.selected.length,
        };
        this.loggingService.logEvent(EVENT_MULTISELECT_DETAILS, multiselectProps);
      }

      const barcodeDialogRef = this.dialogService.openBarcodeDialog(
        this.experimentId,
        res.linkIdentifier,
        res.expirationDate
      );
      barcodeDialogRef.afterOpened().subscribe(_ => (this.barcodeDialogIsOpen = true));
      barcodeDialogRef.afterClosed().subscribe(_ => (this.barcodeDialogIsOpen = false));
    });
  }

  onClickFusionCounterLink() {
    window.open(this.fusionUrl, '_blank');

    const props: { [key: string]: number | string } = {
      ExperimentId: this.experimentId,
      DeviceType: this.appState.GetCurrentExperiment().deviceType,
      ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
      NumberOfResults: this.experimentCount,
    };

    this.loggingService.logEvent(EVENT_RESULTS_LINK_FUSION, props);
  }

  private setFilteredDataMenus(tableMenu?: MenuItem, pageMenu?: MenuItem) {
    if (tableMenu) {
      tableMenu.label = `Filtered Data`;
      tableMenu.items = [
        {
          label: `Select All (${this.listData.filteredData.length})`,
          command: () => {
            this.listData.filteredData.forEach(row => this.selectionModel.select(row));
          },
        },
        {
          label: 'Select None',
          command: () => {
            this.listData.filteredData.forEach(row => this.selectionModel.deselect(row));
          },
        },
      ];
    }

    const pageIndex = this.listData.paginator?.pageIndex ?? 0;
    const pageSize = this.listData.paginator?.pageSize ?? 5;

    if (pageMenu) {
      pageMenu.label = `Page`;
      pageMenu.items = [
        {
          label: `Select All (${this.listData.filteredData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).length
            })`,
          command: () => {
            this.listData.filteredData
              .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
              .forEach(row => this.selectionModel.select(row));
          },
        },
        {
          label: 'Select None',
          command: () => {
            this.listData.filteredData
              .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
              .forEach(row => this.selectionModel.deselect(row));
          },
        },
      ];
    }
  }

  private setUnfilteredDataMenus(tableMenu?: MenuItem, pageMenu?: MenuItem) {
    if (tableMenu) {
      tableMenu.label = `Table`;
      tableMenu.items = [
        {
          label: `Select All (${this.listData.data.length})`,
          command: () => {
            this.listData.data.forEach(row => this.selectionModel.select(row));
          },
        },
        {
          label: 'Select None',
          command: () => {
            this.listData.data.forEach(row => this.selectionModel.deselect(row));
          },
        },
      ];
    }

    const pageIndex = this.listData.paginator?.pageIndex ?? 0;
    const pageSize = this.listData.paginator?.pageSize ?? 5;

    if (pageMenu) {
      pageMenu.label = `Page`;
      pageMenu.items = [
        {
          label: `Select All (${this.listData.filteredData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).length
            })`,
          command: () => {
            this.listData.filteredData
              .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
              .forEach(row => this.selectionModel.select(row));
          },
        },
        {
          label: 'Select None',
          command: () => {
            this.listData.filteredData
              .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
              .forEach(row => this.selectionModel.deselect(row));
          },
        },
      ];
    }
  }

  private populateDetailsTable() {
    const currentSelection = new Set<number>(this.selectionModel.selected.map(x => x.detailId));
    this.selectionModel.clear();
    this.loading = true;
    this.apiService
      .getDetailsList('experimentId', this.experimentId)
      .pipe(
        takeUntil(this._destroying$),
        finalize(() => (this.loading = false))
      )
      .subscribe((details: IDetailDisplayItem[]) => {
        this.apiService.detailsDefaultSort(details);
        this.listData = new MatTableDataSource(details);
        this.listData.sort = this.sort;
        this.refreshParsedScanValueConflicts();

        this.listData.paginator = this.paginator;

        this.listData.filterPredicate = (data: any, filter: string) => {
          const tableFilters = this.columnFilterService.getActiveTableFilters('detail');
          const filteredColumns = this.columnFilterService.getTableFilterColumns('detail');

          const columnFilterFn = () => {
            return filteredColumns
              .map(fc => {
                return tableFilters[fc].has((data as any)[fc]);
              })
              .every(matchesFilters => matchesFilters);
          };

          const displayedColumnsFilterFn = () => {
            return this.displayedColumns.some(ele => {
              if (ele == 'actions') {
                return false;
              }

              var parts = ele.split('.');
              var columnData = undefined;
              if (parts.length > 1) {
                columnData = data.data[parts[1]].toString();
              } else {
                if (data[ele] == undefined) return false;
                columnData = data[ele].toString();
              }

              return columnData != undefined && columnData.toLowerCase().indexOf(filter) != -1;
            });
          };

          if (filteredColumns.length && this.searchKey.length) {
            return columnFilterFn() && displayedColumnsFilterFn();
          } else if (filteredColumns.length && !this.searchKey.length) {
            return columnFilterFn();
          } else if (!filteredColumns.length && this.searchKey.length) {
            return displayedColumnsFilterFn();
          } else {
            return true;
          }
        };

        this.listData.data.forEach(x => {
          if (currentSelection.has(x.detailId)) {
            this.selectionModel.select(x);
          }
        });

        this.refreshTableFilter();
      });
  }

  private async setDetailUnevaluableWithExceptionHandling(
    detail: Detail,
    batchReason: string,
    evtSource: MatCheckbox,
    prevValue: boolean
  ) {
    try {
      return await this.apiService.setDetailUnevaluableWithReasonAsync(detail, batchReason);
    } catch (err: any) {
      detail.unevaluable = !detail.unevaluable;
      evtSource.checked = prevValue;
      if (err.status == HttpStatusCode.BadRequest && err.error.expectedException) {
        this.notificationService.error(err.error.message);
      } else {
        throw err;
      }
      return;
    }
  }

  private refreshParsedScanValueConflicts() {
    this._parsedSampleTubeIds = {};
    this.conflictingSampleTubeDetailIds = {};

    this._parsedDeviceIds = {};
    this.conflictingDeviceDetailIds = {};

    this.listData.data.forEach(d => {
      if (d.sampleTubeID) {
        const parsedSampleTubeId = parseMatches(d.sampleTubeID);
        if (parsedSampleTubeId && parsedSampleTubeId != '000000') {
          if (!this._parsedSampleTubeIds[parsedSampleTubeId]) {
            this._parsedSampleTubeIds[parsedSampleTubeId] = new Set<number>();
          }

          this._parsedSampleTubeIds[parsedSampleTubeId].add(d.detailId);
        }
      }

      if (d.deviceID) {
        const parsedDeviceId = parseMatches(d.deviceID);
        if (parsedDeviceId && parsedDeviceId != '000000') {
          if (!this._parsedDeviceIds[parsedDeviceId]) {
            this._parsedDeviceIds[parsedDeviceId] = new Set<number>();
          }

          this._parsedDeviceIds[parsedDeviceId].add(d.detailId);
        }
      }
    });

    for (let parsedSampleTubeId of Object.keys(this._parsedSampleTubeIds)) {
      if (this._parsedSampleTubeIds[parsedSampleTubeId] && this._parsedSampleTubeIds[parsedSampleTubeId].size > 1) {
        for (let detailId of this._parsedSampleTubeIds[parsedSampleTubeId]) {
          this.conflictingSampleTubeDetailIds[detailId] = true;
        }
      }
    }

    for (let parsedDeviceId of Object.keys(this._parsedDeviceIds)) {
      if (this._parsedDeviceIds[parsedDeviceId] && this._parsedDeviceIds[parsedDeviceId].size > 1) {
        for (let detailId of this._parsedDeviceIds[parsedDeviceId]) {
          this.conflictingDeviceDetailIds[detailId] = true;
        }
      }
    }
  }

  private addParsedDetailIdAndUpdateConflicts(
    parsedIdMap: { [parsedId: string]: Set<number> },
    conflictingIdMap: {
      [detailId: number]: boolean;
    },
    detailId: number,
    parsedValue?: string
  ) {
    if (!parsedValue) {
      return;
    }

    if (!parsedIdMap[parsedValue]) {
      parsedIdMap[parsedValue] = new Set<number>();
    }

    parsedIdMap[parsedValue].add(detailId);

    // If the new parsedValue key-value has multiples, it is now in conflict
    if (parsedIdMap[parsedValue].size > 1) {
      for (let parsedDeviceId of Object.keys(parsedIdMap)) {
        if (parsedIdMap[parsedDeviceId] && parsedIdMap[parsedDeviceId].size > 1) {
          for (let detailId of parsedIdMap[parsedDeviceId]) {
            conflictingIdMap[detailId] = true;
          }
        }
      }
    }
  }

  private resetSampleTubeIdMapForDetailId(detailId: number, previousParsedValue?: string, parsedValue?: string) {
    this.resetBarcodeDetailIds(
      this.conflictingSampleTubeDetailIds,
      this._parsedSampleTubeIds,
      detailId,
      previousParsedValue,
      parsedValue
    );
  }

  private resetBarcodeDetailIds(
    conflictingDetailIdMap: {
      [detailId: number]: boolean;
    },
    parsedIdMap: {
      [parsedId: string]: Set<number>;
    },
    detailId: number,
    previousParsedValue?: string,
    parsedValue?: string
  ) {
    if (previousParsedValue && parsedValue != previousParsedValue && parsedIdMap[previousParsedValue]) {
      parsedIdMap[previousParsedValue].delete(detailId);
      delete conflictingDetailIdMap[detailId];
    }

    if (previousParsedValue && parsedIdMap[previousParsedValue]) {
      // If the previous number of detailIds for this parsed value is now 1, it is no longer in conflict
      if (parsedIdMap[previousParsedValue].size == 1) {
        for (let detailId of parsedIdMap[previousParsedValue]) {
          delete conflictingDetailIdMap[detailId];
        }
      }
    }
  }

  private refreshTableFilter() {
    const filteredColumns = this.columnFilterService.getTableFilterColumns('detail');
    this.listData.filter = this.searchKey.length
      ? this.searchKey.trim().toLowerCase()
      : filteredColumns.length
        ? 'custom-filter'
        : '';
  }
}
