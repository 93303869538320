import { Pipe, PipeTransform } from '@angular/core';
import { AssayType } from '../services/labpartner.service.model';

@Pipe({ name: 'assayTypeCodeFormat' })

export class AssayTypeCodeFormatPipe implements PipeTransform {
  transform(typeCode: AssayType): string {
    if ( !typeCode ) {
        return '';
    }
    return `${typeCode.name} (${typeCode.code})`;
  }
}
