import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-cell-edit-display',
  templateUrl: './cell-edit-display.component.html',
  styleUrls: ['./cell-edit-display.component.scss']
})
export class CellEditDisplayComponent implements OnChanges {
  @Input() ColValue!: any;
  @Input() ColName!: string;
  @Input() IsEdit: boolean = false;
  @Input() IsSelected: boolean = false;
  @Input() DataType: string = 'string';
  public colValue!: any;
  public colName!: string;
  public isEdit: boolean = false;
  public isSelected: boolean = false;
  public dataType: string = 'string';

  public barcodeID: string = "barcode";

  constructor() { }
  
  ngOnChanges(changes: SimpleChanges): void {
     this.colName = this.ColName;
     this.colValue = this.ColValue;
     this.isEdit = this.IsEdit;
     this.isSelected = this.IsSelected;
     this.dataType = this.DataType;
  }

  ngOnInit(): void {
  }

}

