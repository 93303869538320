<div class="search-div">
  <span class="p-input-icon-right">
    <input
      type="text"
      pInputText
      placeholder="Search"
      autocomplete="off"
      [(ngModel)]="searchKey"
      (ngModelChange)="onChange($event)" />
    <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
      <i class="pi pi-times" *ngIf="searchKey"></i>
    </span>
  </span>
  <label class="inst">Select a File(s) (&lt; 5MB)</label>
  <button mat-raised-button (click)="hiddenfileinput.click()" #uploadInput
  [disabled]="!(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"
  >
    <mat-icon>upload</mat-icon>Select File(s)
  </button>

  <input
    type="file"
    onclick="this.value = null"
    (change)="onFileChange($event)"
    #hiddenfileinput
    style="display: none"
    multiple="multiple" />

  <div class="file-upload-spinner" *ngIf="fileProcessing">
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>

  <div class="progress-bar-container" *ngIf="apiService.isLoading$ | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
<div class="mat-elevation-z8">
  <mat-table [dataSource]="listData" matSort matSortActive="dateCreated" matSortDirection="desc">
    <ng-container matColumnDef="fileName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>File Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.fileName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fileSize">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Size</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.fileSize }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.dateCreated | date : 'longDate' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdByName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.createdByName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button matTooltip="Get file" (click)="onGetFile(row)">
          <mat-icon>download</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Remove upload"
          (click)="onDelete(row)"
          [disabled]="!(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsNotComplete')">
          <mat-icon>delete</mat-icon>
        </button>
        <!-- (notesService.experimentNotesCountMap | async)!.FileUpload[row.uploadId] ?? 0 -->
        <app-note-display
          [experimentId]="experimentId"
          [noteCount]="
            (notesService.experimentNotesCountMap | async) &&
            (notesService.experimentNotesCountMap | async)?.FileUpload &&
            (notesService.experimentNotesCountMap | async)!.FileUpload[row.uploadId]
          "
          [objectID]="row.uploadId"
          [objectName]="objectName">
        </app-note-display>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6"> No data. </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: listData != null }"></mat-footer-row>
    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }">
    </mat-footer-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
</div>
