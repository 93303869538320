<div class="dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary" mat-dialog-title>
    {{ data.title }}
  </mat-toolbar>
</div>
<div mat-dialog-content class="dialog-content">
  <table mat-table [dataSource]="data.auditData" class="mat-elevation-z0">
    <ng-container *ngFor="let displayCol of data.displayedColumns | keyvalue" matColumnDef="{{ displayCol.key }}">
      <th mat-header-cell *matHeaderCellDef>{{ displayCol.value }}</th>
      <td
        mat-cell
        #auditCell
        *matCellDef="let element; let row"
        [class.is-change-date]="displayCol.key == 'changeDate'"
        [class.hide-overflow]="expandedRow != element && displayCol.key != 'changeDate'"
        [ngSwitch]="displayCol.key">
        <span
          *ngSwitchCase="'changeDate'"
          matTooltip="{{ element[displayCol.key] | date: 'MM/dd/YYYY hh:mm:ss aa z' }}"
          matTooltipClass="audit-dialog-tooltip">
          {{ element[displayCol.key] | date: 'MM/dd/YYYY hh:mm:ss aa z' }}
        </span>

        <span *ngSwitchCase="'expand'">
          <button
            mat-icon-button
            aria-label="expand row"
            matTooltip="Click to expand"
            (click)="expandedRow = expandedRow === element ? null : element; $event.stopPropagation()">
            <mat-icon *ngIf="expandedRow !== element">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedRow === element">keyboard_arrow_up</mat-icon>
          </button>
        </span>

        <span *ngSwitchDefault matTooltip="{{ element[displayCol.key] }}" matTooltipClass="audit-dialog-tooltip">
          {{ element[displayCol.key] }}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="rowDef; sticky: true"></tr>

    <tr mat-row *matRowDef="let row; columns: rowDef"></tr>
  </table>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button color="primary" (click)="onClickOk()">OK</button>
</div>
