<div class="instrument-error-dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary">
    <div>Instrument Error</div>
  </mat-toolbar>
</div>

<form [formGroup]="instrumentErrorForm" class="normal-form" (submit)="onSubmit(instrumentErrorForm.value)">
  <div mat-dialog-content class="instrument-error-dialog-content">
    <div class="instrument-error-controls-group">
      <div class="p-default-form-field p-float-label bottom-border-only">
        <input pInputText id="instrumentNumber" type="text" aria-describedby="name-error" formControlName="instrumentNumber" />
        <label for="instrumentNumber">Instrument S/N</label>
        <small id="instrument-number-error" class="p-error" *ngIf="instrumentErrorForm.controls.instrumentNumber.errors?.required">
          This field is mandatory.
        </small>
        <small id="name-error" class="p-error" *ngIf="instrumentErrorForm.controls.instrumentNumber.errors?.maxlength">
          This field is max length 200.
        </small>
      </div>

      <div class="p-default-form-field p-float-label p-static-float">
        <p-dropdown 
          id="known-error"
          styleClass="device-type-dropdown"
          appendTo="body"
          [options]="errorUserResponses"
          optionLabel="responseTitle"
          optionValue="responseId"
          [(ngModel)]="selectedIntrumentError" 
          [ngModelOptions]="{standalone: true}"
          (onChange)="onErrorChange($event)"
          [disabled]="isLoading || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"></p-dropdown>
        <label for="known-error">Known Error</label>
      </div>

      <div class="p-default-form-field p-float-label bottom-border-only p-static-float">
        <textarea
          pInputText
          id="error-text"
          formControlName="errorText"
          maxlength="2000"
          >
        </textarea>
        <label for="error-text">Error Text</label>
      </div>

      <div class="instrument-error-controls-group message">
        Select an option from the Known Error choices, and/or type it manually in Error Text.
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="instrument-error-dialog-actions">
      <button mat-raised-button color="primary" type="button" *ngIf="hasError" (click)="onRemove()" [disabled]="isLoading"
      [disabled]="!(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')">
        Remove Instrument Error
      </button>
      <button 
          type="button"
          *ngIf="hasError"
          color="primary"
          mat-icon-button
          class="experiment-edit-icon"
          matTooltip="This option is to remove Instrument Error check and delete the info saved previously, use it only in case you had recorded an Instrument Error mistakenly.">
          <mat-icon>help</mat-icon>
        </button>
    <div class="separator"></div>
      <button mat-raised-button color="warn" type="button" (click)="onCancel()" [disabled]="isLoading">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="instrumentErrorForm.invalid || isLoading || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')">
        Submit
      </button>
  </div>
</form>
