import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { MatSort } from '@angular/material/sort';
import { DialogService } from 'src/app/shared/dialog.services';
import {
  AuditedChangeResponse,
  Experiment,
  ExperimentType,
  ImportData,
  Logistic,
} from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';
import { xlsImportData } from 'src/app/xls-import/xls-import.component';
import { ILogisticDialogData, ILogisticDialogResult, LogisticComponent } from '../logistic/logistic.component';
import {
  EVENT_APPEND_LOGISITCS,
  EVENT_CREATE_LOGISITC,
  EVENT_DELETE_LOGISTIC,
  EVENT_IMPORT_LOGISITCS,
} from 'src/app/services/logging-constants';
import { LoggingService } from 'src/app/services/logging.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { NotificationService } from 'src/app/shared/notification.service';
import {
  AuditObjectIdCountMap,
  AuditObjectName,
  ILogisticsAuditChangeReasons,
} from 'src/app/services/audit.service.models';
import { IChangeSet } from 'src/app/changes-dialog/changes-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ITableColumnFilter } from 'src/app/column-filter/column-filter.service';

interface ILogisticDisplayItem {
  name: string;
  value: string;
  data?: { [key: string]: any };
}

@Component({
  selector: 'app-logistic-list',
  templateUrl: './logistic-list.component.html',
  styleUrls: ['./logistic-list.component.scss'],
})
export class LogisticListComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() experimentId: number = 0;
  @Input() logisticsAuditCountMap?: AuditObjectIdCountMap;
  @Input() currentExperiment: Experiment | null = null;
  @Input() currentRoles: string[] = [];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<any>;

  listData: MatTableDataSource<ILogisticDisplayItem> = new MatTableDataSource();

  objectName = 'Logistics';
  importData!: ImportData;
  jsonData!: any;
  logistic!: Logistic;

  displayedColumns!: string[];
  // material table mat-header-cell and mat-cell requires only the property name (not the full property path)
  propertyNames!: string[];

  // material table mat-header-row requires the full property path (e.g. data.temperature) so we build those up separately
  propertyPaths!: string[];

  searchKey: string = '';

  constructor(
    public apiService: LabpartnerService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private loggingService: LoggingService
  ) {
    super();
  }

  ngOnInit() {
    this.apiService.getLogisticsList('experimentId', this.experimentId).subscribe((logistics: Logistic) => {
      this.parseLogisticData(logistics);
    });
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  onSearchClear() {
    this.searchKey = '';
    this.onChange('');
  }

  onChange(newVal: string) {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  changedJSON(importData: xlsImportData) {
    if (this.listData.data.length > 0) {
      const dialog = this.dialogService.openConfirmDialog(
        'Are you sure you want to import this data, previous data will be overwritten ?'
      );
      dialog.afterClosed().subscribe(res => {
        if (res) {
          this.performImport(importData, true);
        }
      });
    } else {
      this.performImport(importData, false);
    }
  }

  onCreate() {
    const dialogConfig = new MatDialogConfig<ILogisticDialogData>();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '40%';
    dialogConfig.data = {
      isCreate: true,
      experimentType: this.appState.GetCurrentExperiment().type,
      logisticKey: '',
      name: '',
      value: '',
    };

    const dialogRef = this.dialog.open<LogisticComponent, ILogisticDialogData, ILogisticDialogResult>(
      LogisticComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (!dialogResult) {
        return;
      }

      if (dialogResult.submitClicked) {
        if (this.jsonData == '') {
          this.jsonData = JSON.parse(
            JSON.stringify({
              [dialogResult.data.name]: dialogResult.data.value,
            })
          );
        } else {
          this.jsonData[dialogResult.data.name] = dialogResult.data.value;
        }

        var logisticDisplayItem = {
          name: dialogResult.data.name,
          value: dialogResult.data.value,
        };

        let changeSet: IChangeSet[] = [];
        changeSet.push({
          identifier: 'New Logistic',
          field: dialogResult.data.name,
          oldValue: dialogResult.data.value,
          newValue: '+ 1 Logistic',
        });

        const dialogRef = this.dialogService.openConfirmChangesDialog(
          changeSet,
          this.appState.GetCurrentExperiment().type,
          { isLogistic: true }
        );

        dialogRef.afterClosed().subscribe(async data => {
          if (
            data?.submitClicked &&
            (data?.reasonProvided ||
              this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
          ) {
            this.apiService
              .createLogisticKeyValue(
                this.experimentId,
                logisticDisplayItem.name,
                logisticDisplayItem.value,
                data.batchReason
              )
              .subscribe(
                (updatedLogistic: Logistic) => {
                  if (!this.logistic) {
                    this.logistic = updatedLogistic;
                    this.parseLogisticData(updatedLogistic);
                  } else {
                    this.logistic.importData = JSON.stringify(this.jsonData);
                    this.listData.data.push(logisticDisplayItem);
                  }

                  this.listData._updateChangeSubscription();
                  this.appState.SetExperimentDataChanged(this.experimentId);

                  this.notificationService.success('Logistic created');

                  const props: { [key: string]: string | number } = {
                    ExperimentId: this.experimentId,
                    DeletedLogisticName: logisticDisplayItem.name,
                    DeletedLogisticValue: logisticDisplayItem.value,
                    DeviceType: this.appState.GetCurrentExperiment().deviceType,
                  };
                  this.loggingService.logEvent(EVENT_CREATE_LOGISITC, props);
                },
                (err: any) => {
                  const expectedErr = err.error as AuditedChangeResponse;
                  if (expectedErr.expectedException) {
                    this.notificationService.error(expectedErr.message);
                  } else {
                    throw err;
                  }
                }
              );
          }
        });
      }
    });
  }

  onEdit(row: ILogisticDisplayItem) {
    const dialogConfig = new MatDialogConfig<ILogisticDialogData>();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.data = {
      isCreate: false,
      experimentType: this.appState.GetCurrentExperiment().type,
      logisticKey: row.name,
      name: row.name,
      value: row.value,
    };

    const dialogRef = this.dialog.open<LogisticComponent, ILogisticDialogData, ILogisticDialogResult>(
      LogisticComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (!dialogResult) {
        return;
      }

      if (dialogResult.submitClicked && this.listData.data) {
        delete this.jsonData[dialogResult.data.logisticKey];

        this.jsonData[dialogResult.data.name] = dialogResult.data.value;

        var logisticDisplayItem: ILogisticDisplayItem = {
          name: dialogResult.data.name,
          value: dialogResult.data.value,
        };

        this.listData.data.splice(
          this.listData.data.map(d => d.name).indexOf(dialogResult.data.logisticKey),
          1,
          logisticDisplayItem
        );

        await this.updateLogisticAsync(this.jsonData, this.logistic, dialogResult.auditChangeReasons);
      }
    });
  }

  onDelete(row: ILogisticDisplayItem) {
    const key = row.name;

    const value = this.jsonData[key];
    delete this.jsonData[key];

    this.logistic.importData = JSON.stringify(this.jsonData);

    let changeSet: IChangeSet[] = [];
    changeSet.push({
      identifier: 'Logistic',
      field: row.name,
      oldValue: row.value,
      newValue: 'DELETED',
    });

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      this.appState.GetCurrentExperiment().type,
      { isDelete: true, isLogistic: true }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        this.apiService.deleteLogisticKeyValue(this.experimentId, key, value, data.batchReason).subscribe(
          (logistic: Logistic) => {
            this.parseLogisticData(logistic);

            this.listData._updateChangeSubscription();
            this.appState.SetExperimentDataChanged(this.experimentId);

            const props: { [key: string]: string | number } = {
              ExperimentId: this.experimentId,
              DeletedLogisticName: row.name,
              DeletedLogisticValue: row.value,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
            };
            this.loggingService.logEvent(EVENT_DELETE_LOGISTIC, props);
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        );
      }
    });
  }

  showAuditDialog(logistic: Logistic) {
    this.dialogService.openChangelogDialog(
      `Change log for Logistics`,
      this.experimentId,
      AuditObjectName.Logistics,
      logistic.logisticId
    );
  }

  private performImport(importData: xlsImportData, promptForChanges: boolean = false) {
    let isAppend = false;
    // TODO: Uncomment this to honor Import/Append for Logistics
    // Note: Must also update the app-xls-import [overrideIsImportStatus] property (just delete the binding completely)
    // if ((this.listData.data.length > 0) || (this.appState.GetCurrentExperiment()?.status ?? -1) >= ReadyToTestStatus) {
    //   isAppend = true;
    // }

    let logistic: Logistic = this.getLogistic(importData);

    const importDataKeys = Object.keys(importData.data[0]);

    if (promptForChanges) {
      let changeSet: IChangeSet[] = [];

      for (let key of importDataKeys) {
        const value = importData.data[0][key];
        changeSet.push({
          identifier: 'New Logistic',
          field: key,
          oldValue: value,
          newValue: '+ 1 Logistic',
        });
      }

      for (let existingLogistic of this.listData.data) {
        changeSet.push({
          identifier: 'Logistic',
          field: existingLogistic.name,
          oldValue: existingLogistic.value,
          newValue: 'DELETED',
          data: 'DELETED',
        });
      }

      const dialogRef = this.dialogService.openConfirmChangesDialog(
        changeSet,
        this.appState.GetCurrentExperiment().type,
        { isLogistic: true }
      );

      dialogRef.afterClosed().subscribe(async data => {
        if (
          data?.submitClicked &&
          (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
        ) {
          this.createMultipleLogistics(isAppend, importDataKeys.length, logistic, data.batchReason);
        }
      });
    } else {
      this.createMultipleLogistics(false, importDataKeys.length, logistic, undefined);
    }
  }

  private async createMultipleLogistics(
    isAppend: boolean,
    logisticCount: number,
    logistic: Logistic,
    batchReason?: string
  ) {
    this.apiService.createMultipleLogistics(isAppend, this.experimentId, logistic, batchReason).subscribe(
      (newLogistics: Logistic) => {
        const props: { [key: string]: number } = {
          ExperimentId: this.experimentId,
          ImportedLogisticCount: logisticCount,
          DeviceType: this.appState.GetCurrentExperiment().deviceType,
        };
        this.loggingService.logEvent(isAppend ? EVENT_APPEND_LOGISITCS : EVENT_IMPORT_LOGISITCS, props);

        this.notificationService.success('Logistics imported');

        this.appState.SetExperimentDataChanged(this.experimentId);
        this.parseLogisticData(newLogistics);
      },
      (err: any) => {
        const expectedErr = err.error as AuditedChangeResponse;
        if (expectedErr.expectedException) {
          this.notificationService.error(expectedErr.message);
        } else {
          throw err;
        }
      }
    );
  }

  private getLogistic(importData: xlsImportData) {
    this.createImportRecord(importData);

    const elem = importData.data[0];

    let logistic: Logistic = {
      logisticId: 0,
      experimentId: this.experimentId,
      importData: JSON.stringify(elem),
      dateCreated: new Date(),
      dateUpdated: new Date(),
      createdBy: this.apiService.getLoggedInUser(),
    };

    return logistic;
  }

  private createImportRecord(data: xlsImportData) {
    let importData: ImportData = {
      importDataId: 0,
      experimentId: this.experimentId,
      type: 2,
      importSource: data.file,
      importHeader: data.header.length > 1 ? data.header.slice(1).join('') : data.header.join(''),
      dateCreated: new Date(),
      createdBy: this.apiService.getLoggedInUser(),
    };

    this.apiService.createImportData(importData).subscribe((result: ImportData) => {
      this.importData = result;
    });
  }

  private async updateLogisticAsync(
    jsonData: any,
    logistic: Logistic,
    logisticsAuditChangeReasons?: ILogisticsAuditChangeReasons
  ) {
    try {
      this.logistic.importData = JSON.stringify(jsonData);

      await this.apiService.updateLogistic(logistic, logisticsAuditChangeReasons).toPromise();
      this.listData._updateChangeSubscription();
      this.appState.SetExperimentDataChanged(this.experimentId);
    } catch (err: any) {
      const expectedErr = err.error as AuditedChangeResponse;
      if (expectedErr.expectedException) {
        this.notificationService.error(expectedErr.message);
      } else {
        throw err;
      }
    }
  }

  private parseLogisticData(logistic: Logistic) {
    this.listData = new MatTableDataSource();
    if (!logistic) {
      this.jsonData = {};
      return;
    }

    this.logistic = logistic;
    this.jsonData = JSON.parse(logistic.importData);

    for (let key of Object.keys(this.jsonData)) {
      var logisticDisplayItem = {
        name: key,
        value: this.jsonData[key],
      };
      this.listData.data.push(logisticDisplayItem);
    }

    this.displayedColumns = ['name', 'value', 'actions'];

    this.listData.sort = this.sort;
    this.listData.paginator = this.paginator;

    this.listData.filterPredicate = (logisticItem, filter) => {
      return this.displayedColumns.some(ele => {
        if (ele == 'actions') {
          return false;
        }

        var columnData = undefined;

        var parts = ele.split(',');

        if (parts.length > 1) {
          columnData = logisticItem.data ? logisticItem.data[parts[1]].toString() : '';
        } else {
          columnData = (logisticItem as any)[ele].toString();
        }

        return columnData != undefined && columnData.toLowerCase().indexOf(filter) != -1;
      });
    };
  }
}
