import { Component, OnInit, OnDestroy } from '@angular/core';
import { BundleService } from '../../shared/bundle.service';
import { BaseComponent } from '../../support/base.component';
import { LabpartnerService } from '../../services/labpartner.service';
import { AssayType, Bundle } from 'src/app/services/labpartner.service.model';
import { NotificationService } from 'src/app/shared/notification.service';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { EVENT_CREATE_BUNDLE } from 'src/app/services/logging-constants';
import { UserAccountService } from 'src/app/services/user-account.service';
import { FormGroup, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-bundle',
  templateUrl: './bundle.component.html',
  styleUrls: ['./bundle.component.scss'],
})
export class BundleComponent extends BaseComponent implements OnInit, OnDestroy {
  wasCanceled: boolean = false;
  bundleTypes!: any;
  selectedBundleTypeId: number = 0;
  isClinicalTrialChecked: boolean = false;
  showClinicalTrial: boolean = false;
  disableClinicalTrialCheckBox: boolean = false;
  isCurtainBlocked: boolean = false;

  public serialsAreValid = false;
  public datesAreValid = false;
  public bundleId: number | undefined = 0;

  public assayTypeCodes: AssayType[] = [];

  constructor(
    public service: BundleService,
    private apiService: LabpartnerService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<BundleComponent>,
    private router: Router,
    public accountService: UserAccountService,
    private loggingService: LoggingService
  ) {
    super();
    this.bundleTypes = service.bundleTypes;
  }

  ngOnInit(): void {
    this.selectedBundleTypeId = this.service.form.value.bundleType;
    this.bundleId = this.service.form.value.$key;
    this.showClinicalTrial = this.accountService.isClinicalTrial(); //if clincial trial role

    if (this.service.form.value.isClinicalTrial) {
      this.isClinicalTrialChecked = true;
      this.disableClinicalTrialCheckBox = true;
    }

    this.setIsValid();
    this.setFormGroupFromBundleType(this.service.form);

    this.subscription.add(
      this.service.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        this.setIsValid();
      })
    );

    let sub = this.apiService.getAssayTypes().subscribe(codes => {
      this.assayTypeCodes = codes;
    });
    this.subscription.add(sub);
  }

  setIsValid(): void {
    let form = this.service.form;
    this.serialsAreValid = this.serialNumberValidator(form);
    this.datesAreValid = this.dateValidator(form);
    if (!this.serialsAreValid || !this.datesAreValid) {
      form.setErrors({ incorrect: true });
    }
  }

  serialNumberValidator(form: FormGroup): boolean {
    const serialNumbers = form.get('instrumentSerialNumbers')?.value;
    if (!serialNumbers) {
      return true;
    } else if (serialNumbers.length == 0) {
      return true;
    } else if (serialNumbers.length > 0) {
      const alphaNumericRegex = /^([a-zA-Z0-9]+)(,\s*[a-zA-Z0-9]+)*$/g;
      const valid = alphaNumericRegex.test(serialNumbers);
      return valid;
    } else {
      return false;
    }
  }

  dateValidator(form: FormGroup): boolean {
    const startDate = form.get('startDate')?.value;
    const endDate = form.get('endDate')?.value;
    if (!startDate || !endDate) {
      return true;
    }
    else if (startDate instanceof Date && endDate instanceof Date && startDate < endDate) {
      return true ;
    } else {
      return false;
    }
  }

  displayInvalidFormFields(form: UntypedFormGroup): void {
    if (!form.valid) {
      let invalids = this.findInvalidControlsRecursive(form);
      console.log(invalids);
    }
  }

  // Returns an array of invalid control/group names, or a zero-length array if
  // no invalid controls/groups where found.
  findInvalidControlsRecursive(formToInvestigate: UntypedFormGroup | UntypedFormArray): string[] {
    var invalidControls: string[] = [];
    let recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control?.invalid) invalidControls.push(field);
        if (control instanceof UntypedFormGroup) {
          recursiveFunc(control);
        } else if (control instanceof UntypedFormArray) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  onCancel() {
    this.wasCanceled = true;
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.notificationService.warn(':: Canceled');
    let dialogresult = { ok: false, data: null };
    this.onClose(dialogresult);
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  onSubmit(data: any) {
    this.isCurtainBlocked = true;
    if (this.wasCanceled) return;

    if (this.isClinicalTrialChecked) {
      data.isClinicalTrial = true;
    }

    console.log(data);

    data.instrumentSerialNumbers = this.removeAnyDuplicates(data.instrumentSerialNumbers);

    if (!this.service.form.get('$key')!.value) {
      console.log('inserting new record');

      const sub = this.apiService.createSerialNumbersBundle(data).subscribe(
          (result: Bundle) => {
            this.notificationService.success(':: Bundle created successfully');
            let dialogresult = { ok: true, data: result };
            this.onClose(dialogresult);

            const props: { [key: string]: number } = { BundleId: result.bundleId };
            this.loggingService.logEvent(EVENT_CREATE_BUNDLE, props);
          },
          (err: any) => {
            // TODO: show an error with toast, etc.
            this.notificationService.warn(':: Error creating Bundle');
            console.log(err);
            let dialogresult = { ok: false, data: err };
            this.onClose(dialogresult);
          }
        );
        this.subscription.add(sub);
    } else {
      console.log('updating existing record');

      const sub = this.apiService.updateBundle(this.service.form.get('$key')!.value, data).subscribe(
        (result: Bundle) => {
          this.notificationService.success(':: Bundle updated successfully');
          let dialogresult = { ok: true, data: result };
          this.onClose(dialogresult);
        },
        (err: any) => {
          // TODO: show an error with toast, etc.
          this.notificationService.warn(':: Error updating Bundle');
          let dialogresult = { ok: false, data: err };
          console.log(err);
          this.onClose(dialogresult);
        }
      );
      this.subscription.add(sub);
    }
  }

  removeAnyDuplicates(data: string): string {
    return [...new Set(data.split(','))].join(',');
  }

  bundleTypeOnChange(event: any) {
    // old school approach, can't mix ngModel with formNames.
    this.selectedBundleTypeId = event.value;
    console.log(this.selectedBundleTypeId);
    this.setFormGroupFromBundleType(this.service.form);
  }

  onClose(dialogresult: any) {
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.isCurtainBlocked = false;
    this.dialogRef.close(dialogresult);
  }

  toggleClinicalStatus($event: MatCheckboxChange): void {
    this.isClinicalTrialChecked = !this.isClinicalTrialChecked;
    console.log('isClinicalTrialChecked: ', this.isClinicalTrialChecked);
  }

  setFormGroupFromBundleType(form: UntypedFormGroup): void {
    form.get('endDate')?.enable({ emitEvent: false });
    form.controls.endDate.setValidators(Validators.required);

    form.get('assayTypeCode')?.enable({ emitEvent: false });
    form.controls.assayTypeCode.setValidators(Validators.required);

    form.get('startDate')?.enable({ emitEvent: false });
    form.controls.startDate.setValidators(Validators.required);

    form.get('instrumentSerialNumbers')?.enable({ emitEvent: false });
    form.controls.instrumentSerialNumbers.setValidators(Validators.required);

    form.get('endDate')?.updateValueAndValidity({ emitEvent: false });
    form.get('assayTypeCode')?.updateValueAndValidity({ emitEvent: false });
    form.get('startDate')?.updateValueAndValidity({ emitEvent: false });
    form.get('instrumentSerialNumbers')?.updateValueAndValidity({ emitEvent: false });
  }
}
