import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Sample } from '../services/labpartner.service.model';

@Injectable({
  providedIn: 'root'
})
export class SampleService {

  constructor() { }

  form: UntypedFormGroup = new UntypedFormGroup({
    $key: new UntypedFormControl(null),
    name: new UntypedFormControl('', Validators.required),
    dateCreated: new UntypedFormControl('')});

  initializeFormGroup() {
    this.form.setValue({
      $key: null,
      name: '',
      dateCreated: '',
    });
  }

  populateForm(experiment: Sample) {
    this.form.setValue({
      $key: experiment.experimentId,
      name: experiment.name,
      dateCreated: experiment.dateCreated
    });
  }
}

