import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { Injector, Type, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { AppModule } from './app/app.module';
import { ConfigurationService } from './app/services/configuration.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const injector = Injector.create({
  providers: [
    { provide: HttpClient, deps: [HttpHandler] },
    { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest() }) },
    { provide: ConfigurationService, deps: [HttpClient] }]
});

const configurationService = injector.get(ConfigurationService as Type<ConfigurationService>);

console.log("Initializing configuration service");

configurationService.initializeConfiguration().then(() => {
  const config = configurationService.getConfig();
  const msalConfig = config.msalConfig;

  const msalInstance = new PublicClientApplication(
    {
      auth: {
        clientId: msalConfig.clientId,
        authority: msalConfig.authority,
        redirectUri: msalConfig.redirectUri,
        // navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v1-docs/ie-support.md
      }
    });

  const guardConfig: MsalGuardConfiguration = {
    interactionType: InteractionType.Redirect,
  };

  const apiServiceVersionUrlBase = config.apiServiceEndpoint + 'v1/';
  const apiScope = config.apiScope;

  const interceptorConfig: MsalInterceptorConfiguration = {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([ // protectedResources go here. MSAL adds appropriate token to outbound API calls
      [`${apiServiceVersionUrlBase}Barcode/GetBarcodesByIdentifier*`, null],
      [apiServiceVersionUrlBase, [apiScope]],
    ])
  };

  platformBrowserDynamic(
    [
      { provide: ConfigurationService, useValue: configurationService },
      { provide: MSAL_INSTANCE, useValue: msalInstance },
      { provide: MSAL_INTERCEPTOR_CONFIG, useValue: interceptorConfig },
      { provide: MSAL_GUARD_CONFIG, useValue: guardConfig },
    ])
    .bootstrapModule(AppModule).catch(err => console.error(err));

});
