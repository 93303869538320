import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'release-notes-dialog',
  templateUrl: 'release-notes-dialog.component.html',
  styleUrls: ['release-notes-dialog.component.scss'],
})
export class ReleaseNotesDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ReleaseNotesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  onClickClose(): void {
    this.dialogRef.close();
  }
}
