import { Pipe, PipeTransform } from '@angular/core';
import {
  CompleteStatus,
  DetailsGeneratedStatus,
  Experiment,
  VerbedExperimentStatus,
  ReadyToTestStatus,
} from '../services/labpartner.service.model';
import { ROLE_NAMES } from '../services/user-account.service';

@Pipe({ name: 'hasAnyRoles' })
export class HasAnyRolesPipe implements PipeTransform {
  constructor() { }

  transform(
    currentRoles: string[] | null,
    roleNames: (keyof typeof ROLE_NAMES)[],
    experiment: Experiment | null,
    status: keyof typeof VerbedExperimentStatus
  ): boolean {
    if (!currentRoles || (status != 'IsAnyStatus' && !experiment) || currentRoles.length == 0) {
      return false;
    }

    const isAdmin = currentRoles.indexOf(ROLE_NAMES.ADMIN) != -1;
    // Is admin and no experiment specified, so return true
    if (isAdmin && !experiment) {
      return true;
    }

    let experimentHasValidStatus: boolean = experiment?.status == VerbedExperimentStatus[status];
    let hasValidRoles: boolean = false;

    switch (status) {
      case 'IsAnyStatus':
        experimentHasValidStatus = true;
        break;
      case 'IsNotComplete':
        experimentHasValidStatus = experiment ? experiment.status != CompleteStatus : false;
        break;
      case 'IsLessThanReadyToTest':
        experimentHasValidStatus = experiment ? experiment.status < ReadyToTestStatus : false;
        break;
      case 'IsGreaterThanDetailsGenerated':
        experimentHasValidStatus = experiment ? experiment.status > DetailsGeneratedStatus : false;
        break;
    }

    if (!roleNames || roleNames.length === 0 || isAdmin) {
      hasValidRoles = true;
    }
    else {
      for (let role of roleNames) {
        const hasRole = currentRoles.indexOf(ROLE_NAMES[role]) != -1;
        if (hasRole) {
          hasValidRoles = true;
        }
      }
    }

    // Return true if the user has any of the roles and the experiment has the correct status
    return hasValidRoles && experimentHasValidStatus;
  }
}
