<button mat-raised-button (click)="hiddenfileinput.click()" [disabled]="disabled">
  <mat-icon>upload</mat-icon>{{ IsImportStatus ? 'Import XLS' : 'Append XLS' }}
</button>

<input
  type="file"
  onclick="this.value = null"
  (change)="onFileChange($event)"
  style="display: none"
  #hiddenfileinput
  [disabled]="disabled" />
