<button mat-icon-button (click)="onNote()" [disabled]="isEditMode || isDisabled">
  <div *ngIf="getNoteCounter() > 0">
    <mat-icon
      [matBadge]="getNoteCounter()"
      matBadgeColor="warn"
      matTooltip="Click to add / view notes"
      matBadgePosition="below">
      notes
    </mat-icon>
  </div>
  <div *ngIf="getNoteCounter() == 0">
    <mat-icon matTooltip="Click to add notes">notes</mat-icon>
  </div>
</button>
