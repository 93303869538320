import { Pipe, PipeTransform } from '@angular/core';
import { Experiment, ExperimentStatus } from '../services/labpartner.service.model';

@Pipe({ name: 'statusIsNot' })
export class ExperimentStatusIsNotPipe implements PipeTransform {
  constructor() {}

  transform(experiment: Experiment | null, statuses: (keyof typeof ExperimentStatus)[]): boolean {
    if (!experiment) {
      return false;
    }

    for (let status of statuses) {
      if (ExperimentStatus[experiment.status] != status) {
        return true;
      }
    }
    return false;
  }
}
