import { Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export abstract class BaseComponent implements OnDestroy {

  protected subscription = new Subscription();

  ngOnDestroy(): void {
    this.ngOnDestroyInternal();
    this.subscription.unsubscribe();
  }

  protected abstract ngOnDestroyInternal(): void;
}
