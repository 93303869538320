import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration, FeatureFlags } from './configuration.service.model';
import { BehaviorSubject, from, map, Observable } from 'rxjs';
import { AppConfigurationClient } from '@azure/app-configuration';
import { BaseComponent } from '../support/base.component';

@Injectable(
  //   {
  //   providedIn: 'root'  // not using automatic injection into root module since we are creating it manually in main.ts bootstrapping
  // }
)
export class ConfigurationService  extends BaseComponent {

  private initialized: boolean = false;
  private configuration: Configuration | undefined;
  private _featureFlags: BehaviorSubject<FeatureFlags> = new BehaviorSubject<FeatureFlags>({} as FeatureFlags);
  public featureFlags$: Observable<FeatureFlags> = this._featureFlags.asObservable();

  private azAppConfigurationClient?:AppConfigurationClient;

  constructor(private httpClient: HttpClient) {
    super();
  }


  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }


  public async initializeConfiguration(): Promise<void> {
    if (this.initialized) {
      console.debug('ConfigurationService already initialized. Skipping another fetch');
      return;
    }

    const configData = await this.httpClient.get<Configuration>('assets/configuration.json').toPromise();
    
    if (!configData) { return; }

    this.configuration = {
      apiServiceEndpoint: configData.apiServiceEndpoint,
      apiScope: configData.apiScope,
      appInsightsKey: configData.appInsightsKey,
      msalConfig: {
        clientId: configData.msalConfig.clientId,
        authority: configData.msalConfig.authority,
        redirectUri: configData.msalConfig.redirectUri
      },
      noAccessContactMessage: configData.noAccessContactMessage,
      instructionVideoUrl: configData.instructionVideoUrl,
      environmentName: configData.environmentName,
      labpartnerAppconfigCnn: configData.labpartnerAppconfigCnn,
      featureFlags: configData.featureFlags
    };

    try {
      this.azAppConfigurationClient = new AppConfigurationClient(configData.labpartnerAppconfigCnn);

      this.subscription.add(
        this.getFeatureFlagsByLabel().subscribe(flag => {
          let featureflags :any= this._featureFlags.value;
          featureflags[flag.key] = flag.value;
          this._featureFlags.next(featureflags);
        }));
      
    } catch (error) {

      console.error("Failed to connect to labpartner-{environment}-appconfig azure resource, please double check connection string ",error);
    }
     

      
    if (configData.featureFlags) {
      this._featureFlags.next(configData.featureFlags);
    }
     

    console.log("configuration initialized");

    this.initialized = true;

  }

  public getConfig(): Configuration {
    if (!this.initialized) {
      throw new Error('ConfigurationService must be initialized first.');
    }

    return this.configuration!;
  }

  public getFeatureFlags(): FeatureFlags | undefined {
    if (!this.initialized || !this.configuration) {
      throw new Error('ConfigurationService must be initialized first.');
    }

    return this.configuration.featureFlags ? this.configuration.featureFlags : {} as FeatureFlags;
  }

  public getFeatureFlagsByLabel(): Observable<any> {
  
    if (!this.azAppConfigurationClient) {
      throw new Error('AppConfigurationClient is not initialized.');
    }
    return from(this.azAppConfigurationClient.listConfigurationSettings())
      .pipe(      
        map((flags) =>{
          if(flags.value && flags.key ){

              let key : any= flags.key.split('/').pop();
              let value = JSON.parse(flags.value).enabled;

              return {key,value};
          
          }else{
            return {key:'',value:false};
          }       
        })
      );
  }
}