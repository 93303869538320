import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { LoggingService } from 'src/app/services/logging.service';
import { BaseDetailListPrintFormatsComponent } from '../detail-list-print/BaseDetailListPrintFormats';
import { PAGE_NAME_PRINTZEBRASHEET } from 'src/app/services/logging-constants';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-detail-list-print-zebra',
  templateUrl: './detail-list-print-zebra.component.html',
  styleUrls: ['./detail-list-print-zebra.component.scss']
})
export class DetailListPrintZebraComponent extends BaseDetailListPrintFormatsComponent implements OnInit {

  constructor(apiService: LabpartnerService,
    route: ActivatedRoute,
    loggingService: LoggingService,
    public notificationService: NotificationService
  ) {

    super(apiService, route, loggingService, notificationService);

    this.LABELS_PER_ROW = 5;
    this.LABELS_PER_PAGE = Number.MAX_SAFE_INTEGER;
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  ngOnInit(): void {

    this.OnInit(PAGE_NAME_PRINTZEBRASHEET);

  }

}

