<div class="search-div">
  <span class="p-input-icon-right">
    <input
      type="text"
      pInputText
      placeholder="Search"
      autocomplete="off"
      [(ngModel)]="searchKey"
      (ngModelChange)="onChange($event)" />
    <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
      <i class="pi pi-times" *ngIf="searchKey"></i>
    </span>
  </span>

  <button
    mat-raised-button
    matTooltip="Generate details for experiment: Samples x Conditions x Replicates"
    (click)="onGenerateDetails()"
    [disabled]="
      apiService.isLoading ||
      (currentExperiment | statusIs : ['ReadyToTest', 'Complete']) ||
      !(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsNotComplete') ||
      currentExperiment?.samples?.length == 0 ||
      currentExperiment?.conditions?.length == 0
    ">
    <mat-icon>queue</mat-icon>{{ (currentExperiment | statusIs : ['Created']) ? 'Generate Details' : 'Adjust Details' }}
  </button>

  <button mat-raised-button [matMenuTriggerFor]="menuPrintTargets"
    [disabled]="
        apiService.isLoading ||
        (currentExperiment | statusIs : ['Created', 'DetailsGenerated'])
      "><mat-icon>print</mat-icon>Print</button>

  <mat-menu #menuPrintTargets="matMenu">
    <button mat-menu-item (click)="onPrint('Avery')" matTooltip="Click to print on Avery #94102 3/4 by 3/4 inch labels">
      Avery Sheets
    </button>
    <button mat-menu-item (click)="onPrint('Barcodes')" matTooltip="Click to print barcodes">Barcodes</button>
    <button mat-menu-item (click)="onPrintRunSheet()" matTooltip="Click to print Run Sheet">Run Sheet</button>
    <button
      mat-menu-item
      (click)="generateBarcodeLinkIdentifier()"
      matTooltip="Click to generate mobile QR code"
      [disabled]="!listData || !listData.data || listData.data.length == 0">
      Mobile QR Code
    </button>
    <button mat-menu-item (click)="downloadFile()" matTooltip="Click to download details as XLSX">Download XLSX</button>
  </mat-menu>

  <button
    mat-raised-button
    matTooltip="Set ready to perform test"
    (click)="onSetReady()"
    [disabled]="
      apiService.isLoading ||
      (currentExperiment | statusIsNot : ['DetailsGenerated']) ||
      !(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsDetailsGenerated')
    ">
    <mat-icon>science</mat-icon>Set Ready To Test
  </button>

  <button
    mat-raised-button
    matTooltip="Set test complete, disable further editing"
    (click)="onSetComplete()"
    [disabled]="
      apiService.isLoading ||
      (currentExperiment | statusIsNot : ['ReadyToTest']) ||
      !(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsReadyToTest')
    ">
    <mat-icon>check</mat-icon>Set Complete
  </button>

  <button
    mat-raised-button
    class="scanner-direction"
    matTooltip="Direction to auto advance after scan: {{ scanCompleteAdvanceDirection }}"
    (click)="toggleScanDirection()"
    [disabled]="!selectionModel.isEmpty() ||  !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')" >
    <mat-icon>sensors</mat-icon>
    <mat-icon *ngIf="scanCompleteAdvanceDirection == 'DOWN'">arrow_downward</mat-icon>
    <mat-icon *ngIf="scanCompleteAdvanceDirection == 'RIGHT'">arrow_forward</mat-icon>
    <mat-icon *ngIf="scanCompleteAdvanceDirection == 'OFF'" class="auto-advance-off">close</mat-icon>
  </button>

  <button
    mat-icon-button
    class="scanner-setup-help"
    matTooltip="Click for help configuring scanner"
    (click)="scannerHelper.click()">
    <a href="/assets/scanner-help.html" target="_blank" #scannerHelper><mat-icon>help_outline</mat-icon></a>
  </button>

  <div class="duplicate-scan-ids">
    <div class="sample-tube-warning" *ngIf="(conflictingSampleTubeDetailIds | keyvalue).length > 0">
      Duplicate Sample Tube IDs exist.
    </div>
    <div class="device-warning" *ngIf="(conflictingDeviceDetailIds | keyvalue).length > 0">
      Duplicate Device IDs exist.
    </div>
  </div>

  <div class="generating-export-container" *ngIf="generatingExport">
    <span class="loading-text" >Generating export...</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div *ngIf="isSavannaDevice && (experimentCount > 0)" class="savanna-experiment-link">
    <a routerLink="/savanna-results"  [queryParams]="{ experimentid: experimentId }">View Savanna Data</a>
  </div>

  <div *ngIf="isSavannaDevice" class="fusion-experiment-link">
    <a [ngClass]="isExperimentLinkCounterDisabled?'disabled':null"
       [routerLink]="[]" (click)="onClickFusionCounterLink()"
       >Show Results</a>
    <span [hidden]="experimentCount === 0">({{experimentCount}})</span>
  </div>

</div>

<div class="mat-elevation-z8 table-container">
  <mat-table #detailsTable [dataSource]="listData" matSort (contentChanged)="tableDataChanged()">
    <ng-container matColumnDef="select" >
      <mat-header-cell *matHeaderCellDef >
        <button #btn type="button" pButton icon="pi pi-list"
          class="p-button-rounded p-button-secondary p-button-text multi-select-btn"
          (click)="multiSelectMenu.toggle($event)"  [disabled]="  !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"></button>
        <p-tieredMenu #multiSelectMenu [model]="multiSelectItems" [popup]="true" appendTo="body"
          styleClass="p-multi-select-menu" (onShow)="onMultiSelectMenuShow($event)"></p-tieredMenu>
        <span class="multi-select-checkbox" *ngIf="selectionModel.selected.length">
          ({{ selectionModel.selected.length }})
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" >
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionModel.toggle(element) : null"
          [checked]="selectionModel.isSelected(element)"   [disabled]="  !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')">
        </mat-checkbox >
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sampleLabel">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Sample
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="sampleLabel"
          displayName="Sample"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <app-cell-edit-display Element="element" ColName="sampleLabel"></app-cell-edit-display>
      <mat-cell *matCellDef="let element">
        <app-cell-edit-display
          ColValue="{{ element.sampleLabel }}"
          ColName="Sample"
          [IsEdit]="element.isEdit"></app-cell-edit-display
      ></mat-cell>
    </ng-container>
    <ng-container matColumnDef="sampleName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Sample Name
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="sampleName"
          displayName="Sample Name"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.sampleName }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="conditionLabel">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Condition
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="conditionLabel"
          displayName="Condition"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.conditionLabel }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="conditionName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Condition Name
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="conditionName"
          displayName="Condition Name"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.conditionName }}</mat-cell>
    </ng-container>
    <!-- LoD condition info -->
    <ng-container matColumnDef="lodInfo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cartridge Lot
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="cartridgeLot"
          displayName="Cartridge Lot"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.cartridgeLot }}</mat-cell>
    </ng-container>
    <!-- Precision condition info -->
    <ng-container matColumnDef="precisionInfo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Condition Info
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="precisionInfo"
          displayName="Condition Info"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.precisionInfo }}</mat-cell>
    </ng-container>
    <!-- DEC condition info -->
    <ng-container matColumnDef="decInfo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Condition Info
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="decInfo"
          displayName="Condition Info"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.decInfo }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="replicateNo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Replicate No
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="replicateNo"
          displayName="Replicate No"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.replicateNo }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="readableBarcode">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
      <span matTooltip="Set Ready to Test to see and print barcodes" [matTooltipDisabled]="currentExperiment | statusIs : ['ReadyToTest', 'Complete']">
        Readable Barcode
      </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="full-span" matTooltip="Set Ready to Test to see and print barcodes" [matTooltipDisabled]="currentExperiment | statusIs : ['ReadyToTest', 'Complete']">
          {{ (currentExperiment | statusIs : ['ReadyToTest', 'Complete']) ? element.readableBarcode : '' }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="barcodeID">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        <span matTooltip="Set Ready to Test to see and print barcodes" [matTooltipDisabled]="currentExperiment | statusIs : ['ReadyToTest', 'Complete']">
          Barcode ID
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="full-span" matTooltip="Set Ready to Test to see and print barcodes" [matTooltipDisabled]="currentExperiment | statusIs : ['ReadyToTest', 'Complete']">      
          <app-barcode-print *ngIf="(currentExperiment | statusIs : ['ReadyToTest', 'Complete'])"        
            [barcodeData]="element.barcodeID" 
            [templateType]="BarcodesTemplates.DISPLAY"
            [@barcodeFade]="barcodeDialogIsOpen"
            ></app-barcode-print>
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sampleTubeID">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Sample Tube ID </mat-header-cell>
      <mat-cell
        *matCellDef="let element; let rowIndex = index"
        id="scan-field-{{ rowIndex }}-0"
        class="scan-field"
        [class.is-duplicate]="conflictingSampleTubeDetailIds[element.detailId]">
        <app-scan-display
          matTooltip="Click to scan sample tube id
          {{
            !selectionModel.isEmpty()
              ? 'Multi-row action: ' +
                selectionModel.selected.length +
                ' row' +
                (selectionModel.selected.length == 1 ? '' : 's') +
                ' selected'
              : ''
          }}"
          matTooltipClass="scan-tooltip"
          [detail]="element"
          [detailId]="element.detailId"
          [colValue]="element.sampleTubeID"
          [currentExperiment]="currentExperiment"
          [currentRoles]="currentRoles"
          [selectedDetails]="selectionModel.selected"
          colName="Sample Tube ID"
          (onEnterEvent)="onScanComplete(rowIndex, 0)"
          (singleParsedValueEvent)="sampleTubeIdParsed($event)"
          (dialogsOpen)="multiselectDialogsOpen = $event"
          [disabled]="!selectionModel.isEmpty() && !selectionModel.isSelected(element) || !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')">
        </app-scan-display>
        <mat-icon class="duplicate-device-icon" *ngIf="!multiselectDialogsOpen">dynamic_feed</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="deviceID">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Device ID</mat-header-cell>
      <mat-cell
        *matCellDef="let element; let rowIndex = index"
        id="scan-field-{{ rowIndex }}-1"
        class="scan-field"
        [class.is-duplicate]="conflictingDeviceDetailIds[element.detailId]">
        <app-scan-display
          matTooltip="Click to scan device/disposable id
           {{
            !selectionModel.isEmpty()
              ? 'Multi-row action: ' +
                selectionModel.selected.length +
                ' row' +
                (selectionModel.selected.length == 1 ? '' : 's') +
                ' selected'
              : ''
          }}"
          matTooltipClass="scan-tooltip"
          [detail]="element"
          [detailId]="element.detailId"
          [colValue]="element.deviceID"
          [currentExperiment]="currentExperiment"
          [currentRoles]="currentRoles"
          [selectedDetails]="selectionModel.selected"
          colName="Device ID"
          (onEnterEvent)="onScanComplete(rowIndex, 1)"
          (singleParsedValueEvent)="deviceIdParsed($event)"
          (dialogsOpen)="multiselectDialogsOpen = $event"
          [disabled]="!selectionModel.isEmpty() && !selectionModel.isSelected(element)|| !(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus') ">
        </app-scan-display>
        <mat-icon class="duplicate-device-icon" *ngIf="!multiselectDialogsOpen">dynamic_feed</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="instrumentError">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        <label matTooltip="Instrument Error">Inst Error</label>
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="instrumentError"
          displayName="Instrument Error"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          matTooltip="Multi-row action: {{ selectionModel.selected.length }} rows selected."
          [matTooltipDisabled]="selectionModel.isEmpty()"
          class="instrument-error-checkbox"
          [disabled]="
            !(currentRoles | hasAnyRoles : [] : currentExperiment : 'IsReadyToTest') ||
            (currentExperiment | statusIsNot : ['ReadyToTest']) ||
            (!selectionModel.isEmpty() && !selectionModel.isSelected(element)) ||
            (element.unevaluable && !element.instrumentError)
          "
          [checked]="element.instrumentError"
          (change)="
            $event
              ? !selectionModel.isEmpty()
                ? toggleMultipleDetailsInstrumentError(element, $event)
                : toggleDetailInstrumentError(element, $event)
              : null
          "></mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="unevaluable">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        <label matTooltip="Not Evaluable">Not Eval</label> 
        <app-column-filter
          #columnFilter
          [tableName]="filterTableName"
          columnName="unevaluable"
          displayName="Unevaluable"
          [disabled]="loading"></app-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          matTooltip="Multi-row action: {{ selectionModel.selected.length }} rows selected."
          [matTooltipDisabled]="selectionModel.isEmpty()"
          class="unevaluable-checkbox"
          [disabled]="
            !(currentRoles | hasAnyRoles : [] : currentExperiment : 'IsReadyToTest') ||
            (currentExperiment | statusIsNot : ['ReadyToTest']) ||
            element.sampleIgnore ||
            element.conditionIgnore ||
            (!selectionModel.isEmpty() && !selectionModel.isSelected(element)) ||
            (element.instrumentError && !element.unevaluable)
          "
          [checked]="element.unevaluable || element.sampleIgnore || element.conditionIgnore"
          (change)="
            $event
              ? !selectionModel.isEmpty()
                ? toggleMultipleDetailsUnevaluable(element, $event)
                : toggleDetailUnevaluable(element, $event)
              : null
          "></mat-checkbox>
        <div [style.visibility]="element.sampleIgnore ? 'visible' : 'hidden'">&nbsp;S</div>
        <div [style.visibility]="element.conditionIgnore ? 'visible' : 'hidden'">&nbsp;C</div>
        <div [style.visibility]="element.unevaluable ? 'visible' : 'hidden'">&nbsp;D</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button
          mat-icon-button
          matTooltip="Add replicate of same sample and condition"
          [disabled]="
            (!selectionModel.isEmpty() && !selectionModel.isSelected(row)) ||
            !(
              (currentRoles | hasAnyRoles : ['READER'] : currentExperiment : 'IsReadyToTest') ||
              (currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsNotComplete')
            ) ||
            (currentExperiment | statusIs : ['Created','DetailsGenerated','Complete']) ||
            addingReplicate
          "
          (click)="selectionModel.selected.length ? addMultipleReplicates() : addReplicate(row)">
          <mat-icon>control_point</mat-icon>
        </button>
        <app-note-display
          [experimentId]="experimentId"
          [noteCount]="detailsNotesCountMap && detailsNotesCountMap[row.detailId]"
          [hasLiveNote]="detailsLiveNotesCountMap && detailsLiveNotesCountMap[row.detailId] > 0"
          [objectID]="row.detailId"
          [objectName]="'Detail'"
          [isDisabled]="!selectionModel.isEmpty() && !selectionModel.isSelected(row)"
          [selectedDetails]="selectionModel.selected"
          [experimentDetail]="row">
        </app-note-display>
        <mat-icon
          *ngIf="detailsAuditCountMap && detailsAuditCountMap[row.detailId] > 0"
          class="audit-count-icon"
          matTooltip="{{ detailsAuditCountMap && detailsAuditCountMap[row.detailId] }} change log records"
          matBadgeColor="warn"
          (click)="showAuditDialog(row)"
          [style.visibility]="!selectionModel.isEmpty() ? 'visible' : 'visible'">
          receipt_long
        </mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6"> No data. </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: listData != null }"></mat-footer-row>
    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }">
    </mat-footer-row>
  </mat-table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>

