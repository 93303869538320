export function parseMatches(scanValue: string) {
  const regex = /^(\d{1})(\d{2})(\d{6})(\d{6})(19|2[0-9])(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])\d*$/;
  const groups = regex.exec(scanValue);
  // [1] - Barcode Type
  // [2] - Assay Type
  // [3] - Serial Number
  // [4] - Savanna Lot Number / Sofia2 Pouched L/N
  // [5] - YY
  // [6] - MM
  // [7] - DD
  if (groups && groups[0] && groups[3]) {
    return groups[3];
  } else {
    return undefined;
  }
}
