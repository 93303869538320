<!-- Title Container -->
<mat-toolbar *ngIf="isInDialog" color="primary" class="title">
    <span>Data Insights Preview</span>
</mat-toolbar>
<div class="body-container">
    <!-- Header Actions Section -->
    <div class="view-actions">
        <button mat-raised-button color="light" (click)="addAutomatedReport()">
            Generate Report
        </button>
        <button color="light" mat-mini-fab (click)="loadAutomatedReports()"
            matTooltip="Refresh Data Insights Preview"><mat-icon>refresh</mat-icon></button>
        <div class="separator"></div>
        <mat-slide-toggle color="primary" [(ngModel)]="showArchivedAutomatedReports" (change)="setAutomatedReports()">
            <span>Show archived Data Insights Previews</span>
        </mat-slide-toggle>
    </div>
    <!-- Table Container -->
    <div class="mat-elevation-z8 table-container">
        <table mat-table #table [dataSource]="dataSource">
            <!-- AutomatedReport ID -->
            <ng-container matColumnDef="fileName">
                <th mat-header-cell *matHeaderCellDef> File Name </th>
                <td mat-cell *matCellDef="let row">
                    {{getFileName(row)}}
                    <button [style.visibility]="!row.isActive ? 'visible' : 'hidden'" mat-icon-button
                        (click)="unarchiveAutomatedReport(row)" matTooltip="Unarchive the Data Insights Preview"
                        [disabled]="!(currentRoles | hasAnyRoles : ['ADMIN', 'WRITER'] : null : 'IsAnyStatus') || !user || user.userId != row.createdBy">
                        <mat-icon>unarchive</mat-icon>
                    </button>
                </td>
            </ng-container>
            <!-- AutomatedReport createdBy -->
            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef> Created By </th>
                <td mat-cell *matCellDef="let row"> {{row.createdByName}} </td>
            </ng-container>
            <!-- AutomatedReport dateCreated -->
            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let row"> {{row.dateCreated | date : 'longDate'}} </td>
            </ng-container>
            <!-- AutomatedReport Actions -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let row">
                    <div class="actions">
                        <button mat-icon-button matTooltip="Download the Data Insights Preview"
                            [disabled]="row.status != AutomatedReportStatus.Completed"
                            (click)="downloadAutomatedReport(row)">
                            <mat-icon>download</mat-icon>
                        </button>
                        <div>
                            <button [style.visibility]="row.isActive ? 'visible' : 'hidden'" mat-icon-button
                                (click)="archiveAutomatedReport(row)" matTooltip="Archive the AutomatedReport"
                                [disabled]="!(currentRoles | hasAnyRoles : ['ADMIN', 'WRITER'] : null : 'IsAnyStatus') || !user || user.userId != row.createdBy">
                                <mat-icon>archive</mat-icon>
                            </button>
                        </div>
                        <div>
                            <button [style.visibility]="row.hasDuplicateResults ? 'visible' : 'hidden'" mat-icon-button
                                matTooltip="This report has a results duplicated">
                                <mat-icon>warning</mat-icon>
                            </button>
                        </div>
                        <div [style.visibility]="row.status == AutomatedReportStatus.Created ? 'visible' : 'hidden'">
                            <mat-progress-spinner
                                matTooltip="This report is being created. Check back in a few minutes." class="spinner"
                                mode="indeterminate" diameter="24">
                            </mat-progress-spinner>
                        </div>
                    </div>
                </td>
            </ng-container>
            <!-- AutomatedReport dailyExternalControls -->
            <ng-container matColumnDef="dailyExternalControls">
                <th mat-header-cell *matHeaderCellDef> Daily External Controls </th>
                <td mat-cell *matCellDef="let row"> {{row.dailyExternalControlExperimentId}} </td>
            </ng-container>
            <!-- AutomatedReport Actions -->
            <ng-container matColumnDef="actions2">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <!-- Footer Actions  -->
    <div class="footer">
        <div class="separator"></div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
    </div>
</div>