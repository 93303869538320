import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil, filter, switchMap, distinctUntilChanged } from 'rxjs';
import { BundleComponent } from 'src/app/bundles/bundle/bundle.component';
import { ExperimentListForBundleComponent } from 'src/app/bundles/experiment-list-for-bundle/experiment-list-for-bundle.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { Experiment, NotesCountMap, User, StudyType, StudyTypes, BundleStudyType, BundleStudyTypes, AssayName, ExperimentExtended, Bundle, ExperimentStatus } from 'src/app/services/labpartner.service.model';
import { PAGE_NAME_EXPERIMENTSLIST, EVENT_CREATE_BUNDLE } from 'src/app/services/logging-constants';
import { LoggingService } from 'src/app/services/logging.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { BundleService } from 'src/app/shared/bundle.service';
import { ExperimentService } from 'src/app/shared/experiment.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { BaseComponent } from 'src/app/support/base.component';

@Component({
    selector: 'app-select-external-control',
    templateUrl: './select-external-control.component.html',
    styleUrls: ['./select-external-control.component.scss']
})
export class SelectExternalControlComponent extends ExperimentListForBundleComponent implements OnInit, OnDestroy {
    ExperimentStatus = ExperimentStatus;
    constructor(
        public bundleService: BundleService,
        protected experimentService: ExperimentService,
        protected apiService: LabpartnerService,
        public accountService: UserAccountService,
        public appState: AppStateService,
        protected notificationService: NotificationService,
        protected loggingService: LoggingService,
        public dialogRef: MatDialogRef<BundleComponent>
    ) {
        super(
            bundleService,
            experimentService,
            apiService,
            accountService,
            appState,
            notificationService,
            loggingService,
            dialogRef
        );
    }

    // Filter out experiments that are not external controls
    filterInitialExperiments(): void {
        this.experiments = this.experiments.filter(x => {
            return x.studyType == StudyType.DailyExternalControls &&
                (x.status === ExperimentStatus.ReadyToTest || x.status === ExperimentStatus.Complete);
        });
    }

    // Add the selected experiment to the bundle
    onAdd(row: any) {
        this.bundleData.data = [row];
        this.selectedExperiments = [row];
        const experimentIds = this.selectedExperiments.map(x => x.experimentId.toString());
        this.bundleExperimentIds = experimentIds.toString();
        this.bundleService.form.patchValue({
            experimentIds: this.bundleExperimentIds,
        });
        this.bundleData._updateChangeSubscription();
    }

    // Validate the form
    isFormDisabled() {
        return this.selectedExperiments.length === 0;
    }

    // Submit the form
    onSubmit(data: any) {
        this.dialogRef.close({
            ok: true,
            data: this.selectedExperiments[0]
        });
    }
}
