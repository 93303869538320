import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ExperimentService } from 'src/app/shared/experiment.service';

import { LabpartnerService } from 'src/app/services/labpartner.service';
import { MatSort } from '@angular/material/sort';
import {
  AssayName,
  AuditedChangeResponse,
  DetailsGeneratedStatus,
  Experiment,
  ExperimentExtended,
  ExperimentType,
  NotesCountMap,
  StudyType,
  User,
} from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccountService } from 'src/app/services/user-account.service';
import { LoggingService } from 'src/app/services/logging.service';
import {
  EVENT_ARCHIVE_EXPERIMENT,
  EVENT_CHANGE_OWNER,
  EVENT_UNARCHIVE_EXPERIMENT,
  PAGE_NAME_EXPERIMENTSLIST,
} from 'src/app/services/logging-constants';
import {
  ExperimentDialogComponent,
  ExperimentDialogData,
  ExperimentDialogResult,
} from '../experiment-dialog/experiment-dialog.component';
import { NotesService } from 'src/app/services/notes-service.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { AppStateService } from 'src/app/services/app-state.service';
import { CanEditExperimentPipe } from 'src/app/pipes/can-edit-experiment.pipe';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnFilterComponent } from 'src/app/column-filter/column-filter.component';
import { ColumnFilterService, ITableColumnFilter } from 'src/app/column-filter/column-filter.service';
import { IChangeSet } from 'src/app/changes-dialog/changes-dialog.component';
import { DialogService } from 'src/app/shared/dialog.services';

@Component({
  selector: 'app-experiment-list',
  templateUrl: './experiment-list.component.html',
  styleUrls: ['./experiment-list.component.scss'],
})
export class ExperimentListComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChildren(ColumnFilterComponent) columnFilterQueryList?: QueryList<ColumnFilterComponent>;

  public experiments: Experiment[] = [];
  public objectName = 'Experiment';
  public CanCreate = false;
  public displayedColumns = [
    'name',
    'experimentId',
    'assayName',
    'protocolNumber',
    'replicates',
    'dateCreated',
    'deviceName',
    'expStatus',
    'createdByName',
    'actions',
  ];

  loading = false;

  listData!: MatTableDataSource<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  searchKey: string = '';
  devices!: any;
  status!: any;
  allExperimentNoteCounts: NotesCountMap = {};
  showArchivedExperiments: boolean = false;

  currentExperiment: Experiment | null = null;
  currentUser?: User;
  currentRoles: string[] = [];

  assayNames: AssayName[] = [];

  filterTableName: keyof ITableColumnFilter = 'experiment';

  StudyType = StudyType;

  experimentMonthFilter?: number = 6;

  fromDate: string = "";
  toDate: string = "";

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private service: ExperimentService,
    private apiService: LabpartnerService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    private notesService: NotesService,
    private dialog: MatDialog,
    private router: Router,
    private columnFilterService: ColumnFilterService,
    private notificationService: NotificationService,
    private loggingService: LoggingService,
    private canEditExperimentPipe: CanEditExperimentPipe,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) {
    super();
    this.devices = service.devices.sort(x => x.position);
    this.status = service.status;
  }

  async ngOnInit() {
    // await to make sure userlist is available
    await this.accountService.SetUserList();

    // await until the user has been (possibly created and) fetched, set in our account state
    await this.accountService.WaitUntilWeHaveUser();

    this.appState.ExperimentOpened.pipe(takeUntil(this._destroying$)).subscribe(e => {
      this.currentExperiment = e;
    });

    this.accountService.currentRoles$.pipe(takeUntil(this._destroying$)).subscribe(roles => {
      this.currentRoles = roles;
    });

    this.accountService.currentUser$.pipe(takeUntil(this._destroying$)).subscribe(user => {
      this.currentUser = user;
    });

    this.columnFilterService.tableFiltersUpdated$.pipe(takeUntil(this._destroying$)).subscribe(tableName => {
      if (tableName != 'experiment' && tableName != 'all') {
        return;
      }
      this.refreshTableFilter();
    });

    this.columnFilterService.tableFiltersReset$.pipe(takeUntil(this._destroying$)).subscribe(tableName => {
      if (tableName != 'experiment' && tableName != 'all') {
        return;
      }
      this.columnFilterService.deleteQueryListFilters(this.columnFilterQueryList!);
    });

    this.loadData();

    this.notesService.allExperimentsNotesCountMap.pipe(takeUntil(this._destroying$)).subscribe(noteCounts => {
      this.allExperimentNoteCounts = noteCounts;
    });

    this.notesService.experimentNotesCountMap.pipe(takeUntil(this._destroying$)).subscribe(noteCounts => {
      if (noteCounts && noteCounts.Experiment) {
        for (const experimentId of Object.keys(noteCounts.Experiment)) {
          this.allExperimentNoteCounts[+experimentId] =
            noteCounts.Experiment[+experimentId] ?? this.allExperimentNoteCounts[+experimentId] ?? 0;
        }
      }
    });

    this.loggingService.logPageView(PAGE_NAME_EXPERIMENTSLIST);
  }

  loadData() {
    // Keep empty toDate to get the latest value of the experiments
    this.toDate = "";

    if (this.experimentMonthFilter) {
      let date = new Date();
      date.setMonth(date.getMonth() - this.experimentMonthFilter);
      this.fromDate = date.toISOString();

    } else {
      this.fromDate = "";
    }

    this.loadExperiments();
    this.loadNoteCounts();
  }
  /**
   * Gets the note counts for all the experiments
   */
  loadNoteCounts() {
    this.subscription.add(
      this.apiService.getNoteCountsForAllExperiments(this.fromDate, this.toDate).subscribe(noteCounts => {
        let currentNoteCounts = this.notesService.allExperimentsNotesCountMap.getValue();
        this.notesService.allExperimentsNotesCountMap.next({ ...currentNoteCounts, ...noteCounts });
      })
    );
  }

  loadExperiments() {
    this.loading = true;
    this.subscription.add(
      forkJoin([
        this.apiService.getExperimentsList(this.fromDate, this.toDate),
        this.apiService.getAssayNames(false)
      ]).pipe(
        takeUntil(this._destroying$),
        finalize(() => (this.loading = false))
      ).subscribe((results) => {
        this.assayNames = results[1];
        this.setAssayName(results[0]);

        const mergedExperiments = [
          ...new Map([...this.experiments, ...results[0]].map(item => [item.experimentId, item])).values()
        ];
        this.experiments = mergedExperiments;
        for (const experiment of this.experiments as ExperimentExtended[]) {
          experiment.createdByName = this.accountService.getUserName(experiment.createdBy);
          experiment.deviceName = this.service.getDeviceName(experiment.deviceType);
          experiment.expStatus = this.service.getStatus(experiment.status);
        }
        this.route.queryParams.subscribe((params) => {
          // link from savanna
          if (params.experimentid) {
            const linkedExperiment = this.experiments.find(e => e.experimentId == params.experimentid);
            if (linkedExperiment) {
              this.onOpenExperiment(linkedExperiment);
            }
          }
        });
        this.listData = new MatTableDataSource(this.experiments);
        this.setExperiments();
        this.listData.sort = this.sort;
        this.listData.paginator = this.paginator;
        this.listData.filterPredicate = (data: any, filter: string) => {
          const tableFilters = this.columnFilterService.getActiveTableFilters('experiment');
          const filteredColumns = this.columnFilterService.getTableFilterColumns('experiment');

          const columnFilterFn = () => {
            return filteredColumns
              .map(fc => {
                return tableFilters[fc].has((data as any)[fc]);
              })
              .every(matchesFilters => matchesFilters);
          };

          const displayedColumnsFilterFn = () => {
            return this.displayedColumns.some(
              ele =>
                data[ele] != undefined && ele != 'actions' && data[ele].toString().toLowerCase().indexOf(filter) != -1
            );
          };

          if (filteredColumns.length && this.searchKey.length) {
            return columnFilterFn() && displayedColumnsFilterFn();
          } else if (filteredColumns.length && !this.searchKey.length) {
            return columnFilterFn();
          } else if (!filteredColumns.length && this.searchKey.length) {
            return displayedColumnsFilterFn();
          } else {
            return true;
          }
        };

        this.refreshTableFilter();
      })
    );
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  tableDataChanged() {
    this.columnFilterService.refreshQueryListDisplayFilters(this.columnFilterQueryList!, this.listData, this.searchKey);
  }

  onSearchClear() {
    setTimeout(() => {
      this.searchKey = '';
      this.onSearchChange('');
    });
  }

  onChangeOwner(row: Experiment, userId: number) {
    console.log('Changing experiment: ' + row.experimentId + ' to userId: ' + userId);
    const fromUserId = row.createdBy;
    this.apiService.changeExperimentOwner(userId, row.experimentId).subscribe((result: Experiment) => {
      Object.assign(row, { createdByName: this.accountService.getUserName(userId) });

      const props: { [key: string]: number } = {
        ExperimentId: result.experimentId,
        FromUserId: fromUserId,
        ToUserId: userId,
      };
      this.loggingService.logEvent(EVENT_CHANGE_OWNER, props);

      this.notificationService.success(':: Experiment owner updated successfully');
    });
  }

  onSearchChange(newVal: string) {
    this.refreshTableFilter();
  }

  onCreate() {
    const dialogConfig = new MatDialogConfig<ExperimentDialogData>();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.data = {
      isReadOnly: false,
      isEditMode: false,
      experiment: {
        experimentId: 0,
        name: '',
        assayName: '',
        protocolNumber: '',
        deviceType: 0,
        replicates: 5,
        dateCreated: new Date(),
        dateUpdated: new Date(),
        status: 0,
        type: 0,
        createdBy: 0,
        isArchived: 0,
        studyType: StudyType.ImportExcel,
        assayNameId: null
      },
    };

    const dialogRef = this.dialog.open<ExperimentDialogComponent, ExperimentDialogData, ExperimentDialogResult>(
      ExperimentDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.success) {
        let data = dialogResult.data as ExperimentExtended;
        data.createdByName = this.accountService.getUserName(data.createdBy);
        data.deviceName = this.service.getDeviceName(data.deviceType);
        data.expStatus = this.service.getStatus(data.status);
        this.setAssayName([data]);
        this.listData.data.push(data);
        this.listData._updateChangeSubscription();
      }
    });
  }

  onArchive(row: ExperimentExtended) {
    let changeSet: IChangeSet[] = [];
    changeSet.push({
      identifier: `Experiment ${row.experimentId} ${row.name}`,
      field: 'Experiment',
      oldValue: `UNARCHIVED`,
      newValue: 'ARCHIVED',
    });

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      row.type === null ? ExperimentType.ResearchAndDevelopment : row.type,
      { isDelete: false }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || row.type == ExperimentType.ResearchAndDevelopment || row.type === null)
      ) {
        this.apiService.archiveExperiment(row.experimentId, data.batchReason).subscribe(
          () => {
            this.loadData();

            this.notificationService.success('Experiment archived');

            const props: { [key: string]: string | number } = {
              ExperimentId: row.experimentId,
              DeviceType: row.deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
            };
            this.loggingService.logEvent(EVENT_ARCHIVE_EXPERIMENT, props);
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        );
      }
    });
  }

  onUnarchive(row: any) {
    let changeSet: IChangeSet[] = [];
    changeSet.push({
      identifier: `Experiment ${row.experimentId} ${row.name}`,
      field: 'Experiment',
      oldValue: `ARCHIVED`,
      newValue: 'UNARCHIVED',
    });

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      row.type === null ? ExperimentType.ResearchAndDevelopment : row.type,
      { isDelete: false }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || row.type == ExperimentType.ResearchAndDevelopment || row.type === null)
      ) {
        this.apiService.unarchiveExperiment(row.experimentId, data.batchReason).subscribe(
          () => {
            this.loadData();

            this.notificationService.success('Experiment unarchived');

            const props: { [key: string]: string | number } = {
              ExperimentId: row.experimentId,
              DeviceType: row.deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
            };
            this.loggingService.logEvent(EVENT_UNARCHIVE_EXPERIMENT, props);
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        );
      }
    });
  }

  onEdit(row: any) {
    const canEdit = this.canEditExperimentPipe.transform(this.currentUser, this.currentRoles, row);
    const currentExperiment = { ...row };

    const dialogConfig: MatDialogConfig<ExperimentDialogData> = {
      disableClose: false,
      autoFocus: true,
      width: '60%',
      data: {
        isReadOnly: !canEdit,
        isEditMode: true,
        experiment: currentExperiment,
      },
    };

    const dialogRef = this.dialog.open<ExperimentDialogComponent, ExperimentDialogData, ExperimentDialogResult>(
      ExperimentDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((dialogResult: ExperimentDialogResult | undefined) => {
      if (dialogResult?.data) {
        let existingRowIdx = this.listData.data.findIndex(
          (exp: Experiment) => exp.experimentId == dialogResult.data.experimentId
        );

        let existingRowData: ExperimentExtended = { ...row, ...dialogResult.data };
        this.setAssayName([existingRowData]);
        existingRowData.deviceName = this.service.getDeviceName(dialogResult.data.deviceType);

        this.listData.data.splice(existingRowIdx, 1, existingRowData);
        this.listData._updateChangeSubscription();
      }
    });
  }

  onOpenExperiment(exp: Experiment) {
    console.log('Opening experiment: ' + exp.experimentId);
    console.log('exp.status', exp.status);
    console.log('this.router', this.router);
    // "smart" open. take user to where we think they might want to go, based on status
    if (exp.status < DetailsGeneratedStatus) {
      this.router.navigate(['/experiment-detail', exp.experimentId, 0]);
    } else {
      this.router.navigate(['/experiment-detail', exp.experimentId, 3]);
    }
  }

  // Toggle the showArchivedExperiments flag
  showArchivedExperimentsToggle() {
    this.showArchivedExperiments = !this.showArchivedExperiments;
    this.setExperiments();
  }

  // Set the experiments to show based on the showArchivedExperiments flag
  setExperiments() {
    this.listData.data = this.showArchivedExperiments ? this.experiments : this.experiments.filter(e => e.isArchived == 0);
    this.listData._updateChangeSubscription();
  }

  private refreshTableFilter() {
    const filteredColumns = this.columnFilterService.getTableFilterColumns('experiment');
    this.listData.filter = this.searchKey.length
      ? this.searchKey.trim().toLowerCase()
      : filteredColumns.length
        ? 'custom-filter'
        : '';
  }

  // Set the assay name for the experiments
  setAssayName(experiments: Experiment[]) {
    experiments.forEach(experiment => {
      if (experiment.assayNameId) {
        experiment.assayName = this.assayNames.find(a => a.id == experiment.assayNameId)?.name + "";
      }
    });
  }
}
