import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bundleAssayTypeFormat' })

export class BundleAssayTypeFormatPipe implements PipeTransform {
  transform(value: any): string {
    if (value.bundleType == 0) {
      if ( value.assayType == null || value.assayTypeCode == undefined ) {
        return '';
      }
      return `${value.assayType.name} (${value.assayType.code})`;
    } else {
      return '';
    }
  }
}
