import { StaticColumn } from "src/app/services/labpartner.service.model";

export class ConditionColumns {
    static lodColumns: StaticColumn[] = [
        {
            prop: 'cartridgeLot',
            header: 'Cartridge Lot',
            required: true
        }
    ];

    static generalColumns: StaticColumn[] = [
        {
            prop: 'name',
            header: 'Name',
            required: true
        },
        {
            prop: 'cartridgeLot',
            header: 'Cartridge Lot'
        }

    ];

    static dailyExternalControlsColumns: StaticColumn[] = [
        {
            prop: 'cartridgeLot',
            header: 'Cartridge Lot',
            required: true
        },
        {
            prop: 'testingDate',
            header: 'TestingDate',
            required: true,
            isCustom: true,
            valueType: 'date', inputType: 'date'
        },
    ];

    static precisionColumns: any[] = [
        {
            prop: 'precisionDay',
            header: 'Precision Day',
            required: true,
            inputType: 'number'
        },
        {
            prop: 'cartridgeLot',
            header: 'Cartridge Lot',
            required: true
        },
        {
            prop: 'precisionEvent',
            header: 'Precision Event',
            required: true,
            inputType: 'number'
        },
    ];

    static intSubsColumns: any[] = [
        {
            prop: 'intSubsName',
            header: 'Interfering Substance Name',
            required: true
        },
        {
            prop: 'intSubsPartNumber',
            header: 'Interfering Substance Part Number',
            required: true
        },
        {
            prop: 'intSubsLotNumber',
            header: 'Interfering Substance Lot Number',
            required: true
        },
        {
            prop: 'intSubsActiveIngredient',
            header: 'Interfering Substance Active Ingredient',
            required: true
        },
        {
            prop: 'intSubsConcentration1x',
            header: 'Interfering Substance Concentration Value',
            isCustom: true,
            inputType: 'number',
            valueType: 'number',
            required: true,
            minValue: 0
        },
        {
            prop: 'intSubsConcentration1xUnit',
            header: 'Interfering Substance Concentration Unit',
            required: true
        }
    ];
}