import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IAudit, AuditCountMap } from './audit.service.models';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  private v1AuditingBaseURL = 'v1/Auditing';
  private apiUrlBase: string;

  public auditCountMap: Subject<AuditCountMap> = new Subject<AuditCountMap>();

  constructor(private httpClient: HttpClient, configService: ConfigurationService) {
    const config = configService.getConfig();

    this.apiUrlBase = config.apiServiceEndpoint;
    if (!this.apiUrlBase.endsWith('/')) {
      this.apiUrlBase = `${this.apiUrlBase}/`;
    }
  }

  getAuditCounts(experimentId: number, objectName?: string, objectId?: number): Observable<AuditCountMap> {
    let url = `${this.apiUrlBase}${this.v1AuditingBaseURL}/GetAuditCounts?experimentId=${experimentId}`;

    if (!!objectName) {
      url = url + `&objectName=${objectName}`;
    }

    if (!!objectId && objectId > 0) {
      url = url + `&objectId=${objectId}`;
    }

    return this.httpClient.get<AuditCountMap>(url).pipe(tap(acm => this.auditCountMap.next(acm)));
  }

  getAudits(experimentId: number, objectName?: string, objectId?: number, sortOrder?: string, forceObjectId?: boolean): Observable<IAudit[]> {
    let url = `${this.apiUrlBase}${this.v1AuditingBaseURL}/GetAuditsWithFilters?experimentId=${experimentId}`;

    if (!!objectName) {
      url = url + `&objectName=${objectName}`;
    }

    if ((!!objectId && objectId > 0) || forceObjectId) {
      url = url + `&objectId=${objectId}`;
    }

    if (forceObjectId) {
      url = url + `&forceObjectId=1`;
    }

    if (!!sortOrder) {
      url = url + `&sortOrder=${sortOrder}`;
    }

    return this.httpClient.get<IAudit[]>(url);
  }

  getAuditExport(experimentId: number): Observable<HttpResponse<Blob>> {
    let url = `${this.apiUrlBase}${this.v1AuditingBaseURL}/GetAuditExport/${experimentId}`;
    return this.httpClient.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  getAuditExportByBundle(bundleId: number): Observable<HttpResponse<Blob>> {
    let url = `${this.apiUrlBase}${this.v1AuditingBaseURL}/GetAuditExportByBundle/${bundleId}`;
    return this.httpClient.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }
}
