import { Injectable } from '@angular/core';
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, filter } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { MsalBroadcastService } from '@azure/msal-angular';
import { UserAccountService } from './user-account.service';
import { InteractionStatus } from '@azure/msal-browser';

@Injectable({
    providedIn: 'root'
})
// RolesGuard is a custom guard that checks if the user has the required roles to access a route
export class RolesGuard {

    constructor(
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        public accountService: UserAccountService,
        private router: Router
    ) { }

    // canActivate is a function that checks if the user has the required roles to access a route
    canActivate: CanActivateFn = (
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> => {
        // inProgress$ is an observable that emits the current state of the authentication request
        return this.msalBroadcastService.inProgress$.pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None || status == InteractionStatus.Startup),
            switchMap(() => {
                // account is the current user account
                const account = this.authService.instance.getAllAccounts()[0];

                // if there is no account, navigate to the noaccess route
                if (!account) {
                    this.router.navigate(['/noaccess']);
                    return of(false);
                }

                this.accountService.setUser(account);
                const userRoles = this.accountService.getRoles();
                const requiredRoles: string[] = next.data.roles;

                // if there are no required roles, allow access
                if (!requiredRoles) {
                    return of(true);
                }

                // if the user has the required roles, allow access
                if (userRoles && requiredRoles.some(role => userRoles.includes(role))) {
                    return of(true);
                }
                // if the user does not have the required roles, navigate to the noaccess route
                else {
                    console.log('RolesGuard no roles');
                    this.router.navigate(['/noaccess']);
                    return of(false);
                }
            }),
            catchError(() => {
                // if there is an error, navigate to the noaccess route
                this.router.navigate(['/noaccess']);
                return of(false)
            })
        );
    }
}
