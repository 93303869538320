import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IDetailDisplayItem } from '../detail/detail-list/detail-list.component';

@Injectable({
  providedIn: 'root',
})
export class DetailsService {
  private _currentDetails: BehaviorSubject<IDetailDisplayItem[]> = new BehaviorSubject<IDetailDisplayItem[]>([]);
  currentDetails$ = this._currentDetails.asObservable();

  setCurrentDetails(details: IDetailDisplayItem[]) {
    this._currentDetails.next(details);
  }

  getCurrentDetails() {
    return this._currentDetails.getValue();
  }

  clearCurrentDetails() {
    this._currentDetails.next([]);
  }
}
