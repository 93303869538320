import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DetailGroupedBySampleAndCondition } from 'src/app/services/labpartner.service.model';
import { PAGE_NAME_SAVANNAEXPERIMENTS } from 'src/app/services/logging-constants';
import { LoggingService } from 'src/app/services/logging.service';
import { UserAccountService } from 'src/app/services/user-account.service';

@Component({
  selector: 'app-savanna-results',
  templateUrl: './savanna-results.component.html',
  styleUrls: ['./savanna-results.component.scss'],
})
export class SavannaResultsComponent implements OnInit {
  selectedTabIndex: number = 0;
  selectedExperimentId: number = 0;
  selectedExperimentOwner: number = 0;

  experimentResults?: { data: DetailGroupedBySampleAndCondition[], dynamicColumns: string[] };

  constructor(public userAccountService: UserAccountService, private loggingService: LoggingService) { }

  ngOnInit(): void {
    this.loggingService.logPageView(PAGE_NAME_SAVANNAEXPERIMENTS);
  }

  selectedTabChanged(event: MatTabChangeEvent) {
    console.log(`tab changed: ${event.index}`);

    if (event.index < 0 || event.index > 2) {
      console.error('unexpected tab index');
      return;
    }

    this.selectedTabIndex = event.index;

    // this.trackPageView(event.index);
  }

  onExperimentSelected(evt: { expId: number; expOwner: number }): void {
    console.log(`parent, got it ${evt.expId}`);

    this.selectedExperimentId = evt.expId;
    this.selectedExperimentOwner = evt.expOwner;
    this.selectedTabIndex = 1;
  }

  onResultsLoaded(evt: { data: DetailGroupedBySampleAndCondition[], dynamicColumns: string[] }) {
    this.experimentResults = evt;
  }
}
