import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Detail, Experiment, Note } from '../services/labpartner.service.model';
import { BaseComponent } from '../support/base.component';
import { INotesDialogData, NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { LabpartnerService } from '../services/labpartner.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IDetailDisplayItem } from '../detail/detail-list/detail-list.component';
import { NotesService } from '../services/notes-service.service';
import { concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-note-display',
  templateUrl: './note-display.component.html',
  styleUrls: ['./note-display.component.scss'],
})
export class NoteDisplayComponent extends BaseComponent implements OnChanges, OnDestroy {
  @Input() noteCount?: number | null;
  @Input() experimentId: number = 0;
  @Input() objectID!: number;
  @Input() objectName!: string;
  @Input() isEditMode: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() selectedDetails: IDetailDisplayItem[] = [];
  @Input() experimentDetail?: Detail;
  @Input() hasLiveNote?: boolean;

  liveNoteCountApplied: boolean = false;

  notes?: Note[];
  liveNote?: Note;
  currentNote!: Note;

  constructor(
    private notesService: NotesService,
    private dialog: MatDialog,
    private apiService: LabpartnerService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isEditMode && !(changes.objectID || changes.objectName)) {
      return;
    }
    this.getNotes();
  }

  private getNotes() {
    if ((this.notes && this.notes.length > 0)) {
      this.currentNote = this.notes[0];
      this.noteCount = Math.max(this.noteCount ?? 0, this.notes.length);
    }
  }

  ngOnInit(): void { }

  protected ngOnDestroyInternal(): void { }

  onNote() {
    this.apiService
      .getNotesList('ObjectID', this.objectID, this.objectName)
      .pipe(
        concatMap(notes => {
          this.notes = notes;

          if (this.objectName === 'Detail') {
            return this.apiService.getNotesList('ObjectID', this.objectID, 'DetailLiveNote');
          }

          return of(undefined);
        })
      )
      .subscribe(liveNotes => {
        if (liveNotes) {
          this.liveNote = liveNotes[0];
        }

        this.openNote();
      });
  }

  getNoteCounter() {
    var noteCount = 0;
    if (this.noteCount) {
      noteCount += this.noteCount;
    }
    if (this.hasLiveNote) {
      noteCount++;
    }
    return noteCount;
  }

  private openNote() {
    this.subscription.add(
      this.apiService.getExperiment(this.experimentId)
        .subscribe((experiment: Experiment) => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.panelClass = 'add-view-notes-dialog';
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.width = '60%';
          dialogConfig.data = <INotesDialogData>{
            experimentId: this.experimentId,
            notes: this.notes,
            liveNote: this.liveNote,
            objectId: this.objectID,
            objectName: this.objectName,
            selectedDetails: this.selectedDetails,
            experimentDetail: this.experimentDetail,
            experiment: experiment
          };

          const dialogRef = this.dialog.open(NotesDialogComponent, dialogConfig);

          dialogRef.afterClosed().subscribe((dialogResult: { ok: boolean; data: any }) => {
            if (dialogResult && dialogResult.ok) {
              this.apiService
                .getNoteCountsByExperimentId(this.experimentId)
                .subscribe(experimentNoteCounts => {
                  this.notesService.experimentNotesCountMap.next(experimentNoteCounts);
                  this.getNotes();
                });
            }
          });
        })
    );
  }
}
