<div class="duplicated-container" *ngIf="!isLoading && combinedDetailsAndResultsFiltered.length > 0">
    <div class="actions-container">
        <div class="separator"></div>
        <mat-form-field name="assay-layout" class="assay-layout-form-field" *ngIf="currentExperimentId != 0">
            <mat-label for="assay-layout">Assay Layout</mat-label>
            <mat-select [(ngModel)]="selectedAssayIdRevision" (selectionChange)="initializeColumns(true)"
                matBadge="{{ assayIdRevisionGroups.length }}" [matBadgeHidden]="assayIdRevisionGroups.length <= 1">
                <mat-option *ngFor="let assayIdRevision of assayIdRevisionGroups" [value]="assayIdRevision.value">
                    {{ assayIdRevision.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="table-container">
        <div *ngFor="let detail of combinedDetailsAndResultsWithDuplicates">
            <div>
                This barcode <b>{{detail.detail.barcodeID}}</b> has results duplicated, select the correct one:
            </div>
            <p-table styleClass="savanna-results-tbl" dataKey="result.assayId" [value]="detail.otherResults"
                [columns]="selectedColumns" [loading]="isLoading">
                <ng-template pTemplate="header" let-columns>
                    <tr *ngIf="!isLoading">
                        <th class="freezable-column" pFrozenColumn>                            
                        </th>
                        <th class="freezable-column" *ngFor="let col of columns" pFrozenColumn
                            [frozen]="col.parentField == 'assay' ? false : frozenMode">
                            <ng-container *ngIf="col.parentField != 'assay'">
                                {{ col.header }}
                            </ng-container>
                            <ng-container *ngIf="col.parentField == 'assay'">
                                {{ col.header }}
                            </ng-container>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td class="freezable-column" pFrozenColumn>
                            <mat-checkbox  [checked]="rowData.isSelected" (change)="onSelectionChanged(detail,rowData)"></mat-checkbox >                               
                        </td>
                        <td class="freezable-column" *ngFor="let col of columns" pFrozenColumn
                            [frozen]="col.parentField == 'assay' ? false : frozenMode">
                            <ng-container *ngIf="col.parentField != 'assay'">
                                <ng-container *ngIf="col.field == 'barcodeID'; else tmpInstrumentError ">

                                    <ng-container
                                        *ngIf="currentExperiment?.status != VerbedExperimentStatus.IsDetailsGenerated">
                                        {{ detail.detail.barcodeID }}
                                    </ng-container>
                                </ng-container>
                                <ng-template #tmpInstrumentError>
                                    <ng-container *ngIf="col.field == 'instrumentError'; else tmpUnevalData">
                                        <mat-checkbox class="instrument-error-checkbox" [disabled]="true"
                                            [checked]="detail.detail.instrumentError">
                                        </mat-checkbox>
                                    </ng-container>
                                </ng-template>
                                <ng-template #tmpUnevalData>
                                    <ng-container *ngIf="col.field == 'unevaluable'; else tmpNotes">
                                        <div class="not-eval-container">
                                            <mat-checkbox class="unevaluable-checkbox" [disabled]="true"
                                                [checked]="detail.detail.unevaluable || detail.detail.sampleIgnore || detail.detail.conditionIgnore">
                                            </mat-checkbox>
                                            <div [style.visibility]="detail.detail.sampleIgnore ? 'visible' : 'hidden'">
                                                &nbsp;S</div>
                                            <div
                                                [style.visibility]="detail.detail.conditionIgnore ? 'visible' : 'hidden'">
                                                &nbsp;C</div>
                                            <div [style.visibility]="detail.detail.unevaluable ? 'visible' : 'hidden'">
                                                &nbsp;D</div>
                                        </div>
                                    </ng-container>
                                </ng-template>
                                <ng-template #tmpNotes>
                                    <ng-container *ngIf="col.field == 'notes'; else tmplNotBarcodeId">
                                        <!-- <app-note-display [experimentId]="currentExperimentId"
                                            [noteCount]="detailsNotesCountMap && detailsNotesCountMap[rowData.detail.detailId]"
                                            [hasLiveNote]="detailsLiveNotesCountMap && detailsLiveNotesCountMap[rowData.detail.detailId] > 0"
                                            [objectID]="rowData.detail.detailId" [objectName]="'Detail'"
                                            [experimentDetail]="rowData.detail">
                                        </app-note-display> -->
                                    </ng-container>
                                </ng-template>
                                <ng-template #tmplNotBarcodeId>
                                    {{ detail[col.parentField] && detail[col.parentField][col.field] }}
                                </ng-template>
                            </ng-container>

                            <ng-container *ngIf="col.parentField == 'assay'">
                                <ng-container *ngIf="
                  rowData != null &&
                  rowData.assayId == selectedAssayIdRevision?.assayId &&
                  rowData.assayRevision == selectedAssayIdRevision?.assayRevision &&
                  rowData.shortNameTargetMap &&
                  rowData.shortNameTargetMap[col.field]
                ">
                                    <ng-container *ngIf="validateCurrentLayout(rowData)">
                                        <ng-container
                                            [ngSwitch]="rowData.shortNameTargetMap[col.field].qualitativeResult">
                                            <ng-container *ngSwitchCase="'Positive'"
                                                [ngTemplateOutlet]="tmplPositiveResult"></ng-container>
                                            <ng-container *ngSwitchCase="'Negative'"
                                                [ngTemplateOutlet]="tmplNegativeResult"></ng-container>
                                            <ng-container *ngSwitchCase="'Invalid'"
                                                [ngTemplateOutlet]="tmplInvalidResult"></ng-container>
                                        </ng-container>
                                        <mat-icon *ngIf="rowData.shortNameTargetMap[col.field].isUnevaluable"
                                            class="unevaluable-warning  unevaluable-badge" matTooltip="Not Evaluable">
                                            warning
                                        </mat-icon>
                                    </ng-container>
                                </ng-container>
                                <!-- <ng-container
                                    *ngIf="result == null && (detail.detail.instrumentError || (detail.targetError && columnHasUnevaluable(rowData,col.field)))">
                                    <mat-icon class="unevaluable-warning" matTooltip="Not Evaluable">
                                        warning
                                    </mat-icon>
                                </ng-container> -->

                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <ng-template #tmplPositiveResult>
                <img class="result-images" src="/assets/images/result-positive.png" />
            </ng-template>

            <ng-template #tmplNegativeResult>
                <img class="result-images" src="/assets/images/result-negative.png" />
            </ng-template>

            <ng-template #tmplInvalidResult>
                <img class="result-images" src="/assets/images/result-invalid.png" />
            </ng-template>


            <div [style.visibility]="'hidden'">
                <ng-container [ngTemplateOutlet]="tmplPositiveResult"></ng-container>
                <ng-container [ngTemplateOutlet]="tmplNegativeResult"></ng-container>
                <ng-container [ngTemplateOutlet]="tmplInvalidResult"></ng-container>
            </div>
        </div>
    </div>


    <div class="actions-container">
        <div class="separator"></div>
        <button mat-raised-button color="primary" (click)="continue()">Continue</button>
    </div>
</div>