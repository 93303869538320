import { StaticColumn } from "src/app/services/labpartner.service.model";

export class AnalyteColumns {
    static lodAnalyteColumns: StaticColumn[] = [
        {
            prop: 'analyteTemplateId',
            header: 'Name',
            required: false,
            isCustom: true,
            inputType: 'select',
            options: [
                { value: 0, label: 'None' },
            ],
            valueType: 'number'
        },
        {
            prop: 'strainIsolateSubtype',
            header: 'Strain/Isolate/Subtype',
            required: true
        },
        {
            prop: 'source',
            header: 'Source',
            required: true
        },
        {
            prop: 'partNumber',
            header: 'Part Number',
            required: true
        },
        {
            prop: 'lotNumber',
            header: 'Lot Number',
            required: true
        },
        {
            prop: 'concentration1x',
            header: 'Concentration Value',
            isCustom: true,
            inputType: 'number',
            valueType: 'number',
            required: true,
            minValue: 0
        },
        {
            prop: 'concentration1xUnit',
            header: 'Concentration Unit',
            required: true
        }
    ];

    static precisionAnalyteColumns: StaticColumn[] = [
        {
            prop: 'analyteTemplateId',
            header: 'Name',
            required: false,
            isCustom: true,
            inputType: 'select',
            options: [
                { value: 0, label: 'None' },
            ],
            valueType: 'number'
        },
        {
            prop: 'strainIsolateSubtype',
            header: 'Strain/Isolate/Subtype',
            required: true
        },
        {
            prop: 'precisionSource',
            header: 'Source',
            required: true
        },
        {
            prop: 'precisionPartNumber',
            header: 'Part Number',
            required: true
        },
        {
            prop: 'precisionLotNumber',
            header: 'Lot Number',
            required: true
        },
        {
            prop: 'concentration1x',
            header: 'Concentration Value',
            isCustom: true,
            inputType: 'number',
            valueType: 'number',
            required: true,
            minValue: 0
        },
        {
            prop: 'relativeLOD',
            header: 'Relative LOD',
            required: true
        }
    ];

    static generalAnalyteColumns: StaticColumn[] = [
        {
            prop: 'analyteTemplateId',
            header: 'Name',
            required: false,
            isCustom: true,
            inputType: 'select',
            options: [
                { value: 0, label: 'None' },
            ],
            valueType: 'number'
        },
        {
            prop: 'strainIsolateSubtype',
            header: 'Strain/Isolate/Subtype',
            required: false
        },
        {
            prop: 'concentration1x',
            header: 'Concentration Value',
            isCustom: true,
            inputType: 'number',
            valueType: 'number',
            required: true,
            minValue: 0
        },
        {
            prop: 'concentration1xUnit',
            header: 'Concentration Unit',
            required: true
        }
    ];
}