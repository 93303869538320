import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as XLSX from 'xlsx';
import { AppStateService } from '../services/app-state.service';
import { Experiment, ReadyToTestStatus } from '../services/labpartner.service.model';
import { UserAccountService } from '../services/user-account.service';
import { NotificationService } from '../shared/notification.service';
import { BaseComponent } from '../support/base.component';

export interface xlsImportData {
  file: string,
  header: string[],
  data: any,
}

@Component({
  selector: 'app-xls-import',
  templateUrl: './xls-import.component.html',
  styleUrls: ['./xls-import.component.scss']
})
export class XlsImportComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() isCombinedSampleConditionImport: boolean = false;
  @Input() overrideIsImportStatus?: boolean;

  @Output() jsonChanged = new EventEmitter();
  @Output() fileChanged = new EventEmitter();

  csvData!: any;
  fileName!: string;
  IsImportStatus: boolean = true;

  constructor(public accountService: UserAccountService,
    public appState: AppStateService,
    private notificationService: NotificationService) {
    super();
  }

  ngOnInit(): void {
    // watch for event - "opened" really means state was changed (and experiment was reloaded)
    this.subscription.add(this.appState.ExperimentOpened.subscribe((exp: Experiment) => {
      this.updateImportStatus();
    }));

    this.updateImportStatus();
  }

  protected ngOnDestroyInternal(): void { }

  updateImportStatus() {
    if ((this.appState.GetCurrentExperiment()?.status ?? -1) >= ReadyToTestStatus) {
      this.IsImportStatus = this.overrideIsImportStatus ? this.overrideIsImportStatus : false;
    }
  }

  onFileChange(ev: any) {
    let workBook: XLSX.WorkBook | null = null;

    const reader = new FileReader();

    const file = ev.target.files[0] as File;
    this.fileName = file.name;

    if (!file.name.endsWith('.xlsx')) {
      return this.notifyWithErrorMessage('Uploaded file must be an XLSX file with 2 separate sheets named Samples and Conditions.');
    }

    if (this.isCombinedSampleConditionImport) {
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });

        if (workBook.SheetNames.length < 2) {
          return this.notifyWithErrorMessage('Uploaded XLSX workbook should have 2 separate sheets named Samples and Conditions.');
        }

        let samplesSheetIdx: number = -1;
        let conditionsSheetIdx: number = -1;

        for (let i = 0; i < workBook.SheetNames.length; i++) {
          switch (workBook.SheetNames[i]) {
            case 'Samples':
              samplesSheetIdx = i;
              break;
            case 'Conditions':
              conditionsSheetIdx = i;
              break;
            default:
              continue;
          }
        }

        if (samplesSheetIdx == -1) {
          return this.notifyWithErrorMessage('"Samples" sheet not found in workbook. Workbook should have 2 separate sheets named Samples and Conditions.');
        }

        if (conditionsSheetIdx == -1) {
          return this.notifyWithErrorMessage('"Conditions" sheet not found in workbook. Workbook should have 2 separate sheets named Samples and Conditions.');
        }

        const multiImportData = this.multiCsvToArray(workBook, samplesSheetIdx, conditionsSheetIdx);
        this.jsonChanged.emit(multiImportData);
      }
    } else {
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        // We assume the first sheet has data
        const sheet = workBook!.Sheets[workBook.SheetNames[0]];
        this.csvData = XLSX.utils.sheet_to_json(sheet, { blankrows: false, rawNumbers: false, raw: false });
        this.csvToArray();
      }
    }
    reader.readAsBinaryString(file);
    this.fileChanged.emit(file);
  }

  private notifyWithErrorMessage(msg: string): void {
    this.notificationService.error(msg);
    this.fileName = '';
    return;
  }

  private csvToArray() {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter

    const header = Object.keys(this.csvData[0]);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const data = this.csvData;

    let importData: xlsImportData = { file: this.fileName, header: header, data: data };
    this.jsonChanged.emit(importData);
  }

  private multiCsvToArray(workbook: XLSX.WorkBook, samplesSheetIdx: number, conditionsSheetIdx: number): { importedSamplesData: xlsImportData, importedConditionsData: xlsImportData } {
    let hasSampleRows: boolean = false;
    let hasConditionRows: boolean = false;

    const samplesSheet = workbook!.Sheets[workbook.SheetNames[samplesSheetIdx]];
    const conditionsSheet = workbook!.Sheets[workbook.SheetNames[conditionsSheetIdx]];

    const samplesJsonArray = XLSX.utils.sheet_to_json(samplesSheet, { blankrows: false, rawNumbers: false, raw: false }) as any[];
    hasSampleRows = samplesJsonArray.length > 0;

    const conditionsJsonArray = XLSX.utils.sheet_to_json(conditionsSheet, { blankrows: false, rawNumbers: false, raw: false }) as any[];
    hasConditionRows = conditionsJsonArray.length > 0;

    let samplesHeader: string[] = [];
    if (hasSampleRows) {
      samplesHeader = this.getMaxPropertyKeys(samplesJsonArray);
    }

    let conditionsHeader: string[] = [];
    if (hasConditionRows) {
      conditionsHeader = this.getMaxPropertyKeys(conditionsJsonArray);
    }

    const multiImportData: { importedSamplesData: xlsImportData, importedConditionsData: xlsImportData } = {
      importedSamplesData: { file: this.fileName, header: samplesHeader, data: samplesJsonArray },
      importedConditionsData: { file: this.fileName, header: conditionsHeader, data: conditionsJsonArray }
    };

    return multiImportData;
  }

  private getMaxPropertyKeys(jsonObject: any[]) {
    let maxPropIndex = 0;
    let maxPropCount = 0;

    for (let i = 0; i < jsonObject.length; i++) {
      const curPropCount = Object.keys(jsonObject[i]).length;
      if (maxPropCount < curPropCount) {
        maxPropCount = curPropCount;
        maxPropIndex = i;
      }
    }

    const headerKeys = Object.keys(jsonObject[maxPropIndex]);

    for (let obj of jsonObject) {
      for (let key of headerKeys) {
        if (!obj.hasOwnProperty(key)) {
          obj[key] = '';
        }
      }
    }

    return headerKeys;
  }
}
