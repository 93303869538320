<div class="search-div">
  Choose an experiment below to view results

  <span class="p-input-icon-right">
    <input
      type="text"
      pInputText
      placeholder="Search"
      autocomplete="off"
      [(ngModel)]="searchKey"
      (ngModelChange)="onChange($event)" />
    <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
      <i class="pi pi-times" *ngIf="searchKey"></i>
    </span>
  </span>
</div>

<div class="mat-elevation-z8 table-container">
  <mat-table [dataSource]="listData" matSort matSortActive="dateCreated" matSortDirection="desc">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a [routerLink]="[]" (click)="onOpenExperiment(element)" matTooltip="Show results for this experiment">{{
          element.name
        }}</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="experimentId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Exp ID</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.experimentId | experimentFormat : element.type }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="assayName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Assay Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.assayName }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="protocolNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Protocol Number</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.protocolNumber }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="replicates">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Replicate No</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.replicates }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="expStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.expStatus }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="dateCreated">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.dateCreated | date : 'shortDate' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdByName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.createdByName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6"> No data. </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.selected-experiment]="row.experimentId == selectedExperiment"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: listData != null }"></mat-footer-row>
    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }"></mat-footer-row>
  </mat-table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>

