<p-toast position="top-right"></p-toast>

<app-loader></app-loader>

<mat-toolbar
  id="main-toolbar"
  color="primary"
  class="primary-mat-toolbar"
  [ngClass]="getBannerClass()"
  *ngIf="!viewingUnauthenticatedRoute">
  <div class="titleView">
    <button *ngIf="accountService.hasValidRole()" class="menu-icon" mat-button matTooltip="Click to toggle menu options" (click)="drawer.toggle()">
      <mat-icon inline="true">menu</mat-icon>
    </button>

    <button
      id="Home"
      class="home-icon"
      matTooltip="Click to return to experiments"
      [matTooltipDisabled]="isTitlebarToolTipDisabled"
      mat-button
      (click)="goHome()">
      <mat-icon id="HomeIcon">home</mat-icon> <span> {{ titleBarName }}</span>
      <span *ngIf="!isProduction"> - {{ environmentName }}</span>
    </button>
  </div>

  <div style="text-align: center">
    <div *ngIf="currentExperiment" class="experiment-header">
      <span class="experiment-name-header">
        {{ currentExperiment.name }} ({{ currentExperiment.experimentId | experimentFormat : currentExperiment.type }})
        <button
          mat-icon-button
          class="experiment-edit-icon"
          (click)="onEdit()"
          matTooltip="{{
            (currentUser | canEditExperiment : currentRoles : currentExperiment)
              ? 'Edit the experiment'
              : 'View experiment settings'
          }}"  [disabled]="!(currentRoles | hasAnyRoles : ['WRITER','READER','VIEWER'] : currentExperiment : 'IsAnyStatus')">
          <mat-icon>{{
            (currentUser | canEditExperiment : currentRoles : currentExperiment) ? 'edit' : 'info_outline'
          }}</mat-icon>
        </button>
        <app-note-display
          [experimentId]="currentExperiment.experimentId"
          [noteCount]="experimentNoteCounts && experimentNoteCounts[currentExperiment.experimentId]"
          [objectID]="currentExperiment.experimentId"
          [objectName]="'Experiment'"
          class="experiment-note">
        </app-note-display>
        <button
          mat-icon-button
          *ngIf="(experimentAuditCount ?? 0) > 0"
          class="experiment-audit-icon"
          [class.has-notes]="experimentNoteCounts && experimentNoteCounts[currentExperiment.experimentId] > 0"
          (click)="showAuditDialog(currentExperiment)"
          matTooltip="{{ experimentAuditCount }} change log records">
          <mat-icon>receipt_long</mat-icon>
        </button>
        <button
          *ngIf="!currentExperiment.isArchived"
          mat-icon-button
          class="experiment-clone-icon"
          (click)="cloneExperiment(currentExperiment)"
          matTooltip="Clone experiment"
          [disabled]="!(currentRoles | hasAnyRoles : ['WRITER','READER'] : currentExperiment : 'IsAnyStatus')"
          >   
          <mat-icon>add_circle</mat-icon>
        </button>
      </span>
      <span id="status-bar" class="status-bar">
        Created
        <mat-icon style="color: #1bff00">check_circle</mat-icon>
        | Details Generated
        <span
          *ngIf="
            appState.GetCurrentExperiment().status < 2;
            then thenBlockStatusUnchecked;
            else elseBlockStatusChecked
          "></span>
        | Ready to Test
        <span
          *ngIf="
            appState.GetCurrentExperiment().status < 3;
            then thenBlockStatusUnchecked;
            else elseBlockStatusChecked
          "></span>
        | Complete
        <span
          *ngIf="
            appState.GetCurrentExperiment().status < 4;
            then thenBlockStatusUnchecked;
            else elseBlockStatusChecked
          "></span>
      </span>

      <ng-template #thenBlockStatusUnchecked>
        <mat-icon>radio_button_unchecked</mat-icon>
      </ng-template>

      <ng-template #elseBlockStatusChecked>
        <mat-icon style="color: #1bff00">check_circle</mat-icon>
      </ng-template>
    </div>
  </div>

  <div class="navigation-items">
    <div>
      <button mat-icon-button [matMenuTriggerFor]="helpMenu" matTooltip="Click for help menu">
        <mat-icon class="helpIcon">help_outline</mat-icon>
      </button>
      <mat-menu #helpMenu="matMenu">
        <div class="helpDropContent">
          <button mat-menu-item (click)="showInstructionVideo()">
            <mat-icon>ondemand_video</mat-icon>
            <span>User Guide Videos</span>
          </button>
          <hr />
          <button mat-menu-item (click)="showReleaseNotes()">
            <mat-icon>history</mat-icon>
            <span>Release Notes</span>
          </button>
          <a mat-menu-item href="/3rdpartylicenses.txt" target="_blank">
            <mat-icon>workspace_premium</mat-icon>
            <span>Licenses</span>
          </a>
          <hr />
          <div class="helpVersion">{{ environmentName }} - v{{ appVersion }}</div>
        </div>
      </mat-menu>
    </div>
    <div *ngIf="!loginDisplay">
      <button mat-raised-button matTooltip="Click to log in" (click)="login()">Login</button>
    </div>
    <div *ngIf="loginDisplay">
      <button mat-icon-button [matMenuTriggerFor]="profileMenu" matTooltip="Click to log out / view privileges">
        <mat-icon class="profileIcon">person_outline</mat-icon>
      </button>
      <mat-menu #profileMenu="matMenu">
        <div class="profileDropContent">
          <div>{{ displayName }}</div>
          <hr />
          
          <div *ngIf="!accountService.isViewer()" >
            <div class="roleListItem">
              Admin<mat-icon (click)="removeAdmin()" *ngIf="accountService.isAdmin()" class="check">check</mat-icon>
            </div>
            <div class="roleListItem">
              Writer<mat-icon (click)="removeWriter()" *ngIf="accountService.isWriter()" class="check">check</mat-icon>
            </div>
            <div class="roleListItem">
              Operator<mat-icon *ngIf="accountService.isReader()" class="check">check</mat-icon>
            </div>
          </div>
          <div *ngIf="accountService.isViewer()">
            <div class="roleListItem">
              Admin<mat-icon (click)="removeAdmin()" *ngIf="accountService.isAdmin()" class="check">check</mat-icon>
            </div>
            <div class="roleListItem">
              Writer<mat-icon (click)="removeWriter()" *ngIf="accountService.isWriter()" class="check">check</mat-icon>
            </div>
            <div class="roleListItem">
              Operator<mat-icon (click)="removeReader()" *ngIf="accountService.isReader()" class="check">check</mat-icon>
            </div>
            <div    class="roleListItem">
              Viewer<mat-icon  *ngIf="accountService.isViewer()" class="check">check</mat-icon>
            </div>
          </div>
          
          <hr />
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

<!-- <div id='print-header'>
  PRINTER HEADER
</div> -->
<mat-drawer-container class="sidenav-container" autosize>
  <mat-drawer #drawer class="sidenav-drawer" mode="side">
    <mat-nav-list class="sidenav-list" (click)="drawer.toggle()">
      <div class="navbutton-column">
        <button mat-button class="navbutton" (click)="navigateLabpartnerRoute()" *ngIf="accountService.hasValidRole()">Lab Partner</button>
        <button mat-button class="navbutton" (click)="navigateSavannaRoute()" *ngIf="accountService.hasValidRole()">Savanna Results</button>
        <button mat-button class="navbutton" (click)="navigateBundlesRoute()">Results Bundler</button>
        <button mat-button class="navbutton" (click)="navigateAdmin()" *ngIf="accountService.isAdmin()">Admin</button>
      </div>
    </mat-nav-list>
  </mat-drawer>

  <div class="sidenav-content">
    <main>
      <div class="content" role="main">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
      </div>
    </main>
  </div>
</mat-drawer-container>
