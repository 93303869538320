import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Bundle } from '../services/labpartner.service.model';

export const BundleTypeSerialNumbers = 0;
export const BundleTypeExperimentIds = 1;

type ExtendedBundle = {
  [P in keyof Omit<Bundle, 'bundleId'>]?: Bundle[P] | AbstractControl;
} & { $key?: number | AbstractControl };

export interface IBundleFormGroup extends UntypedFormGroup {
  value: Bundle & { $key?: number };
  controls: Record<keyof ExtendedBundle, AbstractControl>;
}

@Injectable({
  providedIn: 'root',
})
export class BundleService {
  constructor() {}

  public bundleTypes = [
    { id: BundleTypeSerialNumbers, value: 'Serial Numbers' },
    { id: BundleTypeExperimentIds, value: 'Experiment IDs' },
  ];

  form: IBundleFormGroup = new UntypedFormGroup({
    $key: new UntypedFormControl(undefined),
    name: new UntypedFormControl('', Validators.required),
    bundleType: new UntypedFormControl(0, Validators.required),
    instrumentSerialNumbers: new UntypedFormControl(''),
    experimentIds: new UntypedFormControl(''),
    assayTypeCode: new UntypedFormControl(''),
    startDate: new UntypedFormControl(undefined),
    endDate: new UntypedFormControl(undefined),
    dateCreated: new UntypedFormControl(undefined),
    createdBy: new UntypedFormControl(0),
    isClinicalTrial: new UntypedFormControl(false),
    isUsedToGenerateReport: new UntypedFormControl(false),
    bundleStudyType: new UntypedFormControl(null),
    assayNameId: new UntypedFormControl(null)
  }) as IBundleFormGroup;

  initializeFormGroup() {
    this.form.controls.startDate.clearValidators();
    this.form.controls.endDate.clearValidators();
    this.form = new UntypedFormGroup({
      $key: new UntypedFormControl(undefined),
      name: new UntypedFormControl('', Validators.required),
      bundleType: new UntypedFormControl(0, Validators.required),
      instrumentSerialNumbers: new UntypedFormControl(''),
      experimentIds: new UntypedFormControl(''),
      assayTypeCode: new UntypedFormControl(''),
      startDate: new UntypedFormControl(undefined),
      endDate: new UntypedFormControl(undefined),
      dateCreated: new UntypedFormControl(undefined),
      createdBy: new UntypedFormControl(0),
      isClinicalTrial: new UntypedFormControl(false),
      isUsedToGenerateReport: new UntypedFormControl(false),
      bundleStudyType: new UntypedFormControl(null),
      assayNameId: new UntypedFormControl(null)
    }) as IBundleFormGroup;
  }

  initializeExperimentFormGroup() {
    this.form.controls.startDate.clearValidators();
    this.form.controls.endDate.clearValidators();
    this.form.controls.bundleType.clearValidators();
    this.form = new UntypedFormGroup({
      $key: new UntypedFormControl(undefined),
      name: new UntypedFormControl('', Validators.required),
      bundleType: new UntypedFormControl(1),
      instrumentSerialNumbers: new UntypedFormControl(''),
      experimentIds: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      assayTypeCode: new UntypedFormControl(''),
      startDate: new UntypedFormControl(undefined),
      endDate: new UntypedFormControl(undefined),
      dateCreated: new UntypedFormControl(undefined),
      createdBy: new UntypedFormControl(0),
      isClinicalTrial: new UntypedFormControl(false),
      isUsedToGenerateReport: new UntypedFormControl(false),
      bundleStudyType: new UntypedFormControl(null),
      assayNameId: new UntypedFormControl(null)
    }) as IBundleFormGroup;
  }

  populateForm(bundle: Bundle) {
    this.form.setValue({
      $key: bundle.bundleId,
      name: bundle.name,
      bundleType: bundle.bundleType,
      instrumentSerialNumbers: bundle.instrumentSerialNumbers,
      experimentIds: bundle.experimentIds,
      assayTypeCode: bundle.assayTypeCode,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
      dateCreated: bundle.dateCreated,
      createdBy: bundle.createdBy,
      isClinicalTrial: bundle.isClinicalTrial,
      isUsedToGenerateReport: bundle.isUsedToGenerateReport,
      bundleStudyType: bundle.bundleStudyType,
      assayNameId: bundle.assayNameId
    } as ExtendedBundle);
  }

  public getBundleTypeNames(id: any): string {
    if (id == undefined) return 'none';
    var item = this.bundleTypes.find(x => x.id == id);
    if (item == undefined) return 'none';
    return item.value;
  }
}
