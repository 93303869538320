import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IChangeSet } from '../changes-dialog/changes-dialog.component';

import { AppStateService } from '../services/app-state.service';
import { LabpartnerService } from '../services/labpartner.service';
import {
  AuditedChangeResponse,
  Experiment,
  ExperimentType,
  FileToUpload,
  IFileToUploadDisplay,
} from '../services/labpartner.service.model';
import { EVENT_DELETE_UPLOAD, EVENT_UPLOAD_FILE } from '../services/logging-constants';
import { LoggingService } from '../services/logging.service';
import { NotesService } from '../services/notes-service.service';
import { UserAccountService } from '../services/user-account.service';
import { DialogService } from '../shared/dialog.services';
import { NotificationService } from '../shared/notification.service';
import { BaseComponent } from '../support/base.component';

// Max size 1MB * 5 for a 5MB limit
const MAX_SIZE: number = 1048576 * 5;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() experimentId: number = 0;
  @Input() currentExperiment: Experiment | null = null;
  @Input() currentRoles: string[] = [];

  public uploads!: IFileToUploadDisplay[];
  @ViewChild(MatTable) table!: MatTable<IFileToUploadDisplay>;
  @ViewChild('uploadInput') uploadInput!: ElementRef;
  listData: MatTableDataSource<any> = new MatTableDataSource();
  objectName = 'FileUpload';
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  Files: any[] = [];
  uploadingFiles: any[] = [];
  fileProcessing: boolean = false;

  public displayedColumns = ['fileName', 'fileSize', 'dateCreated', 'createdByName', 'actions'];
  // material table mat-header-cell and mat-cell requires only the property name (not the full property path)
  public propertyNames!: string[];

  // material table mat-header-row requires the full property path (e.g. data.temperature) so we build those up separately
  public propertyPaths!: string[];

  public searchKey: string = '';

  constructor(
    public apiService: LabpartnerService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    public notesService: NotesService,
    private dialogService: DialogService,
    private loggingService: LoggingService,
    private notificationService: NotificationService
  ) {
    super();
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  async ngOnInit() {
    // await to make sure userlist is available
    await this.GetUploads();
  }

  private async GetUploads() {
    // await to make sure userlist is available
    await this.accountService.SetUserList();

    // await until the user has been (possibly created and) fetched, set in our account state
    await this.accountService.WaitUntilWeHaveUser();

    const sub = this.apiService
      .getUploadsList('experimentId', this.experimentId)
      .subscribe((uploads: IFileToUploadDisplay[]) => {
        this.uploads = uploads;
        for (const upload of uploads) {
          upload.createdByName = this.accountService.getUserName(upload.createdBy);
        }
        this.listData = new MatTableDataSource(this.uploads);
        this.listData.sort = this.sort;
        this.listData.paginator = this.paginator;
        this.listData.filterPredicate = (data, filter) => {
          return this.displayedColumns.some(ele => {
            return (
              data[ele] != undefined && ele != 'actions' && data[ele].toString().toLowerCase().indexOf(filter) != -1
            );
          });
        };
      });
    this.subscription.add(sub);
  }

  onSearchClear() {
    this.searchKey = '';
    this.onChange('');
  }

  onChange(newVal: string) {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  public base64ToBlob(b64Data: string, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public onGetFile(row: FileToUpload) {
    const sub = this.apiService.downloadFile(row).subscribe((download: FileToUpload) => {
      var a = document.createElement('a');
      //Convert 64 bit string to byte array and then to blob
      var blob = this.base64ToBlob(download.fileAsBase64, download.fileType);
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = download.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
    this.subscription.add(sub);
  }

  public onDelete(row: IFileToUploadDisplay) {
    var uploadId = row.uploadId;

    let changeSet: IChangeSet[] = [];
    changeSet.push({
      identifier: `${row.fileName}`,
      field: 'Entire File',
      oldValue: `${row.fileName}`,
      newValue: 'DELETED',
    });

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      this.appState.GetCurrentExperiment().type,
      { isDelete: true }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        this.apiService.deleteUpload(this.experimentId, uploadId, data.batchReason).subscribe(
          (res: any) => {
            const index = this.listData.data.indexOf(row, 0);
            if (index > -1) {
              this.listData.data.splice(index, 1);
            }
            this.listData._updateChangeSubscription();

            this.notificationService.success('File deleted');

            const props: { [key: string]: string | number } = {
              ExperimentId: this.experimentId,
              DeletedFileName: row.fileName,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
            };
            this.loggingService.logEvent(EVENT_DELETE_UPLOAD, props);
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              this.notificationService.warn(':: Error deleting file: ' + row.fileName + ' messsage:' + err.message);
            }
          }
        );
      }
    });
  }

  onFileChange(event: any) {
    this.Files = [];

    // See if any file(s) have been selected from input
    if (event.target.files && event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
        let file = event.target.files[index];

        // Don't allow file sizes over 5MB
        if (file.size < MAX_SIZE) {
          // Set theFile property
          this.Files.push(file);
        } else {
          // Display error message
          this.notificationService.warn(':: File with name ' + file.name + ' is too large.');
        }
      }
    }

    if (this.Files.length > 0) {
      this.uploadFile();
    }
  }

  uploadFile(): void {
    this.fileProcessing = true;

    // copy files to local variable
    this.uploadingFiles = this.Files;

    // loop through files and add property complete to false
    for (let file of this.uploadingFiles) {
      Object.assign(file, { complete: false });
    }

    this.Files = [];

    // loop through files and upload
    for (let index = 0; index < this.uploadingFiles.length; index++) {
      this.readAndUploadFile(this.uploadingFiles[index]);
      const props: { [key: string]: string } = {
        ExperimentId: this.appState.GetCurrentExperiment().experimentId.toString(),
        FileUpload: this.uploadingFiles[index].name,
        DeviceType: this.appState.GetCurrentExperiment().deviceType.toString(),
      };
      this.loggingService.logEvent(EVENT_UPLOAD_FILE, props);
    }
  }

  private readAndUploadFile(file: any) {
    let fileUpload: FileToUpload = {
      uploadId: 0,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      lastModifiedTime: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      fileAsBase64: '',
      experimentId: this.experimentId,
      dateCreated: new Date(),
      createdBy: this.apiService.getLoggedInUser(),
    };

    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    let reader = new FileReader();

    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      fileUpload.fileAsBase64 = reader.result!.toString();

      if (this.uploads.filter(x => x.fileName == fileUpload.fileName).length > 0) {
        this.notificationService.warn(':: File with name ' + fileUpload.fileName + ' already exists.');
        file.complete = true;

        if (this.uploadingFiles.filter(x => x.complete == false).length == 0) {
          this.fileProcessing = false;
        }
      } else {
        // POST to server
        this.apiService.uploadFile(fileUpload).subscribe(
          (resp: any) => {
            if (resp) {
              Object.assign(fileUpload, { createdByName: this.accountService.getUserName(fileUpload.createdBy) });
            }

            this.listData.data.push(fileUpload);
            this.listData._updateChangeSubscription();

            file.complete = true;

            // loop uploadingfiles to see if all are complete
            if (this.uploadingFiles.filter(x => x.complete == false).length == 0) {
              this.notificationService.success(':: Upload Successful');
              this.fileProcessing = false;
              this.GetUploads();
            }
          },
          (err: any) => {
            this.notificationService.warn(
              ':: Error uploading file: ' + fileUpload.fileName + ' messsage:' + err.message
            );
            this.fileProcessing = false;
          }
        );
      }
    }; // reader.onload

    // Read the file
    reader.readAsDataURL(file);
  }
}
