<div *ngIf="templateType === BarcodesTemplates.AVERY_SHEET && base64Image" 
    class="container" style='text-align: center; width: 19mm; height: 19mm;' 
    >
    <img  alt='DataMatrix Barcode'
    [src]='base64Image'
    />
    <label class="upper-label">{{upperLabel}}</label>
   
    <label class="lower-label"> {{lowerLabel}}</label>
</div>

<div *ngIf="templateType === BarcodesTemplates.BARCODES && base64Image"
      class="avery-barcode"
      [class.smaller-10-barcode]="labelsPerRow == 10"
      [class.smaller-9-barcode]="labelsPerRow == 9"
      [class.smaller-8-barcode]="labelsPerRow == 8"
      [class.smaller-7-barcode]="labelsPerRow == 7"
      [class.smaller-6-barcode]="labelsPerRow == 6">
      <label class="upper-label">{{upperLabel}}</label>
      <img
        alt="DataMatrix Barcode"
        class="barcode"
        [src]="base64Image" />
        <label class="lower-label"> {{lowerLabel}}</label>
</div>

<div *ngIf="templateType === BarcodesTemplates.DISPLAY && base64Image"
    style="text-align: center; display: flex">
    <img
      alt="DataMatrix Barcode"
      [src]="base64Image" />
</div>
