import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { UserAccountService } from './services/user-account.service';
import { LoggingService } from './services/logging.service';
import { AppStateService } from './services/app-state.service';
import { Experiment, NotesCountMap, User } from './services/labpartner.service.model';
import { LabpartnerService } from './services/labpartner.service';
import { NavigationEnd, Router } from '@angular/router';
import { EVENT_CLONE_EXPERIMENT, EVENT_USER_NO_ACCESS, EVENT_USER_VERSION } from './services/logging-constants';
import { ConfigurationService } from './services/configuration.service';
import {
  ExperimentDialogComponent,
  ExperimentDialogData,
  ExperimentDialogResult,
} from './experiments/experiment-dialog/experiment-dialog.component';
import { AuditService } from './services/audit.service';
import { AuditObjectName } from './services/audit.service.models';
import { DialogService } from './shared/dialog.services';
import { ExperimentFormatPipe } from './pipes/experiment-format.pipe';
import { NotesService } from './services/notes-service.service';
import { ReleaseNotesDialogComponent } from './release-notes-dialog/release-notes-dialog.component';
import { CanEditExperimentPipe } from './pipes/can-edit-experiment.pipe';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PrimeNGConfig } from 'primeng/api';
import { ColumnFilterService } from './column-filter/column-filter.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from './shared/notification.service';


export class ApplicationType {
  static LabPartner = 'Lab Partner';
  static ResultsBundler = 'Results Bundler';
  static SavannaResults = 'Savanna Results';
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isExpanded = new Set<number>();

  title = 'Lab Partner Web';
  isIframe = false;
  loginDisplay = false;
  displayName = '';
  isProduction = false;
  environmentName = '';
  appVersion = '0.0.0.0';
  isTitlebarToolTipDisabled = false;

  public titleBarName: string = '';
  public appType: string = '';

  private readonly _destroying$ = new Subject<void>();
  currentExperiment!: Experiment;
  currentRoles: string[] = [];
  currentUser?: User;

  experimentAuditCount?: number;
  experimentNoteCounts?: NotesCountMap;

  viewingUnauthenticatedRoute: boolean = false;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    public apiService: LabpartnerService,
    public notesService: NotesService,
    private router: Router,
    private columnFilterService: ColumnFilterService,
    private loggingService: LoggingService,
    private configService: ConfigurationService,
    private auditService: AuditService,
    private matDialogService: MatDialog,
    private dialogService: DialogService,
    private experimentPipe: ExperimentFormatPipe,
    private canEditExperimentPipe: CanEditExperimentPipe,
    private primengConfig: PrimeNGConfig,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService
  ) {
    this.primengConfig.ripple = true;
    this.matIconRegistry.addSvgIcon(
      'xlsx',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/excel.svg'
      )
    );

  }

  ngOnInit(): void {
    console.log(`app version ${environment.version}`);

    this.isIframe = window !== window.parent && !window.opener;
    this.environmentName = this.configService.getConfig().environmentName;
    this.isProduction = this.environmentName.toLowerCase() === 'prod';
    this.appVersion = environment.version;

    this.router.events.subscribe(e => {
      //console.log(`ROUTER: ${e}`);
      if (
        e instanceof NavigationEnd &&
        e.url.startsWith('/mobile-barcodes') &&
        e.url.match(/\/mobile-barcodes\/[0-9]+\/[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}/)
      ) {
        this.viewingUnauthenticatedRoute = true;
      }

      if (!this.router.url.includes("/experiment-detail/")) {
        this.appState.ClearCurrentExperiment();
      }
      this.setAppNames();
    });

    this.accountService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this.accountService.currentRoles$.subscribe(roles => {
      this.currentRoles = roles;
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None || status == InteractionStatus.Startup),
        takeUntil(this._destroying$)
      )
      .subscribe((status: InteractionStatus) => {
        console.log(`msal.inProgress... ${status}`);
        this.setLoggedInUser();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        console.log('msal.msalSubject LOGIN SUCCESS');
        this.setLoggedInUser();
      });

    this.appState.ExperimentOpened.pipe(takeUntil(this._destroying$)).subscribe(async (exp: Experiment) => {
      this.currentExperiment = exp;

      this.columnFilterService.resetTableFilter('detail');

      // If we have a current experiment, we are likely in the experiment details area
      // Get granular note counts for that experiment
      if (this.currentExperiment) {
        await this.auditService.getAuditCounts(this.currentExperiment.experimentId).toPromise();
        this.apiService
          .getNoteCountsByExperimentId(this.currentExperiment.experimentId)
          .subscribe(experimentNoteCounts => {
            this.notesService.experimentNotesCountMap.next(experimentNoteCounts);
            this.experimentNoteCounts = experimentNoteCounts.Experiment;
          });
      }
    });

    this.appState.ExperimentDataChanged.pipe(takeUntil(this._destroying$)).subscribe(async (expId: number) => {
      await this.auditService.getAuditCounts(expId).toPromise();

      if (this.currentExperiment) {
        this.apiService
          .getNoteCountsByExperimentId(this.currentExperiment.experimentId)
          .subscribe(experimentNoteCounts => {
            this.notesService.experimentNotesCountMap.next(experimentNoteCounts);
            this.experimentNoteCounts = experimentNoteCounts.Experiment;
          });
        this.apiService.getExperiment(this.currentExperiment.experimentId).subscribe((experiment: Experiment) => {
          this.appState.SetCurrentExperiment(experiment);
        });
      }
    });

    this.auditService.auditCountMap.pipe(takeUntil(this._destroying$)).subscribe(acm => {
      if (this.appState.GetCurrentExperiment()) {
        const experimentId = this.appState.GetCurrentExperiment().experimentId;
        this.experimentAuditCount = acm.Experiment && acm.Experiment[experimentId];
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    console.log('destroying');
  }

  setAppNames(): void {
    var path = window.location.pathname;

    if (path.startsWith('/bundles')) {
      this.titleBarName = 'Results Bundler';
      this.appType = ApplicationType.ResultsBundler;
      this.isTitlebarToolTipDisabled = true;
    } else if (path.startsWith('/savanna-results')) {
      this.titleBarName = 'Savanna Results';
      this.appType = ApplicationType.SavannaResults;
      this.isTitlebarToolTipDisabled = true;
    } else {
      this.titleBarName = 'Lab Partner';
      this.appType = ApplicationType.LabPartner;
      this.isTitlebarToolTipDisabled = false;
    }
  }

  login(): void {
    this.authService.loginRedirect();
  }

  logout() {
    this.authService.logoutRedirect();
    this.accountService.clearUser();
  }

  removeAdmin() {
    this.accountService.removeAdmin();
  }

  removeWriter() {
    this.accountService.removeWriter();
  }

  removeReader() {
    this.accountService.removeReader();
  }

  setLoggedInUser() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.loginDisplay = true;

      const firstAccount = this.authService.instance.getAllAccounts()[0];
      console.log(`user = ${firstAccount.name}`);

      this.accountService.setUser(firstAccount);
      this.displayName = firstAccount.name!;

      // check if user has any valid roles, if app type is not ResultsBundler.
      // if not, we're outta here
      // No roles will be assigned if trying to access ResultsBundler.
      // (poor man's guard - couldn't get a real guard working with timing vs MsalGuard/login/roles)
      const roles = this.accountService.getRoles();
      if ((roles == undefined || roles.length == 0) && this.appType !== ApplicationType.ResultsBundler) {
        const props: { [key: string]: string } = { UserId: firstAccount.username };
        this.loggingService.logEvent(EVENT_USER_NO_ACCESS, props);
        this.router.navigate(['/noaccess']);
      } else {
        var props: { [key: string]: string };
        if (this.appType !== ApplicationType.ResultsBundler) {
          props = {
            UserId: firstAccount.username,
            AppVersion: this.appVersion,
            Roles: roles.join(','),
          };
        } else {
          props = {
            UserId: firstAccount.username,
            AppVersion: this.appVersion,
            Roles: '',
          };
        }
        this.loggingService.logEvent(EVENT_USER_VERSION, props);
      }
    } else {
      this.loginDisplay = false;
    }
  }

  goHome() {
    this.appState.ClearCurrentExperiment();
    if (this.appType == ApplicationType.LabPartner) {
      this.router.navigate(['/']);
    } else if (this.appType == ApplicationType.ResultsBundler) {
      this.router.navigate(['/bundles']);
    } else if (this.appType == ApplicationType.SavannaResults) {
      this.router.navigate(['/savanna-results']);
    }
  }

  showInstructionVideo() {
    const url = this.configService.getConfig().instructionVideoUrl;
    window.open(url, '_blank');
  }

  onEdit() {
    const canEdit = this.canEditExperimentPipe.transform(this.currentUser, this.currentRoles, this.currentExperiment);

    const dialogConfig: MatDialogConfig<ExperimentDialogData> = {
      disableClose: false,
      autoFocus: true,
      width: '60%',
      data: {
        isReadOnly: !canEdit,
        isEditMode: true,
        experiment: {
          ...this.currentExperiment,
          createdByName: this.accountService.getUserName(this.currentExperiment.createdBy),
        },
      },
    };

    const dialogRef = this.matDialogService.open<
      ExperimentDialogComponent,
      ExperimentDialogData,
      ExperimentDialogResult
    >(ExperimentDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((dialogResult: ExperimentDialogResult | undefined) => {
      if (dialogResult?.data) {
        this.apiService.getExperiment(dialogResult.data.experimentId).subscribe((experiment: Experiment) => {
          this.appState.SetCurrentExperiment(experiment);
        });
      }
    });
  }

  cloneExperiment(experiment: Experiment) {
    if (this.apiService.isLoading) {
      return;
    }

    this.apiService.validateSamplesAndConditions(experiment.experimentId).subscribe((validateResult: boolean) => {
      if (!validateResult) {
        this.dialogService.openAckDialog(
          'Can not clone the experiment, make sure you have created samples & conditions.'
        );
      } else {
        const dialog = this.dialogService.openConfirmDialog(
          'Confirm: clone the experiment "' + experiment.name + '" (' + experiment.experimentId + ') ?'
        );
        dialog.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            this.apiService.cloneExperiment(experiment.experimentId).subscribe((clonedExperiment: Experiment) => {
              if (clonedExperiment) {
                this.notificationService.success('Experiment cloned successfully');

                const props: { [key: string]: number } = {
                  ExperimentId: clonedExperiment.experimentId,
                  DeviceType: experiment.deviceType,
                };
                this.loggingService.logEvent(EVENT_CLONE_EXPERIMENT, props);

                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigate(["/experiment-detail/" + clonedExperiment.experimentId + "/0"]);
                });
              } else {
                this.notificationService.error('Error cloning experiment');
              }
            }, error => {
              this.notificationService.error('Error cloning experiment');
            });
          }
          else {
            this.notificationService.warn(':: Canceled');
          }
        });
      }
    });
  }

  showAuditDialog(experiment: Experiment) {
    this.dialogService.openChangelogDialog(
      `Change log for ${experiment.name} (${this.experimentPipe.transform(experiment.experimentId, experiment.type)})`,
      experiment.experimentId,
      AuditObjectName.Experiment,
      experiment.experimentId
    );
  }

  showReleaseNotes() {
    this.matDialogService.open(ReleaseNotesDialogComponent, {
      width: '800px',
      panelClass: 'release-notes-dialog-container',
      disableClose: false,
      position: { top: '10px' },
      autoFocus: false
    });
  }

  navigateBundlesRoute(): void {
    this.router.navigate(['/bundles'])
      .then(() => {
        this.titleBarName = "Results Bundler";
        this.appType = ApplicationType.ResultsBundler;
        this.isTitlebarToolTipDisabled = true;
        this.appState.ClearCurrentExperiment();
      })
  }

  navigateLabpartnerRoute(): void {
    this.router.navigate(['/'])
      .then(() => {
        this.titleBarName = "Lab Partner";
        this.appType = ApplicationType.LabPartner
        this.isTitlebarToolTipDisabled = false;
        this.appState.ClearCurrentExperiment();
      })
  }

  navigateSavannaRoute(): void {
    this.router.navigate(['/savanna-results'])
      .then(() => {
        this.titleBarName = "Savanna Results";
        this.appType = ApplicationType.SavannaResults;
        this.isTitlebarToolTipDisabled = true;
        this.appState.ClearCurrentExperiment();
      })
  }

  navigateAdmin(): void {
    this.router.navigate(['/admin/assays'])
      .then(() => {
        this.titleBarName = "Manage Assays";
        this.appType = ApplicationType.LabPartner;
        this.isTitlebarToolTipDisabled = false;
        this.appState.ClearCurrentExperiment();
      })
  }

  getBannerClass(): string {
    if (this.isProduction) return "";
    return this.environmentName.toLowerCase() === 'validated'
      ? 'banner-validated' : 'banner-non-prod';
  }
}
