<div class="device-target-error-channel-dialog-header">
    <mat-toolbar id="dialog-titlebar" color="primary">
        <div *ngIf="isEntireCartridgeError">Not Evaluable Entire cartridge </div>
        <div *ngIf="!isEntireCartridgeError">Not Evaluable Chamber</div>
    </mat-toolbar>
</div>
<div class="dialog-container">
    <ng-container *ngIf="isEntireCartridgeError">
        <span>Selecting the {{CHAMBERS_NUM}} chambers means that the entire cartridge is</span>
        <span>Not Evaluable. The system will check the checkbox for</span>
        <span>the entire cartridge instead.</span>
    </ng-container>
    <ng-container *ngIf="!isEntireCartridgeError">
        <span>Selecting the {{CHANNELS_NUM}} channels from the same</span>
        <span>chamber means that the whole chamber is</span>
        <span>Not Evaluable. The system will check the checkbox for</span>
        <span>the whole chamber instead.</span>
    </ng-container>
    <div class="controls">
        <button mat-raised-button color="primary" (click)="close()">Ok</button>
    </div>
</div>