<div class="dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary" mat-dialog-title> New Column </mat-toolbar>
</div>
<div mat-dialog-content class="dialog-content">
  <form [formGroup]="addColumnForm" (submit)="onSubmit()" class="add-column-form">
    <mat-form-field class="column-name-input">
      <mat-label>Column Name</mat-label>
      <input matInput class="column-name-input" formControlName="columnName" />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!addColumnForm.valid">Submit</button>
</div>
