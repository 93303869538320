import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Galleria } from 'primeng/galleria';
import { finalize } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';

import { LabpartnerService } from 'src/app/services/labpartner.service';
import { LoggingService } from 'src/app/services/logging.service';
import { BaseComponent } from 'src/app/support/base.component';
import { EVENT_VIEW_BARCODE_LINK } from '../services/logging-constants';

@Component({
  selector: 'app-mobile-barcodes',
  templateUrl: './mobile-barcodes.component.html',
  styleUrls: ['./mobile-barcodes.component.scss'],
})
export class MobileBarcodesComponent extends BaseComponent implements OnInit {
  @ViewChild('galleria') galleria?: Galleria;

  barcodes: any[] = [];
  images: any[] = [];
  linkError: boolean = false;
  noBarcodesError: boolean = false;
  fullscreen: boolean = false;
  activeIndex: number = 0;
  onFullScreenListener: any;

  private startPos?: {
    x: number;
    y: number;
  } = undefined;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigurationService,
    private apiService: LabpartnerService,
    private loggingService: LoggingService
  ) {
    super();
  }

  ngOnInit(): void {
    const apiEndpoint = this.configService.getConfig().apiServiceEndpoint;
    const experimentId = +this.route.snapshot.params.experimentId;
    const linkIdentifier = `${this.route.snapshot.params.linkIdentifier}`;
    const newImages: any[] = [];

    const props: { [key: string]: number | string | null } = {
      ExperimentId: experimentId,
      LinkIdentifier: linkIdentifier,
      InvalidLink: 1,
    };

    if (experimentId && linkIdentifier) {
      this.apiService
        .getBarcodesByIdentifier(+experimentId, linkIdentifier)
        .pipe(
          finalize(() => {
            this.loggingService.logEvent(EVENT_VIEW_BARCODE_LINK, props);
          })
        )
        .subscribe(
          res => {
            props.InvalidLink = 0;
            props.DeviceType = res.deviceType;

            if (res.isExpired) {
              props.ExpiredLink = 1;
            }

            // This happens when a valid link yields no barcodes...either they were deleted/replaced or the experiment has none
            if (res.readableBarcodes && res.readableBarcodes.length == 1 && res.readableBarcodes[0] == null) {
              this.noBarcodesError = true;
            } else {
              props.BarcodeCount = res.readableBarcodes.length;

              res.readableBarcodes.forEach(readableBarcode => {
                this.barcodes.push(readableBarcode);

                newImages.push({
                  previewImageSrc: `${apiEndpoint}v1/Barcode/DataMatrix/${readableBarcode}?pixelSize=12`,
                  thumbnailImageSrc: `${apiEndpoint}v1/Barcode/DataMatrix/${readableBarcode}`,
                  alt: readableBarcode,
                  title: readableBarcode.slice(7),
                  header: readableBarcode.slice(0, 6),
                });
              });

              this.images = newImages;
              this.galleria!.numVisible = 1;
            }
          },
          (err: any) => {
            this.linkError = true;
          }
        );
    } else {
      this.loggingService.logEvent(EVENT_VIEW_BARCODE_LINK, props);
    }

    this.bindDocumentListeners();
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
    this.unbindDocumentListeners();
  }

  navForward(evt: Event) {
    this.changePageOnTouch(null, -1);

    if (evt && evt.cancelable) {
      evt.preventDefault();
    }
  }

  navBackward(evt: Event) {
    this.changePageOnTouch(null, 1);

    if (evt && evt.cancelable) {
      evt.preventDefault();
    }
  }

  onTouchEnd(evt: TouchEvent) {
    let touchobj = evt.changedTouches[0];
    if (this.startPos) {
      this.changePageOnTouch(evt, touchobj.pageX - this.startPos.x);
    }
  }

  onTouchMove(evt: TouchEvent) {
    if (evt.cancelable) {
      evt.preventDefault();
    }
  }

  onTouchStart(evt: TouchEvent) {
    let touchobj = evt.changedTouches[0];

    this.startPos = {
      x: touchobj.pageX,
      y: touchobj.pageY,
    };
  }

  changePageOnTouch(evt: any, diff: any) {
    if(!this.galleria || !this.galleria.value) return;

    if (diff < 0) {
      let nextItemIndex = this.activeIndex + 1;
      let activeIndex =
        this.galleria?.circular && this.galleria.value.length  - 1 === this.activeIndex ? 0 : nextItemIndex;
      this.galleria?.onActiveItemChange(activeIndex);
    }

    if (diff > 0) {
      let prevItemIndex = this.activeIndex !== 0 ? this.activeIndex - 1 : 0;
      let activeIndex =
        this.galleria?.circular && this.activeIndex === 0 ? this.galleria?.value.length - 1 : prevItemIndex;
      this.galleria?.onActiveItemChange(activeIndex);
    }
  }

  toggleFullScreen() {
    if (this.fullscreen) {
      this.closePreviewFullScreen();
    } else {
      this.openPreviewFullScreen();
    }
  }

  openPreviewFullScreen() {
    let elem = this.galleria?.element.nativeElement.querySelector('.p-galleria');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem['mozRequestFullScreen']) {
      /* Firefox */
      elem['mozRequestFullScreen']();
    } else if (elem['webkitRequestFullscreen']) {
      /* Chrome, Safari & Opera */
      elem['webkitRequestFullscreen']();
    } else if (elem['msRequestFullscreen']) {
      /* IE/Edge */
      elem['msRequestFullscreen']();
    }
  }

  onFullScreenChange() {
    this.fullscreen = !this.fullscreen;
  }

  closePreviewFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any)['mozCancelFullScreen']) {
      (document as any)['mozCancelFullScreen']();
    } else if ((document as any)['webkitExitFullscreen']) {
      (document as any)['webkitExitFullscreen']();
    } else if ((document as any)['msExitFullscreen']) {
      (document as any)['msExitFullscreen']();
    }
  }

  bindDocumentListeners() {
    this.onFullScreenListener = this.onFullScreenChange.bind(this);
    document.addEventListener('fullscreenchange', this.onFullScreenListener);
    document.addEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenListener);
    document.addEventListener('msfullscreenchange', this.onFullScreenListener);
  }

  unbindDocumentListeners() {
    document.removeEventListener('fullscreenchange', this.onFullScreenListener);
    document.removeEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.removeEventListener('webkitfullscreenchange', this.onFullScreenListener);
    document.removeEventListener('msfullscreenchange', this.onFullScreenListener);
    this.onFullScreenListener = null;
  }
}
