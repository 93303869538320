import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User } from '../services/labpartner.service.model';
import { UserAccountService } from '../services/user-account.service';

@Component({
  selector: 'app-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss']
})
export class UserDisplayComponent implements OnChanges {
  @Input() userId!: number;
  userName: string = "Unknown User";

  constructor(private accountService: UserAccountService) { }

  ngOnChanges(changes: SimpleChanges): void {
    let users = this.accountService.getUsers();
    this.findUser(users);

  }

  findUser(users: User[]) {
    const found = users.find(u => u.userId == this.userId);
    if (found != null) {
      this.userName = found.displayName;
    }
  }

  ngOnInit(): void {
  }

}
