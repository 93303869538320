import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDetailDisplayItem } from '../detail/detail-list/detail-list.component';
import { AppStateService } from '../services/app-state.service';

import { LabpartnerService } from '../services/labpartner.service';
import { Detail, Experiment, ExperimentType, Note } from '../services/labpartner.service.model';
import { EVENT_ADD_NOTE, EVENT_MULTISELECT_DETAILS } from '../services/logging-constants';
import { LoggingService } from '../services/logging.service';
import { UserAccountService } from '../services/user-account.service';
import { DialogService } from '../shared/dialog.services';
import { NotificationService } from '../shared/notification.service';
import { BaseComponent } from '../support/base.component';

export interface INotesDialogData {
  experimentId: number;
  notes: Note[];
  liveNote: Note;
  objectId: number;
  objectName: string;
  selectedDetails: IDetailDisplayItem[];
  experimentDetail?: Detail;
  experiment: Experiment;
}

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
})
export class NotesDialogComponent extends BaseComponent implements OnInit {
  @ViewChild('liveNoteContent', { static: false }) liveNoteContent!: ElementRef;

  liveNote: Note | null = null;
  addMode = false;
  hideSideNav = false;
  notes: Note[] = [];

  noteTitle: string = '';
  noteBody: string = '';

  notesList: Note[] = [];

  searchKey: string = '';
  filteredList: Note[] = [];

  objectName: string = "";

  selectedIndexTab = 0;
  ignoreTabChange: boolean = false;

  experimentDetail?: Detail;
  experimentId?: number;
  experiment: Experiment;
  currentExperiment!: Experiment;
  currentRoles: string[] = [];

  isSaving: boolean = false;

  constructor(
    private appState: AppStateService,
    private dialogRef: MatDialogRef<NotesDialogComponent>,
    private apiService: LabpartnerService,
    private accountService: UserAccountService,
    private notificationService: NotificationService,
    private loggingService: LoggingService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: INotesDialogData
  ) {
    super();
    this.objectName = this.data.objectName;
    this.experimentId = this.data.experimentId;
    this.experiment = this.data.experiment;
  }

  ngOnInit(): void {
    this.addNoteHandler();

    if (this.data.selectedDetails.length > 0) {
      this.notes = [];
      this.notesList = [];
      this.filteredList = [];
      this.hideSideNav = true;
    } else {
      this.notes = this.data.notes;
      this.liveNote = this.data.liveNote;
      this.notesList = this.notes;
      this.filteredList = this.notes;

      if (this.data.notes.length == 0) {
        this.hideSideNav = true;
      }
      if (this.data.objectName === 'Detail') {
        this.hideSideNav = false;
        this.experimentDetail = this.data.experimentDetail;
        if (this.liveNote) {
          this.liveNote.createdByName = this.accountService.getUserName(this.liveNote.createdBy);
        }
      }
      this.notesList.forEach((note: Note) => {
        note.createdByName = this.accountService.getUserName(note.createdBy);
        note.selected = false;
      });
    }

    this.accountService.currentRoles$.subscribe(roles => {
      this.currentRoles = roles;
    });

  }

  protected ngOnDestroyInternal(): void {
    this.subscription.unsubscribe();
  }

  onClickNote(note: Note) {
    if (this.addMode && (this.noteTitle.length > 0 || this.noteBody.length > 0)) {
      const dialog = this.dialogService.openConfirmDialog(
        'Do you want to view the selected note data? Current edits will be overwritten'
      );
      dialog.afterClosed().subscribe(res => {
        if (res) {
          this.noteTitle = note.noteTitle;
          this.noteBody = note.noteText;
          this.addMode = false;
          this.updateSelectedNote(note);
        }
      });
    } else {
      this.noteTitle = note.noteTitle;
      this.noteBody = note.noteText;
      this.addMode = false;
      this.updateSelectedNote(note);
    }
  }

  onSearchChange(newVal: string) {
    if (this.notesList && this.notesList.length > 0) {
      this.filteredList = this.notesList.filter((note: Note) => {
        if (newVal.length === 0 || note.noteTitle.indexOf(newVal) > -1 || note.noteText.indexOf(newVal) > -1) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredList = [];
    }
  }

  saveOneTimeNote() {
    if (this.data.selectedDetails.length == 0) {
      this.apiService
        .createNote(this.data.experimentId, this.data.objectId, this.data.objectName, this.noteTitle, this.noteBody)
        .subscribe(
          (result: Note) => {
            this.notificationService.success(':: Note created successfully');
            let dialogresult = { ok: true, data: result };
            this.dialogRef.close(dialogresult);
            this.appState.SetExperimentDataChanged(this.experimentId!);
          },
          (err: any) => {
            this.notificationService.warn(':: Error creating note');
            console.log(err);
            let dialogresult = { ok: false, data: err };
            this.dialogRef.close(dialogresult);
          }
        );
    }

    if (this.data.selectedDetails.length > 0) {
      const confirmResult = this.dialogService.openConfirmDialog(
        `This action will add this note to ${this.data.selectedDetails.length} selected Detail${this.data.selectedDetails.length == 1 ? '' : 's'
        }` + ', are you sure you want to continue?'
      );

      confirmResult.afterClosed().subscribe(confirmed => {
        if (!confirmed) {
          this.notificationService.info(':: Cancelled bulk operation');
          this.isSaving = false;
          return;
        }

        this.apiService
          .createMultipleNotes(
            this.data.experimentId,
            this.data.selectedDetails.map(d => d.detailId),
            this.noteTitle,
            this.noteBody
          )
          .subscribe(
            res => {
              if (res.ok) {
                this.notificationService.success(':: Notes created successfully');
                this.dialogRef.close({ ok: true });
                this.appState.SetExperimentDataChanged(this.experimentId!);
                const multiselectProps: { [key: string]: number | string } = {
                  ExperimentId: this.data.experimentId,
                  DeviceType: this.experiment.deviceType,
                  ExperimentOwner: this.experiment.createdBy ?? 0,
                  MultiselectAction: EVENT_ADD_NOTE,
                  ItemsSelected: this.data.selectedDetails.length,
                };
                this.loggingService.logEvent(EVENT_MULTISELECT_DETAILS, multiselectProps);
              } else {
                this.isSaving = false;
              }
            },
            (err: any) => {
              this.notificationService.warn(':: Error creating notes');
              console.log(err);
              this.dialogRef.close({ ok: false });
            }
          );
      });
    }
  }

  async saveLiveNote() {
    if (this.data.selectedDetails.length == 0) {
      let shouldOpenDialog = true;
      if (!this.liveNote) {
        shouldOpenDialog = false;
        this.liveNote = {} as Note;
      }


      if (shouldOpenDialog) {
        const dialogRef = this.dialogService.openConfirmChangesDialog(
          [
            {
              identifier: `Detail ${this.experimentDetail?.sampleLabel} ${this.experimentDetail?.conditionLabel} ${this.experimentDetail?.replicateNo}`,
              field: 'LiveNoteEdit',
              oldValue: this.liveNote?.noteTitle + " " + this.liveNote?.noteText,
              newValue: this.noteTitle + " " + this.noteBody

            },
          ],
          this.experiment.type == null ? ExperimentType.ResearchAndDevelopment : this.experiment.type,
          { isDelete: false }
        );

        let data = await dialogRef.afterClosed().toPromise()

        if (
          !(data?.submitClicked &&
            (data?.reasonProvided || this.experiment.type == ExperimentType.ResearchAndDevelopment || this.experiment.type === null))
        ) {
          this.isSaving = false;
          return
        };
      }

      this.liveNote.noteText = this.noteBody;
      this.liveNote.noteTitle = this.noteTitle;


      this.apiService
        .createNote(this.data.experimentId, this.data.objectId, this.data.objectName + 'LiveNote', this.noteTitle, this.noteBody)
        .subscribe(
          (result: Note) => {
            if (this.liveNote)
              this.liveNote.createdByName = this.accountService.getUserName(result.createdBy);
            this.notificationService.success(':: Live Note created/edited successfully');
            let dialogresult = { ok: true, data: result };
            this.dialogRef.close(dialogresult);
            this.appState.SetExperimentDataChanged(this.experimentId!);
          },
          (err: any) => {
            this.notificationService.warn(':: Error creating/editing live note');
            console.log(err);
            let dialogresult = { ok: false, data: err };
            this.dialogRef.close(dialogresult);
          }
        );
    }
  }
  saveNoteHandler() {
    this.isSaving = true;
    if (this.selectedIndexTab == 0) {
      this.saveOneTimeNote();
    }
    else if (this.selectedIndexTab == 1) {
      this.saveLiveNote();
    }
  }

  addNoteHandler() {
    this.addMode = true;
    this.noteTitle = '';
    this.noteBody = '';
  }

  closeOneTimeNote() {
    if ((this.noteTitle.length > 0 || this.noteBody.length > 0) && this.addMode) {
      const dialog = this.dialogService.openConfirmDialog('Are you sure you want to close without saving this note?');
      dialog.afterClosed().subscribe(res => {
        if (res) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  closeLiveNote() {
    if ((this.noteTitle.length > 0 || this.noteBody.length > 0) && this.addMode && (this.liveNote?.noteText != this.noteBody || this.liveNote?.noteTitle != this.noteTitle)) {
      const dialog = this.dialogService.openConfirmDialog('Are you sure you want to close without saving this note?');
      dialog.afterClosed().subscribe(res => {
        if (res) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  closeNoteHandler() {
    if (this.selectedIndexTab == 0) {
      this.closeOneTimeNote();
    }
    else if (this.selectedIndexTab == 1) {
      this.closeLiveNote();
    }

  }

  onTabChange() {
    if (this.selectedIndexTab == 1 && !this.ignoreTabChange) {
      if (this.addMode && (this.noteTitle.length > 0 || this.noteBody.length > 0)) {
        const dialog = this.dialogService.openConfirmDialog(
          'Do you want to view the selected note data? Current edits will be overwritten'
        );
        dialog.afterClosed().subscribe(res => {
          if (res) {
            this.noteTitle = this.liveNote ? this.liveNote.noteTitle : "";
            this.noteBody = this.liveNote ? this.liveNote.noteText : "";
            this.addMode = true;
          } else {
            this.selectedIndexTab = 0;
            this.ignoreTabChange = true;
          }
        });
      } else {
        this.noteTitle = this.liveNote ? this.liveNote.noteTitle : "";
        this.noteBody = this.liveNote ? this.liveNote.noteText : "";
        this.addMode = true;
      }
    }
    else if (this.selectedIndexTab == 0 && !this.ignoreTabChange) {
      if (this.noteTitle != this.liveNote?.noteTitle || this.noteBody != this.liveNote.noteText) {
        const dialog = this.dialogService.openConfirmDialog(
          'Do you want to leave? Current edits will be lost'
        );
        dialog.afterClosed().subscribe(res => {
          if (res) {
            this.noteTitle = "";
            this.noteBody = "";
            this.addMode = true;
            this.notes.forEach(n => n.selected = false);
            this.filteredList.forEach(x => x.selected = false);
          } else {
            this.selectedIndexTab = 1;
            this.ignoreTabChange = true;
          }
        });
      } else {
        this.noteTitle = "";
        this.noteBody = "";
        this.addMode = true;
        this.notes.forEach(n => n.selected = false);
        this.filteredList.forEach(x => x.selected = false);
      }
    }
    this.ignoreTabChange = false;
  }

  checkOverflow() {
    if (!this.liveNoteContent || !this.liveNoteContent.nativeElement) return false;

    let el = this.liveNoteContent.nativeElement;

    let isOverflowing = el.clientWidth < el.scrollWidth
      || el.clientHeight < el.scrollHeight;

    return isOverflowing;
  }

  private updateSelectedNote(note: Note) {
    this.notesList = this.notesList.map(n => {
      return n.noteId == note.noteId ? { ...n, selected: true } : { ...n, selected: false };
    });
    this.filteredList = this.filteredList.map(n => {
      return n.noteId == note.noteId ? { ...n, selected: true } : { ...n, selected: false };
    });
  }
}
