import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuditDisplayedKeysWithNames, IAudit } from '../services/audit.service.models';

export interface IAuditDialogData {
  title: string;
  auditData: IAudit[];
  displayedColumns: AuditDisplayedKeysWithNames;
}

export interface IAuditDialogResult {
  okClicked: boolean;
}

@Component({
  selector: 'audit-dialog',
  templateUrl: 'audit-dialog.component.html',
  styleUrls: ['audit-dialog.component.scss'],
})
export class AuditDialogComponent implements OnInit {
  rowDef = Object.keys(this.data.displayedColumns);

  expandedRow: any;

  afterViewInit = false;

  constructor(
    public dialogRef: MatDialogRef<AuditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAuditDialogData
  ) {}

  ngOnInit(): void {}

  onClickOk(): void {
    this.dialogRef.close(<IAuditDialogResult>{
      okClicked: true,
    });
  }
}
