<mat-toolbar id="dialog-titlebar" mat-dialog-title color="primary">
  <span *ngIf="bundleId == null">Create Bundle</span>
  <span *ngIf="bundleId != null">Edit Bundle (ID: {{ bundleId }})</span>
</mat-toolbar>

<form
  #theForm
  [formGroup]="service.form"
  class="normal-form"
  (submit)="onSubmit(service.form.value)"
  >
  <p-blockUI [blocked]="isCurtainBlocked"></p-blockUI>
  <div class="bundle-controls-container">
    <div class="bundle-controls-inputs" mat-dialog-content>
      <div class="bundle-controls-column">
        <input type="hidden" formControlName="$key" />
        <input type="hidden" formControlName="dateCreated" />
        <input type="hidden" formControlName="createdBy" />

        <mat-form-field>
          <input formControlName="name" matInput placeholder="Bundle Name" required />
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>

        <mat-form-field>
          <input formControlName="instrumentSerialNumbers" matInput placeholder="Comma Separated Instrument S/N" />
        </mat-form-field>

        <mat-form-field *ngIf="selectedBundleTypeId == 0 && showClinicalTrial">
          <input hidden="true" matInput />
          <mat-checkbox
            color="primary"
            [checked]="isClinicalTrialChecked"
            [disabled]="disableClinicalTrialCheckBox"
            (change)="toggleClinicalStatus($event)">
            Clinical Trial
          </mat-checkbox>
          <mat-hint *ngIf="isClinicalTrialChecked && !disableClinicalTrialCheckBox" id="ClinicalWarning">
            Cannot change after clicking OK
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="bundle-controls-column">
        <mat-form-field appearance="fill">
          <mat-label>Assay Type</mat-label>
          <mat-select formControlName="assayTypeCode" placeholder="Assay Type">
            <mat-option *ngFor="let typeCode of assayTypeCodes" value="{{ typeCode.code }}">
              {{ typeCode | assayTypeCodeFormat }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="padding-right: 10px">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="startPicker" formControlName="startDate" placeholder="Start Date" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" style="padding-right: 10px">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="endPicker" formControlName="endDate" placeholder="End Date" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="bundle-action-buttons" mat-dialog-actions>
      <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="service.form.invalid">Ok</button>
    </div>
  </div>

  <div style="height: 20px; margin-top: 10px">
    <div *ngIf="!serialsAreValid" class="multi-field-validation-error alert">
      Must provide Instrument Serial Numbers (comma-separated numbers and letters)
    </div>
  </div>

  <div style="height: 20px; margin-top: 10px; margin-bottom: 10px">
    <div *ngIf="!datesAreValid" class="multi-field-validation-error alert">
      Must be valid dates and End Date > Start Date
    </div>
  </div>
</form>
