import { Pipe, PipeTransform } from '@angular/core';
import { CompleteStatus, Experiment, User } from '../services/labpartner.service.model';
import { ROLE_NAMES } from '../services/user-account.service';

@Pipe({ name: 'canEditExperiment' })
export class CanEditExperimentPipe implements PipeTransform {
  constructor() {}

  transform(user?: User, currentRoles?: string[], experiment?: Experiment): boolean {
    if (!experiment || !currentRoles) {
      return false;
    }

    const isAdmin = currentRoles.indexOf(ROLE_NAMES.ADMIN) != -1;
    if (isAdmin) {
      return true;
    }

    const isWriter = currentRoles.indexOf(ROLE_NAMES.WRITER) != -1;
    if (isWriter && user && experiment.status != CompleteStatus) {
      return true;
    }

    return false;
  }
}
