import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SavannaConfigService } from '../services/savanna-config.service';

export interface CanSavannaConfigDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SavannaConfigCanDeactivateGuard  {
  constructor(private savannaConfigService: SavannaConfigService) {}

  canDeactivate(component: CanSavannaConfigDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let confirmResponse = false;
    if (this.savannaConfigService.hasPendingChanges()) {
      confirmResponse = confirm(`There are unsaved Savanna configuration changes, are you sure you want to continue?`);
    }
    return confirmResponse || !this.savannaConfigService.hasPendingChanges();
  }
}
