import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { PAGE_NAME_PRINTAVERYSHEET } from 'src/app/services/logging-constants';
import { LoggingService } from 'src/app/services/logging.service';
import { BaseDetailListPrintFormatsComponent } from '../detail-list-print/BaseDetailListPrintFormats';
import { SelectItem } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-detail-list-print-avery',
  templateUrl: './detail-list-print-avery.component.html',
  styleUrls: ['./detail-list-print-avery.component.scss'],
})
export class DetailListPrintAveryComponent extends BaseDetailListPrintFormatsComponent implements OnInit {  
  skipRowOptions: SelectItem[] = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
  ];

  selectedSkipRowOption: number = 0;
  get skipRows() {
    return [...Array(this.selectedSkipRowOption).keys()];
  }

  private _defaultLabelsPerPage = 80;

  constructor(
    apiService: LabpartnerService, 
    route: ActivatedRoute, 
    loggingService: LoggingService,
    public notificationService: NotificationService
  ) {
    super(apiService, route, loggingService, notificationService);

    this.LABELS_PER_ROW = 8;
    this.LABELS_PER_PAGE = this._defaultLabelsPerPage;
    this.LABELS_ON_FIRST_PAGE = this._defaultLabelsPerPage;
  }

  ngOnInit(): void {
    this.OnInit(PAGE_NAME_PRINTAVERYSHEET);
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  onSkipRowChange(evt: DropdownChangeEvent) {
    this.LABELS_ON_FIRST_PAGE = this._defaultLabelsPerPage - this.LABELS_PER_ROW * evt.value;
  }
}
