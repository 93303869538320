<div class="container" [formGroup]="formGroup">
    <mat-toolbar id="dialog-titlebar" color="primary">
        Conditions generation
    </mat-toolbar>
    <div class="body-container">
        <mat-radio-group formControlName="testingDaysSelect" (change)="testingDaysSelectChanged()">
            <div class="row-container">
                <mat-radio-button class="label" [value]="1">Testing Days</mat-radio-button>
                <mat-form-field>
                    <input matInput formControlName="testindDays" type="number" min="1"/>
                </mat-form-field>
                <button mat-mini-fab color="primary" class="info-button" matTooltip="Number of the days generated consecutively, if you enter 10, the combination will be: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10"><mat-icon>info</mat-icon></button>
            </div>
            <div class="row-container">
                <mat-radio-button class="label" [value]="2">Specific Days</mat-radio-button>
                <mat-form-field>
                    <input matInput type="text" formControlName="testingSpecificDays" />
                </mat-form-field>
                <button mat-mini-fab color="primary" class="info-button" matTooltip="Combinations generated for specific days or range days (it works as the printer) if you enter: 2-5, 7, 11, the combinations days for conditions should be: 2, 3, 4, 5, 7, 11"><mat-icon>info</mat-icon></button>
            </div>
        </mat-radio-group>

        <div class="row-container">
            <div class="label">
                <span>Cartridge lots</span>
                <span class="required">*</span>
            </div>
            <mat-form-field>
                <input matInput formControlName="cartridgeLotsNumber" type="number" min="1" (change)="cartridgeLotsChanged()" />
            </mat-form-field>
        </div>
        
        <div class="cartridges-container" formArrayName="cartridgeLots">
            <div class="row-container" *ngFor="let lot of cartridgeLots.controls; let i = index">
                <div class="label">
                    Cartridge lot {{i+1}}
                </div>
                <mat-form-field>
                    <input matInput [formControlName]="i" required />
                </mat-form-field>
            </div>
        </div> 

        <div class="row-container">
            <div class="label">
                <span>Events</span>
                <span class="required">*</span>
            </div>
            <mat-form-field>
                <input matInput formControlName="events" type="number" min="1" />
            </mat-form-field>
        </div>
    </div>
    <div class="separator"></div>
    <div class="actions-container">
        <button mat-raised-button (click)="onCancel()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="formGroup.invalid" (click)="onClose()">Generate rows for conditions</button>
    </div>
</div>