<div class="no-print">
  <mat-form-field>
    <input
      matInput
      name="columnNumber"
      type="number"
      min="1"
      max="10"
      placeholder="Number of Columns"
      autocomplete="off"
      [errorStateMatcher]="errorMatcher"
      (keydown)="onColumnNumberKeydown($event)"
      [(ngModel)]="numberOfColumns"
      (ngModelChange)="onColumnNumberChange($event)" />
    <mat-error>Please enter a value between 1 and 10.</mat-error>
  </mat-form-field>
  <button mat-raised-button class="print-btn" (click)="print()">
    <mat-icon>print</mat-icon>
    Print
  </button>
</div>
<div class="print-container" [style.visibility]="isLoading? 'hidden': 'visible'">
  <ng-container *ngFor="let barcode of barcodes; let itemIndex = index">
      <app-barcode-print 
        [barcodeData]="barcode" 
        (onLoaded)="onBarcodeLoaded($event)" 
        [labelsPerRow]="labelsPerRow" 
        [templateType]="BarcodesTemplates.BARCODES" >
      </app-barcode-print>
    <div class="avery-row-spacer" *ngIf="isLastInRow(itemIndex + 1) && !isLastOnPage(itemIndex + 1)"></div>
    <div class="pagebreak" *ngIf="isLastOnPage(itemIndex + 1)"></div>
  </ng-container>
</div>
