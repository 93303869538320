<div>
  <div class="content-container">
    <mat-icon id="close-icon" (click)="closeDialog()">close</mat-icon>
    <span class="content-span full-width">{{data.message}}</span>
  </div>
  <ng-container *ngIf="!data.isOk">
    <button mat-flat-button id="no-button" [mat-dialog-close]="false">NO</button>
    <button mat-flat-button id="yes-button" [mat-dialog-close]="true">YES</button>
  </ng-container>
  <ng-container *ngIf="data.isOk">
    <button mat-flat-button id="ok-button" [mat-dialog-close]="true">OK</button>
  </ng-container>
</div>