import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

interface csvControl {
    name: string,
    label: string,
    value: string,

}

@Component({
  selector: 'app-csv-form',
  templateUrl: './csv-form.component.html',
  styleUrls: ['./csv-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CsvFormComponent implements OnChanges {
  @Input() csvData!: any;
  
  CsvControls!:csvControl[];

  constructor(private fb: UntypedFormBuilder) { }

  public csvForm: UntypedFormGroup = this.fb.group({});

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.csvData.firstChange)
    {
        this.createForm(this.csvData.headers, this.csvData.Rows, this.csvData.Id);
    }
  }

  createForm(headers: string, rowdata: string, recordId:number)
  {
      let headerArray = headers.split(',');
      for(const item in headerArray) {
        this.csvForm.addControl(item,this.fb.control(''));
      }
  }

  onSubmit() {

  }
}
