import { StaticColumn } from "src/app/services/labpartner.service.model";

export class SampleColumns {
  // LOD columns
  static lodColumns: StaticColumn[] = [
    {
      prop: 'name',
      header: 'Sample Name',
      required: true
    },
    {
      prop: 'matrix',
      header: 'Sample Matrix',
      required: true
    },
    {
      prop: 'matrixLotNumber',
      header: 'Sample Matrix Lot Number',
      required: true
    },
    {
      prop: 'prepEvent',
      header: 'Sample Prep Event',
      required: true
    }
  ];

  static generalColumns: StaticColumn[] = [
    {
      prop: 'name',
      header: 'Sample Name',
      required: true
    }
  ];

  // Daily External Controls columns
  static dailyExternalControlsColumns: StaticColumn[] = [
    {
      prop: 'name',
      header: 'Sample Name'
    },
    {
      prop: 'externalControlType',
      header: 'External Control Type',
      required: true,
      isCustom: true,
      inputType: 'select',
      options: [
        { value: 'true', label: 'Positive' },
        { value: 'false', label: 'Negative' }
      ],
      valueType: 'boolean'
    },
    {
      prop: 'externalControlPartNumber',
      header: 'External Control Part Number',
      required: true
    },
    {
      prop: 'externalControlLotNumber',
      header: 'External Control Lot Number',
      required: true
    }
  ];

  // Precision columns
  static precisionColumns: StaticColumn[] = [
    {
      prop: 'name',
      header: 'Sample Name',
      required: true
    },
    {
      prop: 'precisionMatrix',
      header: 'Sample Matrix',
      required: true
    },
    {
      prop: 'precisionMatrixLotNumber',
      header: 'Sample Matrix Lot Number',
      required: true
    },
    {
      prop: 'precisionPrepEvent',
      header: 'Sample Prep Event',
      required: true
    }
  ];

  // Interfering Substance columns
  static intSubsColumns: StaticColumn[] = [
    {
      prop: 'name',
      header: 'Sample Name',
      required: true
    },
    {
      prop: 'intSubsMatrix',
      header: 'Sample Matrix',
      required: true
    },
    {
      prop: 'intSubsMatrixLotNumber',
      header: 'Sample Matrix Lot Number',
      required: true
    },
    {
      prop: 'intSubsPrepEvent',
      header: 'Sample Prep Event',
      required: true
    }
  ];
}