<div class="logistics-dialog-header">
  <mat-toolbar id="dialog-titlebar" color="primary">
    <div>Create/Edit Logistics</div>
  </mat-toolbar>
</div>
<form [formGroup]="logisticForm" class="logistics-form" (submit)="onSubmit(logisticForm)">
  <div mat-dialog-content class="logistics-dialog-content">
    <div class="p-float-label">
      <input
        id="logistic-name"
        type="text"
        aria-describedby="logistic-name-required"
        pInputText
        formControlName="name" />
      <label for="logistic-name">Name*</label>
    </div>
    <small id="logistic-name-required" class="p-error" *ngIf="logisticForm.controls.name.errors?.required">
      This field is mandatory.
    </small>
    <div class="p-float-label">
      <input id="logistic-value" type="text" pInputText formControlName="value" />
      <label for="logistic-value">Value</label>
    </div>
  </div>
  <div mat-dialog-actions class="logistics-dialog-actions">
    <button mat-raised-button color="warn" type="button" (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="logisticForm.invalid">Ok</button>
  </div>
</form>
