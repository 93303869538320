import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forEach } from 'cypress/types/lodash';

@Component({
    selector: 'app-conditions-precision-generation',
    templateUrl: './conditions-precision-generation.component.html',
    styleUrls: ['./conditions-precision-generation.component.scss']
})
export class ConditionsPrecisionGenerationComponent {
    formGroup: FormGroup;

    get cartridgeLots(): FormArray {
        return this.formGroup.get('cartridgeLots') as FormArray;
    }

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        public dialogRef: MatDialogRef<ConditionsPrecisionGenerationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // Create the form
        this.formGroup = this.fb.group({
            testingDaysSelect: [this.data.testingDaysSelect],
            testindDays: [this.data.testindDays],
            testingSpecificDays: [this.data.testingSpecificDays],
            cartridgeLotsNumber: [this.data.cartridgeLotsNumber, [Validators.required, Validators.min(1), Validators.max(99)]],
            cartridgeLots: fb.array(this.data.cartridgeLots.map((cartridgeLot: string) => fb.control(cartridgeLot, [Validators.required]))),
            events: [this.data.events, [Validators.required, Validators.min(1)]]
        });

        this.testingDaysSelectChanged();
        this.cartridgeLotsChanged();
    }

    // Validate the form
    testingDaysSelectChanged() {
        // Clear the validators
        this.formGroup.controls.testindDays.clearValidators();
        this.formGroup.controls.testingSpecificDays.clearValidators();
        // Enable the inputs
        this.formGroup.controls.testindDays.enable();
        this.formGroup.controls.testingSpecificDays.enable();

        if (this.formGroup.controls.testingDaysSelect.value == 1) {
            // If the user selects the first option, the second input is disabled and the first input is enabled
            this.formGroup.controls.testindDays.addValidators([Validators.required, Validators.min(1), Validators.max(99)]);
            this.formGroup.controls.testingSpecificDays.disable();
        } else {
            // If the user selects the second option, the first input is disabled and the second input is enabled
            this.formGroup.controls.testingSpecificDays.addValidators([Validators.minLength(1), Validators.required, Validators.pattern(/^(\d+(-\d+)?)(,\s*\d+(-\d+)?)*$/)]);
            this.formGroup.controls.testindDays.disable();
        }
        // Update the form
        this.formGroup.controls.testindDays.updateValueAndValidity();
        this.formGroup.controls.testingSpecificDays.updateValueAndValidity();
    }



    // Add or remove cartridge lots
    cartridgeLotsChanged() {
        let cartridgeLotsNumber = this.cartridgeLots.controls.length;
        if (cartridgeLotsNumber < this.formGroup.controls.cartridgeLotsNumber.value) {
            // If the user selects a higher number of cartridge lots, add the missing inputs
            let diff = this.formGroup.controls.cartridgeLotsNumber.value - cartridgeLotsNumber;
            for (let i = 0; i < diff; i++) {
                this.cartridgeLots.push(this.fb.control("", [Validators.required]));
            }
        }
        else if (this.formGroup.controls.cartridgeLotsNumber.value < cartridgeLotsNumber) {
            // If the user selects a lower number of cartridge lots, remove the extra inputs
            let diff = cartridgeLotsNumber - this.formGroup.controls.cartridgeLotsNumber.value;
            for (let i = 0; i < diff; i++) {
                this.cartridgeLots.removeAt(this.cartridgeLots.length - 1);
            }
        }
    }

    // Save the form data   
    onClose() {
        this.dialogRef.close(this.formGroup.getRawValue());
    }

    // Close the dialog
    onCancel() {
        this.dialogRef.close();
    }
}
