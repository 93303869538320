import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { Experiment } from '../services/labpartner.service.model';

export interface ExperimentFormGroup extends UntypedFormGroup {
  value: Experiment;

  controls: {
    $key: AbstractControl;
    name: AbstractControl;
    assayName: AbstractControl;
    protocolNumber: AbstractControl;
    deviceType: AbstractControl;
    replicates: AbstractControl;
    dateCreated: AbstractControl;
    status: AbstractControl;
    type: AbstractControl;
    createdBy: AbstractControl;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ExperimentService {

  constructor() { }

  // Note: if modifying devices mappings here, ensure they match GetExperimentDetails.sql and AuditExport.sql in the DB stored procedures
  public devices = [
    { position: 0, id: 0, value: 'none', typeCode: 'U' },   // Note: Maps to Unknown in ResultsAPI
    { position: 1, id: 7, value: 'Sofia1', typeCode: 'AA'}, // Note: Position 1 but ID of 7, intentional
    { position: 2, id: 1, value: 'Sofia2', typeCode: 'SL'},
    { position: 3, id: 2, value: 'Savanna', typeCode: 'WC' },
    { position: 4, id: 3, value: 'Sofia Q', typeCode: 'SQ'},
    { position: 5, id: 4, value: 'Solana', typeCode: 'FF' },
    { position: 6, id: 5, value: 'Triage', typeCode: '' },
    { position: 7, id: 6, value: 'QuickVue', typeCode: 'QV' }
  ]

  // Note: if modifying status mappings here, ensure they match GetExperimentDetails.sql and AuditExport.sql in the DB stored procedures
  public status = [
    {id: 0, value: 'unknown'},
    {id: 1, value: 'Created'},
    {id: 2, value: 'Details Generated'},
    {id: 3, value: 'Ready To Test'},
    {id: 4, value: 'Completed'},
  ]

  form: ExperimentFormGroup = new UntypedFormGroup({
    $key: new UntypedFormControl(null),
    name: new UntypedFormControl('', Validators.required),
    assayName: new UntypedFormControl('',Validators.required),
    protocolNumber: new UntypedFormControl('',Validators.required),
    deviceType: new UntypedFormControl(0, Validators.required),
    replicates: new UntypedFormControl(5, Validators.required),
    dateCreated: new UntypedFormControl(''),
    status: new UntypedFormControl(0),
    type: new UntypedFormControl(0),
    createdBy: new UntypedFormControl(0),

  }) as ExperimentFormGroup;

  initializeFormGroup() {
    this.form.setValue({
      $key: null,
      name: '',
      assayName: '',
      protocolNumber: '',
      deviceType: 0,
      replicates: 5,
      dateCreated: '',
      status: 0,
      type: 0,
      createdBy: 0,
    });
  }

  populateForm(experiment: Experiment) {
    this.form.setValue({
      $key: experiment.experimentId,
      name: experiment.name,
      assayName: experiment.assayName,
      protocolNumber: experiment.protocolNumber,
      deviceType: experiment.deviceType,
      replicates: experiment.replicates,
      dateCreated: experiment.dateCreated,
      status: experiment.status,
      type: experiment.type,
      createdBy: experiment.createdBy,
    });
  }

  public getDeviceName(id: any): string {
    if (id == undefined) return "none";
    var item = this.devices.find(x => x.id == id);
    if (item == undefined) return "none";
    return item.value;
  }
  public getStatus(id: number): string {
    if (id == undefined) return "unknown";
    var item = this.status.find(x => x.id == id);
    if (item == undefined) return "unknown";
    return item.value;
  }
}
