import { Injectable, EventEmitter } from '@angular/core';
import { Experiment } from './labpartner.service.model';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  public ExperimentOpened: EventEmitter<Experiment> = new EventEmitter<Experiment>();
  public ExperimentDataChanged: EventEmitter<number> = new EventEmitter<number>();

  private currentExperiment!: Experiment;

  constructor() {}

  public SetCurrentExperiment(exp: Experiment) {
    this.currentExperiment = exp;
    this.ExperimentOpened.emit(exp);
  }

  public SetExperimentDataChanged(expId: number) {
    this.ExperimentDataChanged.emit(expId);
  }

  public ClearCurrentExperiment() {
    this.ExperimentOpened.emit(undefined);
  }

  public GetCurrentExperiment(): Experiment {
    return this.currentExperiment;
  }
}
